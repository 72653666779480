import { Navigate, Route, Routes } from 'react-router-dom';

import EditManagedReseller from './edit-managed-reseller';
import EditEmployee from './employees/edit-employee';
import EmployeesIndex from './employees/employees-index';
import NewEmployee from './employees/new-employee';
import NewManagedReseller from './new-managed-resseller';
import Root from './root';

import AdminBillingPage from '../kal-admin/billing/billing-index';
import ResellerDetails from '../kal-admin/resellers/reseller-details/route';
import ResellersIndex from '../kal-admin/resellers/resellers-index';
import AccountSwitch from '../kal-admin/resellers/switch/route';

export const BASE_SLUG = 'reseller-manager';

export default function KalAdminRoutes() {
  return (
    <Routes>
      <Route element={<Root />}>
        <Route index element={<Navigate to="resellers" />} />
        <Route path="employees" element={<EmployeesIndex />}>
          <Route path="new" element={<NewEmployee />} />
          <Route path=":employeeId/edit" element={<EditEmployee />} />
        </Route>
        <Route path="resellers" element={<ResellersIndex />}>
          <Route path="new" element={<NewManagedReseller />} />
          <Route path=":resellerId/login-as" element={<AccountSwitch />} />
        </Route>
        <Route path="resellers/:resellerId" element={<ResellerDetails />}>
          <Route path="edit" element={<EditManagedReseller />} />
        </Route>
        <Route path="billing" element={<AdminBillingPage />} />
        <Route
          path="*"
          element={
            <div className="mx-auto flex h-full max-w-md items-center justify-center">
              <div className="text-center font-prompt">
                <h1 className="text-5xl font-bold text-brand-primary">404</h1>
                <p className="text-2xl text-brand-primary">Not found</p>
              </div>
            </div>
          }
        />
      </Route>
    </Routes>
  );
}
