import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { useEffect, useRef } from 'react';
import { Save, XCircle } from 'react-feather';
import { useNavigate } from 'react-router';

import ResellerForm from '../kal-admin/resellers/features/ResellerForm';

import { Button } from 'components/ui/button';
import { getErrorMessage, isMutationSuccess } from 'services/api/base-api';
import { useCreateResellerMutation } from 'services/api/resellers/endpoints';

export default function NewManagedReseller() {
  const navigate = useNavigate();
  const [createReseller, mutation] = useCreateResellerMutation();

  return (
    <Modal
      onClose={() => navigate('..', { replace: true })}
      isOpen
      overrides={{
        Dialog: {
          props: {
            className: 'max-w-3xl w-full',
          },
        },
      }}
    >
      <ModalHeader className="text-xl font-semibold">
        Add New Reseller
      </ModalHeader>
      <ModalBody>
        <MutationErrorBanner
          error={mutation.error}
          show={mutation.isError}
          focusOnError
        />
        <ResellerForm
          id="admin-new-reseller-form"
          initialValues={{ allowParentResellerCreation: false }}
          allowedRepresentatives={['ParentResellerAdmin']}
          onSubmit={async (data) => {
            const result = await createReseller({
              isEnabled: true,
              businessName: data.resellerAccountName,
              accountRep: data.accountRepresentative,
              mainContactName: data.operationalContactName,
              mainContactEmail: data.email,
              mainContactPhone: data.phoneNumber,
              billingContactName: data.operationalContactName,
              billingContactEmail: data.email,
              billingContactPhone: data.phoneNumber,
              firstName: data.firstName,
              lastName: data.lastName,
              password: data.password,
              address: {
                ...data.resellerAddress,
                street2: data.resellerAddress.street2 ?? '',
              },
              billingAddress: {
                ...data.billingAddress,
                street2: data.billingAddress.street2 ?? '',
              },
              subdomain: data.subdomain || null,
              domain: data.domain || null,
              parentReseller: data.isParentReseller,
            });
            if (isMutationSuccess(result)) {
              navigate('..');
            }
          }}
        />
      </ModalBody>
      <ModalFooter className="space-x-4 pb-5">
        <Button href=".." linkOptions={{ replace: true }} variant="plain">
          Cancel
        </Button>
        <Button
          startEnhancer={<Save className="h-full w-full" />}
          form="admin-new-reseller-form"
          type="submit"
          isLoading={mutation.isLoading}
        >
          Save Reseller
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function MutationErrorBanner({
  error,
  focusOnError,
  show = true,
}: {
  error: FetchBaseQueryError | SerializedError | undefined;
  focusOnError?: boolean;
  show?: boolean;
}) {
  const bannerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (show && focusOnError) {
      bannerRef.current?.focus();
    }
  }, [show, focusOnError]);

  if (!show) return null;

  return (
    <div className="mb-4 p-4">
      <div
        className="flex rounded-md bg-red-50 p-5"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        role="alert"
        ref={bannerRef}
      >
        <div className="flex-shrink-0">
          <XCircle className="h-6 w-6 text-red-500" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-semibold text-red-800">
            Failed to create a Reseller.
          </h3>
          <div className="mt-2 text-sm font-medium text-red-700">
            {error ? getErrorMessage(error) : ''}
          </div>
        </div>
      </div>
    </div>
  );
}
