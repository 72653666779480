import { mergeOverrides } from 'baseui';
import { Tag } from 'baseui/tag';
import type { TagProps, TagVariant } from 'baseui/tag';
import clsx from 'clsx';

import { WorkRequestStatus } from 'services/api/work-requests/types';
import { useLocale } from 'utils/hooks/useLocale';

export const WORK_REQUEST_STATUS_LABELS: Record<WorkRequestStatus, string> = {
  Created: 'Created',
  EstCreated: 'Pending',
  EstScheduled: 'Scheduled',
  EstStarted: 'Started',
  EstCompleted: 'Completed',
  EstSent: 'Sent',
  EstApproved: 'Approved',
  EstDenied: 'Denied',
  Scheduled: 'Scheduled',
  Started: 'In Progress',
  Completed: 'Completed',
  InvoiceCreated: 'Unsent',
  InvoiceSent: 'Sent',
  Collected: 'Paid',
  Cancelled: 'Cancelled',
  Archived: 'Archived',
};

const SHARED_COLORS = {
  pending: {
    solid: {
      text: 'text-white',
      background: 'bg-yellow-400',
    },
    light: {
      text: 'text-yellow-500',
      background: 'bg-yellow-50',
    },
  },
  completed: {
    solid: {
      text: 'text-white',
      background: 'bg-green-600',
    },
    light: {
      text: 'text-green-600',
      background: 'bg-green-50',
    },
  },
  failed: {
    solid: {
      text: 'text-white',
      background: 'bg-red-600',
    },
    light: {
      text: 'text-red-600',
      background: 'bg-red-50',
    },
  },
};

type CustomTagVariant = Exclude<TagVariant, 'outlined'>;
export const WORK_REQUEST_STATUS_COLORS: Record<
  WorkRequestStatus,
  Record<CustomTagVariant, { text: string; background: string }>
> = {
  Created: {
    solid: {
      text: 'text-blue-600',
      background: 'bg-blue-50',
    },
    light: {
      text: 'text-blue-600',
      background: 'bg-blue-50',
    },
  },
  EstCreated: {
    solid: SHARED_COLORS.pending.solid,
    light: SHARED_COLORS.pending.light,
  },
  EstScheduled: {
    solid: SHARED_COLORS.pending.solid,
    light: SHARED_COLORS.pending.light,
  },
  EstStarted: {
    solid: SHARED_COLORS.pending.solid,
    light: SHARED_COLORS.pending.light,
  },
  EstCompleted: {
    solid: SHARED_COLORS.completed.solid,
    light: SHARED_COLORS.completed.light,
  },
  EstSent: {
    solid: SHARED_COLORS.pending.solid,
    light: SHARED_COLORS.pending.light,
  },
  EstApproved: {
    solid: SHARED_COLORS.completed.solid,
    light: SHARED_COLORS.completed.light,
  },
  EstDenied: {
    solid: SHARED_COLORS.failed.solid,
    light: SHARED_COLORS.failed.light,
  },
  Scheduled: {
    solid: SHARED_COLORS.pending.solid,
    light: SHARED_COLORS.pending.light,
  },
  Started: {
    solid: SHARED_COLORS.pending.solid,
    light: SHARED_COLORS.pending.light,
  },
  Completed: {
    solid: SHARED_COLORS.completed.solid,
    light: SHARED_COLORS.completed.light,
  },
  InvoiceCreated: {
    solid: SHARED_COLORS.pending.solid,
    light: SHARED_COLORS.pending.light,
  },
  InvoiceSent: {
    solid: SHARED_COLORS.pending.solid,
    light: SHARED_COLORS.pending.light,
  },
  Collected: {
    solid: SHARED_COLORS.completed.solid,
    light: SHARED_COLORS.completed.light,
  },
  Cancelled: {
    solid: SHARED_COLORS.failed.solid,
    light: SHARED_COLORS.failed.light,
  },
  Archived: {
    solid: SHARED_COLORS.pending.solid,
    light: SHARED_COLORS.pending.light,
  },
};

export default function StatusBadge(
  props: { status: WorkRequestStatus; variant?: CustomTagVariant } & Omit<
    TagProps,
    'variant'
  >,
) {
  const { status, variant = 'light', ...tagProps } = props;
  const { t, isLocaleReady } = useLocale('glossary', { useSuspense: false });

  if (!isLocaleReady) return null;

  return (
    <Tag
      closeable={false}
      variant={variant}
      {...tagProps}
      overrides={mergeOverrides(
        {
          Root: {
            props: {
              className: clsx(
                'flex justify-center rounded-md border-none text-center',
                WORK_REQUEST_STATUS_COLORS[status][variant].text,
                WORK_REQUEST_STATUS_COLORS[status][variant].background,
              ),
            },
          },
        },
        tagProps.overrides,
      )}
    >
      {t(`status.${WORK_REQUEST_STATUS_LABELS[status]}`, { ns: 'glossary' })}
    </Tag>
  );
}
