import { zodResolver } from '@hookform/resolvers/zod';
import type {
  CheckboxOverrides,
  SharedStyleProps as CheckboxSharedProps,
} from 'baseui/checkbox';
import { StatefulCheckbox, StyledToggle } from 'baseui/checkbox';
import { DatePicker } from 'baseui/datepicker';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { FilePlus, User } from 'react-feather';
import { ControllerRenderProps, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from 'components/Button';
import { FormController } from 'components/FormController';
import { Image } from 'components/Image';
import { Input } from 'components/Input';
import SkillsSelect from 'features/SkillsSelect';
import { skillschema } from 'services/api/skills/types';
import { useLocale } from 'utils/hooks/useLocale';

const nonEmptyString = z
  .string()
  .trim()
  .min(1, { message: 'errors.invalid_type_received_undefined' });
const baseSchema = z.object({
  image: z
    .object({
      file: z.instanceof(FileList, { message: 'Required' }).optional(),
      dataStoreId: z.number().optional().default(0),
    })
    .catch({ file: undefined, dataStoreId: 0 }),
  firstname: nonEmptyString,
  lastname: nonEmptyString,
  phone: nonEmptyString,
  email: nonEmptyString.email(),
  password: nonEmptyString,
  isTechnician: z.boolean().optional().default(false),
  // assignedCrew: z.number().array(),
  skills: skillschema.array(),
  licenseNumber: z.string().optional(),
  licenseExpiration: z.coerce.date().optional(),
});

const schema = baseSchema.superRefine((data, ctx) => {
  if (data.licenseNumber && !data.licenseExpiration) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['licenseExpiration'],
      message: 'errors.invalid_type_received_undefined',
    });
  }
  if (!data.licenseNumber && data.licenseExpiration) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['licenseNumber'],
      message: 'errors.invalid_type_received_undefined',
    });
  }
});

type FormFields = z.infer<typeof schema>;

export default function FSAdminEmployeeForm({
  initialValue = {},
  onSubmit: onSubmitHandler,
  id = 'fs-employee-form',
  type = 'CREATE',
}: {
  type?: 'CREATE' | 'UPDATE';
  id?: string;
  initialValue?: Partial<FormFields>;
  onSubmit: (data: FormFields) => void;
}) {
  const { control, handleSubmit } = useForm<FormFields>({
    resolver: zodResolver(schema),
    defaultValues: {
      ...initialValue,
      password:
        type === 'UPDATE'
          ? // This is to pass validation
            '0'
          : '',
    },
  });

  const onSubmit = handleSubmit(onSubmitHandler);
  const { t } = useLocale();

  return (
    <form id={id} className="grid grid-cols-2 gap-x-4" onSubmit={onSubmit}>
      <FormController
        control={control}
        name="image"
        overrides={{ Root: { props: { className: 'col-span-full' } } }}
        defaultValue={{
          dataStoreId: 0,
          file: undefined,
        }}
      >
        {({ field: { ref, ...field } }) => <EmployeeImage {...field} />}
      </FormController>
      <FormController
        control={control}
        name="firstname"
        label={t('First Name')}
        defaultValue=""
      >
        {({ field }) => <Input {...field} />}
      </FormController>
      <FormController
        control={control}
        name="lastname"
        label={t('Last Name')}
        defaultValue=""
      >
        {({ field }) => <Input {...field} />}
      </FormController>
      <FormController
        control={control}
        name="phone"
        label={t('Phone Number')}
        overrides={{ Root: { props: { className: 'col-span-2 w-1/2' } } }}
      >
        {({ field }) => <Input {...field} placeholder="000-000-0000" />}
      </FormController>
      <FormController control={control} name="email" label={t('Email Address')}>
        {({ field }) => <Input {...field} />}
      </FormController>
      {type === 'CREATE' ? (
        <FormController control={control} name="password" label={t('Password')}>
          {({ field }) => <Input {...field} type="password" />}
        </FormController>
      ) : (
        <div />
      )}
      {/* <FormController
        control={control}
        name="licenseNumber"
        label={t("Driver’s License Number (Optional)")}
      >
        {({ field }) => (
          <Input
            {...field}
            placeholder="L000-000-000"
            value={field.value ?? ''}
          />
        )}
      </FormController> */}
      {/* <FormController
        control={control}
        name="licenseExpiration"
        label={t("Driver’s License expiration date")}
      >
        {({ field }) => (
          <DatePicker
            value={field.value}
            onChange={({ date }) => {
              if (!Array.isArray(date)) {
                field.onChange(date !== null ? date : undefined);
              }
            }}
            overrides={{ Input: { component: Input } }}
            clearable
          />
        )}
      </FormController> */}
      <FormController
        control={control}
        name="isTechnician"
        label={t('Technician')}
        overrides={{
          Root: {
            props: {
              className: 'col-span-2',
            },
          },
        }}
      >
        {({ field }) => (
          <StatefulCheckbox
            initialState={{ checked: field.value }}
            checkmarkType="toggle"
            onChange={(e) => field.onChange(e.target.checked)}
            onBlur={field.onBlur}
            overrides={
              {
                ToggleTrack: {
                  props: {
                    className: 'w-12',
                  },
                },
                Toggle: {
                  component: (props: CheckboxSharedProps) => (
                    <StyledToggle
                      {...props}
                      className={clsx(
                        'flex h-6 w-10 items-center justify-center rounded px-2 text-sm',
                        props.$checked
                          ? 'translate-x-1/2 text-white'
                          : 'text-gray-700',
                      )}
                    >
                      {props.$checked ? t('Yes') : t('No')}
                    </StyledToggle>
                  ),
                },
              } as CheckboxOverrides
            }
          />
        )}
      </FormController>
      <fieldset className="mt-12">
        <legend className="text-xl font-semibold">{t('Skillset')}</legend>
        <FormController control={control} name="skills" defaultValue={[]}>
          {({ field }) => (
            <SkillsSelect
              value={field.value}
              onChange={({ value, skills }) => {
                field.onChange(
                  skills.filter(
                    (item) => !!value.find((s) => s.id === item.name),
                  ),
                );
              }}
            />
          )}
        </FormController>
      </fieldset>
    </form>
  );
}

function ImagePreview({ file }: { file?: File }) {
  const [src, setSrc] = useState<string | null | undefined>('');

  useEffect(() => {
    if (file?.name) {
      const reader = new FileReader();
      reader.addEventListener('load', (e) => {
        const result = e.target?.result?.toString();
        if (result) {
          setSrc(result);
        }
      });
      reader.readAsDataURL(file);
    }
  }, [file]);

  if (!src) {
    return null;
  }
  return <img src={src} alt="Preview" className="h-full w-full object-cover" />;
}

function EmployeeImage({
  value,
  onChange,
}: Omit<ControllerRenderProps<FormFields, 'image'>, 'ref'>) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useLocale();

  return (
    <div className="flex items-start space-x-4">
      <div className="h-24 w-24 overflow-hidden rounded-md bg-brand-primary-50">
        {value.file && value.file instanceof FileList && value.file.length ? (
          <ImagePreview file={value.file[0]} />
        ) : value.dataStoreId ? (
          <Image
            className="h-full w-full object-cover"
            alt="Employee"
            imageId={value.dataStoreId}
          />
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <User className="text-brand-primary" size={40} />
          </div>
        )}
      </div>
      <div>
        <label className="relative">
          <input
            type="file"
            ref={fileInputRef}
            className="absolute inset-0 opacity-0"
            onChange={(e) =>
              onChange({
                ...value,
                file: e.target.files,
              } as FormFields['image'])
            }
            accept="image/x-png,image/jpeg,image/jpg"
          />
        </label>
        <Button
          type="button"
          startEnhancer={<FilePlus size={16} />}
          size="compact"
          kind="secondary"
          onClick={() => fileInputRef.current?.click()}
        >
          {t('Upload Image')}
        </Button>
        <p className="mt-2 font-prompt text-sm text-gray-500">
          <span className="font-semibold">{t('Attached')}:</span>{' '}
          {value instanceof FileList && value.length ? value[0].name : ''}
        </p>
      </div>
    </div>
  );
}
