import { Banner } from 'baseui/banner';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal';
import { Spinner } from 'baseui/spinner';
import { Textarea } from 'baseui/textarea';
import { AlertCircle } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { Button } from 'components/Button';
import { FormController } from 'components/FormController';
import { getErrorMessage } from 'services/api/base-api';
import {
  useCustomerQuery,
  useEditCustomerMutation,
} from 'services/api/customers/endpoints';

const schema = z.object({
  note: z.string().trim().optional().default(''),
});

type FormFields = z.infer<typeof schema>;

export default function NewNote() {
  const params = useParams();
  const navigate = useNavigate();
  const customerQuery = useCustomerQuery(Number(params.customerId));
  const [addNote, mutationState] = useEditCustomerMutation();

  return (
    <Modal isOpen onClose={() => navigate('..')}>
      <ModalHeader>Add Note</ModalHeader>
      <ModalBody>
        {customerQuery.isError ? (
          <div className="text-center">
            <AlertCircle className="mx-auto h-12 w-12 text-red-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Error</h3>
            <p className="mt-1 text-sm text-gray-500">
              {getErrorMessage(
                customerQuery.error,
                'Failed to get customer notes.',
              )}
            </p>
            <div className="mt-6">
              <Button type="button" size="mini" onClick={customerQuery.refetch}>
                Try Again.
              </Button>
            </div>
          </div>
        ) : customerQuery.isSuccess ? (
          <div>
            {mutationState.isError && (
              <Banner title="Error" kind="negative">
                {getErrorMessage(
                  mutationState.error,
                  'Failed to update note. Try Again.',
                )}
              </Banner>
            )}
            <EditNoteForm
              initialValue={{ note: customerQuery.data.notes }}
              onSubmit={async (values) => {
                const result = await addNote({
                  ...customerQuery.data,
                  notes: values.note,
                });
                if ('data' in result) {
                  navigate('..');
                }
              }}
            />
          </div>
        ) : (
          <div className="flex justify-center">
            <Spinner />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          kind="tertiary"
          disabled={mutationState.isLoading}
          onClick={() => navigate('..')}
        >
          Cancel
        </Button>
        <ModalButton form="new-note-form" isLoading={mutationState.isLoading}>
          Add Note
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}

function EditNoteForm({
  id = 'new-note-form',
  initialValue = {},
  onSubmit: onSubmitHandler,
}: {
  id?: string;
  initialValue: Partial<FormFields>;
  onSubmit: (value: FormFields) => void;
}) {
  const { control, handleSubmit } = useForm<FormFields>({
    defaultValues: initialValue,
  });
  const onSubmit = handleSubmit((data) => {
    onSubmitHandler(data);
  });

  return (
    <form onSubmit={onSubmit} id={id}>
      <FormController control={control} name="note">
        {({ field }) => <Textarea {...field} />}
      </FormController>
    </form>
  );
}
