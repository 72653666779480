import { z } from 'zod';

import {
  LineItem,
  ModifiableWorkRequestProperties,
  WorkRequest,
  modifiableWorkRequestPropertiesSchema,
  workRequestSchema,
} from './types';

import { baseAPI, isMutationFailed } from '../base-api';
import { WORK_REQUEST_STATUS_VALUES } from '../constants';

const stripUndefined = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  const result: { [key: string]: any } = {};
  for (const key in obj) {
    if (obj[key] !== undefined) {
      result[key] = obj[key];
    }
  }
  return result;
};

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
type PickRequired<T, K extends keyof T> = Partial<T> & Required<Pick<T, K>>;
const createWorkRequestSchema = modifiableWorkRequestPropertiesSchema.omit({
  id: true,
  invoiceId: true,
  estimateId: true,
  status: true,
  prevStatus: true,
  recurring: true,
  num: true,
  estimateDuration: true,
});
type CreateWorkRequestPayload = z.infer<typeof createWorkRequestSchema> & {
  estimateDuration: string | number;
};
type UpdateWorkRequestPayload = PickRequired<
  ModifiableWorkRequestProperties,
  'id'
>;

const mutationOptionsSchema = z
  .object({
    invalidate: z.boolean().catch(true).default(true),
  })
  .partial()
  .default({
    invalidate: true,
  })
  .catch({
    invalidate: true,
  });
type MutationRequestOptions = {
  mutationOptions?: z.infer<typeof mutationOptionsSchema>;
};

/**
 * NOTE:
 * TWorkRequest and WorkRequest should be the same while we migrate to the
 * update shape of the resource and `WorkRequest` type will be used moving forward
 */
export const endpoints = baseAPI.injectEndpoints({
  // TODO: Update payload type
  endpoints: (builder) => ({
    workRequest: builder.query<WorkRequest, number>({
      query: (id) => `workrequests/${id}`,
      providesTags: (workRequest) =>
        workRequest ? [{ type: 'Work-Requests', id: workRequest.id }] : [],
    }),
    _work_request: builder.mutation<WorkRequest, number>({
      query: (id) => `workrequests/${id}`,
    }),
    createWorkRequest: builder.mutation<
      WorkRequest,
      CreateWorkRequestPayload & {
        workTypeItems: Array<
          Pick<
            LineItem,
            | 'name'
            | 'description'
            | 'totalPrice'
            | 'unitPrice'
            | 'quantity'
            | 'isTaxable'
            | 'workTypeId'
          > & {
            duration: number;
          }
        >;
      }
    >({
      query: (payload) => ({
        url: `workrequests`,
        method: 'POST',
        body: {
          ...payload,
          recurring: false,
          status: payload.estimateRequired
            ? WORK_REQUEST_STATUS_VALUES.EstCreated
            : WORK_REQUEST_STATUS_VALUES.Created,
          prevStatus: payload.estimateRequired
            ? WORK_REQUEST_STATUS_VALUES.EstCreated
            : WORK_REQUEST_STATUS_VALUES.Created,
        },
      }),
      invalidatesTags: ['Work-Requests', 'Estimates', 'Jobs'],
    }),

    updateWorkRequest: builder.mutation<
      WorkRequest,
      UpdateWorkRequestPayload & MutationRequestOptions
    >({
      async queryFn(payload, api, extraOptions, baseQuery) {
        const result = await baseQuery({ url: `workrequests/${payload.id}` });
        if (result.error) {
          return { error: result.error };
        }
        const validated = workRequestSchema.safeParse(result.data);
        if (!validated.success) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Failed to parse response data',
            },
          };
        }
        const updateWorkRequestResult = await baseQuery({
          url: `workrequests/${payload.id}`,
          method: 'PUT',
          body: { ...validated.data, ...stripUndefined(payload) },
        });

        // if (address.id) {
        //   const addressResult = await baseQuery({
        //     url: `addresses/${address.id}`,
        //     method: 'PUT',
        //     body: address,
        //   });

        //   if (isMutationFailed(addressResult)) {
        //     return { error: addressResult.error };
        //   }
        // }

        if (isMutationFailed(updateWorkRequestResult)) {
          return { error: updateWorkRequestResult.error };
        }
        return { data: workRequestSchema.parse(updateWorkRequestResult.data) };
      },
      invalidatesTags: (response, error, payload) => {
        return response &&
          mutationOptionsSchema.parse(payload.mutationOptions).invalidate
          ? [
              { type: 'Work-Requests', id: response.id },
              'Estimates',
              'Invoices',
              'Jobs',
            ]
          : [];
      },
    }),
    lineItems: builder.query<
      LineItem[],
      { clientId?: number; customerId: number } | void
    >({
      query(args) {
        return {
          url: 'worktypeitems',
          params: args
            ? {
                clientId: args.clientId,
                customerId: args.customerId,
              }
            : undefined,
        };
      },
      providesTags: (response) =>
        response
          ? [
              { type: 'Work-Requests', id: response[0].workRequestId },
              { type: 'Work-Requests', id: 'line-items' },
            ]
          : [],
    }),
    saveLineItems: builder.mutation<
      LineItem[],
      Array<
        Optional<LineItem, 'id' | 'duration'> & /**
         * The API expects the duration to be formatted as ISO duration. but as an alternative it can also be
         * in seconds
         */ { durationInSeconds: number }
      >
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const newItems = arg.filter((item) => item.id === undefined);
        const itemsToUpdate = arg.filter((item) => item.id !== undefined);
        const results = await Promise.all([
          ...newItems.map((item) =>
            baseQuery({
              url: 'work-type-items',
              method: 'POST',
              body: { ...item, duration: item.durationInSeconds },
            }),
          ),
          baseQuery({
            url: 'work-type-items',
            method: 'PUT',
            body: itemsToUpdate.map((item) => ({
              ...item,
              duration: item.durationInSeconds,
            })),
          }),
        ]);
        const failedRequests = results.filter((result) =>
          isMutationFailed(result),
        );
        if (failedRequests.length && failedRequests[0].error) {
          return {
            error: failedRequests[0].error,
          };
        }
        return { data: results.map((item) => item.data) as LineItem[] };
      },
    }),
    deleteLineItems: builder.mutation<boolean, number[]>({
      query(args) {
        return {
          url: 'work-type-items',
          method: 'DELETE',
          body: args,
        };
      },
    }),
    modifyLineItems: builder.mutation<
      LineItem,
      | {
          type: 'NEW_ITEM';
          value: Omit<LineItem, 'id'>;
        }
      | {
          type: 'UPDATE_ITEM';
          value: LineItem;
        }
      | {
          type: 'REMOVE_ITEM';
          value: LineItem;
        }
    >({
      query(arg) {
        const method = {
          NEW_ITEM: 'POST',
          UPDATE_ITEM: 'PUT',
          REMOVE_ITEM: 'DELETE',
        }[arg.type as typeof arg['type']];

        const body = {
          NEW_ITEM: arg.value,
          UPDATE_ITEM: arg.value,
          REMOVE_ITEM: undefined,
        }[arg.type as typeof arg['type']];

        const url = {
          NEW_ITEM: 'worktypeitems',
          UPDATE_ITEM:
            arg.type === 'UPDATE_ITEM' ? `worktypeitems/${arg.value.id}` : '',
          REMOVE_ITEM:
            arg.type === 'REMOVE_ITEM' ? `worktypeitems/${arg.value.id}` : '',
        }[arg.type as typeof arg['type']];

        return {
          url,
          method,
          body: body,
        };
      },
      invalidatesTags: (response, error, arg) =>
        response
          ? [
              { type: 'Work-Requests', id: arg.value.workRequestId },
              { type: 'Work-Requests', id: 'line-items' },
              { type: 'Estimates', id: arg.value.workRequestId },
            ]
          : [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useWorkRequestQuery,
  useCreateWorkRequestMutation,
  useUpdateWorkRequestMutation,
  useLineItemsQuery,
  useModifyLineItemsMutation,
  useSaveLineItemsMutation,
  useDeleteLineItemsMutation,
} = endpoints;
