import { Skeleton } from 'baseui/skeleton';
import { clsx } from 'clsx';

import { Card } from '../Card';

import theme from 'theme';

const PRESET = {
  blue: 'blue',
  red: 'red',
  yellow: 'yellow',
  green: 'green',
  gray: 'gray',
} as const;

type CustomColors = {
  background: string;
  iconBackground: string;
  text: string;
  primaryColor: string;
  accentColor: string;
};

export type BaseMetricCardProps = {
  icon: React.ReactNode;
  value: React.ReactNode;
  description: React.ReactNode;
  action?: ((colors: CustomColors) => React.ReactNode) | React.ReactNode;
  isLoading?: boolean;
};
export type ConditionalMetricCardProps =
  | {
      preset?: never;
      colors: Partial<CustomColors>;
    }
  | {
      preset: keyof typeof PRESET;
      colors?: never;
    };

export type MetricCardProps = BaseMetricCardProps & ConditionalMetricCardProps;

export const PRESET_COLORS: Record<
  keyof typeof PRESET,
  Required<CustomColors>
> = {
  blue: {
    primaryColor: '#E5F3FF',
    accentColor: '#309CFF',
    background: 'bg-[#E5F3FF]',
    iconBackground: 'bg-[#309CFF]',
    text: 'text-[color:#309CFF]',
  },
  red: {
    primaryColor: '#FFF2F5',
    accentColor: '#FF3030',
    background: 'bg-[#FFF2F5]',
    iconBackground: 'bg-[#FF3030]',
    text: 'text-[color:#FF3030]',
  },
  yellow: {
    primaryColor: '#FFFBD8',
    accentColor: '#FFC42E',
    background: 'bg-[#FFFBD8]',
    iconBackground: 'bg-[#FFC42E]',
    text: 'text-[color:#FFC42E]',
  },
  green: {
    primaryColor: '#E5FFEB',
    accentColor: '#6BC07D',
    background: 'bg-[#E5FFEB]',
    iconBackground: 'bg-[#6BC07D]',
    text: 'text-[color:#6BC07D]',
  },
  gray: {
    primaryColor: '#F5F5F5',
    accentColor: '#737791',
    background: 'bg-[#F5F5F5]',
    iconBackground: 'bg-[#737791]',
    text: 'text-[color:#737791]',
  },
};

export default function MetricCard(props: MetricCardProps) {
  const { value, description, action, icon, colors, preset, isLoading } = props;

  const cardBg = preset
    ? PRESET_COLORS[preset].background
    : colors?.background ?? 'bg-brand-primary-50';
  const iconBg = preset
    ? PRESET_COLORS[preset].iconBackground
    : colors?.iconBackground ?? 'bg-brand-primary';
  const textColor = preset
    ? PRESET_COLORS[preset].text
    : colors?.text ?? 'text-brand-prmary';

  return (
    <Card
      overrides={{
        Root: {
          props: {
            className: clsx(cardBg, 'flex h-full border-0'),
          },
        },
        Contents: {
          props: {
            className: 'w-full',
          },
        },
        Body: {
          props: {
            className: 'h-full mb-0',
          },
        },
      }}
    >
      <div className="mb-0 flex h-full flex-col space-y-4">
        <div
          className={clsx(
            'flex h-10 w-10 items-center justify-center rounded-lg text-white',
            iconBg,
          )}
        >
          {icon}
        </div>
        <div className="flex flex-1 flex-col">
          <div className="text-3xl font-semibold">
            {isLoading ? <Skeleton width="100%" rows={1} animation /> : value}
          </div>
          <div className="font-prompt text-xs">
            {isLoading ? (
              <Skeleton
                width="100%"
                overrides={{ Root: { props: { className: 'mt-2' } } }}
                animation
                rows={1}
              />
            ) : (
              description
            )}
          </div>
          <nav className={clsx('mt-auto block', { hidden: isLoading })}>
            {action instanceof Function
              ? action({
                  background: cardBg,
                  iconBackground: iconBg,
                  text: textColor,
                  primaryColor: preset
                    ? PRESET_COLORS?.[preset].primaryColor
                    : theme.colors.primary,
                  accentColor: preset
                    ? PRESET_COLORS?.[preset].accentColor
                    : theme.colors.primary50,
                })
              : action}
          </nav>
        </div>
      </div>
    </Card>
  );
}
