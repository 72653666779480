import { Dialog, DialogProps } from './dialog';
export {
  DialogActions as AlertActions,
  DialogBody as AlertBody,
  DialogDescription as AlertDescription,
  DialogTitle as AlertTitle,
} from './dialog';

export function AlertDialog(props: Omit<DialogProps, 'isDismissable'>) {
  return <Dialog {...props} role="alertdialog" isDismissable={false} />;
}
