/* eslint-disable react/prop-types */
import { clsx } from 'clsx';
import type React from 'react';
import { mergeProps, useLabel } from 'react-aria';
import {
  Switch as AriaSwitch,
  SwitchProps as AriaSwitchProps,
  LabelContext,
  SwitchContext,
} from 'react-aria-components';
import { useController } from 'react-hook-form';

import { useSlot, withControlledField } from './utils';

import { ControlledFieldProps } from '../forms/fieldset';

export function SwitchGroup({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      data-slot="control"
      {...props}
      className={clsx(
        className,

        // Basic groups
        'space-y-3 [&_[data-slot=label]]:font-normal',

        // With descriptions
        'has-[[data-slot=description]]:space-y-6 [&_[data-slot=label]]:has-[[data-slot=description]]:font-medium',
      )}
    />
  );
}

function HeadlessSwitchField({
  children,
  control,
  field: _field,
  defaultSelected,
  ...props
}: ControlledFieldProps & {
  children: React.ReactNode;
} & Omit<AriaSwitchProps, 'children'>) {
  const controller = useController({
    control,
    name: _field,
    defaultValue: defaultSelected,
  });
  const isDisabled = props.isDisabled;
  const field = controller.field;
  const [labelRef, label] = useSlot();
  // eslint-disable-next-line prefer-const
  let { labelProps, fieldProps } = useLabel({ label });

  fieldProps = mergeProps(
    fieldProps,
    //   {
    //   "aria-describedby":
    //     [descriptionId, props["aria-describedby"]].filter(Boolean).join(" ") ||
    //     undefined,
    // }
  );

  return (
    <LabelContext.Provider
      value={{
        ...labelProps,
        ref: labelRef,
        ...(isDisabled ? { 'data-disabled': true } : {}),
      }}
    >
      <SwitchContext.Provider
        value={{
          ...mergeProps(props, fieldProps, {
            // We merge this so the user can add their own onChange handler alongside the one we provide from RHF
            onChange: field.onChange,
            onBlur: field.onBlur,
          }),
          ref: field.ref,
          name: field.name,
          isSelected: field.value,
        }}
      >
        <div data-slot="field" className={clsx([props.className])}>
          {children}
        </div>
      </SwitchContext.Provider>
    </LabelContext.Provider>
  );
}
const _HeadlessSwitchField = withControlledField(HeadlessSwitchField);
export { _HeadlessSwitchField as HeadlessSwitchField };

export function _SwitchField(
  props: ControlledFieldProps & {
    children: React.ReactNode;
  } & Omit<AriaSwitchProps, 'children'>,
) {
  return (
    <HeadlessSwitchField
      {...props}
      className={clsx(
        props.className,

        // Base layout
        'grid grid-cols-[1fr_auto] items-center gap-x-8 gap-y-1 sm:grid-cols-[1fr_auto]',

        // Control layout
        '[&>[data-slot=control]]:col-start-2 [&>[data-slot=control]]:self-center',

        // Label layout
        '[&>[data-slot=label]]:col-start-1 [&>[data-slot=label]]:row-start-1 [&>[data-slot=label]]:justify-self-start',

        // Description layout
        '[&>[data-slot=description]]:col-start-1 [&>[data-slot=description]]:row-start-2',

        // With description
        '[&_[data-slot=label]]:has-[[data-slot=description]]:font-medium',
      )}
    />
  );
}
export const SwitchField = withControlledField(_SwitchField);

const colors = {
  'dark/zinc': [
    '[--switch-bg-ring:theme(colors.zinc.950/90%)] [--switch-bg:theme(colors.zinc.900)]',
    '[--switch-ring:theme(colors.zinc.950/90%)] [--switch-shadow:theme(colors.black/10%)] [--switch:white]',
  ],
  'dark/white': [
    '[--switch-bg-ring:theme(colors.zinc.950/90%)] [--switch-bg:theme(colors.zinc.900)]',
    '[--switch-ring:theme(colors.zinc.950/90%)] [--switch-shadow:theme(colors.black/10%)] [--switch:white]',
  ],
  dark: [
    '[--switch-bg-ring:theme(colors.zinc.950/90%)] [--switch-bg:theme(colors.zinc.900)]',
    '[--switch-ring:theme(colors.zinc.950/90%)] [--switch-shadow:theme(colors.black/10%)] [--switch:white]',
  ],
  zinc: [
    '[--switch-bg-ring:theme(colors.zinc.700/90%)] [--switch-bg:theme(colors.zinc.600)]',
    '[--switch-shadow:theme(colors.black/10%)] [--switch:white] [--switch-ring:theme(colors.zinc.700/90%)]',
  ],
  white: [
    '[--switch-bg-ring:theme(colors.black/15%)] [--switch-bg:white]',
    '[--switch-shadow:theme(colors.black/10%)] [--switch-ring:transparent] [--switch:theme(colors.zinc.950)]',
  ],
  red: [
    '[--switch-bg-ring:theme(colors.red.700/90%)] [--switch-bg:theme(colors.red.600)]',
    '[--switch:white] [--switch-ring:theme(colors.red.700/90%)] [--switch-shadow:theme(colors.red.900/20%)]',
  ],
  yellow: [
    '[--switch-bg-ring:theme(colors.yellow.400/80%)] [--switch-bg:theme(colors.yellow.300)]',
    '[--switch-ring:transparent] [--switch-shadow:transparent] [--switch:theme(colors.yellow.950)]',
  ],
  green: [
    '[--switch-bg-ring:theme(colors.green.700/90%)] [--switch-bg:theme(colors.green.600)]',
    '[--switch:white] [--switch-ring:theme(colors.green.700/90%)] [--switch-shadow:theme(colors.green.900/20%)]',
  ],
  sky: [
    '[--switch-bg-ring:theme(colors.sky.600/80%)] [--switch-bg:theme(colors.sky.500)]',
    '[--switch:white] [--switch-ring:theme(colors.sky.600/80%)] [--switch-shadow:theme(colors.sky.900/20%)]',
  ],
  blue: [
    '[--switch-bg-ring:theme(colors.blue.700/90%)] [--switch-bg:theme(colors.blue.600)]',
    '[--switch:white] [--switch-ring:theme(colors.blue.700/90%)] [--switch-shadow:theme(colors.blue.900/20%)]',
  ],
};

type Color = keyof typeof colors;

export function Switch({
  color = 'green',
  className,
  children,
  ...props
}: {
  color?: Color;
  className?: string;
  children?: React.ReactNode;
} & Omit<AriaSwitchProps, 'children'>) {
  return (
    <AriaSwitch
      data-slot="control"
      className={clsx(
        // Base styles
        'group relative',
      )}
      {...props}
    >
      <div
        className={clsx(
          className,

          // Base styles
          'group relative isolate inline-flex h-6 w-10 cursor-default rounded-full p-[3px] sm:h-5 sm:w-8',

          // Transitions
          'transition duration-0 ease-in-out group-data-[pressed]:duration-200',

          // Unchecked
          'bg-zinc-200 ring-1 ring-inset ring-black/5',

          // Checked
          'group-data-[selected]:bg-[--switch-bg] group-data-[selected]:ring-[--switch-bg-ring]',

          // Focus
          'focus:outline-none group-data-[focused]:outline group-data-[focused]:outline-2 group-data-[focused]:outline-offset-2 group-data-[focused]:outline-blue-500',

          // Hover
          'group-data-[hovered]:ring-black/15 group-data-[hovered]:group-data-[selected]:ring-[--switch-bg-ring]',

          // Disabled
          'group-data-[disabled]:bg-zinc-200 group-data-[disabled]:group-data-[selected]:bg-zinc-200 group-data-[disabled]:opacity-50 group-data-[disabled]:group-data-[selected]:ring-black/5',

          // Color specific styles
          colors[color],
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            // Basic layout
            'pointer-events-none relative inline-block h-[1.125rem] w-[1.125rem] rounded-full sm:h-3.5 sm:w-3.5',

            // Transition
            'translate-x-0 transition duration-200 ease-in-out',

            // Invisible border so the switch is still visible in forced-colors mode
            'border border-transparent',

            // Unchecked
            'bg-white shadow ring-1 ring-black/5',

            // Checked
            'group-data-[selected]:bg-[--switch] group-data-[selected]:shadow-[--switch-shadow] group-data-[selected]:ring-[--switch-ring]',
            'group-data-[selected]:translate-x-4 sm:group-data-[selected]:translate-x-3',

            // Disabled
            'group-group-data-[disabled]:group-data-[selected]:bg-white group-group-data-[disabled]:group-data-[selected]:shadow group-group-data-[disabled]:group-data-[selected]:ring-black/5',
          )}
        />
      </div>
    </AriaSwitch>
  );
}

export function LabeledSwitch({
  labels,
}: {
  labels: { on: string; off: string };
}) {
  return (
    <AriaSwitch
      data-slot="control"
      className={clsx(
        // Base styles
        'group relative inline-block',
      )}
    >
      {({ isSelected }) => (
        <div
          className={clsx(
            // Base styles
            'group relative isolate inline-flex cursor-default rounded-[7px]',

            // Dimensions
            'h-7 w-16 p-[3px]',

            // Transitions
            'transition duration-0 ease-in-out group-data-[pressed]:duration-200',

            // Unchecked
            'bg-zinc-200 ring-1 ring-inset ring-black/5',

            // Checked
            'group-data-[selected]:bg-[--switch-bg] group-data-[selected]:ring-[--switch-bg-ring]',

            // Focus
            'focus:outline-none group-data-[focused]:outline group-data-[focused]:outline-2 group-data-[focused]:outline-offset-2 group-data-[focused]:outline-blue-500',

            // Hover
            'group-data-[hovered]:group-data-[selected]:ring-[--switch-bg-ring] group-data-[hovered]:ring-black/10',

            // Disabled
            'group-data-[disabled]:bg-zinc-200 group-data-[disabled]:group-data-[selected]:bg-zinc-200 group-data-[disabled]:opacity-50 group-data-[disabled]:group-data-[selected]:ring-black/5',

            colors.green,
          )}
        >
          <span
            aria-hidden="true"
            className={clsx(
              // Basic layout
              'pointer-events-none relative inline-flex items-center justify-center rounded text-xs',

              // Dimensions
              'h-[22px] w-1/2',

              // Transition
              'translate-x-0 transition duration-200 ease-in-out',

              // Invisible border so the switch is still visible in forced-colors mode
              'border border-transparent',

              // Unchecked
              'bg-white shadow ring-1 ring-black/5',

              // Checked
              'group-data-[selected]:bg-[--switch] group-data-[selected]:shadow-[--switch-shadow] group-data-[selected]:ring-[--switch-ring]',
              'group-data-[selected]:translate-x-full',

              // Disabled
              'group-group-data-[disabled]:group-data-[selected]:bg-white group-group-data-[disabled]:group-data-[selected]:shadow group-group-data-[disabled]:group-data-[selected]:ring-black/5',
            )}
          >
            <span className="text-zinc-950">
              {isSelected ? labels.on : labels.off}
            </span>
          </span>
        </div>
      )}
    </AriaSwitch>
  );
}
