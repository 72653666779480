import { StyledCaption } from 'baseui/form-control';
import type { Option, OnChangeParams } from 'baseui/select';
import { Skeleton } from 'baseui/skeleton';

import { Button } from 'components/Button';
import { Select, SelectProps } from 'components/Select';
import { useSkillsQuery } from 'services/api/skills/endpoints';
import type { Skill } from 'services/api/skills/types';
import { useTypedSelector } from 'store/store';
import { useLocale } from 'utils/hooks/useLocale';

export default function SkillsSelect(
  props: Omit<SelectProps, 'value' | 'onChange'> & {
    value?: Skill[];
    onChange?: (args: OnChangeParams & { skills: Skill[] }) => void;
  },
) {
  const { value, onChange: onChangeHandler, ...selectProps } = props;
  const clientId = useTypedSelector((s) => s.auth.userCredentials.clientId);
  const query = useSkillsQuery({ employeeId: clientId ?? 0 });
  const { t } = useLocale();

  if (query.isError) {
    return (
      <div>
        <StyledCaption $error className="text-base">
          Failed to get skills.{' '}
          <Button type="button" kind="tertiary" onClick={query.refetch}>
            {t('Try Again')}
          </Button>
        </StyledCaption>
      </div>
    );
  }

  if (query.data) {
    const skills = query.data;
    const options: Option[] = skills.map((skill) => ({
      label: skill.name,
      id: skill.name,
    }));

    const selected = options.filter((item) =>
      item.id
        ? value?.some((itemValue) => itemValue.name === item?.id?.toString())
        : false,
    );
    return (
      <Select
        multi
        creatable={false}
        {...selectProps}
        onChange={(onChangeArgs) => {
          if (onChangeHandler) {
            onChangeHandler({ ...onChangeArgs, skills });
          }
        }}
        value={selected}
        options={options}
      />
    );
  }

  return <Skeleton height="40px" width="100%" animation />;
}
