import { UseTranslationOptions, useTranslation } from 'react-i18next';

import { toSupportedLocaleValue } from 'i18n-config';

export const useLocale = (
  namespace: Parameters<typeof useTranslation>[0] = ['common', 'glossary'],
  options?: UseTranslationOptions<undefined>,
) => {
  const params = useTranslation(namespace, options);
  const { i18n, t, '2': isReady } = params;
  const isLocaleReady = Object.keys(i18n).length > 0;
  const locale = toSupportedLocaleValue(i18n.resolvedLanguage);

  return {
    i18n,
    t,
    isLocaleReady: isLocaleReady && isReady,
    currentLocale: locale,
  };
};
