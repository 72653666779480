import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'baseui/snackbar';
import { TimePicker } from 'baseui/timepicker';
import clsx from 'clsx';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isEqual,
  set,
  startOfWeek,
} from 'date-fns';
import { ArrowRight } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import AvailabilityCalendar from './AvailabilityCalendar';

import { Button } from 'components/Button';
import { FormController } from 'components/FormController';
import { FieldErrorMessage, Legend } from 'components/forms/fieldset';
import { GroupedField } from 'components/forms/group-field';
import { QueryResolver } from 'components/QueryResolver';
import {
  HeadlessCheckbox,
  HeadlessCheckboxGroupField,
} from 'components/ui/checkbox';
import { Label } from 'components/ui/label';
import { HeadlessSwitchField, LabeledSwitch } from 'components/ui/switch';
import { isMutationSuccess } from 'services/api/base-api';
import { useClientPropertiesQuery } from 'services/api/clients/endpoints';
import {
  EmployeeService,
  useEmployeeAvailabilityQuery,
} from 'services/api/employees/endpoints';
import {
  formatTime,
  toPayloadDateFormat,
  toPayloadTimeFormat,
} from 'utils/helpers';
import { useLocale } from 'utils/hooks/useLocale';

// https://github.com/gpbl/react-day-picker/blob/master/packages/react-day-picker/src/components/Head/utils/getWeekdays.ts
/**
 * Generate a series of 7 days, starting from the week, to use for formatting
 * the weekday names (Monday, Tuesday, etc.).
 */
export function getWeekdays(
  locale?: Locale,
  /** The index of the first day of the week (0 - Sunday) */
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
): Date[] {
  const start = startOfWeek(new Date(), { locale, weekStartsOn });
  const days = [];
  for (let i = 0; i < 7; i++) {
    const day = addDays(start, i);
    days.push(day);
  }
  return days;
}

const WORKING_DAYS_VALUES = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
} as const;

const schema = z.object({
  employeeID: z.coerce.number(),
  workingHours: z
    .object({
      start: z.string().datetime(),
      end: z.string().datetime(),
      is24HoursAvailable: z.boolean(),
    })
    .refine(
      (data) => {
        if (data.is24HoursAvailable) {
          return true;
        }
        return !isEqual(new Date(data.end), new Date(data.start));
      },
      {
        message: 'time_cannot_be_equal',
      },
    )
    .refine(
      (data) => {
        if (data.is24HoursAvailable) {
          return true;
        }
        return isBefore(new Date(data.start), new Date(data.end));
      },
      {
        message: 'start_time_later_than_end',
      },
    ),
  lunchPeriodHours: z
    .object({
      start: z.string().datetime().nullable(),
      end: z.string().datetime().nullable(),
      allowLunchBreak: z.boolean().default(false),
    })
    .refine(
      (data) => {
        if (!data.allowLunchBreak && (!data.start || !data.end)) {
          return false;
        }
        return true;
      },
      {
        message: 'errors.invalid_type_received_undefined',
      },
    )
    .refine(
      (data) => {
        if (!data.allowLunchBreak || !data.start || !data.end) {
          return true;
        }
        return !isEqual(new Date(data.end), new Date(data.start));
      },
      {
        message: 'time_cannot_be_equal',
      },
    )
    .refine(
      (data) => {
        /**
         * This value for the `start` or `end` should not be null since we have a check before.
         * but to be safe and make typescript happy, we will check if it is null.
         */
        if (!data.allowLunchBreak || !data.start || !data.end) {
          return true;
        }
        return isBefore(new Date(data.start), new Date(data.end));
      },
      {
        message: 'start_time_later_than_end',
      },
    ),
  workingDays: z.nativeEnum(WORKING_DAYS_VALUES).array(),
  timeOffs: z
    .object({ dateStart: z.coerce.date(), dateEnd: z.coerce.date() })
    .array(),
});
type FormFields = z.infer<typeof schema>;

const workingHoursSchema = z
  .object({
    start: z.preprocess(
      (v) => `${toPayloadDateFormat(new Date())}T${v}:00`,
      z.coerce
        .date()
        .catch(set(new Date(), { hours: 8, minutes: 0, seconds: 0 })),
    ),
    end: z.preprocess(
      (v) => `${toPayloadDateFormat(new Date())}T${v}:00`,
      z.coerce
        .date()
        .catch(set(new Date(), { hours: 17, minutes: 0, seconds: 0 })),
    ),
  })
  .transform((values) => ({
    ...values,
    is24Hours: isEqual(values.start, values.end),
  }));

export default function EmployeeWorkingHours({
  employeeID,
}: {
  employeeID: number;
}) {
  const clientProperties = useClientPropertiesQuery();
  const query = useEmployeeAvailabilityQuery({ employeeID });
  return (
    <QueryResolver query={clientProperties}>
      {(properties) => {
        const workingHours = workingHoursSchema.parse({
          start: properties.find((v) => v.propertyName === 'workingHoursStart')
            ?.propertyValue,
          end: properties.find((v) => v.propertyName === 'workingHoursEnd')
            ?.propertyValue,
        });

        return (
          <QueryResolver query={query}>
            {(results) => (
              <EmployeeScheduleForm
                clientWorkingHours={{
                  start: workingHours.start,
                  end: workingHours.end,
                  isClient24HoursAvailable: workingHours.is24Hours,
                }}
                initialValue={{
                  employeeID: employeeID,
                  workingHours:
                    results.workHours?.startTime && results.workHours?.endTime
                      ? {
                          start: results.workHours.startTime,
                          end: results.workHours.endTime,
                          is24HoursAvailable:
                            toPayloadTimeFormat(
                              new Date(results.workHours.startTime),
                            ) === '00:00' &&
                            toPayloadTimeFormat(
                              new Date(results.workHours.endTime),
                            ) === '00:00',
                        }
                      : undefined,
                  workingDays: results.workHours?.preferredDays ?? [
                    'MONDAY',
                    'TUESDAY',
                    'WEDNESDAY',
                    'THURSDAY',
                    'FRIDAY',
                  ],
                  timeOffs: results.timeoff
                    ? [
                        {
                          dateStart: new Date(results.timeoff.startDateTime),
                          dateEnd: new Date(results.timeoff.endDateTime),
                        },
                      ]
                    : [],
                  lunchPeriodHours: {
                    allowLunchBreak:
                      !!results.workHours?.lunchEndTime &&
                      !!results.workHours?.lunchStartTime,
                    start: results.workHours?.lunchStartTime ?? null,
                    end: results.workHours?.lunchEndTime ?? null,
                  },
                }}
              />
            )}
          </QueryResolver>
        );
      }}
    </QueryResolver>
  );
}

function EmployeeScheduleForm({
  initialValue,
  clientWorkingHours,
}: {
  initialValue?: Partial<FormFields>;
  clientWorkingHours: {
    start: Date;
    end: Date;
    isClient24HoursAvailable: boolean;
  };
}) {
  const { t } = useLocale();

  const form = useForm<FormFields>({
    defaultValues: {
      lunchPeriodHours: {
        allowLunchBreak: false,
        end: null,
        start: null,
      },
      ...initialValue,
      workingHours: {
        start:
          initialValue?.workingHours?.start ??
          clientWorkingHours.start.toISOString(),
        end:
          initialValue?.workingHours?.end ??
          clientWorkingHours.end.toISOString(),
        is24HoursAvailable: !!initialValue?.workingHours?.is24HoursAvailable,
      },
    },
    resolver: zodResolver(
      schema
        .refine(
          (v) => {
            if (
              toPayloadTimeFormat(new Date(v.workingHours.start)) ===
              toPayloadTimeFormat(clientWorkingHours.start)
            ) {
              return true;
            }

            return isAfter(
              new Date(v.workingHours.start),
              clientWorkingHours.start,
            );
          },
          {
            message:
              'Start time must not be earlier than your Company working hours',
            path: ['workingHours.start'],
          },
        )
        .refine(
          (v) => {
            const isClientWorkingHours24 =
              toPayloadTimeFormat(clientWorkingHours.start) === '00:00' &&
              toPayloadTimeFormat(clientWorkingHours.end) === '00:00';
            if (
              toPayloadTimeFormat(new Date(v.workingHours.end)) ===
                toPayloadTimeFormat(clientWorkingHours.end) ||
              isClientWorkingHours24
            ) {
              return true;
            }

            return isBefore(
              new Date(v.workingHours.end),
              clientWorkingHours.end,
            );
          },
          {
            message: 'End time must not be later your Company working hours',
            path: ['workingHours.end'],
          },
        ),
    ),
  });
  const { control, setValue } = form;
  const snackbar = useSnackbar();

  const [updateAvailability, mutation] =
    EmployeeService.useSetEmployeeAvailabilityMutation();
  const onSubmit = form.handleSubmit(async (data) => {
    snackbar.enqueue({
      progress: true,
      message: t('employee_availability_updating'),
    });

    const selectedTimeOff = data.timeOffs.find(
      (t) => !!t.dateEnd && !!t.dateStart,
    );
    const allowLunchBreak = data.lunchPeriodHours.allowLunchBreak;
    const result = await updateAvailability({
      workHours: {
        techId: data.employeeID,
        startTime: toPayloadTimeFormat(new Date(data.workingHours.start)),
        endTime: toPayloadTimeFormat(new Date(data.workingHours.end)),
        preferredDays: data.workingDays,
        lunchStartTime:
          !allowLunchBreak || data.lunchPeriodHours.start === null
            ? null
            : toPayloadTimeFormat(new Date(data.lunchPeriodHours.start)),
        lunchEndTime:
          !allowLunchBreak || data.lunchPeriodHours.end === null
            ? null
            : toPayloadTimeFormat(new Date(data.lunchPeriodHours.end)),
      },
      timeoff: {
        techId: data.employeeID,
        startDateTime: selectedTimeOff?.dateStart.toISOString(),
        endDateTime: selectedTimeOff?.dateEnd.toISOString(),
        shouldDelete:
          selectedTimeOff === undefined &&
          (initialValue?.timeOffs?.length ?? 0) !== 0,
      },
    });

    snackbar.dequeue();
    if (isMutationSuccess(result)) {
      snackbar.enqueue({
        message: t('employee_availability_update_successfully'),
      });
    } else {
      snackbar.enqueue({
        message: t('employee_availability_update_error'),
      });
    }
  });
  const toggled24Hours = form.watch('workingHours.is24HoursAvailable', false);
  const isClientWorkingHours24 =
    toPayloadTimeFormat(clientWorkingHours.start) === '00:00' &&
    toPayloadTimeFormat(clientWorkingHours.end) === '00:00';
  const allowLunchBreak = form.watch('lunchPeriodHours.allowLunchBreak');
  const isSubmitting = mutation.isLoading;

  return (
    <form onSubmit={onSubmit} className="grid divide-y px-6">
      <GroupedField className="flex flex-wrap gap-4 py-5">
        <div className="w-full max-w-sm">
          <Legend className="text-brand-primary">{t('Working Hours')}</Legend>
          <GroupedField.Description>
            {t('working_hours_description')}
            <br />
            <br />
            {t('Client Working Hours')}: {formatTime(clientWorkingHours.start)}{' '}
            - {formatTime(clientWorkingHours.end)}
          </GroupedField.Description>
        </div>
        <div className="flex-1">
          <div>
            <div className="flex w-full space-x-4">
              <FormController
                control={control}
                name="workingHours.start"
                label={t('Start time')}
                overrides={{
                  Root: { props: { className: 'w-full' } },
                  ControlContainer: { props: { className: 'mb-0' } },
                }}
                defaultValue={set(new Date(), {
                  hours: 8,
                  minutes: 0,
                  seconds: 0,
                }).toISOString()}
              >
                {({ field }) => (
                  <TimePicker
                    disabled={toggled24Hours}
                    step={1800}
                    value={new Date(field.value)}
                    minTime={clientWorkingHours.start}
                    maxTime={
                      isClientWorkingHours24
                        ? undefined
                        : clientWorkingHours.end
                    }
                    onChange={(date) => {
                      field.value = date ? date.toISOString() : '';
                      field.onChange(field.value);
                    }}
                  />
                )}
              </FormController>
              <div className="flex items-end">
                <span
                  aria-hidden
                  className="-translate-y-1/2 text-brand-primary-500"
                >
                  <ArrowRight />
                </span>
              </div>
              <FormController
                control={control}
                name="workingHours.end"
                label={t('End time')}
                overrides={{
                  Root: { props: { className: 'w-full' } },
                  ControlContainer: { props: { className: 'mb-0' } },
                }}
                defaultValue={set(new Date(), {
                  hours: 17,
                  minutes: 0,
                  seconds: 0,
                }).toISOString()}
              >
                {({ field }) => (
                  <TimePicker
                    disabled={toggled24Hours}
                    step={1800}
                    minTime={clientWorkingHours.start}
                    maxTime={
                      isClientWorkingHours24
                        ? undefined
                        : clientWorkingHours.end
                    }
                    value={new Date(field.value)}
                    onChange={(date) => {
                      field.value = date ? date.toISOString() : '';
                      field.onChange(field.value);
                    }}
                  />
                )}
              </FormController>
            </div>
            <FieldErrorMessage control={control} field="workingHours" />
          </div>
          <div
            className={clsx('py-1', {
              hidden: !clientWorkingHours.isClient24HoursAvailable,
            })}
          >
            <HeadlessSwitchField
              control={form.control}
              field="workingHours.is24HoursAvailable"
              defaultSelected={false}
              className="flex items-center gap-4 py-2"
              onChange={(checked) => {
                if (checked) {
                  setValue(
                    'workingHours.start',
                    set(new Date(), {
                      hours: 0,
                      minutes: 0,
                      seconds: 0,
                    }).toISOString(),
                  );
                  setValue(
                    'workingHours.end',
                    set(new Date(), {
                      hours: 24,
                      minutes: 0,
                      seconds: 0,
                    }).toISOString(),
                  );
                }
              }}
            >
              <LabeledSwitch labels={{ on: t('Yes'), off: t('No') }} />
              <Label>{t('Available 24 hours?')}</Label>
            </HeadlessSwitchField>
          </div>
        </div>
      </GroupedField>
      <GroupedField className="flex flex-wrap gap-4 py-5">
        <div className="w-full max-w-sm">
          <Legend className="text-brand-primary">{t('Lunch Period')}</Legend>
          <GroupedField.Description>
            {t('lunch_period_description')}
          </GroupedField.Description>
        </div>
        <div className="flex-1">
          <div>
            <div className="flex w-full space-x-4">
              <FormController
                control={control}
                name="lunchPeriodHours.start"
                label={t('Start time')}
                overrides={{
                  Root: { props: { className: 'w-full' } },
                  ControlContainer: { props: { className: 'mb-0' } },
                }}
                defaultValue={set(new Date(), {
                  hours: 8,
                  minutes: 0,
                  seconds: 0,
                }).toISOString()}
              >
                {({ field }) => (
                  <TimePicker
                    step={1800}
                    value={field.value ? new Date(field.value) : null}
                    minTime={clientWorkingHours.start}
                    maxTime={
                      isClientWorkingHours24
                        ? undefined
                        : clientWorkingHours.end
                    }
                    onChange={(date) => {
                      field.value = date ? date.toISOString() : '';
                      field.onChange(field.value);
                    }}
                    disabled={!allowLunchBreak}
                  />
                )}
              </FormController>
              <div className="flex items-end">
                <span
                  aria-hidden
                  className="-translate-y-1/2 text-brand-primary-500"
                >
                  <ArrowRight />
                </span>
              </div>
              <FormController
                control={control}
                name="lunchPeriodHours.end"
                label={t('End time')}
                overrides={{
                  Root: { props: { className: 'w-full' } },
                  ControlContainer: { props: { className: 'mb-0' } },
                }}
                defaultValue={set(new Date(), {
                  hours: 17,
                  minutes: 0,
                  seconds: 0,
                }).toISOString()}
              >
                {({ field }) => (
                  <TimePicker
                    step={1800}
                    minTime={clientWorkingHours.start}
                    maxTime={
                      isClientWorkingHours24
                        ? undefined
                        : clientWorkingHours.end
                    }
                    value={field.value ? new Date(field.value) : null}
                    onChange={(date) => {
                      field.value = date ? date.toISOString() : '';
                      field.onChange(field.value);
                    }}
                    disabled={!allowLunchBreak}
                  />
                )}
              </FormController>
            </div>
            <FieldErrorMessage control={control} field="lunchPeriodHours" />
          </div>
          <div className={clsx('py-2')}>
            <HeadlessSwitchField
              control={control}
              field="lunchPeriodHours.allowLunchBreak"
              className="flex items-center gap-4"
            >
              <LabeledSwitch labels={{ off: 'No', on: 'Yes' }} />
              <Label>Allow Lunch Break?</Label>
            </HeadlessSwitchField>
          </div>
        </div>
      </GroupedField>
      <GroupedField className="flex flex-wrap items-start gap-4 py-5">
        <div className="w-full max-w-sm">
          <Legend className="text-brand-primary">{t('Working Days')}</Legend>
          <GroupedField.Description>
            {/* Set working days to be used for scheduling. 
            It will be used to assign a schedule to this technician only when they are available. */}
            {t('technician_working_days_description')}
          </GroupedField.Description>
        </div>
        <HeadlessCheckboxGroupField
          control={control}
          field="workingDays"
          className="flex space-x-2 pb-0.5 pt-8"
        >
          {getWeekdays().map((day) => (
            <HeadlessCheckbox
              key={day.toISOString()}
              value={format(day, 'EEEE').toUpperCase()}
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-brand-primary-50 font-medium text-brand-primary-300 data-[hovered]:bg-brand-primary-500 data-[selected]:bg-brand-primary-500 data-[hovered]:text-white data-[selected]:text-white"
            >
              {format(day, 'EEEEE')}
            </HeadlessCheckbox>
          ))}
        </HeadlessCheckboxGroupField>
      </GroupedField>
      <GroupedField className="flex flex-wrap gap-4 py-5">
        <div className="w-full max-w-sm">
          <Legend className="text-brand-primary">{t('Time off')}</Legend>
          <GroupedField.Description>
            {/* Set a technician's time-off. 
            We will ensure to avoid scheduling work during their designated time-off. */}
            {t('technician_time_off_description')}
          </GroupedField.Description>
        </div>
        <div className="max-w-[18rem]">
          <Controller
            control={control}
            name="timeOffs"
            defaultValue={[]}
            render={({ field }) => (
              <AvailabilityCalendar
                value={field.value.map((v) => ({
                  start: v.dateStart,
                  end: v.dateEnd,
                }))}
                onSelected={(selected) => {
                  field.value = [
                    { dateStart: selected.start, dateEnd: selected.end },
                  ];
                  field.onChange(field.value);
                }}
                onRemovePlotted={(selected) => {
                  field.onChange(
                    field.value.filter(
                      (v) =>
                        !isEqual(v.dateStart, selected.start) &&
                        !isEqual(v.dateEnd, selected.end),
                    ),
                  );
                }}
              />
            )}
          />
        </div>
      </GroupedField>
      <div className="flex justify-end pt-5">
        <Button type="submit" isLoading={isSubmitting}>
          {t('Save')}
        </Button>
      </div>
    </form>
  );
}
