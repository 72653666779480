import {
  Dialog,
  Heading,
  ListBox,
  ListBoxItem,
  Modal,
  ModalOverlay,
  Text,
  Button as Pressable,
} from 'react-aria-components';
import { User, X } from 'react-feather';
import { useNavigate, useParams } from 'react-router';
import { z } from 'zod';

import { MutationBanner } from 'components/Banner';
import { Button } from 'components/Button';
import { QueryResolver } from 'components/QueryResolver';
import { AuthService } from 'services/api/auth/endpoints';
import {
  baseAPI,
  getErrorMessage,
  isMutationSuccess,
} from 'services/api/base-api';
import { ResellerService } from 'services/api/resellers/endpoints';
import { logIn } from 'store/slices/auth';
import { resetPreferences } from 'store/slices/user-preferences';
import { useTypedDispatch } from 'store/store';

function CloseButton(props: { close: VoidFunction }) {
  return (
    <Pressable
      onPress={props.close}
      className="focus:ring-ring absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none data-[entering]:bg-accent data-[entering]:text-muted-foreground"
    >
      <X className="h-4 w-4" />
      <span className="sr-only">Close</span>
    </Pressable>
  );
}

export default function SwitchtoAcount() {
  const navigate = useNavigate();
  return (
    <ModalOverlay
      className="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-black/50 py-3 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0"
      onOpenChange={(open) => !open && navigate('..', { replace: true })}
      defaultOpen
      isDismissable
    >
      <Modal className="relative z-50 grid w-full max-w-lg gap-4 border bg-white p-6 shadow-lg duration-200 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0 data-[entering]:zoom-in-95 data-[exiting]:zoom-out-95 sm:rounded-2xl md:w-full">
        <Dialog className="outline-none">
          {(props) => (
            <>
              <CloseButton {...props} />
              <div>
                <Heading
                  slot="title"
                  className="text-lg font-semibold leading-none tracking-tight"
                >
                  Login as Reseller
                </Heading>
                <p className="mt-1 text-sm text-gray-500">
                  Select an account to login as.
                </p>
              </div>
              <div className="pt-4">
                <Admins />
              </div>
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}

const toNumber = (value: unknown) =>
  z.coerce.number().catch(0).default(0).parse(value);
function Admins() {
  const resellerId = toNumber(useParams().resellerId);
  const navigate = useNavigate();
  const query = ResellerService.useResellerAdminsQuery(
    { resellerId },
    { skip: !resellerId },
  );
  const [loginAs, mutation] = AuthService.useLoginAsMutation();
  const dispatch = useTypedDispatch();

  return (
    <QueryResolver query={query}>
      {(admins) => (
        <>
          <MutationBanner
            show={mutation.isError}
            title="Failed to switch accounts"
            message={mutation.error ? getErrorMessage(mutation.error) : ''}
          />
          <ListBox
            className="grid divide-y"
            selectionMode="none"
            renderEmptyState={() => (
              <div className="p-4 text-center">
                <User
                  aria-hidden="true"
                  className="mx-auto h-12 w-12 text-gray-400"
                />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">
                  No Admins
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  No admin accounts found for this Reseller.
                </p>
              </div>
            )}
          >
            {admins.map((admin) => (
              <ListBoxItem
                key={admin.id}
                className="flex items-center justify-between py-4"
              >
                <div>
                  <Text
                    slot="label"
                    className="text-sm font-medium leading-none"
                  >
                    {admin.firstname} {admin.lastname}
                  </Text>
                  <p className="text-sm text-gray-400">{admin.email}</p>
                </div>
                <Button
                  onClick={async () => {
                    const result = await loginAs({
                      fsaUserId: admin.id,
                    });
                    if (isMutationSuccess(result)) {
                      const data = result.data;
                      dispatch(
                        logIn({
                          accessToken: data.accessToken,
                          userCredentials: {
                            ...data,
                          },
                          refreshToken: data.refreshToken,
                          expiresIn: Date.now() + data.expiresIn,
                          roles: data.roles,
                        }),
                      );
                      dispatch(resetPreferences());
                      dispatch(baseAPI.util.resetApiState());
                      navigate('/res-admin');
                    }
                  }}
                  size="compact"
                  kind="secondary"
                  isLoading={mutation.isLoading}
                >
                  Login as {admin.firstname}
                </Button>
              </ListBoxItem>
            ))}
          </ListBox>
        </>
      )}
    </QueryResolver>
  );
}
