import { Locale } from 'date-fns';
import { enUS, frCA, es } from 'date-fns/locale'; // import all locales we need
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';

const SUPPORTED_LOCALE_VALUES = {
  en: 'en',
  ca: 'ca',
  es: 'es',
} as const;
const supportedLocalesSchema = z.nativeEnum(SUPPORTED_LOCALE_VALUES);
export type SupportedLocales = z.infer<typeof supportedLocalesSchema>;

export const supportedDateFnsLocales: Record<SupportedLocales, Locale> = {
  en: enUS,
  ca: frCA,
  es: es,
};

export const toSupportedLocaleValue = (maybeLocale: unknown) =>
  supportedLocalesSchema.catch('en').parse(maybeLocale);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      addPath: '/locales/{{lng}}/{{ns}}/',
      crossDomain: true,
    },
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: {
      en: ['en'],
      'en-US': ['en'],
      'en-CA': ['ca'],
      'fr-CA': ['ca'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    saveMissing: true,
    saveMissingTo: 'all',
    defaultNS: ['common', 'glossary'],
    ns: ['common', 'glossary'],
    parseMissingKeyHandler() {
      return '__NOT_TRANSLATED__';
    },
    missingKeyHandler() {
      return '__NOT_TRANSLATED__';
    },
    partialBundledLanguages: true,
  });

z.setErrorMap(
  makeZodI18nMap({
    handlePath: false,
  }),
);

export default i18n;
export { i18n, SUPPORTED_LOCALE_VALUES };
