import {
  RefCallback,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

// https://github.com/adobe/react-spectrum/blob/main/packages/react-aria-components/src/utils.tsx#L209
export function useSlot(): [RefCallback<Element>, boolean] {
  // Assume we do have the slot in the initial render.
  const [hasSlot, setHasSlot] = useState(true);
  const hasRun = useRef(false);

  // A callback ref which will run when the slotted element mounts.
  // This should happen before the useLayoutEffect below.
  const ref = useCallback((el: any) => {
    hasRun.current = true;
    setHasSlot(!!el);
  }, []);

  // If the callback hasn't been called, then reset to false.
  useLayoutEffect(() => {
    if (!hasRun.current) {
      setHasSlot(false);
    }
  }, []);

  return [ref, hasSlot];
}

/**
 * A helper function that accepts a user-provided render prop value (either a static value or a function),
 * and combines it with another value to create a final result.
 */
export function composeRenderProps<T, U, V extends T>(
  // https://stackoverflow.com/questions/60898079/typescript-type-t-or-function-t-usage
  value: T extends any ? T | ((renderProps: U) => V) : never,
  wrap: (prevValue: T, renderProps: U) => V,
): (renderProps: U) => V {
  return (renderProps) =>
    wrap(typeof value === 'function' ? value(renderProps) : value, renderProps);
}

export const focusRing = tv({
  base: 'outline outline-offset-2 outline-blue-600',
  variants: {
    isFocusVisible: {
      false: 'outline-0',
      true: 'outline-2',
    },
  },
});

export function composeTailwindRenderProps<T>(
  className: string | ((v: T) => string) | undefined,
  tw: string,
): string | ((v: T) => string) {
  return composeRenderProps(className, (className) => twMerge(tw, className));
}

export type ControlledFieldProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = { control: Control<T>; field: TName };

// Improve type handling
export function withControlledField<P extends object>(
  Component: React.ComponentType<P>,
) {
  // Return a new component that accepts the same props
  return function WrappedComponent<
    T extends FieldValues = FieldValues,
    TName extends FieldPath<T> = FieldPath<T>,
  >(props: ControlledFieldProps<T, TName> & Omit<P, 'control'>) {
    // Pass the control and field props to the wrapped component
    return <Component {...(props as P)} />;
  };
}
