import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { useEffect, useRef } from 'react';
import { Save, XCircle } from 'react-feather';
import { useNavigate, useParams } from 'react-router';

import ResellerForm from '../kal-admin/resellers/features/ResellerForm';

import { Button } from 'components/Button';
import { LinkButton } from 'components/LinkButton';
import { QueryResolver } from 'components/QueryResolver';
import { getErrorMessage, isMutationSuccess } from 'services/api/base-api';
import {
  useEditResellerMutation,
  useResellerQuery,
} from 'services/api/resellers/endpoints';

export default function EditManagedReseller() {
  const navigate = useNavigate();
  const resellerId = Number(useParams().resellerId ?? '0');
  const query = useResellerQuery(resellerId);
  const [editReseller, mutation] = useEditResellerMutation();

  return (
    <Modal
      onClose={() => navigate('..', { replace: true })}
      isOpen
      overrides={{
        Dialog: {
          props: {
            className: 'max-w-3xl w-full',
          },
        },
      }}
    >
      <ModalHeader className="text-xl font-semibold">Edit Reseller</ModalHeader>
      <ModalBody>
        <MutationErrorBanner
          error={mutation.error}
          show={mutation.isError}
          focusOnError
        />
        <QueryResolver query={query}>
          {(reseller) => (
            <ResellerForm
              id="admin-edit-reseller-form"
              allowedRepresentatives={['ParentResellerAdmin']}
              initialValues={{
                resellerAccountName: reseller.businessName,
                accountRepresentative: reseller.accountRep,
                operationalContactName: reseller.mainContactName,
                email: reseller.mainContactEmail,
                phoneNumber: reseller.mainContactPhone,
                firstName: reseller.firstName,
                lastName: reseller.lastName,
                resellerAddress: reseller.address,
                billingAddress: reseller.billingAddress,
                subdomain: reseller.subdomain ?? '',
                domain: reseller.domain ?? '',
                editing: true,
                allowParentResellerCreation: false,
              }}
              onSubmit={async (data) => {
                const result = await editReseller({
                  ...reseller,
                  id: reseller.id,
                  businessName: data.resellerAccountName,
                  accountRep: data.accountRepresentative,
                  mainContactName: data.operationalContactName,
                  mainContactEmail: data.email,
                  mainContactPhone: data.phoneNumber,
                  billingContactName: data.operationalContactName,
                  billingContactEmail: data.email,
                  billingContactPhone: data.phoneNumber,
                  firstName: data.firstName,
                  lastName: data.lastName,
                  address: {
                    ...data.resellerAddress,
                    id: reseller.address.id,
                    street2: data.resellerAddress.street2 ?? '',
                  },
                  billingAddress: {
                    ...data.billingAddress,
                    id: reseller.billingAddress.id,
                    street2: data.billingAddress.street2 ?? '',
                  },
                  subdomain: data.subdomain || null,
                  domain: data.domain || null,
                });
                if (isMutationSuccess(result)) {
                  navigate('..');
                }
              }}
            />
          )}
        </QueryResolver>
      </ModalBody>
      <ModalFooter className="pb-5">
        <LinkButton to=".." replace type="button" kind="tertiary">
          Cancel
        </LinkButton>
        <Button
          startEnhancer={<Save className="h-4 w-4" />}
          form="admin-edit-reseller-form"
          type="submit"
          className="w-full max-w-[10rem]"
          isLoading={mutation.isLoading}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function MutationErrorBanner({
  error,
  focusOnError,
  show = true,
}: {
  error: FetchBaseQueryError | SerializedError | undefined;
  focusOnError?: boolean;
  show?: boolean;
}) {
  const bannerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (show && focusOnError) {
      bannerRef.current?.focus();
    }
  }, [show, focusOnError]);

  if (!show) return null;

  return (
    <div className="mb-4 p-4">
      <div
        className="flex rounded-md bg-red-50 p-5"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        role="alert"
        ref={bannerRef}
      >
        <div className="flex-shrink-0">
          <XCircle className="h-6 w-6 text-red-500" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-semibold text-red-800">
            Failed to create a Reseller.
          </h3>
          <div className="mt-2 text-sm font-medium text-red-700">
            {error ? getErrorMessage(error) : ''}
          </div>
        </div>
      </div>
    </div>
  );
}
