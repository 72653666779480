import type {
  OnChangeParams as SelectOnChangeParams,
  Option,
} from 'baseui/select';

import { Select, SelectProps } from 'components/Select';
import { UtilityService } from 'services/api/utilities/endpoint';
import { Country } from 'services/api/utilities/types';
import { useTypedSelector } from 'store/store';

export function CountrySelect(
  props: Omit<SelectProps, 'value'> & {
    value?: string;
    useClientCountryQuery?: boolean;
  },
) {
  const { value, useClientCountryQuery, ...selectProps } = props;
  const clientId = useTypedSelector((s) => s.auth.userCredentials.clientId);

  const { data: countries = [] } = useClientCountryQuery
    ? UtilityService.endpoints.countryByClientId.useQuery(clientId)
    : UtilityService.endpoints.country.useQuery();

  const options: Option[] =
    Array.isArray(countries) && countries.length > 0
      ? countries.map((country: Country) => ({
          label: country.name,
          id: country.code,
        }))
      : [];

  const selected = options.filter((item) =>
    item.id ? value?.includes(item.id.toString()) : false,
  );
  return (
    <Select
      {...selectProps}
      value={selected}
      options={options}
      creatable={false}
    />
  );
}
