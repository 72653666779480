import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { TypedUseQueryHookResult } from '@reduxjs/toolkit/dist/query/react';

import { Reseller, ResellerProperty, ResellerSubdomainAssets } from './types';

import { baseAPI, isMutationSuccess } from '../base-api';
import { Employee } from '../employees/types';
import { MutationRequestOptions, mutationOptionsSchema } from '../helpers';
import { Address } from '../types.shared';
import { UtilityService } from '../utilities/endpoint';

import { stripUndefined } from 'utils/helpers';
// This removes the readonly properties from a `Reseller` type
type ModifiableReseller = Omit<Reseller, 'parentResellerId' | 'parentReseller'>;

export const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    resellers: builder.query<Reseller[], void>({
      query: () => 'resellers',
      providesTags: (result) => (result ? ['Resellers'] : []),
    }),
    reseller: builder.query<Reseller, number>({
      query: (id) => `resellers/${id}`,
      providesTags: (reseller) =>
        reseller ? [{ type: 'Resellers', id: reseller.id }] : [],
    }),
    resellerAccess: builder.mutation<
      void,
      { enableAccess: boolean; resellerId: number }
    >({
      query: ({ enableAccess, resellerId }) => ({
        url: `resellers/${resellerId}?enable=${enableAccess}`,
        method: 'POST',
      }),
      invalidatesTags: (res, err, arg) =>
        res ? [{ type: 'Resellers', id: arg.resellerId }] : [],
    }),
    resellerAdmins: builder.query<Employee[], { resellerId: number }>({
      query: ({ resellerId }) => `fsausers/reseller-admins/${resellerId}`,
      providesTags: (reseller) =>
        reseller ? [{ type: 'Resellers', id: 'admins' }] : [],
    }),
    createReseller: builder.mutation<
      Reseller,
      Omit<
        ModifiableReseller,
        'id' | 'address' | 'billingAddress' | 'accessKey'
      > & {
        parentReseller?: boolean;
      } & {
        password: string;
        address: Omit<
          Address,
          | 'id'
          | 'resellerId'
          | 'dateCreated'
          | 'dateModified'
          | 'isDeleted'
          | 'dateDeleted'
          | 'authUsername'
        >;
        billingAddress: Omit<
          Address,
          | 'id'
          | 'resellerId'
          | 'dateCreated'
          | 'dateModified'
          | 'isDeleted'
          | 'dateDeleted'
          | 'authUsername'
        >;
      }
    >({
      query: (payload) => ({
        url: `resellers`,
        method: 'POST',
        body: stripUndefined(payload),
      }),
      invalidatesTags: (response) => (response ? ['Resellers'] : []),
    }),
    editReseller: builder.mutation<
      Reseller,
      Omit<
        ModifiableReseller,
        | 'address'
        | 'billingAddress'
        | 'password'
        | 'accessKey'
        | 'parentReseller'
      > & {
        address: Omit<
          Address,
          | 'dateCreated'
          | 'dateModified'
          | 'isDeleted'
          | 'dateDeleted'
          | 'authUsername'
        >;
        billingAddress: Omit<
          Address,
          | 'dateCreated'
          | 'dateModified'
          | 'isDeleted'
          | 'dateDeleted'
          | 'authUsername'
        >;
      }
    >({
      query: (payload) => ({
        url: `resellers/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (reseller) =>
        reseller ? [{ type: 'Resellers', id: reseller.id }] : [],
    }),
    saveResellerProperty: builder.mutation<
      any,
      {
        propertyName: ResellerProperty;
        propertyValue: string;
        resellerId: number;
      }
    >({
      query: (payload) => ({
        url: 'reseller-property',
        method: 'POST',
        body: { ...payload },
      }),
      invalidatesTags: (res) =>
        res
          ? [
              { type: 'Resellers', id: 'property' },
              { type: 'Resellers', id: 'properties' },
              { type: 'Resellers' },
            ]
          : [],
    }),
    saveResellerProperties: builder.mutation<
      any,
      {
        properties: {
          propertyName: ResellerProperty;
          propertyValue: string;
          resellerId: number;
        }[];
      } & MutationRequestOptions
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const responses = await Promise.all(
          arg.properties.map((property) =>
            baseQuery({
              url: 'reseller-property',
              method: 'POST',
              body: { ...property },
            }),
          ),
        );
        const failedRequest = responses.find((response) => response.error);
        if (failedRequest && failedRequest.error) {
          return {
            error: failedRequest.error as FetchBaseQueryError,
          };
        }
        return { data: true };
      },
      invalidatesTags: (res, err, arg) => {
        const invalidate = !!mutationOptionsSchema.parse(arg.mutationOptions)
          .invalidate;
        return res && invalidate
          ? [
              { type: 'Resellers', id: 'property' },
              { type: 'Resellers', id: 'properties' },
            ]
          : [];
      },
    }),
    resellerProperty: builder.query<
      string,
      { name: ResellerProperty; resellerId: number }
    >({
      query: (args) =>
        `reseller-property?property-name=${args.name}&reseller-id=${args.resellerId}`,
      transformResponse(baseQueryReturnValue: any, meta, arg) {
        return baseQueryReturnValue.propertyValue;
      },
      providesTags: (res) =>
        res
          ? [
              { type: 'Resellers', id: 'property' },
              { type: 'Resellers', id: res },
            ]
          : [],
    }),
    resellerProperties: builder.query<
      string[],
      { names: ResellerProperty[]; resellerId: number }
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const responses = await Promise.all(
          arg.names.map((name) =>
            baseQuery(
              `reseller-property?property-name=${name}&reseller-id=${arg.resellerId}`,
            ),
          ),
        );
        const failedRequest = responses.find((response) => response.error);
        if (failedRequest && failedRequest.error) {
          return {
            error: failedRequest.error as FetchBaseQueryError,
          };
        }
        const values = responses
          .filter((res) => res.data)
          .map((res) => (res.data as any).propertyValue)
          .filter((res): res is string => res !== undefined);

        return {
          data: values,
        };
      },
      providesTags: (res) =>
        res
          ? [
              { type: 'Resellers', id: 'properties' },
              { type: 'Resellers', id: res.join(',') },
            ]
          : [],
    }),
    updateAsset: builder.mutation<
      void,
      {
        dataStoreId: number;
        file: File;
        resellerId: number;
        assetType: Extract<ResellerProperty, 'logo' | 'favicon'>;
      }
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const datastoreAsset = await api.dispatch(
          UtilityService.endpoints.image.initiate(arg.dataStoreId),
        );
        let imageId = 0;

        if (datastoreAsset.data) {
          const logo = datastoreAsset.data;
          const updatedImage = await api.dispatch(
            UtilityService.endpoints.updateImage.initiate({
              ...logo,
              file: arg.file,
              resellerId: arg.resellerId,
            }),
          );
          if (!isMutationSuccess(updatedImage)) {
            return { error: updatedImage.error as FetchBaseQueryError };
          }
          imageId = updatedImage.data.id;
        }

        /**
         * If we can't retrieve the existing logo from the bucket
         * we upload a new image
         */
        if (imageId === 0) {
          const uploadedImage = await api.dispatch(
            UtilityService.endpoints.uploadImage.initiate({
              file: arg.file,
              resellerId: arg.resellerId,
            }),
          );
          if (!isMutationSuccess(uploadedImage)) {
            return { error: uploadedImage.error as FetchBaseQueryError };
          }
          imageId = uploadedImage.data.id;
        }

        const savedProperty = await baseQuery({
          url: 'reseller-property',
          method: 'POST',
          body: {
            propertyName: arg.assetType,
            propertyValue: String(imageId),
            resellerId: arg.resellerId,
          },
        });

        if (savedProperty.error) {
          return { error: savedProperty.error };
        }
        return { data: undefined };
      },
    }),
    subdomainAssets: builder.query<
      ResellerSubdomainAssets,
      { subdomain: string }
    >({
      query: (arg) => ({ url: `/reseller/domain/${arg.subdomain}` }),
      extraOptions: { requireAuth: false },
    }),
  }),
  overrideExisting: false,
});

export const {
  useResellersQuery,
  useResellerQuery,
  useResellerPropertyQuery,
  useCreateResellerMutation,
  useEditResellerMutation,
  useSaveResellerPropertyMutation,
  useSaveResellerPropertiesMutation,
} = endpoints;
export { endpoints as ResellerService };
/**
 * TODO:
 * Add argument and types for options
 * FIXME:
 * - There seems to be hidden type mismatches here. but for now it works
 */
export const useResellerProperties = <TNames extends ResellerProperty>(args: {
  names: TNames[];
  resellerId: number;
}) => {
  const query = endpoints.useResellerPropertiesQuery(
    {
      names: args.names,
      resellerId: args.resellerId,
    },
    {
      skip: args.resellerId === 0,
    },
  );

  const { data } = query;
  const dataValues = data
    ? data.reduce(
        (all, value, index) => ({ ...all, [args.names[index]]: value }),
        {} as Record<TNames, string>,
      )
    : undefined;

  return { ...query, data: dataValues } as unknown as TypedUseQueryHookResult<
    Record<TNames, string>,
    unknown,
    any
  >;
};
