import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'baseui/snackbar';
import { millisecondsToMinutes } from 'date-fns';
import parseDuration from 'parse-duration';
import prettyMilliseconds from 'pretty-ms';
import { DollarSign as DollarSignIcon } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { MutationBanner } from 'components/Banner';
import { FieldGroup } from 'components/forms/fieldset';
import { QueryResolver } from 'components/QueryResolver';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from 'components/ui/dialog';
import { Input, TextField } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Select, SelectField, SelectItem } from 'components/ui/select';
import { isMutationSuccess } from 'services/api/base-api';
import {
  useSaveWorkTypeMutation,
  useWorkTypeQuery,
} from 'services/api/work-type/endpoints';
import { useLocale } from 'utils/hooks/useLocale';
import { useEffect } from 'react';

export default function EditItem() {
  const { t } = useLocale();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const params = useParams();
  const serviceQuery = useWorkTypeQuery(Number(params.pricebookId ?? '0'));
  const [editWorkType, mutation] = useSaveWorkTypeMutation();

  return (
    <Dialog onOpenChange={(open) => !open && navigate('..')} defaultOpen>
      <DialogTitle>{t('Edit Item')}</DialogTitle>
      <DialogBody>
        <MutationBanner show={mutation.isError} message={t('mutation_error')} />
        <QueryResolver query={serviceQuery}>
          {(service) => {
            const isQuickBooksLinked = service.quickbooksId !== null;
            const isItemInventory = service.type === 'INVENTORY';

            return (
              <PricebookServiceForm
                id="edit-pricebook-service-form"
                initialValue={{
                  name: service.name,
                  duration: prettyMilliseconds(
                    parseDuration(`${service.estimatedDuration}`) ?? 0,
                  ),
                  price: service.price,
                  type: service.type as 'SERVICE' | 'INVENTORY',
                  description: service.description ?? '',
                  cost: service.cost,
                  isTaxable: service.isTaxable ? 'true' : 'false',
                  quantityOnHand: service.quantityOnHand,
                  sku: service.sku ?? '',
                }}
                onSubmit={async (data) => {
                  const result = await editWorkType({
                    type: 'update',
                    payload: {
                      ...service,
                      name: data.name,
                      estimatedDuration: parseDuration(data.duration, 's'),
                      price: data.price,
                      type: data.type,
                      description: data.description,
                      cost: data.cost,
                      isTaxable: data.isTaxable === 'true',
                      quantityOnHand: data.quantityOnHand,
                      sku: data.sku,
                    },
                  });

                  if (isMutationSuccess(result)) {
                    const message =
                      data.type === 'INVENTORY'
                        ? t('pricebook_inventory_updated_successfully')
                        : t('pricebook_service_updated_successfully');

                    snackbar.enqueue({
                      message: message,
                    });
                    navigate('..');
                  }
                }}
                isQuickBooksLinked={isQuickBooksLinked}
                isItemInventory={isItemInventory}
              />
            );
          }}
        </QueryResolver>
      </DialogBody>
      <DialogActions>
        <Button slot="close" size="lg" variant="plain">
          {t('Cancel')}
        </Button>
        <Button
          size="lg"
          type="submit"
          form="edit-pricebook-service-form"
          isLoading={mutation.isLoading}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const nonEmptyString = z
  .string()
  .trim()
  .min(1, { message: 'errors.invalid_type_received_undefined' });
const schema = z
  .object({
    name: nonEmptyString,
    duration: z.string(),
    price: z.coerce.number(),
    type: z.nativeEnum(
      {
        SERVICE: 'SERVICE',
        INVENTORY: 'INVENTORY',
      } as const,
      {
        invalid_type_error: 'errors.invalid_type_received_undefined',
      },
    ),
    description: z.string(),
    sku: z.string(),
    cost: z.coerce.number(),
    isTaxable: z.nativeEnum(
      {
        true: 'true',
        false: 'false',
      } as const,
      {
        invalid_type_error: 'errors.invalid_type_received_undefined',
      },
    ),
    quantityOnHand: z.coerce.number(),
  })
  .superRefine((data, ctx) => {
    if (data.type === 'SERVICE') {
      if (!data.duration || data.duration.trim().length === 0) {
        ctx.addIssue({
          path: ['duration'],
          code: z.ZodIssueCode.custom,
          params: { i18n: 'invalid_duration' },
        });
      } else {
        const parsedValue = parseDuration(data.duration);
        if (parsedValue === null) {
          ctx.addIssue({
            path: ['duration'],
            code: z.ZodIssueCode.custom,
            params: { i18n: 'invalid_duration' },
          });
        } else if (millisecondsToMinutes(parsedValue) < 15) {
          ctx.addIssue({
            path: ['duration'],
            code: z.ZodIssueCode.custom,
            params: { i18n: 'invalid_duration' },
          });
        }
      }
    } else if (
      data.type === 'INVENTORY' &&
      data.duration &&
      data.duration.trim().length > 0
    ) {
      const parsedValue = parseDuration(data.duration);
      if (parsedValue === null) {
        ctx.addIssue({
          path: ['duration'],
          code: z.ZodIssueCode.custom,
          params: { i18n: 'invalid_duration' },
        });
      }
    }
  });

type FormFields = z.infer<typeof schema>;

function PricebookServiceForm({
  initialValue,
  onSubmit: onSubmitHandler,
  id = '',
  isQuickBooksLinked,
  isItemInventory,
}: {
  initialValue?: FormFields;
  onSubmit?: (data: FormFields) => void;
  id?: string;
  isQuickBooksLinked: boolean;
  isItemInventory: boolean;
}) {
  const { t } = useLocale();
  const { control, handleSubmit, watch, reset } = useForm<FormFields>({
    defaultValues: initialValue ?? {},
    resolver: zodResolver(schema),
  });

  const type = watch('type');

  const onSubmit = handleSubmit((data) => {
    if (onSubmitHandler) {
      onSubmitHandler(data);
    }
  });

  useEffect(() => {
    if (type === 'INVENTORY') {
      reset((prevValues) => ({ ...prevValues, duration: '' }));
    }
  }, [type, reset]);

  return (
    <form onSubmit={onSubmit} id={id}>
      <FieldGroup>
        <div className="grid gap-x-4 gap-y-5 lg:grid-cols-3">
          <TextField
            control={control}
            field="name"
            isDisabled={isQuickBooksLinked}
          >
            <Label>{t('Item Name')}</Label>
            <Input placeholder="Cable Repair" />
          </TextField>
          <TextField
            control={control}
            field="description"
            isDisabled={isQuickBooksLinked}
          >
            <Label>{t('Item Description')}</Label>
            <Input />
          </TextField>
          <TextField
            control={control}
            field="sku"
            isDisabled={isQuickBooksLinked}
          >
            <Label>SKU</Label>
            <Input />
          </TextField>
        </div>
        <div className="grid gap-x-4 gap-y-5 lg:grid-cols-2">
          <SelectField
            control={control}
            field="type"
            isDisabled={isQuickBooksLinked}
          >
            <Label>{t('Item Type')}</Label>
            <Select>
              <SelectItem id="INVENTORY">{t('INVENTORY')}</SelectItem>
              <SelectItem id="SERVICE">{t('SERVICE')}</SelectItem>
            </Select>
          </SelectField>
          <SelectField
            control={control}
            field="isTaxable"
            isDisabled={isQuickBooksLinked}
          >
            <Label>{t('Taxable')}</Label>
            <Select>
              <SelectItem id="true">{t('Yes')}</SelectItem>
              <SelectItem id="false">{t('No')}</SelectItem>
            </Select>
          </SelectField>
        </div>
        <div className="grid gap-x-4 gap-y-5 lg:grid-cols-2">
          <TextField
            control={control}
            field="duration"
            isDisabled={isItemInventory && isQuickBooksLinked}
          >
            <Label>{t('Estimated Time')}</Label>
            <Input placeholder="2h" />
          </TextField>
          <TextField
            control={control}
            field="price"
            isDisabled={isQuickBooksLinked}
          >
            <Label>{t('Sales Price')}</Label>
            <Input startEnhancer={<DollarSignIcon data-slot="icon" />} />
          </TextField>
          <TextField
            control={control}
            field="cost"
            isDisabled={isQuickBooksLinked}
          >
            <Label>{t('Cost')}</Label>
            <Input startEnhancer={<DollarSignIcon data-slot="icon" />} />
          </TextField>
          <TextField
            control={control}
            field="quantityOnHand"
            isDisabled={isQuickBooksLinked}
          >
            <Label>{t('Quantity On Hand')}</Label>
            <Input />
          </TextField>
        </div>
      </FieldGroup>
    </form>
  );
}
