import { mergeOverrides } from 'baseui';
import { KIND } from 'baseui/button';
import type {
  InputProps as BaseInputProps,
  InputOverrides,
  SharedProps,
} from 'baseui/input';
import { Input as BaseInput } from 'baseui/input';
import { forwardRef, useEffect, useRef } from 'react';
import type { StyleObject } from 'styletron-react';

import { getDefaultKindStyles } from './styles';

import theme from 'theme';

export type InputProps = BaseInputProps & {
  kind?: keyof typeof KIND;
};

const inputOverrides = (props: Pick<InputProps, 'kind'>): InputOverrides => ({
  Root: {
    style: (sharedProps: SharedProps) => ({
      ...getDefaultKindStyles(props.kind ?? 'primary', sharedProps),
    }),
  },
  Input: {
    props: {
      className: 'placeholder:text-gray-500',
    },
    style: {
      backgroundColor: theme.colors.white,
    } as StyleObject,
  },
});

// eslint-disable-next-line react/display-name
const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { kind = KIND.secondary, size = 'default', ...baseInputProps } = props;
  /**
   * This is not recommended, but since Baseweb exposes refs
   * weirdly, we need this hack. In the future this component
   * should migrate to a more flexible Input component like `React Arias` Input component
   */
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (typeof ref === 'function') {
      ref(inputRef.current);
    }
  }, [ref]);

  /**
   * There is an issue when using react hook form with a its controller field ref where
   * the input does not behave correctly as the callback ref messes up with
   * internal logic of the component.
   *
   * So, in this component that we forward a ref we just pass the forwared ref to the internal
   * ref prop to be able to pass it. when there is a use case of needing the input ref please use the `inputRef` prop
   */
  return (
    <BaseInput
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      inputRef={inputRef}
      {...baseInputProps}
      // value={baseInputProps.value}
      size={size}
      overrides={mergeOverrides(
        inputOverrides({ kind }),
        baseInputProps.overrides,
      )}
    />
  );
});

export default Input;
