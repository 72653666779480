import * as React from 'react';
import type { LabelProps as RACLabelProps } from 'react-aria-components';
import {
  LabelContext,
  useContextProps,
  Label as RACLabel,
} from 'react-aria-components';
import { tv, VariantProps } from 'tailwind-variants';

import { cn } from 'utils/helpers';

export const labelVariants = tv({
  base: 'block select-none font-prompt text-base/6 font-semibold text-zinc-950/60 peer-disabled:cursor-not-allowed peer-disabled:opacity-70 data-[disabled]:opacity-50',
});
export type LabelProps = RACLabelProps & VariantProps<typeof labelVariants>;

const Label = React.forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  const { className } = props;
  [props, ref] = useContextProps(props, ref, LabelContext);
  return (
    <RACLabel
      ref={ref}
      {...props}
      className={cn(labelVariants(), className)}
      data-slot="label"
    />
  );
});
Label.displayName = 'Label';

export { Label };
