import { zodResolver } from '@hookform/resolvers/zod';
import { getLocalTimeZone, parseDate } from '@internationalized/date';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Skeleton } from 'baseui/skeleton';
import { useSnackbar } from 'baseui/snackbar';
import { endOfMonth, isAfter, isDate, isToday, startOfMonth } from 'date-fns';
import { useContext, useEffect, useRef } from 'react';
import {
  Button as AriaButton,
  Calendar as AriaCalendar,
  DateInput as AriaDateInput,
  DateInputProps as AriaDateInputProps,
  DatePicker as AriaDatePicker,
  DatePickerProps as AriaDatePickerProps,
  DateSegment as AriaDateSegment,
  Dialog as AriaDialog,
  Group as AriaGroup,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DatePickerStateContext,
  DateValue,
  Group,
  Menu,
  MenuItem,
  MenuTrigger,
} from 'react-aria-components';
import {
  Calendar as CalendarIcon,
  ChevronDown as ChevronDownIcon,
  ChevronLeft,
  ChevronRight as ChevronRightIcon,
  Download as DownloadIcon,
  Truck as TruckIcon,
  User as UserIcon,
} from 'react-feather';
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { tv } from 'tailwind-variants';
import { z } from 'zod';

import { FieldErrorMessage, fieldStyles } from 'components/forms/fieldset';
import { Pagination } from 'components/Pagination';
import { QueryResolver } from 'components/QueryResolver';
import { createTableBuilder } from 'components/Table';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { Heading } from 'components/ui/heading';
import { Label } from 'components/ui/label';
import { Popover } from 'components/ui/popover';
import { Select, SelectField, SelectItem } from 'components/ui/select';
import { Strong, Text } from 'components/ui/text';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { isMutationSuccess } from 'services/api/base-api';
import { EmployeeService } from 'services/api/employees/endpoints';
import {
  ActiveTechnician,
  ActiveTechnicianCount,
} from 'services/api/employees/types';
import { useResellersQuery } from 'services/api/resellers/endpoints';
import { Reseller } from 'services/api/resellers/types';
import { paginatedParamsSchema } from 'services/api/types.shared';
import {
  cn,
  formatDate,
  stripUndefined,
  toPayloadDateFormat,
} from 'utils/helpers';
import { useValidatedSearchParams } from 'utils/hooks/useValidatedSearchParams';
import { toOptionalSchemaProperties } from 'utils/schemas';

const ActiveTechnicianTable = createTableBuilder<ActiveTechnician>();
const TechnicianCountTable = createTableBuilder<ActiveTechnicianCount>();

function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
}

const schema = z.object({
  resellerId: z.coerce
    .number({
      required_error: 'Required',
      invalid_type_error: 'Required',
    })
    .optional(),
  dateStart: z.coerce.date({
    errorMap: ({ code }, { defaultError }) => {
      if (code === 'invalid_date') {
        return { message: 'Required' };
      }
      return { message: defaultError };
    },
  }),
  dateEnd: z.coerce.date({
    errorMap: ({ code }, { defaultError }) => {
      if (code === 'invalid_date') {
        return { message: 'Required' };
      }
      return { message: defaultError };
    },
  }),
  type: z
    .nativeEnum({ list: 'list', summary: 'summary' } as const)
    .optional()
    .nullable()
    .catch('summary' as const),

  // timestamp to indicate submission
  ts: z.coerce.number().optional(),
});

// Since using `superRefine` returns a ZodEffect we cannot manipulate the object anymore so we extract it to another schema
const formFieldsSchema = schema.superRefine((data, ctx) => {
  if (isAfter(data.dateStart, data.dateEnd)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['dateStart'],
      message: 'Start date later than end date',
    });
  }
});
type FormFields = z.infer<typeof schema>;

export default function AdminBillingPage() {
  const searchParamValues = useValidatedSearchParams(
    toOptionalSchemaProperties(schema).optional(),
  ).values;
  const query = useResellersQuery();
  const form = useForm<FormFields>({
    defaultValues: {
      ...searchParamValues,
      resellerId: searchParamValues?.resellerId ?? 0,
      dateStart: searchParamValues?.dateStart ?? startOfMonth(new Date()),
      dateEnd: searchParamValues?.dateEnd ?? endOfMonth(new Date()),
    },
    resolver: zodResolver(formFieldsSchema),
  });
  const navigate = useNavigate();
  const onSubmit = form.handleSubmit((data) => {
    navigate({
      pathname: '.',
      search: `?${new URLSearchParams(
        stripUndefined({
          ts: Date.now(),
          resellerId: data.resellerId ? data.resellerId : undefined,
          dateStart: data.dateStart.toISOString(),
          dateEnd: data.dateEnd.toISOString(),
          type: 'summary',
        }),
      ).toString()}`,
    });
  });

  const onGenerateList = form.handleSubmit((data) => {
    navigate({
      pathname: '.',
      search: `?${new URLSearchParams(
        stripUndefined({
          ts: Date.now(),
          resellerId: data.resellerId ? data.resellerId : undefined,
          dateStart: data.dateStart.toISOString(),
          dateEnd: data.dateEnd.toISOString(),
          type: 'list',
        }),
      ).toString()}`,
    });
  });

  return (
    <div className="space-y-8">
      <DashboardContentHeader title="Billing" description="" />
      <Card>
        <CardHeader>
          <Heading>Active Technicians List</Heading>
        </CardHeader>
        <CardContent>
          <QueryResolver query={query}>
            {(resellers) => (
              <>
                <form className="grid gap-4 md:grid-cols-3" onSubmit={onSubmit}>
                  <SelectField control={form.control} field="resellerId">
                    <Label>Reseller</Label>
                    <Select
                      // @TODO: optimize this later down the line
                      items={[
                        { id: 0, businessName: 'All Resellers' },
                        ...[...resellers]
                          .sort((a, b) => {
                            if (a.businessName < b.businessName) {
                              return 1;
                            } else if (a.businessName > b.businessName) {
                              return -1;
                            } else {
                              return 0;
                            }
                          })
                          .reverse(),
                      ]}
                      variant="default"
                    >
                      {(reseller) => (
                        <SelectItem textValue={reseller.businessName}>
                          {(reseller as Reseller).parentReseller && (
                            <Badge>Parent Reseller</Badge>
                          )}
                          <Text slot="label">{reseller.businessName}</Text>
                        </SelectItem>
                      )}
                    </Select>
                  </SelectField>

                  <DatePickerField control={form.control} field="dateStart">
                    <Label>Billing From</Label>
                    <DatePicker />
                  </DatePickerField>
                  <DatePickerField control={form.control} field="dateEnd">
                    <Label>Billing To</Label>
                    <DatePicker />
                  </DatePickerField>
                  <div className="col-span-full flex justify-end ">
                    <ButtonGrouped>
                      <Button type="submit">Generate Summary</Button>
                      <MenuTrigger>
                        <Button type="button" square>
                          <ChevronDownIcon data-slot="icon" />
                        </Button>
                        <Popover
                          className="w-full max-w-[18rem]"
                          placement="bottom right"
                        >
                          <Menu
                            className="py-1 outline-none data-[focused]:ring"
                            onAction={(key) => {
                              if (key === 'generate-summary') {
                                onSubmit();
                              } else if (key === 'generate-list') {
                                onGenerateList();
                              }
                            }}
                          >
                            <MenuItem
                              id="generate-summary"
                              className="group px-3.5 py-2.5 data-[hovered]:bg-gray-50 data-[focused]:outline-none"
                            >
                              <div>
                                <Text
                                  slot="label"
                                  className="cursor-default group-data-[hovered]:underline"
                                >
                                  <Strong>Generate Summary</Strong>
                                </Text>
                                <Text slot="description">
                                  Shows all reseller clients along with the
                                  total number of technicians.
                                </Text>
                              </div>
                            </MenuItem>
                            <MenuItem
                              id="generate-list"
                              className="group px-3.5 py-2.5 data-[hovered]:bg-gray-50 data-[focused]:outline-none"
                            >
                              <div>
                                <Text
                                  slot="label"
                                  className="cursor-default group-data-[hovered]:underline"
                                >
                                  <Strong>Generate List</Strong>
                                </Text>
                                <Text slot="description">
                                  Shows all the technicians for each reseller.
                                </Text>
                              </div>
                            </MenuItem>
                          </Menu>
                        </Popover>
                      </MenuTrigger>
                    </ButtonGrouped>
                  </div>
                </form>
              </>
            )}
          </QueryResolver>
        </CardContent>
        <CardContent className="border-t">
          {searchParamValues?.type === 'summary' ? (
            <ActiveTechnicianSummary />
          ) : (
            <ActiveTechniciansList />
          )}
        </CardContent>
      </Card>
    </div>
  );
}

function ButtonGrouped({ children }: { children: React.ReactNode }) {
  return (
    <Group className="flex [&>*:first-child]:rounded-r-none [&>*:last-child]:rounded-l-none">
      {children}
    </Group>
  );
}

function ActiveTechnicianSummary() {
  const values = useValidatedSearchParams(
    toOptionalSchemaProperties(
      schema.extend(paginatedParamsSchema.shape),
    ).optional(),
  ).values;

  const query = EmployeeService.endpoints.activeTechniciansCount.useQuery(
    values && Object.keys(values).length !== 0
      ? {
          params: {
            resellerId: values.resellerId,
            dateFrom: toPayloadDateFormat(values.dateStart),
            dateTo: toPayloadDateFormat(values.dateEnd),
          },
        }
      : skipToken,
    { skip: values === undefined },
  );
  const activeTechncians = query.data ?? [];
  const totalTechnicians = query.data?.reduce(
    (prev, curr) => prev + curr.total,
    0,
  );

  const ts = values?.ts;
  const refetch = useRef(query.refetch);
  useEffect(() => {
    if (ts) {
      refetch.current();
    }
  }, [ts]);

  if (!values || Object.keys(values).length === 0) {
    return (
      <div className="rounded-xl border border-dashed py-7 text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          Configure Report
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Get started by selecting a reseller,start date, end date and click{' '}
          <span className="font-medium text-brand-primary">Generate List</span>{' '}
          to view their active technicians.
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <Heading>
            Active Technicians from{' '}
            {values
              ? `${formatDate(values.dateStart)}-${formatDate(values.dateEnd)}`
              : null}
          </Heading>
          <Text>
            Total of <Strong>{totalTechnicians}</Strong> technicians
          </Text>
        </div>
        <div className="col-span-full flex justify-end">
          <ExportList />
        </div>
      </div>
      <div className="mt-4">
        {query.isFetching || query.isLoading ? (
          <Skeleton rows={5} />
        ) : (
          Object.entries(
            groupBy(activeTechncians, (item) => item.resellerName),
          ).map(([reseller, group]) => {
            const parentResellerName = group.find(
              (v) => v.resellerName === reseller,
            )?.parentResellerName;
            return (
              <section key={reseller}>
                <header className="flex items-center space-x-2 border-b bg-gray-50 px-4 py-3 text-sm">
                  <Heading>
                    <span className="flex items-center space-x-2">
                      {!!parentResellerName && (
                        <>
                          <span className="flex items-center space-x-1">
                            <span>{parentResellerName}</span>
                            <Badge>
                              <TechnicianCountValue
                                matchTo="parentResellerName"
                                valueToMatch={parentResellerName}
                              />{' '}
                              technician/s
                            </Badge>
                            <ChevronRightIcon className="h-5 w-5" />
                          </span>
                        </>
                      )}
                      <span>{reseller}</span>
                      <Badge>
                        <TechnicianCountValue
                          matchTo="resellerName"
                          valueToMatch={reseller}
                        />{' '}
                        technician/s
                      </Badge>
                    </span>
                  </Heading>
                </header>
                <TechnicianCountTable.Table
                  data={group}
                  isError={query.isError}
                  isLoading={query.isLoading || query.isFetching}
                  isEmpty={activeTechncians.length === 0}
                  loadingMessage={<Skeleton rows={5} />}
                  emptyMessage={
                    <div className="flex flex-col items-center space-y-2 px-4 py-5 text-center text-sm">
                      <UserIcon aria-hidden="true" className="text-gray-400" />
                      <div>
                        <h3 className="font-bold text-gray-500">
                          No Active Technicians
                        </h3>
                      </div>
                    </div>
                  }
                  overrides={{
                    TableBodyCell: {
                      style: { maxWidth: '10rem', width: '10rem' },
                    },
                  }}
                >
                  <TechnicianCountTable.Column header="Client">
                    {(row) => row.clientName}
                  </TechnicianCountTable.Column>
                  <TechnicianCountTable.Column header="Total Technicians">
                    {(row) => row.total}
                  </TechnicianCountTable.Column>
                </TechnicianCountTable.Table>
              </section>
            );
          })
        )}
      </div>
    </div>
  );
}

function ActiveTechniciansList() {
  const values = useValidatedSearchParams(
    toOptionalSchemaProperties(
      schema.extend(paginatedParamsSchema.shape),
    ).optional(),
  ).values;

  const query = EmployeeService.endpoints.activeTechnicians.useQuery(
    values && Object.keys(values).length !== 0
      ? {
          params: {
            resellerId: values.resellerId,
            dateFrom: toPayloadDateFormat(values.dateStart),
            dateTo: toPayloadDateFormat(values.dateEnd),
            page: values.page,
          },
        }
      : skipToken,
    { skip: values === undefined, refetchOnMountOrArgChange: true },
  );
  const activeTechncians = query.data?.list ?? [];
  const totalTechnicians = query.data?.totalElements ?? 0;

  const ts = values?.ts;
  const refetch = useRef(query.refetch);
  useEffect(() => {
    if (ts) {
      refetch.current();
    }
  }, [ts]);

  if (!values || Object.keys(values).length === 0) {
    return (
      <div className="rounded-xl border border-dashed py-7 text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          Configure Report
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Get started by selecting a reseller,start date, end date and click{' '}
          <span className="font-medium text-brand-primary">Generate List</span>{' '}
          to view their active technicians.
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <Heading>
            Active Technicians from{' '}
            {values
              ? `${formatDate(values.dateStart)}-${formatDate(values.dateEnd)}`
              : null}
          </Heading>
          <Text>
            Total of <Strong>{totalTechnicians}</Strong> technicians
          </Text>
        </div>
        {totalTechnicians !== 0 && (
          <div className="col-span-full flex justify-end">
            <ExportList />
          </div>
        )}
      </div>
      <div className="mt-4">
        {query.isFetching || query.isLoading ? (
          <Skeleton rows={5} />
        ) : (
          Object.entries(
            groupBy(activeTechncians, (item) => item.resellerName),
          ).map(([reseller, group]) => {
            const parentResellerName = group.find(
              (v) => v.resellerName === reseller,
            )?.parentResellerName;

            return (
              <section key={reseller}>
                <header className="flex items-center space-x-2 border-b bg-gray-50 px-4 py-3 text-sm">
                  <Heading>
                    <span className="flex items-center space-x-2">
                      {!!parentResellerName && (
                        <>
                          <span className="flex items-center space-x-1">
                            <span>{parentResellerName}</span>
                            <Badge>
                              <TechnicianCountValue
                                matchTo="parentResellerName"
                                valueToMatch={parentResellerName}
                              />{' '}
                              technician/s
                            </Badge>
                            <ChevronRightIcon className="h-5 w-5" />
                          </span>
                        </>
                      )}
                      <span>{reseller}</span>
                      <Badge>
                        <TechnicianCountValue
                          matchTo="resellerName"
                          valueToMatch={reseller}
                        />{' '}
                        technician/s
                      </Badge>
                    </span>
                  </Heading>
                </header>
                {Object.entries(groupBy(group, (item) => item.clientName)).map(
                  ([client, clientGroup]) => (
                    <section key={client}>
                      <header className="flex items-center space-x-4 border-b bg-gray-50 px-4 py-2 text-sm">
                        <div className="flex items-center space-x-1">
                          <span>
                            <TruckIcon className="h-4 w-4 text-zinc-400" />
                          </span>
                          <Text>{client}</Text>
                        </div>
                        <Badge>
                          <TechnicianCountValue
                            matchTo="clientName"
                            valueToMatch={client}
                          />{' '}
                          technician/s
                        </Badge>
                      </header>
                      <ActiveTechnicianTable.Table
                        data={clientGroup}
                        isError={query.isError}
                        isLoading={query.isLoading || query.isFetching}
                        isEmpty={activeTechncians.length === 0}
                        loadingMessage={<Skeleton rows={5} />}
                        emptyMessage={
                          <div className="flex flex-col items-center space-y-2 px-4 py-5 text-center text-sm">
                            <UserIcon
                              aria-hidden="true"
                              className="text-gray-400"
                            />
                            <div>
                              <h3 className="font-bold text-gray-500">
                                No Active Technicians
                              </h3>
                            </div>
                          </div>
                        }
                        overrides={{
                          TableBodyCell: {
                            style: { maxWidth: '13rem', width: '13rem' },
                          },
                        }}
                      >
                        <ActiveTechnicianTable.Column header="Email Address">
                          {(row) => (
                            <span className="break-all">{row.email}</span>
                          )}
                        </ActiveTechnicianTable.Column>
                        <ActiveTechnicianTable.Column header="Technician Name">
                          {(row) => `${row.firstname} ${row.lastname}`}
                        </ActiveTechnicianTable.Column>
                        <ActiveTechnicianTable.Column header="Was active on">
                          {(row) => formatDate(new Date(row.active))}
                        </ActiveTechnicianTable.Column>
                        <ActiveTechnicianTable.Column header="Was inactive on">
                          {(row) =>
                            row.inactive
                              ? formatDate(new Date(row.inactive))
                              : '---'
                          }
                        </ActiveTechnicianTable.Column>
                      </ActiveTechnicianTable.Table>
                    </section>
                  ),
                )}
              </section>
            );
          })
        )}
        <div className="mt-3 flex justify-end">
          <Pagination totalItems={query.data?.totalElements} pageSize={20} />
        </div>
      </div>
    </div>
  );
}

function ExportList() {
  const values = useValidatedSearchParams(
    toOptionalSchemaProperties(schema.extend(paginatedParamsSchema.shape)),
  ).values;
  const [exportList, mutation] =
    EmployeeService.endpoints.exportActiveTechnicians.useMutation();
  const snackbar = useSnackbar();
  const handleExport = async (
    type: Parameters<typeof exportList>[0]['exportType'],
  ) => {
    snackbar.enqueue({ message: 'Exporting', progress: true });
    const result = await exportList({
      exportType: type,
      resellerId: values.resellerId,
      dateFrom: toPayloadDateFormat(values.dateStart),
      dateTo: toPayloadDateFormat(values.dateEnd),
    });
    snackbar.dequeue();
    if (isMutationSuccess(result)) {
      const csv = result.data;
      const url = window.URL.createObjectURL(new Blob([csv]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${[
          'Active_Techncians',
          type === 'list' ? 'List' : 'Summary',
          toPayloadDateFormat(values.dateStart),
          toPayloadDateFormat(values.dateEnd),
        ].join('_')}.csv`,
      );
      document.body.appendChild(link);
      link.click();
      snackbar.enqueue({ message: 'Exported List' });
    } else {
      snackbar.enqueue({ message: 'Failed to export list' });
    }
  };
  const handleListExport = async () => handleExport('list');
  const handleSummaryExport = async () => handleExport('summary');

  return (
    <ButtonGrouped>
      <Button
        type="button"
        startEnhancer={<DownloadIcon className="h-full w-full" />}
        onClick={handleSummaryExport}
        isLoading={mutation.isLoading}
      >
        Export Summary
      </Button>
      <MenuTrigger>
        <Button type="button" square isDisabled={mutation.isLoading}>
          <ChevronDownIcon data-slot="icon" />
        </Button>
        <Popover className="w-full max-w-[18rem]" placement="bottom right">
          <Menu
            className="py-1 outline-none data-[focused]:ring"
            onAction={(key) => {
              if (key === 'export-summary') {
                handleSummaryExport();
              } else if (key === 'export-list') {
                handleListExport();
              }
            }}
          >
            <MenuItem
              id="export-summary"
              className="group px-3.5 py-2.5 data-[hovered]:bg-gray-50 data-[focused]:outline-none"
            >
              <div>
                <Text
                  slot="label"
                  className="cursor-default group-data-[hovered]:underline"
                >
                  <Strong>Export Summary</Strong>
                </Text>
                <Text slot="description">
                  Export summary of shown data as CSV.
                </Text>
              </div>
            </MenuItem>
            <MenuItem
              id="export-list"
              className="group px-3.5 py-2.5 data-[hovered]:bg-gray-50 data-[focused]:outline-none"
            >
              <div>
                <Text
                  slot="label"
                  className="cursor-default group-data-[hovered]:underline"
                >
                  <Strong>Export List</Strong>
                </Text>
                <Text slot="description">
                  Export list of shown data as CSV.
                </Text>
              </div>
            </MenuItem>
          </Menu>
        </Popover>
      </MenuTrigger>
    </ButtonGrouped>
  );
}

function TechnicianCountValue({
  valueToMatch,
  matchTo,
}: {
  valueToMatch: string;
  matchTo: keyof Pick<
    ActiveTechnicianCount,
    'parentResellerName' | 'clientName' | 'resellerName'
  >;
}) {
  const values = useValidatedSearchParams(
    toOptionalSchemaProperties(
      schema.extend(paginatedParamsSchema.shape),
    ).optional(),
  ).values;
  const data = EmployeeService.endpoints.activeTechniciansCount.useQuery(
    values && Object.keys(values).length !== 0
      ? {
          params: {
            resellerId: values.resellerId,
            dateFrom: toPayloadDateFormat(values.dateStart),
            dateTo: toPayloadDateFormat(values.dateEnd),
          },
        }
      : skipToken,
    { skip: values === undefined },
  ).data;

  return (
    <>
      {data?.reduce((all, item) => {
        const total = item[matchTo] === valueToMatch ? item.total : 0;
        return total + all;
      }, 0)}
    </>
  );
}

const segmentStyles = tv({
  base: 'type-literal:px-0 forced-color-adjust-none forced-colors:text-[ButtonText] inline rounded p-0.5 text-gray-800 caret-transparent outline outline-0 dark:text-zinc-200',
  variants: {
    isPlaceholder: {
      true: 'italic text-gray-600 dark:text-zinc-400',
    },
    isDisabled: {
      true: 'forced-colors:text-[GrayText] text-gray-200 dark:text-zinc-600',
    },
    isFocused: {
      true: 'forced-colors:bg-[Highlight] forced-colors:text-[HighlightText] bg-blue-600 text-white dark:text-white',
    },
  },
});

function DatePickerField<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  children,
  control,
  field: fieldName,
  ...props
}: AriaDatePickerProps<DateValue> & { control: Control<T>; field: TName }) {
  const controller = useController({ control, name: fieldName });
  const field = controller.field;
  const error = !!controller.fieldState.error?.message;

  return (
    <AriaDatePicker
      {...props}
      ref={field.ref}
      name={field.name}
      value={
        field.value && isDate(field.value)
          ? parseDate(toPayloadDateFormat(new Date(field.value)))
          : null
      }
      className={fieldStyles({ className: 'group' })}
      onChange={(selected) =>
        field.onChange(selected.toDate(getLocalTimeZone()))
      }
      onBlur={field.onBlur}
      isInvalid={error}
    >
      {(renderProps) => (
        <>
          {typeof children === 'function' ? children(renderProps) : children}
          <FieldErrorMessage control={control} field={fieldName} />
        </>
      )}
    </AriaDatePicker>
  );
}

function DatePicker() {
  return (
    <>
      <AriaGroup className="relative z-0" data-slot="control">
        <DateInput>
          {(segment) => (
            <AriaDateSegment segment={segment} className={segmentStyles} />
          )}
        </DateInput>
        <AriaButton className="absolute inset-0 flex w-full items-center justify-end rounded pr-3 outline-offset-0">
          <CalendarIcon aria-hidden className="h-4 w-4" />
        </AriaButton>
      </AriaGroup>
      <Popover>
        <AriaDialog className="relative max-h-[inherit] overflow-auto p-6 outline outline-0 [[data-placement]>&]:p-4">
          <Calendar />
        </AriaDialog>
      </Popover>
    </>
  );
}

function Calendar() {
  return (
    <AriaCalendar aria-label="Calendar">
      <header className="flex items-center text-gray-900">
        <Button slot="previous" type="button" variant="plain" square>
          <ChevronLeft className="h-5 w-5" aria-hidden="true" />
        </Button>
        <div className="flex-auto text-center">
          <Heading className="" />
        </div>
        <Button slot="next" type="button" variant="plain" square>
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </header>
      <CalendarGrid className="mt-5 w-full overflow-hidden rounded bg-white text-sm">
        <CalendarGridHeader>
          {(day) => (
            <CalendarHeaderCell>
              <div className="flex h-10 w-10 items-center justify-center text-gray-500">
                <span>{day}</span>
              </div>
            </CalendarHeaderCell>
          )}
        </CalendarGridHeader>
        <CalendarGridBody>
          {(date) => (
            <CalendarCell date={date} className="">
              {(rp) => (
                <span
                  className={cn(
                    'mx-auto w-10 h-10 flex items-center justify-center ',
                    {
                      'bg-brand-primary-600 text-white rounded':
                        // rp.isSelected && isToday(date.toDate(getTimezone())),
                        rp.isSelected,
                      'rounded-l-lg': rp.isSelectionStart,
                      'rounded-r-lg': rp.isSelectionEnd,
                      rounded: !rp.isSelected,
                      'bg-gray-50 text-gray-200': rp.isDisabled,
                      'line-through text-red-500': rp.isUnavailable,
                      'font-semibold': isToday(date.toDate(getLocalTimeZone())),
                    },
                  )}
                >
                  {date.day}
                </span>
              )}
            </CalendarCell>
          )}
        </CalendarGridBody>
      </CalendarGrid>
    </AriaCalendar>
  );
}

function DateInput(props: AriaDateInputProps) {
  const datePickerState = useContext(DatePickerStateContext);

  return (
    <div className="relative" data-slot="control">
      {/* <InputContext.Provider
        value={{
          onFocus: (e) => {
            console.log('event', e);
            if (datePickerState) {
              datePickerState.open();
            }
          },
        }}
      >
        
      </InputContext.Provider> */}
      <AriaDateInput
        {...props}
        data-slot="control"
        className={cn([
          // Base
          'peer form-input transition block w-full rounded-lg border-zinc-950/10 bg-white px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] text-base text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:ring-0',

          // Invalid state
          'data-[invalid]:border-red-500 data-[invalid]:data-[hovered]:border-red-500',

          // Disabled state
          'data-[disabled]:border-zinc-950/20',
        ])}
      />
      {/* <div
        className={cn([
          // Base
          'absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-brand-primary-600',

          // Invalid state
          'peer-data-[invalid]:border-red-500 peer-data-[invalid]:peer-data-[hovered]:border-red-500',

          // Disabled state
          'peer-data-[disabled]:border-zinc-950/20',
        ])}
        aria-hidden="true"
      /> */}
    </div>
  );
}
