import { Card } from 'baseui/card';
import type { SharedStyleProps } from 'baseui/checkbox';
import { Checkbox, StyledToggle } from 'baseui/checkbox';
import { useSnackbar } from 'baseui/snackbar';
import { useState } from 'react';
import { AlertCircle, Check, Edit, Plus, Users } from 'react-feather';
import { Outlet } from 'react-router-dom';

import { createTableBuilder } from 'components/Table';
import { Button } from 'components/ui/button';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { isMutationSuccess } from 'services/api/base-api';
import {
  useEditEmployeeMutation,
  useEmployeesQuery,
} from 'services/api/employees/endpoints';
import { Employee } from 'services/api/employees/types';
import { UserRole } from 'services/api/types.shared';

const INCLUDED_ROLES: UserRole[] = ['ParentResellerAdmin'];
export default function EmployeesIndex() {
  return (
    <>
      <Outlet />
      <div className="flex-1 space-y-5">
        <DashboardContentHeader
          title="Employees"
          description="Track and manage your Kaleidoscope Employees"
        />
        <section className="flex items-start space-x-4">
          <div className="flex-1 overflow-hidden">
            <EmployeesTable />
          </div>
        </section>
      </div>
    </>
  );
}

const { Table, Column } = createTableBuilder<Employee>();
function EmployeesTable() {
  const query = useEmployeesQuery();
  const employees =
    query.data?.filter((employee) =>
      employee.roles.some((role) => INCLUDED_ROLES.includes(role)),
    ) ?? [];

  return (
    <Card>
      <div className="mb-4 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Employees</h1>
        </div>
        <nav className="flex items-center space-x-4">
          <Button
            href="new"
            startEnhancer={<Plus className="h-full w-full" />}
            className="shrink-0"
          >
            New Employee
          </Button>
        </nav>
      </div>
      <Table
        data={employees}
        isLoading={query.isLoading || query.isFetching}
        isError={query.isError}
        isEmpty={employees.length === 0}
        emptyMessage={
          <div className="text-center">
            <Users className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No employees
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by creating a employee.
            </p>
            <div className="mt-6">
              <Button
                href="new"
                startEnhancer={<Plus className="h-full w-full" />}
              >
                New Employee
              </Button>
            </div>
          </div>
        }
      >
        <Column header="ID #">{(row) => row.id}</Column>
        <Column header="Name">
          {(row) => (
            <span className="whitespace-nowrap">
              {`${row.firstname} ${row.lastname}`}
            </span>
          )}
        </Column>
        <Column header="Email">{(row) => row.email}</Column>
        <Column header="Account Status">
          {(row) => (
            <div className="flex">
              <AccountStatusToggle employee={row} />
            </div>
          )}
        </Column>
        <Column header="Actions">
          {(row) => (
            <Button href={`${row.id}/edit`} variant="plain">
              <Edit data-slot="icon" />
            </Button>
          )}
        </Column>
      </Table>
    </Card>
  );
}

function AccountStatusToggle({ employee }: { employee: Employee }) {
  const [editEmployee, mutation] = useEditEmployeeMutation();
  const { enqueue } = useSnackbar();
  const [isChecked, setIsChecked] = useState(employee.isEnabled);

  return (
    <Checkbox
      checkmarkType="toggle"
      checked={isChecked}
      disabled={mutation.isLoading}
      onChange={async ({ target }) => {
        const isActivated = target.checked;
        setIsChecked(isActivated);

        const result = await editEmployee({
          ...employee,
          isEnabled: isActivated,
        });
        if (isMutationSuccess(result)) {
          enqueue({
            message: `Update account status of ${employee.firstname} ${employee.lastname} successfully.`,
            startEnhancer: ({ size }) => <Check size={size} />,
          });
        } else {
          setIsChecked(!isActivated);
          enqueue({
            message: `Failed to update account of ${employee.firstname} ${employee.lastname}. try again.`,
            startEnhancer: ({ size }) => <AlertCircle size={size} />,
          });
        }
      }}
      overrides={{
        Root: {
          props: { className: 'flex justify-center' },
        },
        Toggle: {
          component: (props: SharedStyleProps) => (
            <StyledToggle
              {...props}
              className={props.$checked ? 'bg-green-500' : 'text-gray-700'}
            />
          ),
        },
      }}
    />
  );
}
