import { getOverrides, mergeOverrides } from 'baseui';
import type {
  FormControlProps as BaseFormControlProps,
  FormControlOverrides,
} from 'baseui/form-control';
import {
  FormControl as BaseFormControl,
  StyledLabel,
} from 'baseui/form-control';
import type { Override } from 'baseui/overrides';
import clsx from 'clsx';
import React from 'react';

import theme, { styled } from 'theme';

const Root = styled('div', {});
type OwnProps = {
  description?: string;
  isRequired?: boolean;
  overrides?: FormControlOverrides & Partial<{ Root: Override }>;
};
export type FormControlProps = OwnProps & BaseFormControlProps;

export default function FormControl(props: FormControlProps) {
  const { description, isRequired, ...formControlProps } = props;
  const [RootComponent, rootComponentProps] = getOverrides(
    formControlProps?.overrides?.Root,
    Root,
  );
  return (
    <RootComponent {...rootComponentProps}>
      <BaseFormControl
        {...formControlProps}
        overrides={mergeOverrides(
          {
            Label: {
              component: CustomLabel,
              props: {
                description,
                isRequired,
              },
            },
            LabelContainer: {
              props: {
                className: 'justify-between items-end',
              },
            },
          } as typeof formControlProps.overrides,
          formControlProps.overrides,
        )}
      />
    </RootComponent>
  );
}

function CustomLabel(
  props: React.ComponentProps<typeof StyledLabel> &
    OwnProps & { children: React.ReactNode },
) {
  // eslint-disable-next-line react/prop-types
  const {
    description,
    isRequired,
    // eslint-disable-next-line react/prop-types
    $style = { ...theme.typography.font350 },
    children,
    ...baseLabelProps
  } = props;
  return (
    <>
      <span className="flex flex-col">
        <StyledLabel
          {...baseLabelProps}
          $style={$style}
          className={clsx(
            'inline font-prompt font-semibold text-gray-500',
            baseLabelProps.className,
          )}
        >
          {children}

          {!!isRequired && (
            <span
              title="This field is required"
              className="leading-none text-red-600"
            >
              *
            </span>
          )}
        </StyledLabel>
        {!!description && (
          <StyledLabel
            $as="span"
            $style={{
              color: theme.colors.mono600,
              ...theme.typography.ParagraphXSmall,
            }}
          >
            {description}
          </StyledLabel>
        )}
      </span>
    </>
  );
}
