import { useSnackbar } from 'baseui/snackbar';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import EquipmentForm from './EquipmentForm';

import { MutationBanner } from 'components/Banner';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from 'components/ui/dialog';
import { isErrorWithMessage, isMutationSuccess } from 'services/api/base-api';
import { EquipmentService } from 'services/api/equipment/endpoint';
import { UtilityService } from 'services/api/utilities/endpoint';
import { useTypedSelector } from 'store/store';
import { useLocale } from 'utils/hooks/useLocale';

export default function AddEquipment() {
  const { t } = useLocale();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const snackbar = useSnackbar();
  const [createEquipment, createEquipmentMutation] =
    EquipmentService.endpoints.createEquipment.useMutation();
  const [uploadImage, uploadImageMutation] =
    UtilityService.endpoints.uploadImage.useMutation();
  const [deleteImage, deleteImageMutation] =
    UtilityService.endpoints.deleteImage.useMutation();
  const userCredentials = useTypedSelector((s) => s.auth.userCredentials);

  const isSubmitting = [
    uploadImageMutation,
    deleteImageMutation,
    createEquipmentMutation,
  ].some((m) => m.isLoading);

  const isError = [
    uploadImageMutation,
    deleteImageMutation,
    createEquipmentMutation,
  ].some((m) => m.isError);

  const isDuplicateIdentifier = isErrorWithMessage(
    createEquipmentMutation.error,
  )
    ? createEquipmentMutation.error.data.message ===
      'Equipment Identifier is already used.'
    : false;

  const close = () => {
    if (isSubmitting) return;
    navigate(
      { pathname: '..', search: `?${searchParams.toString()}` },
      { replace: true },
    );
  };

  return (
    <>
      <Dialog defaultOpen onOpenChange={(open) => !open && close()} size="xl">
        <DialogTitle>{t('Create Equipment')}</DialogTitle>
        <DialogBody>
          <MutationBanner
            message={
              isDuplicateIdentifier
                ? t('equipment_save_duplicate_identifier_error')
                : t('equipment_create_error')
            }
            show={isError}
            focusOnError
          />
          <EquipmentForm
            id="create-equipment-form"
            onSubmit={async (data) => {
              const file = data.image.find((v) => v.file)?.file;
              let uploadedImageId = 0;
              if (file) {
                const imageResult = await uploadImage({
                  file,
                  resellerId: userCredentials.resellerId,
                  clientId: userCredentials.clientId,
                  purpose: 'General',
                });
                if (isMutationSuccess(imageResult)) {
                  uploadedImageId = imageResult.data.id;
                }
              }

              const equipment = await createEquipment({
                name: data.name,
                description: data.description,
                logoDatastoreId: uploadedImageId || undefined,
                equipmentType: data.type,
                identifier: data.uid,
                available: data.available,
              });
              if (isMutationSuccess(equipment)) {
                snackbar.enqueue({ message: t('equipment_create_success') });
                close();
              } else {
                // Delete uploaded image when `create equipment` fails.
                // snackbar.enqueue({ message: t('equipment_create_error') });
                if (uploadedImageId) {
                  await deleteImage({ datastoreId: uploadedImageId });
                }
              }
            }}
          />
        </DialogBody>
        <DialogActions>
          <Button type="button" variant="plain" onPress={close}>
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            form="create-equipment-form"
            isLoading={isSubmitting}
          >
            {t('Create Equipment')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
