import {
  createThemedStyled,
  createThemedUseStyletron,
  createThemedWithStyle,
} from 'baseui';
import type { Theme } from 'baseui';
import type { Borders, ComponentColorTokens } from 'baseui/themes';
import { LightTheme, createTheme } from 'baseui/themes';
import type { ColorTokens } from 'baseui/tokens';
import { colors } from 'baseui/tokens';

const brandColors = {
  primary: 'var(--brand-primary-500)',
  primary50: 'var(--brand-primary-50)',
  primary100: 'var(--brand-primary-100)',
  primary200: 'var(--brand-primary-200)',
  primary300: 'var(--brand-primary-300)',
  primary400: 'var(--brand-primary-400)',
  primary500: 'var(--brand-primary-500)',
  primary600: 'var(--brand-primary-600)',
  primary700: 'var(--brand-primary-700)',
};

const overrides: Record<
  keyof Theme,
  Partial<ComponentColorTokens | Borders>
> = {
  colors: {
    inputFill: LightTheme.colors.white,
    tickBorder: brandColors.primary,
    tickFillActive: brandColors.primary,
  },
  animation: {},
  borders: {
    inputBorderRadius: '8px',
    checkboxBorderRadius: '4px',
    toggleFillChecked: brandColors.primary,
  },
  breakpoints: {},
  direction: {},
  grid: {},
  icons: {},
  lighting: {},
  mediaQuery: {},
  name: {},
  sizing: {},
  typography: {},
  zIndex: {},
};

type CustomTheme = Theme & {
  colorTokens: ColorTokens;
};

const baseuiTheme = createTheme(
  {
    ...brandColors,
    primaryFontFamily: 'Montserrat, sans-serif',
  },
  overrides,
);

const theme: CustomTheme = {
  ...baseuiTheme,
  colorTokens: colors,
};

export default theme;
export const styled = createThemedStyled<typeof theme>();
export const withStyle = createThemedWithStyle<typeof theme>();
export const useStyletron = createThemedUseStyletron<typeof theme>();
