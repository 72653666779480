import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { z } from 'zod';

import {
  ActiveTechnician,
  ActiveTechnicianCount,
  ActiveTechnicianListParams,
  CreateEmployeePayload,
  Employee,
  EmployeeAvailability,
  EmployeeAvailabilityPayload,
  EmployeeTimeOff,
  EmployeeWorkHours,
  TrackedEmployeeLocation,
  activeTechniciansParams,
  employeeTimeOffSchema,
  employeeWorkHoursSchema,
} from './types';

import { baseAPI, isMutationSuccess, toPaginatedList } from '../base-api';
import { schedulesParamsSchema } from '../schedule/types';
import {
  ServiceLocation,
  serviceLocationSchema,
} from '../service-locations/types';
import { PaginatedResponse } from '../types.shared';
import { endpoints as utilityService } from '../utilities/endpoint';
import { WorkRequest, workRequestSchema } from '../work-requests/types';

import { RootState } from 'store/store';
import { stripUndefined } from 'utils/helpers';
import {
  toOptionalSchemaProperties,
  toSchemaWithDefaults,
} from 'utils/schemas';

type EmployeeScheduleLocations = Array<{
  serviceLocation: ServiceLocation;
  workRequest: WorkRequest;
}>;

const employeeSchedulesLocationsSchema = schedulesParamsSchema
  .pick({
    dateFrom: true,
    dateTo: true,
    techId: true,
  })
  .required();

type EmployeeSchedulesLocationParams = z.infer<
  typeof employeeSchedulesLocationsSchema
>;

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    employees: builder.query<Employee[], void>({
      query: () => `fsausers`,
      providesTags: (result) => (result ? ['Employees'] : []),
    }),
    createEmployee: builder.mutation<Employee, CreateEmployeePayload>({
      async queryFn(payload, api, extraOptions, baseQuery) {
        const rootState = api.getState() as RootState;
        let imageId = 0;
        if (payload.type === 'FSEmployee' && payload.image) {
          const result = await api.dispatch(
            utilityService.endpoints.uploadImage.initiate({
              clientId: rootState.auth.userCredentials.clientId,
              resellerId: rootState.auth.userCredentials.resellerId,
              file: payload.image,
            }),
          );
          if (isMutationSuccess(result)) {
            imageId = result.data.id;
          } else {
            return { error: result.error as FetchBaseQueryError };
          }
        }

        const result = await baseQuery({
          url: 'fsausers',
          method: 'POST',
          body: { ...payload, photoId: imageId },
        });
        if (isMutationSuccess(result)) {
          return { data: result.data as Employee };
        } else {
          return { error: result.error as FetchBaseQueryError };
        }
      },

      invalidatesTags: (result) => (result ? ['Employees'] : []),
    }),

    editEmployee: builder.mutation<Employee, Employee & { image?: File }>({
      async queryFn(payload, api, extraOptions, baseQuery) {
        const rootState = api.getState() as RootState;
        let photoId = payload.photoId;
        /**
         * TODO:
         * refactor handling fallback uploads
         */
        if (payload.image && payload.photoId) {
          const dataStoreImage = await api.dispatch(
            utilityService.endpoints.image.initiate(payload.photoId),
          );
          if (dataStoreImage.data) {
            const result = await api.dispatch(
              utilityService.endpoints.updateImage.initiate({
                ...dataStoreImage.data,
                file: payload.image,
              }),
            );
            if (!isMutationSuccess(result)) {
              return { error: result.error as FetchBaseQueryError };
            }
          } else {
            const result = await api.dispatch(
              utilityService.endpoints.uploadImage.initiate({
                clientId: rootState.auth.userCredentials.clientId,
                resellerId: rootState.auth.userCredentials.resellerId,
                file: payload.image,
              }),
            );
            if (isMutationSuccess(result)) {
              photoId = result.data.id;
            } else {
              return { error: result.error as FetchBaseQueryError };
            }
          }
        }
        if (payload.image && !payload.photoId) {
          const result = await api.dispatch(
            utilityService.endpoints.uploadImage.initiate({
              clientId: rootState.auth.userCredentials.clientId,
              resellerId: rootState.auth.userCredentials.resellerId,
              file: payload.image,
            }),
          );
          if (isMutationSuccess(result)) {
            photoId = result.data.id;
          } else {
            return { error: result.error as FetchBaseQueryError };
          }
        }

        const result = await baseQuery({
          url: `fsausers/${payload.id}`,
          method: 'PUT',
          body: { ...payload, photoId, password: '' },
        });
        if (isMutationSuccess(result)) {
          return { data: result.data as Employee };
        } else {
          return { error: result.error as FetchBaseQueryError };
        }
      },
      invalidatesTags: (result) =>
        result ? ['Employees', { type: 'Employees', id: result.id }] : [],
    }),
    employee: builder.query<Employee, number>({
      query: (id) => `fsausers/${id}`,
      providesTags: (employee) =>
        employee ? [{ type: 'Employees', id: employee?.id }] : [],
    }),
    employeeLocation: builder.query<TrackedEmployeeLocation, number>({
      query: (id) => `gps/last-location/${id}`,
      providesTags: (res, err, arg) =>
        res
          ? [
              { type: 'Employees', id: 'current-location' },
              { type: 'Employees', id: arg },
            ]
          : [],
    }),
    /**
     * FIXME:
     * - this is a temporary implementation on getting the the service locations data of a workRequest
     */
    employeeScheduleLocations: builder.query<
      EmployeeScheduleLocations,
      EmployeeSchedulesLocationParams
    >({
      async queryFn(args, api, extraOptions, baseQuery) {
        const results = await Promise.all([
          baseQuery({
            url: `fsausers/${args.techId}/schedule-items/work-requests`,
            params: {
              'schedule-day-from': args.dateFrom,
              'schedule-day-to': args.dateTo,
            },
          }),
          baseQuery({
            url: `fsausers/${args.techId}/schedule-items/work-requests/serv-locs`,
            params: {
              'schedule-day-from': args.dateFrom,
              'schedule-day-to': args.dateTo,
            },
          }),
        ]);

        const foundError = results.find((req) => req.error)?.error;
        if (foundError) {
          return { error: foundError };
        }

        const parsedWorkRequests = workRequestSchema
          .array()
          .safeParse(results[0].data);
        const parsedServiceLocations = serviceLocationSchema
          .array()
          .safeParse(results[1].data);

        if (!parsedWorkRequests.success || !parsedServiceLocations.success) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Failed to parse response.',
            },
          };
        }

        return {
          data: parsedWorkRequests.data.reduce((all, current) => {
            const matchingLocation = parsedServiceLocations.data.find(
              (location) => current.servLocId === location.id,
            );
            if (matchingLocation) {
              all.push({
                serviceLocation: {
                  ...matchingLocation,
                  photos: null,
                },
                workRequest: current,
              });
            }
            return all;
          }, [] as EmployeeScheduleLocations),
        };
      },
    }),
    employeeAvailability: builder.query<
      Partial<EmployeeAvailability>,
      { employeeID: number }
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const [workHours, timeOffs] = await Promise.all([
          baseQuery({
            url: `tech-work-hours/${arg.employeeID}`,
          }),
          baseQuery({
            url: `tech-scheduled-time-off/${arg.employeeID}`,
          }),
        ]);

        // need to check if 404
        if (
          workHours.error &&
          typeof workHours.error.status === 'number' &&
          workHours.error.status !== 404
        ) {
          return {
            error: workHours.error,
          };
        }
        if (
          timeOffs.error &&
          typeof timeOffs.error.status === 'number' &&
          timeOffs.error.status !== 404
        ) {
          return {
            error: timeOffs.error,
          };
        }

        const workingHours = employeeWorkHoursSchema.safeParse(workHours.data);
        const timeoff = employeeTimeOffSchema.safeParse(timeOffs.data);

        return {
          data: {
            timeoff: timeoff.success ? timeoff.data : undefined,
            workHours: workingHours.success ? workingHours.data : undefined,
          },
        };
      },
      providesTags: (res, err, arg) =>
        res
          ? [
              { type: 'Employees', id: 'employee-availability' },
              { type: 'Employees', id: arg.employeeID },
            ]
          : [],
    }),
    setEmployeeAvailability: builder.mutation<
      void,
      EmployeeAvailabilityPayload
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const [workHours, timeOffs] = await Promise.all([
          arg.workHours
            ? baseQuery({
                url: `tech-work-hours`,
                method: 'POST',
                body: arg.workHours,
              })
            : undefined,
          arg.timeoff.endDateTime && arg.timeoff.endDateTime
            ? baseQuery({
                url: `tech-scheduled-time-off`,
                method: 'POST',
                body: arg.timeoff,
              })
            : arg.timeoff.techId && arg.timeoff.shouldDelete
            ? baseQuery({
                url: `tech-scheduled-time-off/${arg.timeoff.techId}`,
                method: 'DELETE',
              })
            : undefined,
        ]);

        if (workHours && workHours.error) {
          return { error: workHours.error };
        }

        if (timeOffs && timeOffs.error) {
          return { error: timeOffs.error };
        }

        return { data: undefined };
      },
      invalidatesTags: (res, err, arg) => {
        return !err && (arg.workHours.techId || arg.timeoff.techId)
          ? [
              {
                type: 'Employees',
                id: 'employees-availability',
              },
              { type: 'Employees', id: 'employee-availability' },
            ]
          : [];
      },
    }),
    employeesAvaibility: builder.query<
      Array<{ workHours: EmployeeWorkHours; timeoff?: EmployeeTimeOff }>,
      void
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const results = await Promise.all([
          baseQuery({
            url: `tech-work-hours`,
          }),
          baseQuery({
            url: `tech-scheduled-time-off`,
          }),
        ]);

        const error = results.find((r) => r.error)?.error;
        if (error) {
          return { error };
        }

        const workingHours = employeeWorkHoursSchema
          .array()
          .safeParse(results[0].data);
        const timeoff = employeeTimeOffSchema
          .array()
          .safeParse(results[1].data);

        if (!workingHours.success || !timeoff.success) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Failed to parse response shape',
            },
          };
        }

        return {
          data: workingHours.data.reduce((all, wh) => {
            const matchingTimeoff = timeoff.data.find(
              (v) => v.techId === wh.techId,
            );
            all.push({ timeoff: matchingTimeoff, workHours: wh });
            return all;
          }, [] as Array<{ workHours: EmployeeWorkHours; timeoff?: EmployeeTimeOff }>),
        };
      },
      providesTags: (res) =>
        res ? [{ type: 'Employees', id: 'employees-availability' }] : [],
    }),
    activeTechnicians: builder.query<
      PaginatedResponse<ActiveTechnician>,
      {
        params: Partial<ActiveTechnicianListParams>;
      }
    >({
      query(arg) {
        const schema = toOptionalSchemaProperties(activeTechniciansParams);
        const params = stripUndefined(
          toSchemaWithDefaults(schema, {
            page: 1,
            limit: 20,
          }).parse(arg.params),
        );

        return {
          url: `billing/technicians/history`,
          params,
        };
      },
      transformResponse(baseQueryReturnValue, meta, arg) {
        return toPaginatedList(baseQueryReturnValue);
      },
    }),
    activeTechniciansCount: builder.query<
      Array<ActiveTechnicianCount>,
      {
        params: Omit<Partial<ActiveTechnicianListParams>, 'limit' | 'page'>;
      }
    >({
      query(arg) {
        const schema = toOptionalSchemaProperties(
          activeTechniciansParams.omit({ limit: true, page: true }),
        );
        const params = stripUndefined(schema.parse(arg.params));

        return {
          url: `billing/technicians/history/count`,
          params,
        };
      },
    }),
    exportActiveTechnicians: builder.mutation<
      string,
      Partial<ActiveTechnicianListParams> & { exportType: 'list' | 'summary' }
    >({
      query(arg) {
        const schema = toOptionalSchemaProperties(activeTechniciansParams);
        const params = stripUndefined(
          toSchemaWithDefaults(schema, {
            page: 1,
            limit: 20,
          }).parse(arg),
        );

        if (arg.exportType === 'summary') {
          return {
            url: `billing/technicians/download/count`,
            params,
            responseHandler: (response) => response.blob(),
          };
        }

        return {
          url: `billing/technicians/download`,
          params,
          responseHandler: (response) => response.blob(),
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useEmployeesQuery,
  useEmployeeQuery,
  useCreateEmployeeMutation,
  useEditEmployeeMutation,
  useEmployeeLocationQuery,
  useEmployeeScheduleLocationsQuery,
  useEmployeeAvailabilityQuery,
} = endpoints;
export const EmployeeService = endpoints;
