import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import {
  Input as AriaInput,
  InputProps as AriaInputProps,
  TextArea as AriaTextArea,
  TextAreaProps as AriaTextAreaProps,
  TextField as AriaTextField,
  TextFieldProps as AriaTextFieldProps,
} from 'react-aria-components';
import {
  Image as ImageIcon,
  Trash as TrashIcon,
  Upload as UploadIcon,
} from 'react-feather';
import {
  FieldPath,
  FieldValues,
  useController,
  useForm,
} from 'react-hook-form';
import { z } from 'zod';

import {
  ControlledFieldProps,
  Description,
  Field,
  FieldErrorMessage,
} from 'components/forms/fieldset';
import {
  FilePicker,
  filePickerFieldSchema,
} from 'components/forms/file-picker-field';
import { DataStoreImage } from 'components/Image';
import { Button } from 'components/ui/button';
import { Card, CardContent } from 'components/ui/card';
import { Label } from 'components/ui/label';
import { Select, SelectField, SelectItem } from 'components/ui/select';
import { Switch, SwitchField } from 'components/ui/switch';
import { focusRing } from 'components/ui/utils';
import { equipmentTypeSchema } from 'services/api/equipment/types';
import { cn } from 'utils/helpers';
import { useLocale } from 'utils/hooks/useLocale';

const nonEmptyString = z
  .string()
  .trim()
  .min(1, { message: 'errors.invalid_type_received_undefined' });

const schema = z.object({
  name: nonEmptyString,
  uid: nonEmptyString,
  type: equipmentTypeSchema,
  description: z.string().optional().default(''),
  available: z.boolean().optional().default(true),
  image: filePickerFieldSchema,
});

type FormFields = z.infer<typeof schema>;

export default function EquipmentForm({
  initialValues,
  onSubmit: onSubmitHandler,
  id = 'create-equipment-form',
}: {
  initialValues?: Partial<FormFields>;
  onSubmit: (data: FormFields) => void;
  id?: string;
}) {
  const { t } = useLocale();
  const form = useForm<FormFields>({
    defaultValues: initialValues,
    resolver: zodResolver(schema),
  });
  const { control, handleSubmit } = form;
  const onSubmit = handleSubmit(onSubmitHandler);

  return (
    <form
      className="grid grid-cols-2 gap-x-4 gap-y-6"
      id={id}
      onSubmit={onSubmit}
    >
      <Field className="col-span-full">
        <Label htmlFor="image">{t('Image (Optional)')}</Label>
        <FilePicker.Field control={control} field="image">
          {({ files, hasFieldValue, clearFiles }) => (
            <div>
              {!hasFieldValue && (
                <div className="flex h-40 flex-1 justify-center rounded-lg border border-dashed border-gray-900/25">
                  <div className="space-y-2 px-6 py-5 text-center">
                    <ImageIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />

                    <FilePicker.Trigger>{t('Choose Image')}</FilePicker.Trigger>
                    <p className="text-xs leading-5 text-gray-600">
                      {t('file_picker_copy_description')}
                      {/* Select an image. PNG, JPG, JPEG */}
                    </p>
                  </div>
                </div>
              )}
              {files.length !== 0 && (
                <div className="flex w-full items-center space-x-4">
                  <div className="h-40 flex-1 overflow-hidden rounded-md border">
                    {files.some((v) => !!v.dataStoreId) ? (
                      <DataStoreImage
                        imageId={
                          files.find((v) => !!v.dataStoreId)?.dataStoreId ?? 0
                        }
                        className="h-full w-full object-contain"
                      />
                    ) : null}
                    {files.some((v) => !!v.file) ? (
                      <img
                        src={files[0].url}
                        alt="Reseller Logo"
                        className="h-full w-full object-contain"
                      />
                    ) : null}
                  </div>
                  <div className="flex flex-col space-y-2">
                    <FilePicker.Trigger
                      startEnhancer={
                        <UploadIcon aria-hidden className="h-full w-full" />
                      }
                    >
                      {t('Change image')}
                    </FilePicker.Trigger>
                    <Button
                      onClick={clearFiles}
                      type="button"
                      startEnhancer={
                        <TrashIcon className="h-full w-full" aria-hidden />
                      }
                      variant="plain"
                    >
                      {t('Clear Image')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </FilePicker.Field>
      </Field>
      <TextField control={form.control} field="name">
        <Label>{t('Name')}</Label>
        <Input />
      </TextField>
      <TextField control={form.control} field="uid">
        <Label>{t('Unique ID')}</Label>
        <Input />
      </TextField>
      <SelectField control={form.control} field="type">
        <Label>{t('Type')}</Label>
        <Select>
          <SelectItem id="VEHICLE">{t('Vehicle')}</SelectItem>
          <SelectItem id="TOOL">{t('Tool')}</SelectItem>
        </Select>
      </SelectField>
      <TextField
        control={form.control}
        field="description"
        className="col-span-full"
      >
        <Label>{t('Description (Optional)')}</Label>
        <TextArea rows={5} />
      </TextField>
      <div className="col-span-full">
        <Card>
          <CardContent>
            <SwitchField
              control={form.control}
              field="available"
              className="col-span-full"
              defaultSelected
            >
              <Label>{t('Equipment Available?')}</Label>
              <Description>
                {/* When enabled, this equipment will be shown when viewing tracked
                equipments. */}
                {t('equipment_form_available_description')}
              </Description>
              <Switch />
            </SwitchField>
          </CardContent>
        </Card>
      </div>
    </form>
  );
}

function TextField<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  field: fieldName,
  children,
  ...props
}: ControlledFieldProps<T, TName> & {
  children: React.ReactNode;
} & Omit<AriaTextFieldProps, 'children'>) {
  const controller = useController({ control, name: fieldName });
  const field = controller.field;
  const error = !!controller.fieldState.error?.message;

  return (
    <AriaTextField
      {...props}
      isInvalid={error}
      className={clsx(
        className,
        '[&>[data-slot=label]+[data-slot=control]]:mt-2',
        '[&>[data-slot=label]+[data-slot=description]]:mt-1',
        '[&>[data-slot=description]+[data-slot=control]]:mt-2',
        '[&>[data-slot=control]+[data-slot=description]]:mt-2',
        '[&>[data-slot=control]+[data-slot=error]]:mt-2',
        // '[&>[data-slot=label]]:font-medium',
      )}
      {...field}
      onChange={field.onChange}
    >
      {children && typeof children !== 'function' ? children : null}
      <FieldErrorMessage control={control} field={fieldName} />
    </AriaTextField>
  );
}

function Input(props: AriaInputProps) {
  return (
    <div className="relative" data-slot="control">
      <AriaInput
        {...props}
        className={cn([
          // Base
          'peer form-input transition block w-full border-0 bg-white px-3.5 py-2.5 text-base text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:ring-0',

          // Invalid state
          'data-[invalid]:border-red-500 data-[invalid]:data-[hovered]:border-red-500',

          // Disabled state
          'data-[disabled]:border-zinc-950/20',
        ])}
        data-slot="control"
      />
      <div
        className={cn([
          // Base
          'absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-brand-primary-600',

          // Invalid state
          'peer-data-[invalid]:border-red-500 peer-data-[invalid]:peer-data-[hovered]:border-red-500',

          // Disabled state
          'peer-data-[disabled]:border-zinc-950/20',
        ])}
        aria-hidden="true"
      />
    </div>
  );
}

function TextArea(props: AriaTextAreaProps) {
  return (
    <AriaTextArea
      {...props}
      className={clsx([
        focusRing(),
        'data-[focus-visible]:ring-0',

        // Base
        'form-textarea w-full rounded-md border border-gray-300 bg-white px-3.5 py-2.5 text-base text-gray-900 transition focus:bg-gray-50 focus:ring-0',

        // Invalid state
        'peer-data-[invalid]:border-red-500 peer-data-[invalid]:peer-data-[hovered]:border-red-500',

        // Disabled state
        'peer-data-[disabled]:border-zinc-950/20',
      ])}
      data-slot="control"
    />
  );
}
