import { parse, toSeconds } from 'iso8601-duration';
import { z } from 'zod';

import { Skill } from '../skills/types';
import { UserRole } from '../types.shared';

import { isoDurationToHumanReadable } from 'utils/helpers';

export const loginResponseSchema = z.object({
  accessToken: z.string(),
  tokenType: z.string(),
  refreshToken: z.string(),
  expiresIn: z
    .string()
    .trim()
    .min(1)
    .refine(
      (v) => {
        try {
          return !!isoDurationToHumanReadable(v);
        } catch (error) {
          return false;
        }
      },
      { message: 'Invalid `expiresIn` Duration' },
    )
    .transform((v) => toSeconds(parse(v))),
  resellerId: z.number(),
  clientId: z.number(),
  fsaUserId: z.number(),
});

export type LoginResponse = z.infer<typeof loginResponseSchema>;

export type LoginData = Pick<
  AuthCredentials,
  'accessToken' | 'refreshToken'
> & {
  roles: UserRole[];
};

export type AuthCredentials = {
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  refreshToken: string;
  employee: {
    id: number;
    clientId: number;
    resellerId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phone: string;
    photo: string;
    notes: string;
    skills: string[];
  };
};

export type LoginParams = {
  email: string;
  password: string;
  myGeotabSessionId?: string;
  myGeotabDatabase?: string;
};

export type User = {
  id: number;
  clientId: number;
  resellerId: number;
  email: string;
  isAccountNonExpired: boolean;
  isAccountNonLocked: boolean;
  isCredentialsNonExpired: boolean;
  isEnabled: boolean;
  roles: string[];
  firstname: string;
  lastname: string;
  notes: string;
  phone: string;
  photoId: null;
  skills: Skill[];
  isTech: boolean;
  fullName: string;
};
