import React from 'react';
import {
  Heading as AriaHeading,
  HeadingProps as AriaHeadingProps,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';

const styles = tv({
  variants: {
    level: {
      1: '',
      2: '',
      3: 'text-lg/7 font-semibold tracking-[-0.015em] text-zinc-950 dark:text-white sm:text-base/7',
    },
  },
  defaultVariants: {
    level: 3,
  },
});

export function Heading(props: AriaHeadingProps) {
  return (
    <AriaHeading
      {...props}
      className={styles({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        level: props.level,
      })}
    />
  );
}
