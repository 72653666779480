import { Banner } from 'baseui/banner';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { useSnackbar } from 'baseui/snackbar';
import { Save } from 'react-feather';
import { useNavigate, useParams } from 'react-router';

import MuatationBanner from 'components/Banner/MutationBanner';
import { Button } from 'components/Button';
import { QueryResolver } from 'components/QueryResolver';
import { FSAdminEmployeeForm } from 'features/employees';
import { getErrorMessage, isMutationSuccess } from 'services/api/base-api';
import {
  useEditEmployeeMutation,
  useEmployeeQuery,
} from 'services/api/employees/endpoints';
import { useLocale } from 'utils/hooks/useLocale';

export default function EditEmployee() {
  const snackbar = useSnackbar();
  const { t } = useLocale();
  const navigate = useNavigate();
  const id = Number(useParams().employeeId ?? '0');
  const query = useEmployeeQuery(id);

  const [editEmployee, mutation] = useEditEmployeeMutation();

  return (
    <Modal
      isOpen
      onClose={() => navigate('..', { replace: true })}
      overrides={{
        Dialog: {
          props: {
            className: 'max-w-2xl w-full',
          },
        },
      }}
    >
      <ModalHeader className="text-xl font-semibold">
        {t('Edit Employee')}
      </ModalHeader>
      <ModalBody>
        <MuatationBanner
          show={mutation.isError}
          message={mutation.error ? t('edit_employee_error') : ''}
        />
        <QueryResolver query={query}>
          {(employee) => (
            <FSAdminEmployeeForm
              id="fs-admin-employee-form"
              type="UPDATE"
              initialValue={{
                ...employee,
                image: {
                  dataStoreId: employee.photoId ?? 0,
                },
                isTechnician: employee.isTech,
              }}
              onSubmit={async (data) => {
                const image =
                  data.image.file && data.image.file instanceof FileList
                    ? data.image.file[0]
                    : undefined;
                const updatedRoles = () => {
                  let roles = employee.roles;
                  roles = roles.filter((role) =>
                    data.isTechnician
                      ? role !== 'Coordinator'
                      : role !== 'Mobile',
                  );
                  roles.push(data.isTechnician ? 'Mobile' : 'Coordinator');
                  return roles;
                };
                const result = await editEmployee({
                  ...employee,
                  ...data,
                  isTech: data.isTechnician,
                  phone: data.phone,
                  photoId: employee.photoId,
                  roles: updatedRoles(),
                  image,
                });
                if (isMutationSuccess(result)) {
                  snackbar.enqueue({
                    message: t('employee_update_success'),
                  });
                  navigate('..', { replace: true });
                }
              }}
            />
          )}
        </QueryResolver>
      </ModalBody>
      <ModalFooter>
        <Button
          startEnhancer={<Save />}
          className="w-full"
          type="submit"
          form="fs-admin-employee-form"
          isLoading={mutation.isLoading}
        >
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
