import { forwardRef } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link, useHref, useLinkClickHandler } from 'react-router-dom';

import type { ButtonProps } from '../Button';
import { Button } from '../Button';

export type LinkButtonProps = {
  value: string;
};

// eslint-disable-next-line react/display-name
export const LinkButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & LinkProps
>((props, ref) => {
  const {
    to,
    reloadDocument,
    replace = false,
    state,
    target,
    onClick,
    ...baseButtonProps
  } = props;

  const href = useHref(to);
  const handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
  });
  return (
    <Button
      {...baseButtonProps}
      $as="a"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //  @ts-ignore
      href={href}
      onClick={(clickEvent: unknown) => {
        const event = clickEvent as React.MouseEvent<
          HTMLAnchorElement,
          MouseEvent
        >;
        onClick?.(event);
        if (!event.defaultPrevented) {
          handleClick(event);
        }
      }}
      target={target}
      ref={ref}
    />
  );
});

export default LinkButton;
