import { ServiceLocation, ServiceLocationNote } from './types';

import { baseAPI } from '../base-api';
import { Address } from '../types.shared';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

const stripUndefined = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  const result: { [key: string]: any } = {};
  for (const key in obj) {
    if (obj[key] !== undefined) {
      result[key] = obj[key];
    }
  }
  return result;
};

export const serviceLocationAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    serviceLocations: builder.query<ServiceLocation[], { customerId: number }>({
      query: (params) => {
        const searchParams = new URLSearchParams();
        if (params) {
          searchParams.set('customer', params.customerId.toString());
        }
        return {
          /**
           * TODO:
           * FIXME:
           * - should revert this to the non `test` endpoint
           */
          url: `servlocs?${searchParams.toString()}`,
        };
      },
      providesTags: (result, error, arg) => [
        { type: 'Service-Location', id: arg.customerId },
      ],
    }),
    serviceLocation: builder.query<ServiceLocation, number>({
      query: (id) => `serv-locs/${id}`,
      providesTags: (result, error, arg) =>
        result ? [{ type: 'Service-Location', id: result?.id }] : [],
    }),
    /**
     * This is a mutation version for getting the `Service Location`.
     * this is for using it inside other endpoints. particularly, in `queryFn` where
     * we manually call `initiate`. this also prevents RTK to create cache entries and
     * potentially having weird errors. This endpoint ideally should not be used as a hook and is an internal
     * implementation
     */
    _service_location: builder.mutation<ServiceLocation, number>({
      query: (id) => `serv-locs/${id}`,
    }),
    _service_locations_mutation: builder.mutation<
      ServiceLocation[],
      { customerId: number }
    >({
      query: (params) => {
        const searchParams = new URLSearchParams();
        if (params) {
          searchParams.set('customerId', params.customerId.toString());
        }
        return {
          /**
           * TODO:
           * FIXME:
           * - should revert this to the non `test` endpoint
           */
          url: `servlocs?${searchParams.toString()}`,
        };
      },
    }),
    newServiceLocation: builder.mutation<
      ServiceLocation,
      Omit<ServiceLocation, 'id' | 'address'> & {
        address: Omit<
          Address,
          | 'id'
          | 'resellerId'
          | 'dateCreated'
          | 'dateModified'
          | 'isDeleted'
          | 'dateDeleted'
          | 'authUsername'
        >;
      }
    >({
      query(payload) {
        const { customerId, ...newServiceLocation } = payload;
        return {
          url: `servlocs?customerId=${customerId}`,
          method: 'POST',
          /**
           * TODO:
           * `customerId` should not be required here if it is already
           * declared in the url params.
           */
          body: { ...newServiceLocation, customerId },
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Service-Location', id: arg.customerId },
      ],
    }),
    saveServiceLocation: builder.mutation<
      ServiceLocation,
      Optional<ServiceLocation, 'id'>
    >({
      query(arg) {
        const method = arg.id ? 'PUT' : 'POST';
        const url = arg.id ? `servlocs/${arg.id}` : `servlocs`;
        return {
          url,
          method,
          body: arg,
        };
      },
    }),
    notes: builder.query<
      Array<ServiceLocationNote>,
      {
        params: {
          workRequestId?: number;
          serviceLocationId?: number;
          noteType?: 'ESTIMATE' | 'JOB' | 'INVOICE';
          customerId?: number;
        };
      } | void
    >({
      query: (arg) => {
        const params = arg
          ? stripUndefined({
              'work-request-id': arg.params.workRequestId,
              'serv-loc-id': arg.params.serviceLocationId,
              'note-type': arg.params.noteType,
              'customer-id': arg.params.customerId,
            })
          : undefined;

        return { url: 'notes', params };
      },
      providesTags: (res) => {
        return res ? [{ type: 'Service-Location', id: 'notes' }] : [];
      },
    }),
    noteById: builder.query<ServiceLocationNote, number>({
      query: (arg) => {
        return { url: `notes/${arg}` };
      },
      providesTags: (res, err, arg) => {
        return res ? [{ type: 'Service-Location', id: `note:${arg}` }] : [];
      },
    }),
    createNote: builder.mutation<
      ServiceLocationNote,
      {
        noteValue: string;
        workRequestId: number;
        noteType: 'ESTIMATE' | 'JOB' | 'INVOICE';
      }
    >({
      query(arg) {
        return { url: 'notes', method: 'POST', body: arg };
      },
      invalidatesTags: (res, err, payload) => {
        return res ? [{ type: 'Service-Location' as const, id: 'notes' }] : [];
      },
    }),
    updateNote: builder.mutation<
      ServiceLocationNote,
      {
        noteId: number;
        noteValue: string;
      }
    >({
      query(arg) {
        return { url: 'notes', method: 'PUT', body: arg };
      },
      invalidatesTags: (res, err, payload) => {
        return res ? [{ type: 'Service-Location' as const, id: 'notes' }] : [];
      },
    }),
    deleteNote: builder.mutation<ServiceLocationNote, { id: number }>({
      query(arg) {
        return { url: `notes/${arg.id}`, method: 'DELETE', body: arg };
      },
      invalidatesTags: (res) => {
        return res ? [{ type: 'Service-Location', id: 'notes' }] : [];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useServiceLocationsQuery,
  useNewServiceLocationMutation,
  useSaveServiceLocationMutation,
} = serviceLocationAPI;
export const ServiceLocationService = serviceLocationAPI;
