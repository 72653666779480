import { zodResolver } from '@hookform/resolvers/zod';
import { Banner } from 'baseui/banner';
import { useSnackbar } from 'baseui/snackbar';
import { Tab, StatefulTabs as Tabs } from 'baseui/tabs-motion';
import { format, isAfter, isBefore, isToday, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { DialogTrigger } from 'react-aria-components';
import {
  Edit as EditIcon,
  UserCheck as UserCheckIcon,
  Users as UsersIcon,
  X as XIcon,
} from 'react-feather';
import { useForm } from 'react-hook-form';
import { Link, Outlet, useParams } from 'react-router-dom';
import { z } from 'zod';

import { EmployeeTerminationForm } from './EmployeeTermination';
import EmployeeWorkingHours from './EmployeeWorkingHours';

import { MutationBanner } from 'components/Banner';
import { FormController } from 'components/FormController';
import { Description } from 'components/forms/fieldset';
import { DataStoreImage } from 'components/Image';
import { QueryResolver } from 'components/QueryResolver';
import { createTableBuilder } from 'components/Table';
import {
  Alert,
  AlertFooter,
  AlertTitle,
  AlertDescription as AlertBannerDescription,
} from 'components/ui/alert';
import {
  AlertActions,
  AlertDescription,
  AlertDialog,
  AlertTitle as AlertDialogTitle,
} from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { Heading } from 'components/ui/heading';
import { Label } from 'components/ui/label';
import { Switch, SwitchField } from 'components/ui/switch';
import { Strong, Text } from 'components/ui/text';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import SkillsSelect from 'features/SkillsSelect';
import { getErrorMessage, isMutationSuccess } from 'services/api/base-api';
import {
  EmployeeService,
  useEditEmployeeMutation,
  useEmployeeQuery,
} from 'services/api/employees/endpoints';
import { Employee } from 'services/api/employees/types';
import { useSchedulesQuery } from 'services/api/schedule/endpoints';
import { ScheduleSlot } from 'services/api/schedule/types';
import { Skill, skillschema } from 'services/api/skills/types';
import { useTypedSelector } from 'store/store';
import { toPayloadTimeFormat } from 'utils/helpers';
import { useLocale } from 'utils/hooks/useLocale';

export default function EmployeeDetails() {
  const { t } = useLocale();

  return (
    <>
      <Outlet />
      <div className="flex-1 space-y-5">
        <DashboardContentHeader
          title={t('Employees')}
          description={t('Manage your employees')}
        />
        <section className="flex flex-wrap gap-4">
          <section className="flex-[2] space-y-4">
            <EmployeeInformation />
            <EmployeeTabs />
          </section>
          <aside className="min-w-[25rem] flex-1 shrink-0">
            <WorkHistory />
          </aside>
        </section>
      </div>
    </>
  );
}

/**
 * Should return
 */
const toDateWithTime = (date: string) => {
  return new Date(
    `${date}T${toPayloadTimeFormat(startOfDay(new Date()), {
      includeSeconds: true,
    })}`,
  );
};

function EmployeeInformation() {
  const params = useParams();
  const query = useEmployeeQuery(Number(params.employeeId ?? '0'));
  const { t } = useLocale();

  const lastDay = query.data?.lastDay;
  const today = startOfDay(new Date());

  const isEmployeeTerminated = !!lastDay;
  const isTerminationEffective = lastDay
    ? isToday(new Date(toDateWithTime(lastDay))) ||
      isAfter(today, new Date(toDateWithTime(lastDay)))
    : false;

  const [editEmployee, mutation] =
    EmployeeService.endpoints.editEmployee.useMutation();
  const snackbar = useSnackbar();

  return (
    <QueryResolver query={query}>
      {(employee) => (
        <Card>
          <CardHeader>
            {isEmployeeTerminated && (
              <QueryResolver query={query}>
                {(employee) => (
                  <Alert intent="negative" className="mb-4">
                    <AlertTitle>Employee Terminated</AlertTitle>
                    <AlertBannerDescription>
                      This employee&apos;s last day is {lastDay}. They will no
                      longer have access to the portal and the app. If this was
                      a mistake you can revert this action by reactivating the
                      employee.
                    </AlertBannerDescription>
                    <AlertActions>
                      <Button
                        color="green"
                        variant="default"
                        onClick={async () => {
                          const result = await editEmployee({
                            ...employee,
                            lastDay: null,
                          });
                          if (isMutationSuccess(result)) {
                            snackbar.enqueue({
                              message: 'Employee reactivated successfully',
                            });
                          } else {
                            snackbar.enqueue({
                              message: 'Failed to reactivate employee',
                            });
                          }
                        }}
                        isLoading={mutation.isLoading}
                      >
                        Reactivate Employee
                      </Button>
                    </AlertActions>
                  </Alert>
                )}
              </QueryResolver>
            )}
            <div className="flex justify-between">
              <CardTitle className="text-xl">{t('Employee Details')}</CardTitle>
              <Button
                href="edit"
                variant="plain"
                aria-label={t('Edit Employee')}
                title={t('Edit Employee')}
              >
                <EditIcon data-slot="icon" />
              </Button>
            </div>
          </CardHeader>
          <CardContent>
            <div>
              <div className="px-4 sm:px-0">
                <span className="text-2xl font-semibold text-brand-primary">
                  #{employee.id}
                </span>
                <div className="mt-3 space-y-1.5">
                  {employee.photoId !== null && (
                    <div className="h-48 w-48 shrink-0 overflow-hidden">
                      <DataStoreImage
                        imageId={employee.photoId}
                        alt={`${employee.firstname} ${employee.lastname}`}
                        className="h-full w-full bg-brand-primary-50 object-cover"
                      />
                    </div>
                  )}
                  <Heading>
                    {employee.firstname} {employee.lastname}
                  </Heading>
                </div>
                {employee.isTech && <Text>{t('Technician')}</Text>}
              </div>
              <div className="mt-6">
                <dl className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
                  <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      {t('Phone Number')}
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                      {employee.phone}
                    </dd>
                  </div>
                  <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      {t('Email Address')}
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                      {employee.email}
                    </dd>
                  </div>
                  {/**
                   * TODO:
                   * Add missing data from updated API response
                   */}
                  {/* <div className="flex items-start space-x-7">
            <dt className="mt-1 text-gray-500" title="License">
              <Truck size={16} />
            </dt>
            <dd className="text-sm text-gray-500">0348 - 580 - 4808LM</dd>
          </div> */}
                </dl>
                <div className="grid gap-4">
                  <AccountStatusToggle
                    employee={employee}
                    defaultValues={{
                      accessAllowed: employee.isEnabled,
                      id: employee.id,
                    }}
                  />
                  <div>
                    {!isEmployeeTerminated && (
                      <EmployeeTerminationForm employee={employee} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </QueryResolver>
  );
}

const { Table: RolesTable, Column: RolesColumn } = createTableBuilder<{
  pageName: string;
  readAccess: boolean;
  readWriteAccess: boolean;
  noAccess: boolean;
}>();

function EmployeeTabs() {
  const { t } = useLocale();
  const params = useParams();
  const query = useEmployeeQuery(Number(params.employeeId ?? '0'));

  return (
    <Card>
      <Tabs
        overrides={{
          StyledTabBar: {
            props: {
              className: 'bg-white px-0',
            },
          },
          TabHighlight: {
            props: {
              className: 'bg-brand-primary',
            },
          },
        }}
      >
        {/* <Tab
          title="Permissions"
          overrides={{
            TabPanel: {
              props: {
                className: 'px-0',
              },
            },
          }}
        >
          <form>
            <RolesTable
              data={[
                {
                  pageName: 'Dashboard Page',
                  readAccess: true,
                  readWriteAccess: false,
                  noAccess: false,
                },
              ]}
            >
              <RolesColumn header={t("Page")}>{(row) => row.pageName}</RolesColumn>
              <RolesColumn header={t("Read-only Access")}>
                {(row) => (
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="1"
                      name={`${row.pageName}-access`}
                      defaultChecked={row.readAccess}
                      className="h-5 w-5  checked:accent-brand-primary"
                    />
                  </div>
                )}
              </RolesColumn>
              <RolesColumn header={t("Read-Write Access")}>
                {(row) => (
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      name={`${row.pageName}-access`}
                      defaultChecked={row.readWriteAccess}
                      className="h-5 w-5  checked:accent-brand-primary"
                    />
                  </div>
                )}
              </RolesColumn>
              <RolesColumn header={t("No Access")}>
                {(row) => (
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      name={`${row.pageName}-access`}
                      defaultChecked={row.noAccess}
                      className="h-5 w-5  checked:accent-brand-primary"
                    />
                  </div>
                )}
              </RolesColumn>
            </RolesTable>
          </form>
        </Tab> */}
        <Tab
          title={t('Skillset')}
          overrides={{
            TabPanel: {
              props: {
                className: 'px-0',
              },
            },
          }}
        >
          <QueryResolver query={query}>
            {(employee) => <EmployeeSkills employee={employee} />}
          </QueryResolver>
        </Tab>
        <Tab
          title={t('Schedule')}
          overrides={{
            TabPanel: {
              props: {
                className: 'px-0',
              },
            },
          }}
        >
          <EmployeeWorkingHours employeeID={Number(params.employeeId ?? '0')} />
        </Tab>
      </Tabs>
    </Card>
  );
}

const { Table: SkillsTable, Column: SkillsColumn } =
  createTableBuilder<Skill>();
const schema = z.object({
  skills: skillschema.array().optional(),
});

type FormFields = z.infer<typeof schema>;

function EmployeeSkills({ employee }: { employee: Employee }) {
  const { t } = useLocale();
  const [editEmployee, mutation] = useEditEmployeeMutation();
  const { control, handleSubmit, reset, watch } = useForm<FormFields>({
    resolver: zodResolver(schema),
  });
  const selected = watch('skills') ?? [];
  const onSubmit = handleSubmit(async (data) => {
    const result = await editEmployee({
      ...employee,
      skills: [...employee.skills, ...(data.skills ?? [])],
    });
    if (isMutationSuccess(result)) {
      reset();
    }
  });

  return (
    <div>
      <div>
        <MutationBanner
          show={mutation.isError}
          message={t('client_employee_skills_update_error')}
          // message={'Failed to update employee skills, try again.'}
        />
        {mutation.isError && (
          <Banner title="Error" kind="negative">
            {getErrorMessage(
              mutation.error,
              'Failed to update employee skills, try again.',
            )}
          </Banner>
        )}
        <SkillsTable
          data={employee.skills}
          emptyMessage={
            <div className="text-center">
              <UsersIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                {t('Employees has no skills assigned')}
              </h3>
            </div>
          }
        >
          <SkillsColumn header={t('Skillset')}>
            {(row) => row.name}
          </SkillsColumn>
          <SkillsColumn header={t('Description')}>
            {(row) => row.description}
          </SkillsColumn>
          <SkillsColumn>
            {(row) => (
              <DialogTrigger>
                <Button
                  title={t('Remove Skill')}
                  aria-label={t('Remove Skill')}
                  variant="plain"
                >
                  <XIcon data-slot="icon" />
                </Button>
                <AlertDialog>
                  <AlertDialogTitle>{t('Remove Skill')}</AlertDialogTitle>
                  <AlertDescription>
                    Are you sure you want to remove <Strong>{row.name}</Strong>{' '}
                    from this technician&apos;s skills? This action cannot be
                    undone.
                  </AlertDescription>
                  <AlertActions>
                    <Button slot="close" variant="plain">
                      {t('Cancel')}
                    </Button>
                    <Button
                      color="red"
                      onClick={() => {
                        editEmployee({
                          ...employee,
                          skills: employee.skills.filter(
                            (skill) => skill.name !== row.name,
                          ),
                        });
                      }}
                      isLoading={mutation.isLoading}
                    >
                      {t('Remove Skill')}
                    </Button>
                  </AlertActions>
                </AlertDialog>
              </DialogTrigger>
            )}
          </SkillsColumn>
        </SkillsTable>
      </div>
      <div className="-mx-[--gutter] border-t px-[--gutter] pt-[--gutter]">
        <CardHeader>
          <Heading>{t('Assign New Skillset')}</Heading>
          <Description>
            Select a skillset to assign for this technician.
          </Description>
        </CardHeader>
        <form id="modify-skills-form" className="w-1/3" onSubmit={onSubmit}>
          <FormController control={control} name="skills">
            {({ field }) => (
              <SkillsSelect
                filterOptions={(options, filterValue, excludeOptions) => {
                  const selectableOptions = options.filter(
                    (item) =>
                      !employee.skills.find((skill) => skill.name === item.id),
                  );
                  return selectableOptions;
                }}
                value={field.value}
                onChange={({ value, skills }) => {
                  field.onChange(
                    skills.filter(
                      (item) =>
                        !!value.find((itemValue) => itemValue.id === item.name),
                    ),
                  );
                }}
              />
            )}
          </FormController>
          <Button
            type="submit"
            isDisabled={selected?.length === 0}
            isLoading={mutation.isLoading}
          >
            {t('Assign New Skillset')}
          </Button>
        </form>
      </div>
    </div>
  );
}

const employeeAccessFormSchema = z.object({
  accessAllowed: z.boolean().optional().default(false),
  id: z.number(),
});

type AccountStatusFormFields = z.infer<typeof employeeAccessFormSchema>;

function AccountStatusToggle({
  employee,
  defaultValues,
}: {
  employee: Employee;
  defaultValues?: Partial<AccountStatusFormFields>;
}) {
  const form = useForm<AccountStatusFormFields>({
    defaultValues,
  });
  const { t } = useLocale();
  const [editEmployee] = useEditEmployeeMutation();
  const snackbar = useSnackbar();
  const onSubmit = form.handleSubmit(async (data) => {
    const result = await editEmployee({
      ...employee,
      isEnabled: data.accessAllowed,
      id: data.id,
    });

    if (isMutationSuccess(result)) {
      snackbar.enqueue({
        message: t('employee_access_updated_successfully'),
      });
    } else {
      snackbar.enqueue({
        message: t('employee_access_update_failed'),
      });
    }
  });

  const isSubmitting = form.formState.isSubmitting;

  return (
    <form onSubmit={onSubmit} id="update-employee-details-access-form">
      <Alert>
        <UserCheckIcon className="h-4 w-4" />
        <AlertTitle>
          {defaultValues?.accessAllowed
            ? t('Employee Active')
            : t('Employee Deactivated')}
        </AlertTitle>
        <AlertFooter>
          <SwitchField control={form.control} field="accessAllowed">
            <DialogTrigger
              onOpenChange={(isOpen) => {
                if (!isOpen) {
                  form.setValue(
                    'accessAllowed',
                    !form.getValues().accessAllowed,
                  );
                }
              }}
            >
              <Switch />
              <AlertDialog>
                <AlertDialogTitle>
                  {defaultValues?.accessAllowed
                    ? t('alert_dialog_title_employee_access.deactivate')
                    : t('alert_dialog_title_employee_access.activate')}
                </AlertDialogTitle>
                <AlertDescription>
                  {defaultValues?.accessAllowed
                    ? t('alert_dialog_description_employee_access.disable')
                    : t('alert_dialog_description_employee_access.enable')}
                </AlertDescription>
                <AlertActions>
                  <Button
                    variant="plain"
                    slot="close"
                    isDisabled={isSubmitting}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    color={defaultValues?.accessAllowed ? 'red' : 'green'}
                    type="submit"
                    isLoading={isSubmitting}
                    form="update-employee-details-access-form"
                  >
                    {defaultValues?.accessAllowed
                      ? t('button_employee_access.deactivate')
                      : t('button_employee_access.activate')}
                  </Button>
                </AlertActions>
              </AlertDialog>
            </DialogTrigger>
            <Label>{t('Update Account Status')}</Label>
            <Description>
              {defaultValues?.accessAllowed
                ? t('alert_footer_description_employee_access.deactivate')
                : t('alert_footer_description_employee_access.activate')}
            </Description>
          </SwitchField>
        </AlertFooter>
      </Alert>
    </form>
  );
}

const { Table: WorkHistoryTable, Column: WorkHistoryColumn } =
  createTableBuilder<ScheduleSlot>();
const numberSchema = z.coerce.number().catch(0);
function WorkHistory() {
  const { t } = useLocale();
  const params = useParams();
  const employeeId = numberSchema.parse(params.employeeId);
  const { clientId } = useTypedSelector((s) => s.auth.userCredentials);

  const query = useSchedulesQuery(
    {
      clientId,
      techId: employeeId,
      dateFrom: format(new Date(0), 'yyyy-MM-dd'),
    },
    { skip: employeeId === 0 },
  );

  const workHistoryData = useMemo(
    () => query.data?.schedRows ?? [],
    [query.data?.schedRows],
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('Work History')}</CardTitle>
      </CardHeader>
      <CardContent>
        <WorkHistoryTable
          data={workHistoryData}
          isLoading={query.isLoading || query.isFetching}
          emptyMessage={
            <div className="text-center">
              <UsersIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                {/* Employee has no work history. */}
                {t('emplyee_work_history_empty')}
              </h3>
            </div>
          }
          overrides={{
            Root: {
              style: {
                maxHeight: '16rem',
              },
            },
          }}
        >
          <WorkHistoryColumn header={t('WR#')}>
            {(row) => (
              <Link
                to={`/jobs/${row.workRequestId}/edit`}
                className="underline hover:text-brand-primary"
                title={t('table_view_row', { what: t('Job') })}
                reloadDocument
              >
                <span aria-hidden className="absolute inset-0" />
                {row.num}
              </Link>
            )}
          </WorkHistoryColumn>
          <WorkHistoryColumn header={t('Date')}>
            {(row) =>
              row.actualDay
                ? format(new Date(`${row.actualDay}T00:00:00`), 'MM/dd/yyyy')
                : format(new Date(`${row.scheduleDay}T00:00:00`), 'MM/dd/yyyy')
            }
          </WorkHistoryColumn>
          <WorkHistoryColumn header={t('Work Type')}>
            {(row) => (
              <span
                className="block truncate whitespace-nowrap"
                title={row.workType}
              >
                {row.workType}
              </span>
            )}
          </WorkHistoryColumn>
          {/* <WorkHistoryColumn header={t("Status")}>
          {(row) => (
            <Tag
              closeable={false}
              overrides={{ Root: { props: { className: 'rounded-md' } } }}
              kind="positive"
            >
              {row.}
            </Tag>
          )}
        </WorkHistoryColumn> */}
        </WorkHistoryTable>
      </CardContent>
    </Card>
  );
}
