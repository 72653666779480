import { z } from 'zod';

import { Skill, skillschema, SkillsParams } from './types';

import { baseAPI } from '../base-api';

const skillsAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    skills: builder.query<Skill[], SkillsParams>({
      query: (params) => ({
        url: `clients/${params.employeeId}`,
        async responseHandler(response) {
          const data = await response.json();
          if (process.env.NODE_ENV === 'production') {
            return data.masterSkills;
          }
          return z.array(skillschema).parseAsync(data.masterSkills);
        },
      }),
      providesTags: (response) => (response !== undefined ? ['Skills'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useSkillsQuery } = skillsAPI;
