export const WORK_REQUEST_STATUS_VALUES = {
  Created: 'Created',
  EstCreated: 'EstCreated',
  EstScheduled: 'EstScheduled',
  EstStarted: 'EstStarted',
  EstCompleted: 'EstCompleted',
  EstSent: 'EstSent',
  EstApproved: 'EstApproved',
  EstDenied: 'EstDenied',
  Scheduled: 'Scheduled',
  Started: 'Started',
  Completed: 'Completed',
  InvoiceCreated: 'InvoiceCreated',
  InvoiceSent: 'InvoiceSent',
  Collected: 'Collected',
  Cancelled: 'Cancelled',
  Archived: 'Archived',
} as const;

export const USER_ROLE_VALUES = {
  Mobile: 'Mobile',
  Coordinator: 'Coordinator',
  ClientAdmin: 'ClientAdmin',
  ResellerAdmin: 'ResellerAdmin',
  ParentResellerAdmin: 'ParentResellerAdmin',
  RootAdmin: 'RootAdmin',
} as const;
