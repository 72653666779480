import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'baseui/snackbar';
import { isToday } from 'date-fns';
import { DialogTrigger } from 'react-aria-components';
import { AlertTriangle } from 'react-feather';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { MutationBanner } from 'components/Banner';
import { Description } from 'components/forms/fieldset';
import {
  Alert,
  AlertDescription as AlertBannerDescription,
  AlertTitle,
} from 'components/ui/alert';
import { AlertActions, AlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { DatePicker, DatePickerField } from 'components/ui/date-picker';
import {
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from 'components/ui/dialog';
import { Label } from 'components/ui/label';
import { Strong } from 'components/ui/text';
import { getErrorMessage, isMutationSuccess } from 'services/api/base-api';
import { EmployeeService } from 'services/api/employees/endpoints';
import { Employee } from 'services/api/employees/types';
import { formatDate, toPayloadDateFormat } from 'utils/helpers';

const employeeTerminationSchema = z.object({
  date: z.date(),
});
type EmployeeTerminationFormFields = z.infer<typeof employeeTerminationSchema>;
export function EmployeeTerminationForm({ employee }: { employee: Employee }) {
  const form = useForm<EmployeeTerminationFormFields>({
    defaultValues: { date: new Date() },
    resolver: zodResolver(employeeTerminationSchema),
  });
  const [editEmployee, mutation] =
    EmployeeService.endpoints.editEmployee.useMutation();
  const snackbar = useSnackbar();
  const onSubmitHandler = form.handleSubmit(async (data) => {
    const result = await editEmployee({
      ...employee,
      lastDay: toPayloadDateFormat(data.date),
    });

    if (isMutationSuccess(result)) {
      const terminatedToday = isToday(data.date);
      snackbar.enqueue({
        message: terminatedToday
          ? 'Employee terminated successfully'
          : `Employee termination set. The employee will be terminated on ${formatDate(
              data.date,
            )}.`,
      });
    }
  });
  const dateSelected = form.watch('date');
  const formId = `terminate-employe-${employee.id}`;
  const isSubmitting = form.formState.isSubmitting;

  return (
    <Alert intent="negative">
      <AlertTriangle className="h-5 w-5" aria-hidden="true" />
      <AlertTitle>Employee Termination</AlertTitle>
      <AlertBannerDescription>
        Deleting an employee can revoke their access in the portal and in the
        app. Proceed with extreme care.
      </AlertBannerDescription>
      <AlertActions>
        <DialogTrigger>
          <Button color="red">Terminate Employee</Button>
          <AlertDialog>
            <DialogTitle>Employee Termination</DialogTitle>
            <DialogDescription>
              Are you sure you want to terminate this employee? This action
              cannot be undone. Complete the form below to continue with this
              action.
            </DialogDescription>
            <DialogBody>
              <MutationBanner
                show={mutation.isError}
                message={mutation.error ? getErrorMessage(mutation.error) : ''}
              />
              <form onSubmit={onSubmitHandler} id={formId}>
                <DatePickerField control={form.control} field="date">
                  <Label>Date of Termination</Label>
                  <DatePicker min={new Date()} />
                  <Description elementType="span">
                    We will terminate this employee and their access{' '}
                    <Strong className="underline">
                      {isToday(dateSelected)
                        ? 'Today'
                        : `on ${formatDate(dateSelected)}`}
                    </Strong>
                    . When terminated:
                    <ul className="mt-3 list-disc space-y-1 px-4">
                      <li>They will not be assigned any jobs.</li>
                      <li>
                        Their access to the app and the portal will be revoked.
                      </li>
                      <li>
                        In the event that they have jobs that are still not
                        performed or scheduled after the date of termination you
                        will need to reassign them.
                      </li>
                    </ul>
                  </Description>
                </DatePickerField>
              </form>
            </DialogBody>
            <DialogActions>
              <Button
                variant="plain"
                slot="close"
                size="lg"
                isDisabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                color="red"
                size="lg"
                type="submit"
                form={formId}
                isLoading={isSubmitting}
              >
                Terminate Employee
              </Button>
            </DialogActions>
          </AlertDialog>
        </DialogTrigger>
      </AlertActions>
    </Alert>
  );
}
