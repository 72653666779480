import type {
  ButtonProps as BaseButtonProps,
  ButtonOverrides,
  SharedStyleProps,
  SIZE,
} from 'baseui/button';
import { Button as BaseButton, KIND } from 'baseui/button';
import { mergeOverrides } from 'baseui/helpers/overrides';
import { forwardRef } from 'react';
import type { StyleObject, StyletronProps } from 'styletron-react';

import {
  getDefaultKindStyles,
  getIntentStyles,
  getVariantStyles,
} from './styles';

export declare const INTENT: {
  readonly danger: 'danger';
  readonly warning: 'warning';
  readonly success: 'success';
};

export type Variant = { solid: 'solid'; outline: 'outline' };
export type Intent = keyof typeof INTENT;

type OwnProps = Partial<{
  variant: keyof Variant;
  size: keyof typeof SIZE;
  kind: keyof typeof KIND;
  intent?: Intent;
}>;

type NativeElementProps = Omit<
  React.ComponentPropsWithRef<'button'>,
  keyof OwnProps
>;

export type ButtonProps = Omit<BaseButtonProps, keyof OwnProps> &
  OwnProps &
  NativeElementProps &
  StyletronProps;

type OwnSharedStyles = Omit<OwnProps, keyof BaseButtonProps>;

const baseOverrides = (sharedProps: OwnSharedStyles): ButtonOverrides => ({
  BaseButton: {
    style: (props: SharedStyleProps): StyleObject => ({
      ...getVariantStyles(sharedProps.variant, props.$kind ?? 'primary'),
      ...getDefaultKindStyles(props.$kind ?? 'primary'),
      ...getIntentStyles(sharedProps.intent, props.$kind),
    }),
  },
});

// eslint-disable-next-line react/display-name
const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { variant = 'solid', intent, ...baseButtonProps } = props;

  return (
    <BaseButton
      ref={ref}
      {...baseButtonProps}
      overrides={mergeOverrides(
        baseOverrides({ variant, intent }),
        baseButtonProps.overrides,
      )}
    />
  );
});

export default Button;
