import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { UserRole } from 'services/api/types.shared';

const LOCALSTORAGE_SESSION_KEY = '_session';

export type AuthState = {
  isAuthenticated: boolean;
  isSessionInitialized: boolean;
  userCredentials: {
    clientId: number;
    resellerId: number;
    fsaUserId: number;
  };
  roles: UserRole[];
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
};

const initialState: AuthState = {
  isAuthenticated: false,
  isSessionInitialized: false,
  userCredentials: {
    clientId: 0,
    resellerId: 0,
    fsaUserId: 0,
  },
  roles: [],
  accessToken: '',
  refreshToken: '',
  expiresIn: 0,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initializeSession: () => {
      const storedSession = localStorage.getItem(LOCALSTORAGE_SESSION_KEY);
      //TODO: Validate storedSession with zod
      if (storedSession) {
        return {
          ...initialState,
          ...(JSON.parse(storedSession) as Omit<
            AuthState,
            'isSessionInitialized'
          >),
          isAuthenticated: true,
          isSessionInitialized: true,
        };
      }
      return { ...initialState, isSessionInitialized: true };
    },
    logIn: (
      state,
      action: PayloadAction<
        Pick<
          AuthState,
          | 'accessToken'
          | 'expiresIn'
          | 'userCredentials'
          | 'refreshToken'
          | 'roles'
        >
      >,
    ) => {
      localStorage.setItem(
        LOCALSTORAGE_SESSION_KEY,
        JSON.stringify(action.payload),
      );
      return { ...state, ...action.payload, isAuthenticated: true };
    },
    logOut: () => {
      localStorage.removeItem(LOCALSTORAGE_SESSION_KEY);
      return { ...initialState, isSessionInitialized: true };
    },
    updateTokens: (
      state,
      action: PayloadAction<Pick<AuthState, 'accessToken' | 'refreshToken'>>,
    ) => {
      const storedSession = localStorage.getItem(LOCALSTORAGE_SESSION_KEY);
      if (storedSession !== null) {
        localStorage.setItem(
          LOCALSTORAGE_SESSION_KEY,
          JSON.stringify({
            ...JSON.parse(storedSession),
            ...action.payload,
          }),
        );
      }
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { logIn, logOut, updateTokens, initializeSession } =
  authSlice.actions;

export default authSlice.reducer;
