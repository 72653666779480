import { z } from 'zod';

export const skillschema = z.object({
  name: z.string(),
  description: z.string(),
});

export type Skill = z.infer<typeof skillschema>;

export type SkillsParams = {
  employeeId?: number;
};
