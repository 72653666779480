import { baseAPI, toPaginatedList } from '../base-api';
import { PaginatedResponse, PaginatedParams } from '../types.shared';

import { AuditLogin, AuditLoginListParams, auditLoginParams } from './types';
import { stripUndefined } from 'utils/helpers';
import {
  toOptionalSchemaProperties,
  toSchemaWithDefaults,
} from 'utils/schemas';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

// const PAGINATION_DEFAULT_VALUE: PaginatedParams = { limit: 10, page: 1 };

const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    auditLogin: builder.query<
      PaginatedResponse<AuditLogin>,
      {
        params: Partial<AuditLoginListParams>;
      }
    >({
      query(arg) {
        const schema = toOptionalSchemaProperties(auditLoginParams);
        const params = stripUndefined(
          toSchemaWithDefaults(schema, {
            page: 1,
            limit: 25,
          }).parse(arg.params),
        );

        return {
          url: `audit/login`,
          params,
        };
      },
      transformResponse(
        baseQueryReturnValue,
        meta: { request: Request; response?: Response },
        arg,
      ) {
        return toPaginatedList(baseQueryReturnValue, meta.response?.headers);
      },
    }),
    // auditLogin: builder.query<
    //   PaginatedResponse<AuditLogin>,
    //   {
    //     params?: AuditLoginListParams;
    //   } | void
    // >({
    //   query: (args) => {
    //     const { params = {} } = args ?? {};
    //     const parsedParams = Object.entries(params)
    //       .filter(([key, value]) => Boolean(value))
    //       .reduce(
    //         (all, [key, value]) => ({ ...all, [key]: `${value}` }),
    //         {} as Record<string, string>,
    //       );
    //     return {
    //       url: 'audit/login',
    //       params: { ...PAGINATION_DEFAULT_VALUE, ...parsedParams },
    //     };
    //   },
    //   // providesTags: ['Work-Type'],
    //   transformResponse(
    //     baseQueryReturnValue,
    //     meta: { request: Request; response?: Response },
    //     arg,
    //   ) {
    //     return toPaginatedList(baseQueryReturnValue, meta.response?.headers);
    //   },
    // }),
  }),
  overrideExisting: false,
});

export const { useAuditLoginQuery } = endpoints;
export const AuditLoginService = endpoints;
