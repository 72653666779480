import {
  FetchBaseQueryError,
  TypedUseQueryHookResult,
} from '@reduxjs/toolkit/dist/query/react';
import { Skeleton } from 'baseui/skeleton';
import React from 'react';

import { Button } from 'components/ui/button';
import { Heading } from 'components/ui/heading';
import { Text } from 'components/ui/text';
import { useLocale } from 'utils/hooks/useLocale';

export default function QueryResolver<
  T extends TypedUseQueryHookResult<unknown, unknown, any>,
  TData = T['data'],
>({
  children,
  query,
  onRefetch: onRefetchHandler = query.refetch,
  loadingElement = <Skeleton rows={6} width="100%" />,
  errorElement = null,
  uninitializedElement = null,
}: {
  query: T;
  children: ((d: NonNullable<TData>) => React.ReactNode) | React.ReactNode;
  onRefetch?: () => void;
  loadingElement?: React.ReactNode;
  errorElement?:
    | ((error: FetchBaseQueryError) => React.ReactNode)
    | React.ReactNode;
  uninitializedElement?: React.ReactNode;
}) {
  const { t, isLocaleReady } = useLocale('common', { useSuspense: false });
  if (!isLocaleReady) {
    return null;
  }

  if (query.isUninitialized) {
    return <>{uninitializedElement}</>;
  }

  if (query.isError) {
    if (errorElement !== null) {
      return (
        <>
          {typeof errorElement === 'function'
            ? errorElement(query.error)
            : errorElement}
        </>
      );
    }

    return (
      <div className="min-h-full bg-white px-4 py-16 font-prompt sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <div className="sm:ml-6">
            <div className="font-sans">
              <Heading>{t('Error')}</Heading>
              <Text>
                {t('query_resolver_error')}. {t('Try Again')}.
              </Text>
            </div>
            <div className="mt-5">
              <Button onClick={onRefetchHandler}>{t('Try Again')}</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * By default we do not want to show the previously cached
   * data when the query args changes.
   *
   * In the future we can consolidate this behavior to a prop to
   * choose if we want to show the cached data while fetching a new one
   * or show the loading element when the query is fetching.
   */
  if (query.isSuccess && !query.isFetching && query.data !== undefined) {
    return (
      <>
        {typeof children === 'function'
          ? children(query.data as NonNullable<TData>)
          : children}
      </>
    );
  }

  return <>{loadingElement}</>;
}
