import type { SharedStylePropsArg } from 'baseui/select';
import type { StyleObject } from 'styletron-react';

import type { SelectProps } from './Select';

import theme from 'theme';

export function getDefaultKindStyles(
  kind: SelectProps['kind'],
  sharedProps: SharedStylePropsArg,
): StyleObject {
  const { $isFocused, $error } = sharedProps;

  switch (kind) {
    case 'primary':
      return Object.freeze({});
    case 'secondary':
      return {
        backgroundColor: theme.colors.white,
        borderBottomColor: theme.colors.mono400,
        borderLeftColor: 'transparent',
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
        ...($isFocused
          ? {
              borderBottomColor: theme.colors.primary,
            }
          : {}),
        ...($error
          ? {
              borderBottomColor: theme.colors.negative,
            }
          : {}),
      };
    case 'tertiary':
      return {
        backgroundColor: 'transparent',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderTopWidth: 0,
      };
    default:
      return Object.freeze({});
  }
}
