import type { KIND } from 'baseui/button';
import type { StyleObject } from 'styletron-react';

import type { ButtonProps, Intent } from './Button';

import theme from 'theme';

export function getDefaultKindStyles(kind: keyof typeof KIND): StyleObject {
  switch (kind) {
    case 'primary':
      return Object.freeze({});
    case 'secondary':
      return {
        backgroundColor: theme.colors.primary50,
        ':hover': {
          backgroundColor: theme.colors.primary,
          color: theme.colors.white,
        },
      };
    default:
      return Object.freeze({});
  }
}
export function getVariantStyles(
  variant: ButtonProps['variant'],
  kind: keyof typeof KIND,
): StyleObject {
  switch (variant) {
    case 'solid':
      return Object.freeze({});
    case 'outline':
      return {
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: '1px',
        borderRightWidth: '1px',
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
        borderTopColor: theme.colors.primary,
        backgroundColor: theme.colors.white,
        color: theme.colors.primary,
        borderBottomColor: theme.colors.primary,
        borderLeftColor: theme.colors.primary,
        borderRightColor: theme.colors.primary,
      };
    default:
      return Object.freeze({});
  }
}

export function getIntentStyles(
  intent?: Intent,
  kind?: keyof typeof KIND,
): StyleObject {
  if (!intent) return Object.freeze({});
  const disabledStyles = {
    ':disabled': {
      backgroundColor: theme.colors.buttonDisabledFill,
      color: theme.colors.buttonDisabledText,
      ':hover': {
        backgroundColor: theme.colors.buttonDisabledFill,
        color: theme.colors.buttonDisabledText,
      },
    },
  };

  switch (kind) {
    case 'primary':
      switch (intent) {
        case 'danger':
          return {
            backgroundColor: theme.colors.negative400,
            ...disabledStyles,
            ':hover': {
              backgroundColor: theme.colors.negative500,
              color: theme.colors.white,
            },
          };
        case 'warning':
          return Object.freeze({});
        case 'success':
          return {
            backgroundColor: theme.colors.positive400,
            ...disabledStyles,
            ':hover': {
              backgroundColor: theme.colors.positive500,
              color: theme.colors.white,
            },
          };
        default:
          return Object.freeze({});
      }
    case 'secondary':
      switch (intent) {
        case 'danger':
          return {
            backgroundColor: theme.colors.negative50,
            color: theme.colors.negative400,
            ...disabledStyles,

            ':hover': {
              backgroundColor: theme.colors.negative400,
              color: theme.colors.white,
            },
          };
        case 'warning':
          return Object.freeze({});
        case 'success':
          return {
            backgroundColor: theme.colors.positive50,
            color: theme.colors.positive400,
            ...disabledStyles,
            ':hover': {
              backgroundColor: theme.colors.positive400,
              color: theme.colors.white,
            },
          };
        default:
          return Object.freeze({});
      }
    case 'tertiary':
      switch (intent) {
        case 'danger':
          return {
            backgroundColor: 'transparent',
            color: theme.colors.negative400,
            ...disabledStyles,
            ':hover': {
              backgroundColor: theme.colors.negative400,
              color: theme.colors.white,
            },
          };
        case 'warning':
          return Object.freeze({});
        case 'success':
          return Object.freeze({});
        default:
          return Object.freeze({});
      }
    default:
      return Object.freeze({});
  }
}
