import { useSlotId } from '@react-aria/utils';
import clsx from 'clsx';
import {
  Group as AriaGroup,
  GroupProps as AriaGroupProps,
  Text as AriaText,
  TextProps as AriaTextProps,
} from 'react-aria-components';
import {
  Control,
  FieldPath,
  FieldValues,
  get as getRHFFieldError,
  useController,
  useFormState,
} from 'react-hook-form';
import { tv } from 'tailwind-variants';

import { cn } from 'utils/helpers';
import { useLocale } from 'utils/hooks/useLocale';

const base = [
  '[&>[data-slot=label]+[data-slot=control]]:mt-2',
  '[&>[data-slot=label]+[data-slot=description]]:mt-1',
  '[&>[data-slot=description]+[data-slot=control]]:mt-2',
  '[&>[data-slot=control]+[data-slot=description]]:mt-2',
  '[&>[data-slot=control]+[data-slot=error]]:mt-2',
  // '[&>[data-slot=label]]:font-medium',
];
export const fieldStyles = tv({ base });

export type ControlledFieldProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = { control: Control<T>; field: TName; type?: 'text' | 'number' };

function ControlledField<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  field: fieldName,
  children,
  unstyled,
  type: fieldType,
  ...props
}: ControlledFieldProps<T, TName> & {
  children: React.ReactNode;
  unstyled?: boolean;
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'children'>) {
  const controller = useController({ control, name: fieldName });
  const field = controller.field;
  const error = !!controller.fieldState.error?.message;

  return (
    <div className={clsx(className, base)}>
      {children && typeof children !== 'function' ? children : null}
      <FieldErrorMessage control={control} field={fieldName} />
    </div>
  );
}

export function Fieldset({
  className,
  disabled = false,
  ...props
}: { disabled?: boolean } & AriaGroupProps) {
  const labelId = useSlotId();

  return (
    <AriaGroup
      {...props}
      data-disabled={JSON.stringify(disabled)}
      className={clsx(
        className,
        '[&>*+[data-slot=control]]:mt-7 [&>[data-slot=text]]:mt-1',
      )}
    />
  );
}

export function Legend({ ...props }: AriaTextProps) {
  return (
    <AriaText
      {...props}
      data-slot="legend"
      className={cn(
        'text-base/6 font-semibold text-zinc-950 data-[disabled]:opacity-50 dark:text-white',
        props.className,
      )}
    />
  );
}

export type FieldGroupProps = React.ComponentPropsWithoutRef<'div'>;
export function FieldGroup({
  orientation = 'vertical',
  className,
  ...props
}: FieldGroupProps & { orientation?: 'vertical' | 'horizontal' }) {
  return (
    <div
      {...props}
      data-slot="control"
      className={clsx([
        orientation === 'vertical'
          ? 'space-y-5'
          : 'grid gap-x-4 gap-y-5 lg:grid-cols-2',
        className,
      ])}
    />
  );
}

export function Field<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  field,
  ...props
}: React.ComponentPropsWithoutRef<'div'> & {
  children: React.ReactNode;
} & Partial<ControlledFieldProps<T, TName>>) {
  if (control && field) {
    return (
      <ControlledField
        control={control}
        field={field}
        className={className}
        {...props}
      />
    );
  }
  return <div className={clsx(className, base)} {...props} />;
}

export function FieldErrorMessage<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  control,
  field,
  children,
}: {
  control: Control<T>;
  field: TName | TName[];
  children?: (message: string) => React.ReactNode;
}) {
  const { t, i18n } = useLocale();
  const { errors } = useFormState({ control, name: field, exact: true });
  const error = Array.isArray(field)
    ? field.reduce((errMessage, fieldName) => {
        if (errMessage) {
          return errMessage;
        }
        errMessage = getRHFFieldError(errors, fieldName, '');
        return errMessage;
      }, '')
    : getRHFFieldError(errors, field, '');

  if (!error) {
    return null;
  }
  const { message: messageFromRegister } = error;
  const errorMessage = messageFromRegister;
  const keyedMessage = errorMessage ? `${errorMessage}` : '';

  /**
   * The zod `message` may be passed a translation key (ex:errors.undefined) to handle custom
   * error message without `refine`.
   */
  const keyedMessageExist = keyedMessage
    ? i18n.exists(keyedMessage, {
        ns: 'zod',
      })
    : false;

  const translatedMessage = keyedMessageExist
    ? t(keyedMessage, { ns: 'zod' })
    : // This should already contain the translated message if no `keyed` message
    errorMessage
    ? errorMessage
    : t('default_validation_message', {
        ns: 'zod',
      });

  if (!children) {
    return (
      <AriaText
        data-slot="error"
        slot="errorMessage"
        className="block text-base/6 text-red-600 data-[disabled]:opacity-50 sm:text-sm/6"
      >
        {translatedMessage}
      </AriaText>
    );
  }

  return <>{children(translatedMessage)}</>;
}

export function Description({
  className,
  disabled,
  ...props
}: { className?: string; disabled?: boolean } & AriaTextProps) {
  return (
    <AriaText
      {...props}
      data-slot="description"
      slot="description"
      className={clsx(
        className,
        'block text-base/6 text-zinc-500 data-[disabled]:opacity-50 sm:text-sm/6',
      )}
    />
  );
}
