import { Spinner } from 'baseui/spinner';
import * as React from 'react';
import type {
  PressEvent,
  ButtonProps as RACButtonProps,
} from 'react-aria-components';
import { Link as AriaLink, Button as RACButton } from 'react-aria-components';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
  useHref,
} from 'react-router-dom';
import { tv, VariantProps } from 'tailwind-variants';

import { cn } from 'utils/helpers';

const styles = {
  base: [
    //Variables
    '[--btn-radius:theme(borderRadius.lg)]',

    // Base
    'relative isolate inline-flex items-center justify-center gap-x-2 rounded-[--btn-radius] border text-base/6 font-semibold',

    // Focus
    'focus:outline-none data-[focused]:outline data-[focused]:outline-2 data-[focused]:outline-offset-2 data-[focused]:outline-blue-500',

    // Focus with non `data-` attr
    'focus:outline-none focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue-500',

    // Disabled
    'data-[disabled]:opacity-50',

    // Icon
    // '[&>[data-slot=icon]]:-mx-0.5 [&>[data-slot=icon]]:my-0.5 [&>[data-slot=icon]]:w-5 [&>[data-slot=icon]]:h-5 [&>[data-slot=icon]]:shrink-0 [&>[data-slot=icon]]:text-[--btn-icon] [&>[data-slot=icon]]:sm:my-1 [&>[data-slot=icon]]:sm:w-4 [&>[data-slot=icon]]:sm:h-4',
    '[&_*[data-slot=icon]]:-mx-0.5 [&_*[data-slot=icon]]:my-0.5 [&_*[data-slot=icon]]:w-5 [&_*[data-slot=icon]]:h-5 [&_*[data-slot=icon]]:shrink-0 [&_*[data-slot=icon]]:text-[--btn-icon] [&_*[data-slot=icon]]:sm:my-1 [&_*[data-slot=icon]]:sm:w-4 [&_*[data-slot=icon]]:sm:h-4',

    // Icon SVG
    '[&_*[data-slot=icon]>svg]:w-full [&_*[data-slot=icon]>svg]:h-full',
  ],
  sizing: {
    default: [
      'px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing.3)-1px)] sm:py-[calc(theme(spacing[1.5])-1px)] sm:text-sm/6',
    ],
    lg: [
      'px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)]',
    ],
  },
  solid: [
    // Optical border, implemented as the button background to avoid corner artifacts
    'border-transparent bg-[--btn-border]',

    // Button background, implemented as foreground layer to stack on top of pseudo-border layer
    'before:absolute before:inset-0 before:-z-10 before:rounded-[calc(var(--btn-radius)-1px)] before:bg-[--btn-bg]',

    // Drop shadow, applied to the inset `before` layer so it blends with the border
    'before:shadow',

    // Shim/overlay, inset to match button foreground and used for hover state + highlight shadow
    'after:absolute after:inset-0 after:-z-10 after:rounded-[calc(var(--btn-radius)-1px)]',

    // Inner highlight shadow
    'after:shadow-[shadow:inset_0_1px_theme(colors.white/15%)]',

    // White overlay on hover
    'after:data-[pressed]:bg-[--btn-hover-overlay] after:data-[hovered]:bg-[--btn-hover-overlay]',

    // White overlay on hover on non `data-attr`
    'after:active:bg-[--btn-hover-overlay] after:hover:bg-[--btn-hover-overlay]',

    // Disabled
    'before:data-[disabled]:shadow-none after:data-[disabled]:shadow-none',
  ],
  outline: [
    // Base
    'border-zinc-950/10 text-zinc-950 data-[pressed]:bg-zinc-950/[2.5%] data-[hovered]:bg-zinc-950/[2.5%]',

    // Icon
    '[--btn-icon:theme(colors.zinc.500)] data-[pressed]:[--btn-icon:theme(colors.zinc.700)] data-[hovered]:[--btn-icon:theme(colors.zinc.700)] data-[pressed]:[--btn-icon:theme(colors.zinc.700)] hover:[--btn-icon:theme(colors.zinc.700)]',

    // Icon without `data-attr`
    '[--btn-icon:theme(colors.zinc.500)] active:[--btn-icon:theme(colors.zinc.700)] hover:[--btn-icon:theme(colors.zinc.700)] active:[--btn-icon:theme(colors.zinc.700)] hover:[--btn-icon:theme(colors.zinc.700)]',
  ],
  plain: [
    // Base
    'border-transparent text-zinc-950 data-[pressed]:bg-zinc-950/5 data-[hovered]:bg-zinc-950/5',

    // Base without `data-attr`
    'border-transparent text-zinc-950 active:bg-zinc-950/5 hover:bg-zinc-950/5',

    // Icon
    '[--btn-icon:theme(colors.zinc.500)] data-[pressed]:[--btn-icon:theme(colors.zinc.700)] data-[pressed]:[--btn-icon:theme(colors.zinc.700)] data-[hovered]:[--btn-icon:theme(colors.zinc.700)]',

    // Icon without `data-attr`
    '[--btn-icon:theme(colors.zinc.500)] active:[--btn-icon:theme(colors.zinc.700)] active:[--btn-icon:theme(colors.zinc.700)] hover:[--btn-icon:theme(colors.zinc.700)]',
  ],
  colors: {
    brand: [
      'text-white [--btn-bg:var(--brand-primary-600)] [--btn-border:var(--brand-primary-500)] [--btn-hover-overlay:theme(colors.white/10%)]',
      '[--btn-icon:theme(colors.white)] data-[pressed]:[--btn-icon:var(--brand-primary-200)] data-[hovered]:[--btn-icon:theme(colors.white)]',
      '[--btn-icon:theme(colors.white)] active:[--btn-icon:var(--brand-primary-200)] hover:[--btn-icon:theme(colors.white)]',
    ],
    'dark/zinc': [
      'text-white [--btn-bg:theme(colors.zinc.900)] [--btn-border:theme(colors.zinc.950/90%)] [--btn-hover-overlay:theme(colors.white/10%)]',
      '[--btn-icon:theme(colors.zinc.400)] data-[pressed]:[--btn-icon:theme(colors.zinc.300)] data-[hovered]:[--btn-icon:theme(colors.zinc.300)]',
    ],
    light: [
      'text-zinc-950 [--btn-bg:white] [--btn-border:theme(colors.zinc.950/10%)] [--btn-hover-overlay:theme(colors.zinc.950/2.5%)] data-[pressed]:[--btn-border:theme(colors.zinc.950/15%)] data-[hovered]:[--btn-border:theme(colors.zinc.950/15%)]',
      '[--btn-icon:theme(colors.zinc.500)] data-[pressed]:[--btn-icon:theme(colors.zinc.700)] data-[hovered]:[--btn-icon:theme(colors.zinc.700)] dark:[--btn-icon:theme(colors.zinc.500)] dark:data-[pressed]:[--btn-icon:theme(colors.zinc.400)] dark:data-[hovered]:[--btn-icon:theme(colors.zinc.400)]',
    ],
    'dark/white': [
      'text-white [--btn-bg:theme(colors.zinc.900)] [--btn-border:theme(colors.zinc.950/90%)] [--btn-hover-overlay:theme(colors.white/10%)]',
      '[--btn-icon:theme(colors.zinc.400)] data-[pressed]:[--btn-icon:theme(colors.zinc.300)] data-[hovered]:[--btn-icon:theme(colors.zinc.300)] dark:[--btn-icon:theme(colors.zinc.500)] dark:data-[pressed]:[--btn-icon:theme(colors.zinc.400)] dark:data-[hovered]:[--btn-icon:theme(colors.zinc.400)]',
    ],
    dark: [
      'text-white [--btn-bg:theme(colors.zinc.900)] [--btn-border:theme(colors.zinc.950/90%)] [--btn-hover-overlay:theme(colors.white/10%)]',
      '[--btn-icon:theme(colors.zinc.400)] data-[pressed]:[--btn-icon:theme(colors.zinc.300)] data-[hovered]:[--btn-icon:theme(colors.zinc.300)]',
    ],
    white: [
      'text-zinc-950 [--btn-bg:white] [--btn-border:theme(colors.zinc.950/10%)] [--btn-hover-overlay:theme(colors.zinc.950/2.5%)] data-[pressed]:[--btn-border:theme(colors.zinc.950/15%)] data-[hovered]:[--btn-border:theme(colors.zinc.950/15%)]',
      '[--btn-icon:theme(colors.zinc.400)] data-[pressed]:[--btn-icon:theme(colors.zinc.500)] data-[hovered]:[--btn-icon:theme(colors.zinc.500)]',
    ],
    zinc: [
      'text-white [--btn-hover-overlay:theme(colors.white/10%)] [--btn-bg:theme(colors.zinc.600)] [--btn-border:theme(colors.zinc.700/90%)]',
      '[--btn-icon:theme(colors.zinc.400)] data-[pressed]:[--btn-icon:theme(colors.zinc.300)] data-[hovered]:[--btn-icon:theme(colors.zinc.300)]',
    ],
    red: [
      'text-white [--btn-hover-overlay:theme(colors.white/10%)] [--btn-bg:theme(colors.red.600)] [--btn-border:theme(colors.red.700/90%)]',
      '[--btn-icon:theme(colors.red.300)] data-[pressed]:[--btn-icon:theme(colors.red.200)] data-[hovered]:[--btn-icon:theme(colors.red.200)]',
    ],
    yellow: [
      'text-yellow-950 [--btn-hover-overlay:theme(colors.white/25%)] [--btn-bg:theme(colors.yellow.300)] [--btn-border:theme(colors.yellow.400/80%)]',
      '[--btn-icon:theme(colors.yellow.600)] data-[pressed]:[--btn-icon:theme(colors.yellow.700)] data-[hovered]:[--btn-icon:theme(colors.yellow.700)]',
    ],
    green: [
      'text-white [--btn-hover-overlay:theme(colors.white/10%)] [--btn-bg:theme(colors.green.600)] [--btn-border:theme(colors.green.700/90%)]',
      '[--btn-icon:theme(colors.white/60%)] data-[pressed]:[--btn-icon:theme(colors.white/80%)] data-[hovered]:[--btn-icon:theme(colors.white/80%)]',
    ],
    blue: [
      'text-white [--btn-hover-overlay:theme(colors.white/10%)] [--btn-bg:theme(colors.blue.600)] [--btn-border:theme(colors.blue.700/90%)]',
      '[--btn-icon:theme(colors.blue.400)] data-[pressed]:[--btn-icon:theme(colors.blue.300)] data-[hovered]:[--btn-icon:theme(colors.blue.300)]',
    ],
  },
};

const buttonVariants = tv({
  base: styles.base,
  variants: {
    variant: {
      default: styles.solid,
      outline: styles.outline,
      plain: styles.plain,
      // link: 'text-primary underline-offset-4 hover:underline',
    },
    size: {
      default: styles.sizing.default,
      lg: styles.sizing.lg,
    },
    square: {
      true: '',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
  compoundVariants: [
    // {
    //   variant: 'default',
    //   size: 'default',
    //   square: true,
    //   className: [
    //     // Icon
    //     '[&_*[data-slot=icon]]:text-[--btn-icon] [&_*[data-slot=icon]]:sm:h-4 [&_*[data-slot=icon]]:sm:w-4',
    //     // Padding
    //     '[&_*[data-slot=icon]]:sm:my-1 ',
    //     // Colors
    //     '[--btn-icon:theme(colors.white)] data-[hovered]:[--btn-icon:theme(colors.white)] data-[pressed]:[--btn-icon:var(--brand-primary-200)]',
    //     '[--btn-icon:theme(colors.white)] hover:[--btn-icon:theme(colors.white)] active:[--btn-icon:var(--brand-primary-200)]',
    //   ],
    // },
    // {
    //   variant: 'plain',
    //   size: 'default',
    //   square: true,
    //   className: [
    //     // Icon
    //     // '[&_>[data-slot=icon]]:text-[--btn-icon] [&_>[data-slot=icon]]:sm:h-5 [&_>[data-slot=icon]]:sm:w-5',
    //     // Padding
    //     'p-[calc(theme(spacing[2.5])-1px)] sm:p-[calc(theme(spacing[2])-1px)]',
    //     // Colors
    //     '[--btn-icon:theme(colors.zinc.500)] data-[hovered]:[--btn-icon:theme(colors.zinc.700)] data-[pressed]:[--btn-icon:theme(colors.zinc.700)]',
    //   ],
    // },
  ],
});

export interface ButtonProps
  extends RACButtonProps,
    VariantProps<typeof buttonVariants> {
  onClick?: (e: PressEvent) => void;
  href?: ReactRouterLinkProps['to'];
  linkOptions?: Omit<ReactRouterLinkProps, 'to'>;
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
  isLoading?: boolean;
  color?: keyof typeof styles.colors;
  title?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      square,
      onClick,
      startEnhancer,
      slot,
      endEnhancer,
      isLoading = false,
      color: buttonColor,
      children,
      linkOptions,
      ...props
    },
    ref,
  ) => {
    const href = useHref(props.href ?? '');
    const color = buttonColor
      ? styles.colors[buttonColor]
      : styles.colors.brand;

    if (props.href && linkOptions) {
      return (
        <ReactRouterLink
          to={props.href}
          {...linkOptions}
          className={cn(
            color,
            buttonVariants({
              variant,
              size,
              square,
            }),
            className,
          )}
          ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        >
          {startEnhancer && <span data-slot="icon">{startEnhancer}</span>}
          <span>{typeof children !== 'function' && children}</span>
          {endEnhancer && <span data-slot="icon">{endEnhancer}</span>}
        </ReactRouterLink>
      );
    }
    return props.href ? (
      <AriaLink
        href={href}
        className={cn(
          color,
          buttonVariants({
            variant,
            size,
            square,
          }),
          className,
        )}
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        slot={slot ? slot : null}
      >
        {startEnhancer && <span data-slot="icon">{startEnhancer}</span>}
        <span>{typeof children !== 'function' && children}</span>
        {endEnhancer && <span data-slot="icon">{endEnhancer}</span>}
      </AriaLink>
    ) : (
      <RACButton
        className={cn(
          color,
          buttonVariants({
            variant,
            size,
            square,
          }),
          className,
        )}
        ref={ref}
        {...props}
        onPress={onClick ?? props.onPress}
        isDisabled={isLoading || props.isDisabled}
        slot={slot ? slot : null}
      >
        {(rp) => (
          <>
            {isLoading && <Spinner $size={16} $borderWidth={2} />}
            {!isLoading && startEnhancer && (
              <span data-slot="icon">{startEnhancer}</span>
            )}
            <span>
              {typeof children === 'function' ? children(rp) : children}
            </span>
            {!!isLoading && endEnhancer && (
              <span data-slot="icon">{endEnhancer}</span>
            )}
          </>
        )}
      </RACButton>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants, styles as buttonStyles };
