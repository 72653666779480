import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { format } from 'date-fns';
import { z } from 'zod';

import {
  CreateSheduleSlot,
  NextAvailableOpening,
  NextOpeningParams,
  RecurringSchedulePayload,
  ScheduleItem,
  ScheduleOpenings,
  ScheduleSlot,
  SchedulesParams,
  nextAvailableOpeningSchema,
  nextOpeningParamsSchema,
  schedulesParamsSchema,
  FindSchedule,
} from './types';

import { baseAPI, isMutationFailed, isMutationSuccess } from '../base-api';
import { WorkRequestStatus } from '../types.shared';
import { endpoints as UtilitiesService } from '../utilities/endpoint';
import { endpoints as WorkRequestService } from '../work-requests/endpoints';

function toSchemaWithDefaults<Schema extends z.ZodObject<any>>(
  schema: Schema,
  catchValues: Partial<z.infer<typeof schema>>,
) {
  const entries = Object.entries(schema.shape) as [
    keyof Schema['shape'],
    z.ZodTypeAny,
  ][];
  const newProps = entries.reduce(
    (acc, [key, value]) => {
      acc[key] = value.default(catchValues[key]);
      return acc;
    },
    {} as {
      [key in keyof Schema['shape']]: Schema['shape'][key];
    },
  );
  return z.object(newProps);
}
function toSchemaTransformNullToUndefined<Schema extends z.ZodObject<any>>(
  schema: Schema,
) {
  const entries = Object.entries(schema.shape) as [
    keyof Schema['shape'],
    z.ZodTypeAny,
  ][];
  const newProps = entries.reduce(
    (acc, [key, value]) => {
      acc[key] = value.nullish().transform((x) => x ?? undefined);
      return acc;
    },
    {} as {
      [key in keyof Schema['shape']]: Schema['shape'][key];
    },
  );
  return z.object(newProps);
}

const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    schedules: builder.query<ScheduleItem, SchedulesParams>({
      query: (args) => {
        const parsed = toSchemaWithDefaults(schedulesParamsSchema, {
          dateTo: format(new Date(), 'yyyy-MM-dd'),
          dateFrom: format(new Date(), 'yyyy-MM-dd'),
          includeIsCancelled: false,
        }).parse(
          toSchemaTransformNullToUndefined(schedulesParamsSchema).parse(args),
        );
        return {
          url: 'portal/scheduleitems',
          params: Object.entries(parsed)
            .filter(([key, value]) => value !== undefined)
            .reduce((all, [key, value]) => ({ ...all, [key]: value }), {}),
        };
      },
      transformResponse(baseQueryReturnValue: any, meta, arg) {
        // FIXME: Temporary
        return {
          ...baseQueryReturnValue,
          schedRows: baseQueryReturnValue.schedRows.map((row: any) => ({
            ...row.scheduleItem,
            ...row.workRequest,
          })),
        };
      },
      providesTags: (response) => (response ? ['Schedules'] : []),
    }),
    findSchedule: builder.query<
      FindSchedule[],
      { workRequestId: number; startDate?: string; startTime?: string }
    >({
      query: (args) => {
        const parsed = toSchemaWithDefaults(
          z.object({
            startDate: z.string().optional(),
            startTime: z.string().optional(),
          }),
          {
            startDate: '',
            startTime: '',
          },
        ).parse(args);
        const params = new URLSearchParams();
        if (parsed.startDate) {
          params.set('startDate', parsed.startDate);
        }
        if (parsed.startTime) {
          params.set('startTime', parsed.startTime);
        }
        return {
          url: `work-request/${args.workRequestId}/find-schedule`,
          params,
        };
      },
    }),
    scheduleOpenings: builder.query<
      ScheduleOpenings,
      { workRequestId: number; day?: string | null }
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const { workRequestId, day = format(new Date(), 'yyyy-MM-dd') } = arg;
        const workRequest = await api.dispatch(
          WorkRequestService.endpoints._work_request.initiate(
            arg.workRequestId,
          ),
        );
        if (!isMutationSuccess(workRequest)) {
          return {
            error: workRequest.error as FetchBaseQueryError,
          };
        }
        const openings = await baseQuery({
          url: 'schedule-items/openings',
          params: {
            wrId: workRequestId,
            day,
          },
        });
        if (openings.error) {
          return {
            error: openings.error as FetchBaseQueryError,
          };
        }

        return {
          // TODO: Validated types
          data: {
            ...(openings.data as any),
            workRequestDuration: workRequest.data.estimateDuration,
            prefTechId: workRequest.data.prefTechId,
          },
        };
      },
    }),
    _schedule_openings: builder.mutation<
      ScheduleOpenings,
      { workRequestId: number; day?: string | null }
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const { workRequestId, day = format(new Date(), 'yyyy-MM-dd') } = arg;
        const workRequest = await api.dispatch(
          WorkRequestService.endpoints._work_request.initiate(
            arg.workRequestId,
          ),
        );
        if (!isMutationSuccess(workRequest)) {
          return {
            error: workRequest.error as FetchBaseQueryError,
          };
        }
        const openings = await baseQuery({
          url: 'schedule-items/openings',
          params: {
            wrId: workRequestId,
            day,
          },
        });
        if (openings.error) {
          return {
            error: openings.error as FetchBaseQueryError,
          };
        }

        return {
          data: {
            ...(openings.data as any),
            workRequestDuration: workRequest.data.estimateDuration,
          },
        };
      },
    }),
    nextOpening: builder.query<NextAvailableOpening, NextOpeningParams>({
      query: (args) => {
        const parsed = toSchemaWithDefaults(nextOpeningParamsSchema, {
          duration: 'PT1H',
        }).parse(
          toSchemaTransformNullToUndefined(nextOpeningParamsSchema).parse(args),
        );

        return {
          url: 'schedule-items/next-opening',
          params: Object.entries(parsed)
            .filter(([key, value]) => value !== undefined)
            .reduce((all, [key, value]) => ({ ...all, [key]: value }), {}),
        };
      },
      transformResponse(baseQueryReturnValue, meta, arg) {
        return nextAvailableOpeningSchema.parse(baseQueryReturnValue);
      },
      providesTags: (result) =>
        result !== undefined ? [{ type: 'Schedules', id: 'Next-Opening' }] : [],
    }),
    _nextOpening: builder.mutation<NextAvailableOpening, NextOpeningParams>({
      query: (args) => {
        const parsed = toSchemaWithDefaults(nextOpeningParamsSchema, {
          duration: 'PT1H',
        }).parse(
          toSchemaTransformNullToUndefined(nextOpeningParamsSchema).parse(args),
        );

        return {
          url: 'schedule-items/next-opening',
          params: Object.entries(parsed)
            .filter(([key, value]) => value !== undefined)
            .reduce((all, [key, value]) => ({ ...all, [key]: value }), {}),
        };
      },
      transformResponse(baseQueryReturnValue, meta, arg) {
        return nextAvailableOpeningSchema.parse(baseQueryReturnValue);
      },
      invalidatesTags: (result) =>
        result !== undefined ? [{ type: 'Schedules', id: 'Next-Opening' }] : [],
    }),
    modifySchedule: builder.mutation<
      ScheduleSlot,
      | {
          type: 'CREATE';
          value: CreateSheduleSlot;
        }
      | { type: 'UPDATE'; value: ScheduleSlot }
    >({
      async queryFn(payload, api, extraOptions, baseQuery) {
        let scheduledSlot;

        if (payload.type === 'UPDATE') {
          const result = await baseQuery({
            url: `schedule-items/${payload.value.id}`,
            method: 'PUT',
            body: payload.value,
          });
          if (isMutationSuccess(result)) {
            return { data: result.data as ScheduleSlot };
          }
          return { error: result.error };
        }

        const scheduleResult = await baseQuery({
          url: 'scheduleitems',
          method: 'POST',
          body: payload.value,
        });
        // Handle Error from request above
        if (scheduleResult.error) {
          return {
            error: scheduleResult.error as FetchBaseQueryError,
          };
        }

        const workRequestResult = await api.dispatch(
          WorkRequestService.endpoints._work_request.initiate(
            payload.value.workRequestId,
          ),
        );

        // Handle Error from request above
        if (isMutationFailed(workRequestResult)) {
          return {
            error: { ...(workRequestResult.error as FetchBaseQueryError) },
          };
        }

        const workRequest = workRequestResult.data;
        const NEXT_STATUS: Partial<
          Record<WorkRequestStatus, WorkRequestStatus>
        > = {
          Created: 'Scheduled',
          EstCreated: 'EstScheduled',
          EstApproved: 'Scheduled',
        };
        const newStatus = NEXT_STATUS[workRequest.status as WorkRequestStatus];

        const updatedWorkRequest = await api.dispatch(
          WorkRequestService.endpoints.updateWorkRequest.initiate({
            ...workRequest,
            status: newStatus as WorkRequestStatus,
            prevStatus: workRequest.status,
          }),
        );

        if (isMutationFailed(updatedWorkRequest)) {
          return {
            error: updatedWorkRequest.error as FetchBaseQueryError,
          };
        }
        const scheduleTypeValue = () => {
          // If we are scheduling an approve estimate that is going to be a job
          if (
            workRequest.estimateRequired &&
            workRequest.status === 'EstApproved'
          ) {
            return 'JobSchedule';
          }
          return workRequest.estimateRequired
            ? 'EstimateSchedule'
            : 'JobSchedule';
        };

        await api.dispatch(
          UtilitiesService.endpoints.sendSMS.initiate({
            scheduleType: scheduleTypeValue(),
            workRequestId: workRequest.id,
            type: 'Confirmation',
          }),
        );

        return {
          data: scheduleResult.data as ScheduleSlot,
        };
      },
      invalidatesTags: (response) =>
        response
          ? [
              'Schedules',
              'Jobs',
              'Estimates',
              { type: 'Schedules', id: 'Next-Opening' },
            ]
          : [],
    }),
    cancelSchedule: builder.mutation<ScheduleSlot, number>({
      query(id) {
        return {
          url: `scheduleitems/${id}/cancel`,
          method: 'POST',
        };
      },
      invalidatesTags: (res, error, id) =>
        res
          ? [
              'Schedules',
              'Jobs',
              'Estimates',
              { type: 'Schedules', id: 'Next-Opening' },
              { type: 'Work-Requests', id: res.workRequestId },
            ]
          : [],
    }),
    setRecurringSchedule: builder.mutation<
      { success: boolean },
      RecurringSchedulePayload
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const { workRequestId, ...recurringPayload } = arg;
        const schedule = await baseQuery({
          url: 'schedule-items/recurring',
          method: 'POST',
          body: recurringPayload,
        });
        if (schedule.error) {
          return { error: schedule.error };
        }

        const workRequest = await api.dispatch(
          WorkRequestService.endpoints.updateWorkRequest.initiate({
            id: workRequestId,
            recurring: true,
          }),
        );
        if (isMutationFailed(workRequest)) {
          return { error: workRequest.error as FetchBaseQueryError };
        }

        return { data: { success: true } };
      },
      invalidatesTags: (response) => (response ? ['Schedules'] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSchedulesQuery,
  useNextOpeningQuery,
  useModifyScheduleMutation,
  useScheduleOpeningsQuery,
  useCancelScheduleMutation,
  use_nextOpeningMutation,
} = endpoints;
export const ScheduleService = endpoints;
