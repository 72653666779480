import { clsx } from 'clsx';
import { useState } from 'react';
import {
  Input as AriaInput,
  InputProps as AriaInputProps,
  TextField as AriaTextField,
  TextFieldProps as AriaTextFieldProps,
  Button as AriaButton,
} from 'react-aria-components';
import { Eye as EyeIcon, EyeOff as EyeOffIcon } from 'react-feather';
import { useController } from 'react-hook-form';

import { ControlledFieldProps, withControlledField } from './utils';

import { FieldErrorMessage, fieldStyles } from '../forms/fieldset';

import { cn } from 'utils/helpers';

export type InputProps = {
  type?: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url';
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
} & AriaInputProps;
export function Input({
  startEnhancer,
  endEnhancer,
  className,
  ...props
}: InputProps) {
  return (
    <span
      data-slot="control"
      className={clsx([
        className,

        // Basic layout
        'relative flex w-full',

        // Border
        'border border-transparent',

        // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
        'before:absolute before:inset-px before:bg-white',

        // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
        'dark:before:hidden',

        // Disabled state
        '[&:has([data-disabled])]:opacity-50 before:[&:has([data-disabled])]:bg-zinc-950/5',

        // Icon Styles
        '[&_[data-slot=icon]]:-mx-0.5 [&_[data-slot=icon]]:my-0.5 [&_[data-slot=icon]]:h-5 [&_[data-slot=icon]]:w-5 [&_[data-slot=icon]]:shrink-0 [&_[data-slot=icon]]:text-[--btn-icon] [&_[data-slot=icon]]:sm:my-1 [&_[data-slot=icon]]:sm:h-4 [&_[data-slot=icon]]:sm:w-4',

        // Icon SVG
        '[&_[data-slot=icon]>svg]:h-full [&_[data-slot=icon]>svg]:w-full',

        // Icon Color
        '[--btn-icon:theme(colors.zinc.500)] hover:[--btn-icon:theme(colors.zinc.700)] data-[hovered]:[--btn-icon:theme(colors.zinc.700)] data-[pressed]:[--btn-icon:theme(colors.zinc.700)]',
      ])}
    >
      {startEnhancer && (
        <span
          className={cn([
            'flex items-center shrink-0 relative',

            'pl-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5]))] sm:pl-[calc(theme(spacing[3])-1px)]',
          ])}
        >
          {startEnhancer}
        </span>
      )}
      <AriaInput
        className={clsx([
          // Basic layout
          'peer relative block w-full appearance-none',

          // Padding Layout
          'px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5]))] sm:px-[calc(theme(spacing[3])-1px)]',

          // Typography
          'text-base/6 text-zinc-950 placeholder:text-zinc-500 dark:text-white',

          // Border
          'border border-transparent',

          // Background color
          'bg-transparent dark:bg-white/5',

          // Hide default focus styles
          'focus:outline-none',

          // Disabled state
          'data-[disabled]:dark:bg-white/[2.5%]',
        ])}
        {...props}
      />
      {endEnhancer && (
        <span
          className={cn([
            'flex items-center shrink-0 relative',

            'pr-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5]))] sm:pr-[calc(theme(spacing[3])-1px)]',
          ])}
        >
          {endEnhancer}
        </span>
      )}
      <div
        className={cn([
          // Base
          'absolute inset-x-0 bottom-0 border-t-2 border-zinc-950/10',

          //Focus
          'peer-data-[focused]:border-brand-primary-600',

          // Border
          'peer-data-[hovered]:peer-[:not([data-focused])]:peer-[:not([data-invalid])]:border-zinc-950/20 dark:peer-[:not([data-focused])]:peer-[:not([data-invalid])]:border-white/10 dark:peer-[:not([data-focused])]:peer-[:not([data-invalid])]:peer-data-[hovered]:border-white/20',

          // Invalid state
          'peer-data-[invalid]:border-red-500 peer-data-[invalid]:peer-data-[hovered]:border-red-500 data-[invalid]:peer-data-[hovered]:dark:border-red-500 data-[invalid]:data-[hovered]:peer-data-[hovered]:dark:border-red-500',

          // Disabled state
          'peer-data-[disabled]:border-white/15 peer-data-[disabled]:dark:border-white/15',
        ])}
        aria-hidden="true"
      />
    </span>
  );
}

function PasswordField(props: Omit<InputProps, 'type'>) {
  const [toggled, setToggled] = useState(false);
  return (
    <Input
      {...props}
      endEnhancer={
        <AriaButton
          type="button"
          slot={null}
          data-slot="icon"
          onPress={() => setToggled((prev) => !prev)}
        >
          {toggled ? (
            <EyeOffIcon className="h-4 w-4" />
          ) : (
            <EyeIcon className="h-4 w-4" />
          )}
        </AriaButton>
      }
      type={toggled ? 'text' : 'password'}
    />
  );
}
export { PasswordField as PasswordInput };

function HeadlessTextField({
  control,
  field,
  ...props
}: AriaTextFieldProps & ControlledFieldProps) {
  const controller = useController({ control, name: field, defaultValue: '' });
  const _field = controller.field;
  const isInvalid = !!controller.fieldState.error?.message;

  return <AriaTextField {...props} {..._field} isInvalid={isInvalid} />;
}
const _HeadlessTextField = withControlledField(HeadlessTextField);
export { _HeadlessTextField as HeadlessTextField };

function _TextField({
  children,
  control,
  field,
  ...props
}: AriaTextFieldProps & ControlledFieldProps) {
  const controller = useController({ control, name: field, defaultValue: '' });
  const _field = controller.field;
  const isInvalid = !!controller.fieldState.error?.message;

  return (
    <AriaTextField
      {...props}
      {..._field}
      isInvalid={isInvalid}
      className={clsx([fieldStyles.base])}
    >
      {(values) => (
        <>
          {typeof children !== 'function' ? children : children(values)}
          {isInvalid && <FieldErrorMessage control={control} field={field} />}
        </>
      )}
    </AriaTextField>
  );
}
export const TextField = withControlledField(_TextField);
