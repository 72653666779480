import { StyledAction, StyledBody } from 'baseui/card';
import { Skeleton } from 'baseui/skeleton';
import { Spinner } from 'baseui/spinner';
import { format } from 'date-fns';
import { t } from 'i18next';
import { useState } from 'react';
import { Button } from 'react-aria-components';
import { FileText } from 'react-feather';

import { Pagination } from 'components/Pagination';
import { createTableBuilder } from 'components/Table';
import { useUploadCsvStatusQuery } from 'services/api/customers/endpoints';
import { UploadCsvStatus } from 'services/api/customers/types';
import { cn } from 'utils/helpers';

const { Table, Column } = createTableBuilder<UploadCsvStatus>();
export default function CustomersUploadedCsvIndex() {
  const [currentPage, setCurrentPage] = useState(1);
  const query = useUploadCsvStatusQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const uploadedStatus =
    query.data?.map((status) => ({
      uuid: status.uuid,
      fileName: status.fileName,
      uploadDateTime: status.uploadDateTime,
      successCount: status.successCount,
      failureCount: status.failureCount,
      completedDateTime: status.completedDateTime,
      tenantId: status.tenantId,
    })) ?? [];

  return (
    <>
      <StyledBody className="flex flex-col pt-4">
        <Table
          data={uploadedStatus}
          onRefetch={query.refetch}
          isLoading={query.isLoading || query.isFetching}
          loadingMessage={<Skeleton rows={5} width="100%" />}
          isError={query.isError}
          isEmpty={uploadedStatus.length === 0}
          emptyMessage={
            <div className="flex flex-col items-center space-y-2 px-4 py-5 text-center text-sm">
              <FileText aria-hidden="true" className="text-gray-400" />
              <div>
                <h3 className="pt-1 font-bold text-gray-500">
                  {t('No CSV Files')}
                </h3>
                <p className="pt-2 text-gray-500">
                  {/* No CSV files found. Upload CSV file or refresh the page to try again. */}
                  {t('customer_csv_no_files_found_description')}
                </p>
              </div>
            </div>
          }
          overrides={{
            Root: {
              style: {
                flex: 1,
                flexBasis: '300px',
              },
            },
          }}
        >
          <Column header={t('File Name')}>{(row) => row.fileName}</Column>
          <Column header={t('Upload Date')}>
            {(row) =>
              row.uploadDateTime
                ? format(new Date(row.uploadDateTime), 'MMM dd, yyyy hh:mma')
                : '---'
            }
          </Column>
          <Column header={t('Success Count')}>
            {(row) => (row.successCount ? row.successCount : '0')}
          </Column>
          <Column header={t('Failure Count')}>
            {(row) => (row.failureCount ? row.failureCount : '0')}
          </Column>
          <Column header={t('Completed Date')}>
            {(row) =>
              row.completedDateTime
                ? format(new Date(row.completedDateTime), 'MMM dd, yyyy hh:mma')
                : '---'
            }
          </Column>
        </Table>
      </StyledBody>
      <StyledAction className="flex justify-end">
        <Button
          type="button"
          onPress={() => query.refetch()}
          className={cn([
            'inline-flex items-center rounded-md bg-brand-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-primary-600',
            'data-[disabled]:opacity-50 data-[disabled]:cursor-not-allowed',
          ])}
          isDisabled={query.isFetching}
        >
          {query.isFetching && (
            <Spinner className="mr-2" $size={16} $borderWidth={2} />
          )}
          <span>Refresh Table</span>
        </Button>
        <Pagination
          currentPage={currentPage}
          onChange={({ page }) => {
            setCurrentPage(page);
          }}
        />
      </StyledAction>
    </>
  );
}
