import clsx from 'clsx';
import { useEffect, useState } from 'react';
import {
  ChevronsLeft,
  ChevronsRight,
  Settings,
  Tool,
  Truck,
  User,
  Users,
  FileText,
} from 'react-feather';
import { NavLink, Outlet } from 'react-router-dom';

import { BASE_SLUG } from './index';

import { Button } from 'components/Button';
import { Image } from 'components/Image';
import { useCurrentUser } from 'services/api/auth/endpoints';
import { useResellerPropertyQuery } from 'services/api/resellers/endpoints';
import { setPreferenceValue } from 'store/slices/user-preferences';
import { useTypedDispatch, useTypedSelector } from 'store/store';
import { useLocale } from 'utils/hooks/useLocale';

export default function FSAdminRoot() {
  const { t } = useLocale();

  const { sidebarDefaultMinimized } = useTypedSelector(
    (s) => s.userPreferences,
  );
  const dispatch = useTypedDispatch();
  const resellerId = useCurrentUser().data?.resellerId ?? 0;
  const logoId = Number(
    useResellerPropertyQuery(
      { name: 'logo', resellerId },
      { skip: resellerId === 0 },
    ).data ?? '0',
  );
  const [isCollapsed, setIsCollapsed] = useState(sidebarDefaultMinimized);

  useEffect(() => {
    const handleResize = () => {
      if (sidebarDefaultMinimized) return;
      setIsCollapsed(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [sidebarDefaultMinimized]);

  return (
    <div className="flex h-screen w-full overflow-hidden transition">
      <aside
        className={clsx(' flex flex-shrink-0 flex-col space-y-8 py-12', {
          'w-[20rem]': !isCollapsed,
          'w-28': isCollapsed,
        })}
      >
        <div className="flex flex-col space-y-10">
          <div
            className={clsx('relative', {
              'px-10': !isCollapsed,
              'px-5': isCollapsed,
            })}
          >
            <div
              className={clsx('flex items-center justify-between', {
                'flex-col': isCollapsed,
              })}
            >
              <a href="/" className="flex w-full justify-center">
                {logoId ? (
                  <div
                    className={clsx('', {
                      'flex justify-center items-center': isCollapsed,
                      'w-full h-28': !isCollapsed,
                    })}
                  >
                    <Image
                      imageId={logoId}
                      className="h-full w-full object-contain"
                    />
                  </div>
                ) : (
                  <div className="flex items-center space-x-6">
                    <span className="flex h-14 w-14 items-center justify-center rounded-lg bg-brand-primary text-3xl font-semibold text-white">
                      C
                    </span>
                    {!isCollapsed && (
                      <span className="text-2xl font-semibold">Company</span>
                    )}
                  </div>
                )}
              </a>
              <div className="absolute inset-y-0 right-0 flex translate-x-1/2 items-center opacity-0 hover:opacity-100">
                <Button
                  title={isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
                  size="mini"
                  shape="circle"
                  kind="secondary"
                  onClick={() => {
                    setIsCollapsed((prev) => !prev);
                    dispatch(
                      setPreferenceValue(
                        'sidebarDefaultMinimized',
                        !isCollapsed,
                      ),
                    );
                  }}
                >
                  {isCollapsed ? (
                    <ChevronsRight size={16} />
                  ) : (
                    <ChevronsLeft size={16} />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <nav className="flex flex-1 flex-col overflow-y-auto">
          <ul>
            {[
              // {
              //   name: 'Activity',
              //   slug: `/${BASE_SLUG}`,
              //   Icon: BarChart2,
              // },
              {
                name: t('Employees'),
                slug: `/${BASE_SLUG}/employees`,
                Icon: User,
              },
              // {
              //   name: 'Crews',
              //   slug: `/${BASE_SLUG}/crews`,
              //   Icon: Users,
              // },
              {
                name: t('Equipment'),
                slug: `/${BASE_SLUG}/equipments`,
                Icon: Truck,
              },
              {
                name: t('Pricebook'),
                slug: `/${BASE_SLUG}/pricebook`,
                Icon: Tool,
              },
              {
                name: t('Customers'),
                slug: `/${BASE_SLUG}/customers`,
                Icon: Users,
              },
              {
                name: t('Login History'),
                slug: `/${BASE_SLUG}/login-history`,
                Icon: FileText,
              },
              {
                name: t('Settings'),
                slug: `/${BASE_SLUG}/settings`,
                Icon: Settings,
              },
            ].map((item) => (
              <li key={item.slug}>
                <NavLink
                  title={item.name}
                  to={item.slug}
                  end={item.slug === `/${BASE_SLUG}`}
                  className={({ isActive }) =>
                    clsx(
                      'relative flex items-center hover:text-brand-primary',
                      {
                        'text-brand-primary': isActive,
                        'text-black': !isActive,
                        'pl-12 pr-3 ': !isCollapsed,
                        'justify-center': isCollapsed,
                      },
                    )
                  }
                >
                  {({ isActive }) => (
                    <>
                      {isActive && (
                        <span className="absolute left-0 block h-full w-2 rounded-r-lg bg-brand-primary" />
                      )}
                      <span className="flex items-center space-x-6 py-4">
                        <span className="text-gray-500 hover:text-gray-900">
                          <item.Icon />
                        </span>
                        <span
                          className={clsx('text-lg font-normal ', {
                            'sr-only': isCollapsed,
                          })}
                        >
                          {item.name}
                        </span>
                      </span>
                    </>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
      <main className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-[#F5F5F5] p-5">
        <Outlet />
      </main>
    </div>
  );
}
