import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'baseui/snackbar';
import { Save } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { MutationBanner } from 'components/Banner';
import { FormController } from 'components/FormController';
import { Input } from 'components/Input';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from 'components/ui/dialog';
import { getErrorMessage, isMutationSuccess } from 'services/api/base-api';
import { useCreateEmployeeMutation } from 'services/api/employees/endpoints';
import { useTypedSelector } from 'store/store';

const nonEmptyString = z.string().trim().min(1, { message: 'Required' });
const schema = z.object({
  firstname: nonEmptyString,
  lastname: nonEmptyString,
  password: nonEmptyString,
  email: z.string().email(),
});
type FormFields = z.infer<typeof schema>;

export default function ResellerNewEmployee() {
  const navigate = useNavigate();
  const clientId = useTypedSelector((s) => s.auth.userCredentials.clientId);
  const [createEmployee, mutation] = useCreateEmployeeMutation();
  const { control, handleSubmit } = useForm<FormFields>({
    resolver: zodResolver(schema),
  });
  const snackbar = useSnackbar();
  const onSubmit = handleSubmit(async (data) => {
    const result = await createEmployee({
      type: 'ResellerEmployee',
      /**
       * TODO: Change to proper employee type `role`
       */
      roles: ['ParentResellerAdmin'],
      clientId,
      ...data,
    });
    if (isMutationSuccess(result)) {
      snackbar.enqueue({ message: 'Employee Created' });
      navigate('..');
    }
  });

  return (
    <Dialog
      onOpenChange={(open) => !open && navigate('..', { replace: true })}
      isOpen
      isDismissable
    >
      <DialogTitle>Add New Employee</DialogTitle>
      <DialogBody>
        <MutationBanner
          show={mutation.isError}
          message={mutation.error ? getErrorMessage(mutation.error) : ''}
        />
        <form
          onSubmit={onSubmit}
          className="grid grid-cols-2 gap-x-4"
          id="reseller-new-employee"
        >
          <FormController control={control} name="firstname" label="First Name">
            {({ field }) => <Input {...field} />}
          </FormController>
          <FormController control={control} name="lastname" label="Last Name">
            {({ field }) => <Input {...field} />}
          </FormController>
          <FormController control={control} name="email" label="Email Address">
            {({ field }) => <Input {...field} />}
          </FormController>
          <FormController control={control} name="password" label="Password">
            {({ field }) => <Input {...field} type="password" />}
          </FormController>
        </form>
      </DialogBody>
      <DialogActions>
        <Button variant="plain">Cancel</Button>
        <Button
          startEnhancer={<Save className="h-full w-full" />}
          type="submit"
          form="reseller-new-employee"
          isLoading={mutation.isLoading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
