import { zodResolver } from '@hookform/resolvers/zod';
import {
  AriaColorFieldProps as ColorFieldProps,
  useColorField,
} from '@react-aria/color';
import { parseColor, useColorFieldState } from '@react-stately/color';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { styled } from 'baseui';
import { Skeleton } from 'baseui/skeleton';
import { useSnackbar } from 'baseui/snackbar';
import { expandBorderStyles } from 'baseui/styles';
import clsx from 'clsx';
import { useRef } from 'react';
import {
  DialogTrigger,
  Input,
  InputContext,
  ListBox,
  ListBoxItem,
  Radio,
  RadioGroup,
  useContextProps,
} from 'react-aria-components';
import {
  Edit as EditIcon,
  Image as ImageIcon,
  Trash as TrashIcon,
  Upload as UploadIcon,
  UserCheck as UserCheckIcon,
} from 'react-feather';
import { Control, useForm, useWatch } from 'react-hook-form';
import { Outlet, useParams } from 'react-router-dom';
import { z } from 'zod';

import { MutationBanner } from 'components/Banner';
import { FormController } from 'components/FormController';
import {
  Description,
  Field,
  FieldErrorMessage,
} from 'components/forms/fieldset';
import {
  FilePicker,
  filePickerFieldSchema,
  tofilePickerField,
} from 'components/forms/file-picker-field';
import { DataStoreImage } from 'components/Image';
import { QueryResolver } from 'components/QueryResolver';
import { Alert, AlertFooter, AlertTitle } from 'components/ui/alert';
import {
  AlertActions,
  AlertDescription,
  AlertDialog,
  AlertTitle as AlertDialogTitle,
} from 'components/ui/alert-dialog';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from 'components/ui/card';
import { Heading } from 'components/ui/heading';
import { TextField } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Select, SelectField, SelectItem } from 'components/ui/select';
import { Switch, SwitchField } from 'components/ui/switch';
import { Strong, Text } from 'components/ui/text';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import {
  THEME_COLORS,
  THEME_VALUES,
  themeColorsSchema,
} from 'features/reseller';
import { toHexColorPallete } from 'features/reseller/branding';
import { useCurrentUser } from 'services/api/auth/endpoints';
import {
  getErrorMessage,
  isMutationFailed,
  isMutationSuccess,
} from 'services/api/base-api';
import {
  ResellerService,
  useResellerProperties,
  useResellerQuery,
  useSaveResellerPropertiesMutation,
} from 'services/api/resellers/endpoints';
import generateColorPallete from 'utils/branding';

type ThemePresets = z.infer<typeof themeColorsSchema>;
const numberSchema = z.coerce.number().catch(0);
export default function ResellerDetails() {
  const id = numberSchema.parse(useParams().resellerId);
  const query = useResellerProperties({
    names: ['logo', 'theme', 'theme-hex-pallete', 'favicon'],
    resellerId: id,
  });

  const resellerId = useCurrentUser().data?.resellerId;
  const businessName =
    ResellerService.endpoints.reseller.useQuery(resellerId ?? 0, {
      skip: !resellerId,
    }).data?.businessName ?? '';

  return (
    <>
      <Outlet />
      <div className="flex flex-1 flex-col space-y-5">
        <DashboardContentHeader
          title="Resellers"
          description={`Track and manage your ${businessName} Resellers`}
        />
        <section className="grid flex-1 gap-4 lg:grid-cols-3">
          <section className="lg:col-span-2">
            <ResellerInformation />
          </section>
          <aside>
            <QueryResolver query={query}>
              {(properties) => {
                const colorPallete = toHexColorPallete(
                  properties['theme-hex-pallete'],
                );
                return (
                  <ResellerBranding
                    resellerId={id}
                    initialValues={{
                      theme: {
                        preference: colorPallete.success
                          ? 'color-picker'
                          : 'preset',
                        preset:
                          !properties['theme-hex-pallete'] && properties.theme
                            ? (properties.theme as ThemePresets)
                            : undefined,
                        hexCode: colorPallete.success
                          ? colorPallete.data[500]
                          : '',
                      },
                      // resellerTheme:
                      //   properties.theme as FormFields['resellerTheme'],
                      favicon: properties.favicon
                        ? [
                            {
                              dataStoreId: properties.favicon
                                ? Number(properties.favicon)
                                : 0,
                            },
                          ]
                        : [],
                      resellerLogo: properties.logo
                        ? [
                            {
                              dataStoreId: properties.logo
                                ? Number(properties.logo)
                                : 0,
                            },
                          ]
                        : [],
                    }}
                  />
                );
              }}
            </QueryResolver>
          </aside>
        </section>
      </div>
    </>
  );
}

function ResellerInformation() {
  const params = useParams();
  const resellerId = Number(params.resellerId ?? '0');
  const query = useResellerQuery(resellerId);

  return (
    <QueryResolver
      query={query}
      loadingElement={<Skeleton rows={6} width="100%" />}
    >
      {(reseller) => {
        return (
          <Card className="w-full flex-1">
            <CardHeader>
              <div className="flex items-center justify-between">
                <Heading>Reseller Details</Heading>
                <nav className="flex">
                  <Button
                    href="edit"
                    variant="plain"
                    startEnhancer={<EditIcon className="h-full w-full" />}
                  >
                    Edit Reseller
                  </Button>
                </nav>
              </div>
            </CardHeader>
            <CardContent>
              <div>
                <div className="px-4 sm:px-0">
                  <span className="text-2xl font-semibold text-brand-primary">
                    #{reseller.id}
                  </span>
                  <div className="mt-3 space-y-1.5">
                    {reseller.parentReseller && <Badge>Parent Reseller</Badge>}
                    <Heading className="text-base font-semibold leading-7 text-gray-900">
                      {reseller.businessName}
                    </Heading>
                  </div>
                  <Text>
                    Represented by <Strong>{reseller.accountRep}</Strong>
                  </Text>
                </div>

                <div className="mt-6">
                  <dl className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Contact Person
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                        {reseller.firstName} {reseller.lastName}
                      </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Username
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                        {reseller.mainContactEmail}
                      </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Email address
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                        {reseller.billingContactEmail}
                      </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Contact No.
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                        {reseller.mainContactPhone}
                      </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Billing Address
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                        {`${reseller.address.street},${
                          reseller.address.street2
                            ? `${reseller.address.street2},`
                            : ''
                        }`}
                        {'\n'}
                        {`${reseller.address.city}, ${reseller.address.state}, ${reseller.address.postalCode}`}
                        , {'\n'}
                        {reseller.address.country}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <AccessToggle
                defaultValues={{
                  accessAllowed: reseller.isEnabled,
                  id: reseller.id,
                }}
              />
            </CardContent>
          </Card>
        );
      }}
    </QueryResolver>
  );
}

const resellerAccessFormSchema = z.object({
  accessAllowed: z.boolean().optional().default(true),
  id: z.number(),
});

function AccessToggle({
  defaultValues,
}: {
  defaultValues?: z.infer<typeof resellerAccessFormSchema>;
}) {
  const form = useForm<z.infer<typeof resellerAccessFormSchema>>({
    defaultValues,
  });
  const snackbar = useSnackbar();

  const [updateReseller, mutation] =
    ResellerService.endpoints.resellerAccess.useMutation();
  const onSubmit = form.handleSubmit(async (data) => {
    // Update reseller access status
    const result = await updateReseller({
      enableAccess: data.accessAllowed,
      resellerId: data.id,
    });
    if (isMutationSuccess(result)) {
      // snackbar
      snackbar.enqueue({ message: 'Reseller access updated successfully' });
    } else {
      snackbar.enqueue({ message: 'Failed to update reseller access' });
    }
  });
  const isSubmitting = form.formState.isSubmitting;

  return (
    <form onSubmit={onSubmit} id="update-reseller-access-form">
      <Alert>
        <UserCheckIcon className="h-4 w-4" />
        <AlertTitle>Account Status</AlertTitle>
        <AlertFooter>
          <SwitchField control={form.control} field="accessAllowed">
            <DialogTrigger
              onOpenChange={(isOpen) => {
                if (!isOpen) {
                  form.setValue(
                    'accessAllowed',
                    !form.getValues().accessAllowed,
                  );
                }
              }}
            >
              <Switch />
              <AlertDialog>
                <AlertDialogTitle>
                  {!defaultValues?.accessAllowed
                    ? "You are about to enable this reseller's access"
                    : "Are you sure you want to deactivate this reseller's access?"}
                </AlertDialogTitle>
                <AlertDescription>
                  {!defaultValues?.accessAllowed
                    ? "Proceeding with this action will restore this reseller and their client's access to their portal."
                    : "Proceeding with this action will disable this reseller's access and all of their clients access to the portal."}
                </AlertDescription>
                <AlertActions>
                  <Button
                    variant="plain"
                    slot="close"
                    isDisabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    color={defaultValues?.accessAllowed ? 'red' : 'green'}
                    form="update-reseller-access-form"
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {!defaultValues?.accessAllowed
                      ? 'Activate Reseller'
                      : 'Deactivate Reseller'}
                  </Button>
                </AlertActions>
              </AlertDialog>
            </DialogTrigger>
            <Label>Update Account Status</Label>
            <Description>
              {!defaultValues?.accessAllowed
                ? 'Activate account to allow this reseller to login'
                : 'Deactivate reseller account to restrict access'}
            </Description>
          </SwitchField>
        </AlertFooter>
      </Alert>
    </form>
  );
}

const ColorSwatch = styled<'div', { $color: string }>('div', (props) => {
  return {
    width: props.$theme.sizing.scale700,
    height: props.$theme.sizing.scale700,
    marginRight: props.$theme.sizing.scale300,
    display: 'inline-block',
    backgroundColor: props.$color,
    verticalAlign: 'baseline',
    ...expandBorderStyles(props.$theme.borders.border400),
  };
});
const getLabel = ({ option }: any) => {
  return (
    <div className="flex items-center">
      <ColorSwatch $color={option.color} className="rounded" />
      {option.id}
    </div>
  );
};

const isFile = (file: unknown) =>
  z
    .instanceof(File)
    .refine((arg) => !!arg.name, 'Not valid file.')
    .safeParse(file);

const THEME_COLOR_PREFERENCE_VALUES = {
  preset: 'preset',
  'color-picker': 'color-picker',
} as const;
const schema = z.object({
  theme: z
    .object({
      preference: z.nativeEnum(THEME_COLOR_PREFERENCE_VALUES),
      hexCode: z
        .string()
        .refine(
          (v) => {
            try {
              if (v.length === 0) {
                return true;
              }
              parseColor(`#${v.replace('#', '')}`);
              return true;
            } catch {
              return false;
            }
          },
          { message: 'Invalid color code' },
        )
        .optional(),
      preset: themeColorsSchema.optional(),
    })
    .superRefine((data, ctx) => {
      if (data.preference === 'preset' && !data.preset) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['preset'],
          message: 'Required',
        });
      }
      if (data.preference === 'color-picker' && !data.hexCode) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['hexCode'],
          message: 'Required',
        });
      }
    }),
  // resellerTheme: themeColorsSchema,
  favicon: tofilePickerField({ maxSizeInKB: 80 }),
  resellerLogo: filePickerFieldSchema,
});

type FormFields = z.infer<typeof schema>;

function ResellerBranding({
  initialValues,
  resellerId,
}: {
  initialValues?: Partial<FormFields>;
  resellerId: number;
}) {
  const { control, handleSubmit, watch, clearErrors, formState } =
    useForm<FormFields>({
      defaultValues: {
        ...(initialValues ? initialValues : {}),
      },
      resolver: zodResolver(schema),
    });
  const snackbar = useSnackbar();
  const [saveProperties, mutation] = useSaveResellerPropertiesMutation();
  const [updateAsset, assetMutation] =
    ResellerService.endpoints.updateAsset.useMutation();

  const onSubmit = handleSubmit(async (data) => {
    const imageHandler = async () => {
      const selectedImage = data.resellerLogo.find((v) => v.file);
      const validatedImageFile = isFile(selectedImage?.file);
      if (!validatedImageFile.success) {
        return undefined;
      }
      return updateAsset({
        dataStoreId: selectedImage?.dataStoreId ?? 0,
        file: validatedImageFile.data,
        resellerId,
        assetType: 'logo',
      });
    };

    const faviconHandler = async () => {
      const asset = data.favicon.find((v) => v.file);
      const validatedImageFile = isFile(asset?.file);
      const shouldDeleteAsset =
        !!initialValues?.favicon?.find((item) => item.dataStoreId)
          ?.dataStoreId && !asset;
      if (shouldDeleteAsset) {
        // Remove value for favicon
        return saveProperties({
          properties: [
            {
              propertyName: 'favicon',
              propertyValue: '',
              resellerId,
            },
          ],
          mutationOptions: { invalidate: false },
        });
      }

      if (!validatedImageFile.success) {
        return undefined;
      }

      return updateAsset({
        dataStoreId: asset?.dataStoreId ?? 0,
        file: validatedImageFile.data,
        resellerId,
        assetType: 'favicon',
      });
    };

    const themeHandler = () => {
      if (data.theme.preference === 'preset' && data.theme.preset) {
        return saveProperties({
          properties: [
            {
              propertyName: 'theme',
              propertyValue: data.theme.preset,
              resellerId,
            },
            {
              propertyName: 'theme-hex-pallete',
              propertyValue: '',
              resellerId,
            },
          ],
        });
      }
      return saveProperties({
        properties: [
          {
            propertyName: 'theme',
            propertyValue: '',
            resellerId,
          },
          {
            propertyName: 'theme-hex-pallete',
            propertyValue: JSON.stringify(
              generateColorPallete(data.theme.hexCode)?.colors,
            ),
            resellerId,
          },
        ],
      });
    };

    const requests = await Promise.all([imageHandler(), faviconHandler()]);
    /**
     * We seperate this to trigger the invalidation after ensuring that image upload
     * requests are completed.
     */
    const themeResult = await themeHandler();
    const errorFound = [...requests, themeResult]
      .filter((res): res is NonNullable<typeof res> => res !== undefined)
      .find((res) => isMutationFailed(res));
    if (errorFound) {
      return snackbar.enqueue({
        message: 'Failed to update reseller branding',
      });
    }

    snackbar.enqueue({ message: 'Updated successfully' });
  });

  const errors = [mutation, assetMutation]
    .map((m) => m.error)
    .filter((e): e is FetchBaseQueryError | SerializedError => e !== undefined);
  const isSubmitting = formState.isSubmitting;
  const themePreference = watch(
    'theme.preference',
    initialValues?.theme?.preference,
  );

  return (
    <Card>
      <CardHeader>
        <Heading className="mb-2 text-xl">Reseller Branding</Heading>
        <CardDescription className="text-sm text-gray-500">
          Change themes and upload assets according to reseller branding
        </CardDescription>
      </CardHeader>

      <CardContent>
        <MutationBanner
          show={errors.length !== 0}
          title={'Failed to update reseller assets.'}
          message={
            <ul>
              {errors
                .map((e) => getErrorMessage(e))
                .filter((e) => e.length !== 0)
                .map((error) => (
                  <li key={error}>{error}</li>
                ))}
            </ul>
          }
        />
        <form
          onSubmit={onSubmit}
          id="reseller-property-form"
          className="space-y-6"
        >
          <Field className="col-span-full">
            <Label htmlFor="image">Logo</Label>
            <FilePicker.Field control={control} field="resellerLogo">
              {({ files, hasFieldValue, clearFiles }) => (
                <div>
                  {!hasFieldValue && (
                    <div className="flex h-40 flex-1 justify-center rounded-lg border border-dashed border-gray-900/25">
                      <div className="space-y-2 px-6 py-5 text-center">
                        <ImageIcon
                          className="mx-auto h-12 w-12 text-gray-300"
                          aria-hidden="true"
                        />

                        <FilePicker.Trigger variant="outline">
                          Choose Image
                        </FilePicker.Trigger>
                        <p className="text-xs leading-5 text-gray-600">
                          Select an image. PNG, JPG, JPEG
                        </p>
                      </div>
                    </div>
                  )}
                  {files.length !== 0 && (
                    <div className="w-full space-y-4">
                      <div className="h-40 flex-1 overflow-hidden rounded-md border">
                        {files.some((v) => !!v.dataStoreId) ? (
                          <DataStoreImage
                            imageId={
                              files.find((v) => !!v.dataStoreId)?.dataStoreId ??
                              0
                            }
                            className="h-full w-full object-contain"
                          />
                        ) : null}
                        {files.some((v) => !!v.file) ? (
                          <img
                            src={files[0].url}
                            alt="Reseller Logo"
                            className="h-full w-full object-contain"
                          />
                        ) : null}
                      </div>
                      <div className="flex flex-col space-y-2">
                        <FilePicker.Trigger
                          startEnhancer={
                            <UploadIcon aria-hidden className="h-full w-full" />
                          }
                        >
                          Change image
                        </FilePicker.Trigger>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </FilePicker.Field>
          </Field>
          <Field className="col-span-full">
            <Label htmlFor="image">Favicon</Label>
            <FilePicker.Field control={control} field="favicon">
              {({ files, hasFieldValue, clearFiles }) => (
                <div>
                  {!hasFieldValue && (
                    <div>
                      <div className="flex space-x-4">
                        <div className="shrink-0 ">
                          <div className="flex h-10 w-10 flex-1 items-center justify-center rounded-lg border border-dashed border-gray-900/25">
                            <ImageIcon
                              className="mx-auto  text-gray-300"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                        <FilePicker.Trigger variant="outline">
                          Choose Image
                        </FilePicker.Trigger>
                      </div>
                    </div>
                  )}
                  {files.length !== 0 && (
                    <div className="flex w-full items-center space-x-4">
                      <div className="flex h-10 w-10 shrink-0 items-center justify-center overflow-hidden rounded-md border">
                        {files.some((v) => !!v.dataStoreId) ? (
                          <DataStoreImage
                            imageId={
                              files.find((v) => !!v.dataStoreId)?.dataStoreId ??
                              0
                            }
                            className="h-full w-full object-contain"
                          />
                        ) : null}
                        {files.some((v) => !!v.file) ? (
                          <img
                            src={files[0].url}
                            alt="Reseller Logo"
                            className="h-full w-full object-contain"
                          />
                        ) : null}
                      </div>
                      <div className="flex gap-2">
                        <FilePicker.Trigger
                          startEnhancer={
                            <UploadIcon aria-hidden className="h-full w-full" />
                          }
                        >
                          Change image
                        </FilePicker.Trigger>
                        <Button
                          onClick={clearFiles}
                          type="button"
                          variant="plain"
                          title="Remove Image"
                        >
                          <TrashIcon data-slot="icon" aria-hidden />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </FilePicker.Field>
            <Description>
              Must be <Strong>32 x 32px</Strong> in dimension,{' '}
              <Strong>80KB</Strong> or under, and <Strong>PNG</Strong> or{' '}
              <Strong>JPG</Strong> format.
            </Description>
            <FieldErrorMessage
              control={control}
              field={`favicon.0.file` as const}
            />
          </Field>
          <FormController control={control} name="theme.preference">
            {({ field }) => (
              <RadioGroup
                {...field}
                className="relative "
                onChange={(v) => {
                  field.onChange(v);
                  clearErrors();
                }}
              >
                <Label className="block pb-1 text-sm font-medium leading-6 text-gray-900">
                  Theme Preference
                </Label>
                <div className="flex">
                  <Radio
                    value="preset"
                    className="block flex-1 cursor-pointer rounded-l-md border border-r-0 border-brand-primary px-3 py-1.5 text-center text-sm font-medium text-brand-primary transition data-[focus-visible]:z-20 data-[hovered]:bg-brand-primary-700 data-[hovered]:data-[selected]:bg-brand-primary-700 data-[selected]:bg-brand-primary data-[hovered]:text-white data-[selected]:text-white data-[focus-visible]:outline data-[focus-visible]:outline-2 data-[focus-visible]:outline-offset-2 data-[focus-visible]:outline-blue-300"
                  >
                    Preset
                  </Radio>
                  <Radio
                    value="color-picker"
                    className="block flex-1 cursor-pointer rounded-r-md border border-brand-primary px-3 py-1.5 text-center text-sm font-medium text-brand-primary transition data-[focus-visible]:z-20 data-[hovered]:bg-brand-primary-700 data-[hovered]:data-[selected]:bg-brand-primary-700 data-[selected]:bg-brand-primary data-[hovered]:text-white data-[selected]:text-white data-[focus-visible]:outline data-[focus-visible]:outline-2 data-[focus-visible]:outline-offset-2 data-[focus-visible]:outline-blue-300"
                  >
                    Color Picker
                  </Radio>
                </div>
              </RadioGroup>
            )}
          </FormController>

          <div
            className={clsx('relative', {
              hidden: themePreference === 'color-picker',
            })}
          >
            <SelectField control={control} field="theme.preset">
              <Label>Select Theme</Label>
              <Select
                items={Object.keys(THEME_COLORS).map((item) => ({
                  id: item,
                  value: item,
                }))}
              >
                {(color) => (
                  <SelectItem textValue={color.value}>
                    <div className="flex items-center space-x-2">
                      <div
                        aria-hidden
                        className="h-5 w-5 shrink-0 overflow-hidden rounded-full"
                        style={{
                          backgroundColor:
                            THEME_VALUES[
                              color.value as keyof typeof THEME_COLORS
                            ]['--brand-primary-500'],
                        }}
                      />
                      <span>{color.value}</span>
                    </div>
                  </SelectItem>
                )}
              </Select>
            </SelectField>
          </div>
          <div className={clsx({ hidden: themePreference === 'preset' })}>
            <TextField control={control} field="theme.hexCode">
              <Label>Enter hex code</Label>
              <div data-slot="control" className="space-y-2">
                <ColorField placeholder="e.g. #fff" />
                {/* <GeneratedColorPallete control={control} /> */}
              </div>
            </TextField>
          </div>
          <Button
            className="mt-10 w-full"
            type="submit"
            isLoading={isSubmitting}
          >
            Save
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

function ColorField(props: ColorFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [contextProps] = useContextProps(props, inputRef, InputContext);
  const state = useColorFieldState({ ...props, value: contextProps.value });
  const { inputProps } = useColorField(props, state, inputRef);

  return (
    <div className="relative">
      <div
        aria-hidden
        className="absolute inset-y-0 left-0 flex items-center pl-3"
      >
        <span
          className=" h-6 w-6 rounded-full border"
          style={{
            backgroundColor: state.inputValue
              ? `#${state.inputValue.replace('#', '')}`
              : '#FFFF',
          }}
        />
      </div>
      <Input
        {...inputProps}
        className={clsx(
          inputProps.className,
          'peer form-input block w-full border-0 bg-white py-2.5 pl-12 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6',
        )}
        ref={inputRef}
      />
      <div
        className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-brand-primary-600 group-data-[invalid]:border-red-500"
        aria-hidden="true"
      />
    </div>
  );
}

function GeneratedColorPallete({ control }: { control: Control<FormFields> }) {
  const hex = useWatch({ control, name: 'theme.hexCode' });
  if (!hex) {
    return null;
  }
  const pallete = generateColorPallete(hex);
  return (
    <ListBox
      selectionMode="none"
      orientation="horizontal"
      className="grid grid-cols-7 overflow-hidden rounded border"
    >
      {Object.entries(pallete?.colors ?? {})
        .slice(0, 7)
        .map(([shade, colorCode]) => (
          <ListBoxItem
            key={shade}
            className="aspect-h-1 aspect-w-1"
            style={{ backgroundColor: colorCode }}
          >
            <span className="sr-only">{colorCode}</span>
          </ListBoxItem>
        ))}
    </ListBox>
  );
}
