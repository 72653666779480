import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { z } from 'zod';

import {
  applyColorPallete,
  applyTheme,
  toHexColorPallete,
} from 'features/reseller/branding';
import {
  hexColorPalleteSchema,
  themeColorsSchema,
} from 'features/reseller/branding/constants';
import { currencyOptionSchema } from 'services/api/clients/types';

const LOCALSTORATE_KEY = 'user-prefs';
export const userPrefrencesSliceSchema = z.object({
  theme: themeColorsSchema.catch('Blue'),
  sidebarDefaultMinimized: z.boolean().catch(false),
  hexColorPallete: hexColorPalleteSchema.optional(),
  currencyUsed: currencyOptionSchema.catch('USD'),
  businessName: z.string().optional().catch(''),
  faviconB64: z.string().optional().catch(''),
});

export type UserPreferenceSlice = z.infer<typeof userPrefrencesSliceSchema>;
const initialState: UserPreferenceSlice = userPrefrencesSliceSchema.parse({
  hexColorPallete: undefined,
} as Partial<UserPreferenceSlice>);

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;

type Json = Literal | { [key: string]: Json } | Json[];
const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]),
);

const json = () => jsonSchema;

const stringToJSONSchema = z
  .string()
  .transform((str, ctx): z.infer<ReturnType<typeof json>> => {
    try {
      return JSON.parse(str);
    } catch (e) {
      ctx.addIssue({ code: 'custom', message: 'Invalid JSON' });
      return z.NEVER;
    }
  });
export const userPreferencesSlice = createSlice({
  name: 'user-prefs',
  initialState,
  reducers: {
    setPreferenceValue: (
      state,
      action: PayloadAction<{ name: keyof UserPreferenceSlice; value: any }>,
    ) => {
      localStorage.setItem(
        LOCALSTORATE_KEY,
        JSON.stringify({
          ...state,
          [action.payload.name]: action.payload.value,
        }),
      );
      return { ...state, [action.payload.name]: action.payload.value };
    },
    setTheme: (
      state,
      action?: PayloadAction<
        Partial<Pick<UserPreferenceSlice, 'hexColorPallete' | 'theme'>>
      >,
    ) => {
      const hexPallete = toHexColorPallete(action?.payload.hexColorPallete);
      const theme = themeColorsSchema.safeParse(action?.payload.theme);

      if (hexPallete.success) {
        applyColorPallete(hexPallete.data);
        state.hexColorPallete = hexPallete.data;
      } else if (theme.success) {
        applyTheme(theme.data);
        state.theme = theme.data;
      } else {
        state.theme = 'Blue';
      }

      return state;
    },
    resetPreferences: () => {
      localStorage.setItem(LOCALSTORATE_KEY, JSON.stringify(initialState));
      return initialState;
    },
    initPreferences: (state) => {
      const value = localStorage.getItem(LOCALSTORATE_KEY);
      const validated = userPrefrencesSliceSchema.safeParse(
        stringToJSONSchema.catch({}).parse(value),
      );
      if (validated.success) {
        return { ...state, ...validated.data };
      }
      return initialState;
    },
  },
});

export const {
  resetPreferences,
  initPreferences,
  setTheme: setThemePreference,
} = userPreferencesSlice.actions;
export const setPreferenceValue = <T extends keyof UserPreferenceSlice>(
  name: T,
  value: UserPreferenceSlice[T],
) => userPreferencesSlice.actions.setPreferenceValue({ name, value });

export default userPreferencesSlice.reducer;
