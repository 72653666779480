import { z } from 'zod';

export function toSchemaWithDefaults<Schema extends z.ZodObject<any>>(
  schema: Schema,
  defaultValues: Partial<z.infer<typeof schema>>,
) {
  const entries = Object.entries(schema.shape) as [
    keyof Schema['shape'],
    z.ZodTypeAny,
  ][];
  const newProps = entries.reduce(
    (acc, [key, value]) => {
      const defaultValue = !!defaultValues?.[key];
      if (defaultValue) {
        acc[key] = value.default(defaultValues[key]);
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as {
      [key in keyof Schema['shape']]: Schema['shape'][key];
    },
  );
  return z.object(newProps);
}

export function toOptionalSchemaProperties<Schema extends z.ZodObject<any>>(
  schema: Schema,
) {
  const entries = Object.entries(schema.shape) as [
    keyof Schema['shape'],
    z.ZodTypeAny,
  ][];
  const newProps = entries.reduce(
    (acc, [key, value]) => {
      const isAlreadyOptional = value.isOptional();
      if (!isAlreadyOptional) {
        acc[key] = value.optional();
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as {
      [key in keyof Schema['shape']]: Schema['shape'][key];
    },
  );
  return z.object(newProps);
}
