import { Avatar } from 'baseui/avatar';
import { StatefulPopover } from 'baseui/popover';
import clsx from 'clsx';
import { LogOut, Settings } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';

import { Button } from 'components/Button';
import { LinkButton } from 'components/LinkButton';
import LangSwitcher from 'features/LangSwitcher';
import { BASE_SLUG } from 'pages/fs-admin';
import { useCurrentUser } from 'services/api/auth/endpoints';
import { UserRole } from 'services/api/types.shared';
import { useImageQuery } from 'services/api/utilities/endpoint';
import theme from 'theme';
import { useLocale } from 'utils/hooks/useLocale';

type DashboardContenteHeaderProps = {
  title: React.ReactNode;
  description: React.ReactNode;
};

const USER_ROLE_LABELS: Record<UserRole, string> = {
  RootAdmin: 'Kaleidoscope Admin',
  ResellerAdmin: 'Reseller Admin',
  ClientAdmin: 'FS Client Admin',
  Coordinator: 'Coordinator',
  Mobile: 'Technician',
  ParentResellerAdmin: 'Reseller Manager',
};

const useEmployeeImage = (id: number) => {
  const query = useImageQuery(id, { skip: id === 0 });
  return query.data
    ? `data:${query.data.mediaType};base64,${query.data.dataB64}`
    : '';
};

const ROLES_NEED_TRANSLATION: UserRole[] = [
  'ClientAdmin',
  'Mobile',
  'Coordinator',
];

export default function DashboardContenteHeader(
  props: DashboardContenteHeaderProps,
) {
  const { t } = useLocale();
  const { title, description } = props;
  const user = useCurrentUser().data;
  const location = useLocation();
  const isAdminView = location.pathname.split('/').includes(BASE_SLUG);
  const src = useEmployeeImage(user?.photoId ?? 0);
  const role = user?.roles ?? [];
  const needsTranslation = role.some((r) =>
    ROLES_NEED_TRANSLATION.includes(r as UserRole),
  );

  return (
    <div className="flex items-center justify-between">
      <div>
        <h1 className="text-3xl font-semibold">{title}</h1>
        <p className="font-prompt text-lg font-normal text-gray-500">
          {description}
        </p>
      </div>
      <nav className="flex items-center space-x-4">
        <div className="text-right">
          <div className="text-base leading-5">{user?.fullName}</div>
          <div className="text-sm font-semibold text-brand-primary">
            {needsTranslation && Array.isArray(role) && role.length !== 0
              ? t(`roles.${user?.roles?.[0] as UserRole}`, { ns: 'glossary' })
              : USER_ROLE_LABELS[role[0] as UserRole]}
          </div>
        </div>
        <div className="flex items-center">
          <Avatar name={user?.fullName} size="2.5rem" src={src} />
          <StatefulPopover
            content={() => (
              <ul className="overflow-hidden rounded-md bg-white font-prompt">
                {!isAdminView ? (
                  <li>
                    <Link
                      to="/fs-admin"
                      className="block p-4 transition hover:bg-gray-100"
                    >
                      <div className="space-x-1 text-base font-bold text-brand-primary">
                        <span>{user?.fullName}</span>
                        <span className="text-gray-400">(#{user?.id})</span>
                      </div>
                      <span className="text-sm">Switch to FS Admin</span>
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link
                      to="/"
                      className="block p-4 transition hover:bg-gray-100"
                    >
                      <div className="space-x-1 text-base font-bold text-brand-primary">
                        <span>{user?.fullName}</span>
                        <span className="text-gray-400">(#{user?.id})</span>
                      </div>
                      <span className="text-sm">Switch to FS Client</span>
                    </Link>
                  </li>
                )}
              </ul>
            )}
            placement="bottomRight"
          >
            <Button
              type="button"
              size="compact"
              kind="tertiary"
              shape="square"
              className={clsx('ml-2', {
                hidden: !role.includes('ClientAdmin'),
              })}
              disabled={!role.includes('ClientAdmin')}
            >
              <Settings color={theme.colors.primary} />
            </Button>
          </StatefulPopover>
          {needsTranslation && (
            <div className="flex items-center px-2">
              <LangSwitcher />
            </div>
          )}
          <LinkButton
            to="/logout"
            className="ml-10"
            size="compact"
            kind="tertiary"
            shape="square"
            aria-label="Logout"
          >
            <LogOut color={theme.colors.primary} />
          </LinkButton>
        </div>
      </nav>
    </div>
  );
}
