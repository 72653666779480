import { z } from 'zod';

import { Address, rawAddressSchema } from './types.shared';

const addressLikeSchema = rawAddressSchema.omit({
  resellerId: true,
  dateCreated: true,
  dateModified: true,
  isDeleted: true,
  dateDeleted: true,
  id: true,
  lat: true,
  lng: true,
  timeZone: true
});
type AddressLike = z.infer<typeof addressLikeSchema>;

export function formatAddress(address: Address | AddressLike) {
  const validated = addressLikeSchema.safeParse(address);
  if (!validated.success) return '';

  return `${address.street}, ${address.street2 ?? ''}, ${address.city}, ${
    address.state ?? ''
  } ${address.postalCode}, ${address?.country}`
    .split(',')
    .filter((item) => Boolean(item.trim()))
    .join(',');
}

export const mutationOptionsSchema = z
  .object({
    invalidate: z.boolean().catch(true).default(true),
  })
  .partial()
  .default({
    invalidate: true,
  })
  .catch({
    invalidate: true,
  });
export type MutationRequestOptions = {
  mutationOptions?: z.infer<typeof mutationOptionsSchema>;
};
