import { zodResolver } from '@hookform/resolvers/zod';
import { StyledBody } from 'baseui/card';
import type { CheckboxOverrides } from 'baseui/checkbox';
import { StatefulCheckbox as Checkbox } from 'baseui/checkbox';
import { Spinner } from 'baseui/spinner';
import { useEffect } from 'react';
import { AlertCircle, CheckCircle } from 'react-feather';
import {
  Control,
  FieldPath,
  FieldValues,
  get,
  useForm,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { z } from 'zod';

import { Card } from 'components/Card';
import { FormController } from 'components/FormController';
import { Input } from 'components/Input';
import { CountrySelect } from 'features/CountrySelect';
import { StateSelect } from 'features/StateSelect';
import { getErrorMessage, isMutationSuccess } from 'services/api/base-api';
import { Address, rawAddressSchema } from 'services/api/types.shared';
import { endpoints as UtilityAPIService } from 'services/api/utilities/endpoint';
import { store } from 'store/store';
import { useLocale } from 'utils/hooks/useLocale';

const modifiableAddressSchema = rawAddressSchema.pick({
  street: true,
  street2: true,
  city: true,
  state: true,
  postalCode: true,
  country: true,
});
type AddressLike = z.infer<typeof modifiableAddressSchema>;

const geocodeAddress = async (address: Address | AddressLike) => {
  const formattedAddres = `${address.street} ${address.city} ${address.state} ${address.country}`;

  const result = await store.dispatch(
    UtilityAPIService.endpoints.geocode.initiate({ address: formattedAddres }),
  );
  if (isMutationSuccess(result)) {
    if (result.data.confidence >= 0.8) {
      return {
        latitude: result.data.latitude,
        longitude: result.data.longitude,
        timeZone: result.data.timeZone,
      };
    }
    throw new Error('No confident location found');
  } else {
    throw new Error(getErrorMessage(result.error));
  }
};

const nonEmptyString = z
  .string()
  .trim()
  .min(1, { message: 'errors.invalid_type_received_undefined' });

const locationSchema = z.object({
  street: nonEmptyString,
  street2: z.string().trim().nullable().optional().default(''),
  city: nonEmptyString,
  state: nonEmptyString,
  postalCode: nonEmptyString,
  country: nonEmptyString,
  lat: z.number().refine(
    (val) => {
      return val !== 0;
    },
    { message: 'No coordinates set' },
  ),
  lng: z.number().refine(
    (val) => {
      return val !== 0;
    },
    { message: 'No coordinates set' },
  ),
  timeZone: z.string().trim().nullable().optional().default(''),
});
const paymentMethodEnum = z.enum(['credit/debit', 'paypal']);
const baseSchema = z.object({
  firstName: nonEmptyString,
  lastName: nonEmptyString,
  email: nonEmptyString.email(),
  phone: nonEmptyString,
  businessName: z.string().trim().optional(),
  address: locationSchema.extend({
    // For showing a verified message, is updated manually with `setValue`
    isVerified: z.boolean().optional(),
    isVerifiying: z.boolean().optional(),
  }),
  billingAddress: locationSchema.optional(),
  isCompany: z.boolean(),
  payment: z
    .object({
      paymentMethod: paymentMethodEnum.optional(),
      firstName: z.string().optional(),
      lastName: z.string().optional(),
      cardNumber: z.string().optional(),
      securityCode: z.string().optional(),
      expiration: z.string().optional(),
    })
    .optional(),
});

const schema = baseSchema;

type FormFields = z.infer<typeof schema>;

function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
  let timeout: ReturnType<typeof setTimeout>;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function CustomerForm({
  id = 'customer-form',
  initialValue,
  onSubmit: onSubmitHandler,
}: {
  id?: string;
  initialValue?: Partial<FormFields>;
  onSubmit?: (data: FormFields) => void;
}) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm<FormFields>({
    resolver: zodResolver(schema),
    defaultValues: {
      address: {
        isVerified: false,
        lat: 0,
        lng: 0,
        country: '',
      },
      isCompany: false,
      payment: {
        paymentMethod: paymentMethodEnum.Values['credit/debit'],
      },
      ...(initialValue ?? {}),
    },
  });
  const isCompany = watch('isCompany');
  const isAddressVerified = watch('address.isVerified');
  const selectedCountryCode = watch('address.country');

  const { t } = useLocale();

  const onSubmit = handleSubmit((data) => {
    if (onSubmitHandler) {
      onSubmitHandler(data);
    }
  });

  useEffect(() => {
    const callback = debounce(
      async (
        data: Parameters<Parameters<typeof watch>[0]>[0],
        { name }: Parameters<Parameters<typeof watch>[0]>[1],
      ) => {
        const keysToMatch: FieldPath<FormFields>[] = [
          'address.street',
          'address.city',
          'address.state',
          'address.postalCode',
          'address.country',
        ];
        const isAddress = name && keysToMatch.includes(name);
        const validated = modifiableAddressSchema.safeParse(data.address);
        if (isAddress && validated.success) {
          setValue('address.isVerifiying', true);
          geocodeAddress(validated.data)
            .then((coordinates) => {
              clearErrors('address.isVerified');
              setValue('address.isVerified', true);
              setValue('address.lat', coordinates.latitude);
              setValue('address.lng', coordinates.longitude);
              setValue('address.timeZone', coordinates.timeZone);
            })
            .catch(() => {
              clearErrors('address.lat');
              clearErrors('address.lng');
              setValue('address.lat', 0);
              setValue('address.lng', 0);
              setValue('address.timeZone', '');
              setValue('address.isVerified', false);
              setError('address.isVerified', {
                message:
                  'The address you entered did not return any verified locations.',
              });
            })
            .finally(() => setValue('address.isVerifiying', false));
        }
      },
      950,
    );
    const { unsubscribe } = watch(callback);
    return () => unsubscribe();
  }, [watch, setValue, clearErrors, setError]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'businessName' && isCompany) {
        setValue('firstName', value.businessName || getValues().firstName);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isCompany, setValue, getValues]);

  return (
    <form id={id} className="space-y-4" onSubmit={onSubmit}>
      <div className="grid gap-4 lg:grid-cols-2">
        <Card overrides={{ Root: { props: { className: 'flex-1' } } }}>
          <StyledBody className="relative space-y-6">
            <h2 className="text-xl font-semibold">{t('Customer Details')}</h2>
            <fieldset>
              <legend className="sr-only">{t('Basic Information')}</legend>
              <div className="grid grid-cols-2 gap-x-4">
                <FormController
                  control={control}
                  label={t('First Name')}
                  name="firstName"
                  defaultValue=""
                >
                  {({ field }) => <Input {...field} />}
                </FormController>
                <FormController
                  control={control}
                  label={t('Last Name')}
                  name="lastName"
                  defaultValue=""
                >
                  {({ field }) => (
                    <Input {...field} value={field.value ?? ''} />
                  )}
                </FormController>
                <FormController
                  control={control}
                  label={t('Email Address')}
                  name="email"
                  defaultValue=""
                >
                  {({ field }) => <Input {...field} />}
                </FormController>
                <FormController
                  control={control}
                  label={t('Phone Number')}
                  name="phone"
                  defaultValue=""
                >
                  {({ field }) => (
                    <Input {...field} placeholder="000-000-0000" />
                  )}
                </FormController>
                <FormController
                  control={control}
                  label={
                    <div className="inline-flex items-center space-x-2">
                      <div className="flex-shrink-0">
                        {t('Company (Optional)')}
                      </div>
                      <Checkbox
                        onChange={(e) => {
                          const checked = e.currentTarget.checked;
                          setValue('isCompany', checked);
                          if (checked) {
                            const businessName = getValues().businessName;
                            setValue(
                              'firstName',
                              businessName || getValues().firstName,
                            );
                          }
                        }}
                        checked={isCompany}
                        overrides={
                          {
                            Root: {
                              props: {
                                className: 'flex-shrink-0',
                              },
                            },
                            Label: {
                              props: { className: 'text-sm' },
                            },
                          } as CheckboxOverrides
                        }
                      >
                        {/* Use company as primary name */}
                        {t('customer_company_use_as_primary_label')}
                      </Checkbox>
                    </div>
                  }
                  name="businessName"
                  defaultValue=""
                >
                  {({ field }) => (
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        if (isCompany) {
                          setValue(
                            'firstName',
                            e.target.value || getValues().firstName,
                          );
                        }
                      }}
                    />
                  )}
                </FormController>
              </div>
            </fieldset>
          </StyledBody>
        </Card>
        <Card overrides={{ Root: { props: { className: 'flex-1' } } }}>
          <StyledBody>
            <fieldset className="space-y-4">
              <div className="flex items-center space-x-2">
                <legend className="text-xl font-semibold">
                  {t('Address')}
                </legend>
                {isAddressVerified && (
                  <div className="flex items-center space-x-1 text-green-600">
                    <span>
                      <CheckCircle className="h-4 w-4" />
                    </span>
                    <p className="text-sm">{t('Verified')}</p>
                  </div>
                )}
              </div>
              <VerfyingAddress control={control} path="address.isVerifiying" />
              <RHFErrorMessageValue control={control} field="address.lat">
                {() => (
                  <Alert>
                    <AlertTitle>{t('Address not verified')}</AlertTitle>
                    <AlertDescription>
                      {t('address_not_verified_message')}
                    </AlertDescription>
                    <AlertActions>
                      <button
                        type="button"
                        className="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                        onClick={() => {
                          setValue(
                            'address.street',
                            getValues('address.street'),
                          );
                        }}
                      >
                        {t('Verify Address')}
                      </button>
                    </AlertActions>
                  </Alert>
                )}
              </RHFErrorMessageValue>
              <RHFErrorMessageValue
                control={control}
                field="address.isVerified"
              >
                {(message) => (
                  <Alert>
                    <AlertTitle>{t('Address not verified')}</AlertTitle>
                    <AlertDescription>
                      {t('address_not_verified_message')}
                    </AlertDescription>
                  </Alert>
                )}
              </RHFErrorMessageValue>

              <div className="grid grid-cols-2 gap-x-4">
                <FormController
                  control={control}
                  name="address.street"
                  label={t('Address')}
                  defaultValue=""
                  overrides={{
                    Root: { props: { className: 'col-span-2' } },
                  }}
                >
                  {({ field }) => <Input {...field} />}
                </FormController>
                <FormController
                  control={control}
                  label={t('Unit')}
                  name="address.street2"
                  defaultValue=""
                  overrides={{
                    Root: { props: { className: 'col-span-2 md:w-3/4' } },
                  }}
                >
                  {({ field }) => (
                    <Input {...field} value={field.value ?? ''} />
                  )}
                </FormController>
                <FormController
                  control={control}
                  label={t('City')}
                  name="address.city"
                  defaultValue=""
                >
                  {({ field }) => <Input {...field} />}
                </FormController>
                <FormController
                  control={control}
                  name="address.country"
                  label={t('Country')}
                  defaultValue=""
                >
                  {({ field }) => (
                    <CountrySelect
                      useClientCountryQuery={true}
                      value={field.value}
                      onChange={({ option }) => {
                        field.onChange(option?.id);
                      }}
                      clearable={false}
                    />
                  )}
                </FormController>
                <FormController
                  control={control}
                  label={t('Zip')}
                  name="address.postalCode"
                  defaultValue=""
                >
                  {({ field }) => <Input {...field} />}
                </FormController>
                <FormController
                  control={control}
                  name="address.state"
                  label={t('State')}
                  defaultValue=""
                >
                  {({ field }) => (
                    <StateSelect
                      useClientCountryQuery={true}
                      countryCode={selectedCountryCode}
                      value={field.value}
                      onChange={({ option }) => {
                        field.onChange(option?.id);
                      }}
                      clearable={false}
                    />
                  )}
                </FormController>
              </div>
            </fieldset>
          </StyledBody>
        </Card>
      </div>
    </form>
  );
}

function Alert({ children }: { children: React.ReactNode }) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <AlertCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">{children}</div>
      </div>
    </div>
  );
}

function AlertTitle({ children }: { children: React.ReactNode }) {
  return (
    <h3 className="font-prompt text-sm font-medium text-red-800">{children}</h3>
  );
}

function AlertDescription({ children }: { children: React.ReactNode }) {
  return (
    <div className="mt-2 font-sans text-sm text-red-700">
      <p>{children}</p>
    </div>
  );
}

function AlertActions({ children }: { children: React.ReactNode }) {
  return (
    <div className="mt-4">
      <div className="-mx-2 -my-1.5 flex">{children}</div>
    </div>
  );
}

function VerfyingAddress<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({ control, path }: { control: Control<T>; path: TName }) {
  const isVerifying = useWatch({ control, name: path });
  const { t } = useLocale();
  if (isVerifying) {
    return (
      <div className="flex items-center space-x-1 text-gray-500">
        <Spinner $size={20} $borderWidth={3} />
        <span>{t('Verifying')}</span>
      </div>
    );
  }
  return null;
}

function RHFErrorMessageValue<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  control,
  field,
  children,
}: {
  control: Control<T>;
  field: TName;
  children: (message: string) => React.ReactNode;
}) {
  const { errors } = useFormState({ control, name: field, exact: true });
  const error = get(errors, field, '');

  if (!error) {
    return null;
  }
  const { message: messageFromRegister } = error;
  return <>{children(messageFromRegister)}</>;
}
