import { mergeOverrides } from 'baseui';
import type { Row } from 'baseui/data-table';
import { Skeleton } from 'baseui/skeleton';
import type {
  TableBuilderColumnProps,
  TableBuilderProps,
} from 'baseui/table-semantic';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { AlertCircle, FileText } from 'react-feather';

import { Button } from 'components/Button';
import theme from 'theme';
import { useLocale } from 'utils/hooks/useLocale';

type TableProps<T = Row> = TableBuilderProps<T> & {
  isError?: boolean;
  isEmpty?: boolean;
  onRefetch?: VoidFunction;
};

export default function createTableBuilder<T>() {
  return {
    Table: ({ onRefetch, isEmpty, isError, ...props }: TableProps<T>) => {
      const { t } = useLocale();
      return (
        <TableBuilder<T>
          loadingMessage={<Skeleton rows={5} width="100%" />}
          {...props}
          emptyMessage={
            isError ? (
              <div className="flex flex-col items-center space-y-2 px-4 py-5 text-center text-sm">
                <AlertCircle aria-hidden="true" className="text-red-500" />
                <div>
                  <h3 className="font-bold text-red-500">
                    {t('table_error_message')}
                  </h3>
                  <p className="text-red-500">{t('table_error_description')}</p>
                </div>
                {!!onRefetch && (
                  <div>
                    <Button size="compact" onClick={onRefetch}>
                      {t('Try Again')}
                    </Button>
                  </div>
                )}
              </div>
            ) : isEmpty ? (
              props.emptyMessage ? (
                props.emptyMessage
              ) : (
                <div className="flex flex-col items-center space-y-2 px-4 py-5 text-center text-sm">
                  <FileText aria-hidden="true" className="text-gray-400" />
                  <div>
                    <h3 className="font-bold text-gray-500">
                      {t('table_no_data_message')}
                    </h3>
                    <p className="text-gray-500">
                      {t('table_no_data_description')}
                    </p>
                  </div>
                </div>
              )
            ) : null
          }
          overrides={mergeOverrides(
            {
              TableHeadCell: {
                style: { ...theme.typography.font300, fontFamily: 'Prompt' },
                props: {
                  className: 'font-semibold text-gray-500 py-3 font-prompt',
                },
              },
              TableBodyCell: {
                style: {
                  ...theme.typography.font300,
                  fontFamily: 'Prompt, sans-serif',
                  color: '#737791',
                  verticalAlign: 'middle',
                  paddingTop: '0.75rem',
                  paddingBottom: '0.75rem',
                  position: 'relative',
                },
              },
            } as TableBuilderProps<T>['overrides'],
            props.overrides,
          )}
        />
      );
    },
    Column: (props: TableBuilderColumnProps<T>) => (
      <TableBuilderColumn<T> {...props} />
    ),
  };
}
