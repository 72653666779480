/* eslint-disable react/prop-types */
import React from 'react';

import { cn } from 'utils/helpers';

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { dense?: boolean }
>(({ className, dense = false, ...props }, ref) => (
  <div
    ref={ref}
    className={cn([
      dense ? '[--gutter:theme(spacing.5)]' : '[--gutter:theme(spacing.8)]',
      'group w-full min-w-0 bg-white p-[--gutter] ring-1 ring-zinc-950/10  sm:mb-auto rounded-xl dark:bg-zinc-900 dark:ring-white/10 forced-colors:outline',
      className,
    ])}
    {...props}
    data-state-dense={JSON.stringify(dense)}
  />
));
Card.displayName = 'Card';

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    data-slot="header"
    ref={ref}
    className={cn(
      'space-y-1.5 group-data-[state-dense=false]:mb-6 group-data-[state-dense=true]:mb-4',
      className,
    )}
    {...props}
  />
));
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h3
    ref={ref}
    className={cn(
      'text-balance text-lg/6 font-semibold text-zinc-950 dark:text-white',
      className,
    )}
    {...props}
  />
));
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-base/6 text-gray-800', className)}
    {...props}
  />
));
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div data-slot="body" ref={ref} className={cn(className)} {...props} />
));
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex items-center group-data-[state-dense=false]:mt-8 group-data-[state-dense=true]:mt-5',
      className,
    )}
    {...props}
  />
));
CardFooter.displayName = 'CardFooter';

export function CardActions({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        className,
        '*:w-full w-full sm:*:w-auto flex flex-col-reverse items-center justify-end gap-3 sm:flex-row',
      )}
    />
  );
}

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
};
