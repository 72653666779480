import { Skeleton } from 'baseui/skeleton';
import React from 'react';
import { AlertTriangle } from 'react-feather';

import { QueryResolver } from 'components/QueryResolver';
import { useImageQuery } from 'services/api/utilities/endpoint';

export default function DataStoreImage({
  imageId,
  ...nativeImageProps
}: { imageId: number } & Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'src'
>) {
  const query = useImageQuery(imageId, { skip: imageId === 0 });
  return (
    <QueryResolver
      query={query}
      loadingElement={
        <Skeleton
          rows={1}
          width="100%"
          height="100%"
          overrides={{ Row: { props: { className: 'h-full w-full' } } }}
        />
      }
      errorElement={
        <div className="flex h-full w-full flex-col items-center justify-center">
          <AlertTriangle size={36} />
          <p className="mt-2 text-center text-xs text-gray-500">
            Failed to load image.
          </p>
        </div>
      }
    >
      {(datastoreImage) => (
        <img
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          alt=""
          {...nativeImageProps}
          src={`data:image/${datastoreImage.mediaType};base64,${datastoreImage.dataB64}`}
        />
      )}
    </QueryResolver>
  );
}
