import { z } from 'zod';

import { paginatedParamsSchema } from '../types.shared';

export const auditLoginParams = paginatedParamsSchema.extend({
  userId: z.coerce.number(),
  dateFrom: z.string(),
  dateTo: z.string(),
});

export type AuditLoginListParams = z.infer<typeof auditLoginParams>;

export const auditLoginSchema = z.object({
  fsaUserId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  loginTime: z.string().nullable(),
  remoteAddress: z.string(),
});

export type AuditLogin = z.infer<typeof auditLoginSchema>;
