import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  LoginData,
  LoginParams,
  LoginResponse,
  User,
  loginResponseSchema,
} from './types';

import { baseAPI, isMutationSuccess } from '../base-api';
import { Employee } from '../employees/types';

import { useTypedSelector } from 'store/store';

const authEndpoints = baseAPI.injectEndpoints({
  endpoints(builder) {
    return {
      login: builder.mutation<LoginData, LoginParams>({
        async queryFn(params, api, extraOptions, baseQuery) {
          const result = await baseQuery({
            url: 'auth/login',
            method: 'POST',
            body: {
              email: params.email,
              password: params.password,
              myGeotabSessionId: params.myGeotabSessionId,
              myGeotabDatabase: params.myGeotabDatabase,
            },
          });
          if (!isMutationSuccess(result)) {
            return { error: result.error as FetchBaseQueryError };
          }

          const employeeResult = await baseQuery({
            url: `fsausers/${(result.data as LoginResponse).fsaUserId}`,
            headers: {
              Authorization: `Bearer ${(result.data as LoginData).accessToken}`,
            },
          });

          if (employeeResult.error) {
            return { error: employeeResult.error as FetchBaseQueryError };
          }

          return {
            data: {
              ...(result.data as LoginResponse),
              roles: (employeeResult.data as Employee).roles,
            } as LoginData,
          };
        },
      }),
      gpsTrackingLogin: builder.mutation<
        LoginData,
        { cognitoAccessToken: string }
      >({
        async queryFn(arg, api, extraOptions, baseQuery) {
          const result = await baseQuery({
            url: 'auth/login/gpstrackit/sso',
            method: 'POST',
            headers: {
              'X-GPSTRACKIT-COGNITO-ACCESS-TOKEN': arg.cognitoAccessToken,
            },
          });
          if (!isMutationSuccess(result)) {
            return { error: result.error as FetchBaseQueryError };
          }

          const employeeResult = await baseQuery({
            url: `fsausers/${(result.data as LoginResponse).fsaUserId}`,
            headers: {
              Authorization: `Bearer ${(result.data as LoginData).accessToken}`,
            },
          });

          if (employeeResult.error) {
            return { error: employeeResult.error as FetchBaseQueryError };
          }

          return {
            data: {
              ...(result.data as LoginResponse),
              roles: (employeeResult.data as Employee).roles,
            } as LoginData,
          };
        },
      }),
      resetPassword: builder.mutation<
        {
          message: 'string';
          referenceId: 'string';
        },
        { email: string }
      >({
        query: ({ email }) => ({
          method: 'POST',
          url: `auth/reset-password`,
          body: { email },
        }),
      }),
      changePassword: builder.mutation<
        string,
        { newPassword: string; token: string }
      >({
        query: ({ newPassword, token }) => ({
          method: 'POST',
          url: `auth/change-password`,
          body: {
            passwordResetToken: token,
            newPassword,
          },
          responseHandler: 'text',
        }),
      }),
      user: builder.query<User, number>({
        query: (id) => {
          return {
            url: `fsausers/${id}`,
          };
        },
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          return {
            ...baseQueryReturnValue,
            fullName: `${baseQueryReturnValue.firstname} ${baseQueryReturnValue.lastname}`,
          };
        },
      }),
      loginAs: builder.mutation<
        LoginData & LoginResponse,
        { fsaUserId: number }
      >({
        // query: ({ fsaUserId }) => ,
        async queryFn({ fsaUserId }, api, extraOptions, baseQuery) {
          const result = await baseQuery({
            url: `auth/switch-to/${fsaUserId}`,
            method: 'POST',
          });

          if (result.error) {
            return { error: result.error };
          }
          const validated = loginResponseSchema.safeParse(result.data);
          if (!validated.success) {
            return {
              error: {
                status: 'CUSTOM_ERROR',
                error: 'Failed to parse response',
              },
            };
          }
          const employeeResult = await baseQuery({
            url: `fsausers/${validated.data.fsaUserId}`,
            headers: {
              Authorization: `Bearer ${validated.data.accessToken}`,
            },
          });

          if (employeeResult.error) {
            return { error: employeeResult.error as FetchBaseQueryError };
          }

          return {
            data: {
              ...validated.data,
              roles: (employeeResult.data as Employee).roles,
            },
          };
        },
      }),
    };
  },
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useUserQuery,
  useResetPasswordMutation,
  useChangePasswordMutation,
} = authEndpoints;
export { authEndpoints as AuthService };
export const useCurrentUser = () => {
  const id = useTypedSelector((s) => {
    return s.auth.userCredentials.fsaUserId;
  });
  return useUserQuery(id, { skip: id === 0 });
};
