import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'baseui/snackbar';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { z } from 'zod';

import { FieldGroup } from 'components/forms/fieldset';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from 'components/ui/dialog';
import { Input, TextField } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { TextArea } from 'components/ui/textarea';
import { useCurrentUser } from 'services/api/auth/endpoints';
import { isMutationSuccess } from 'services/api/base-api';
import {
  useClientQuery,
  useEditClientMutation,
} from 'services/api/clients/endpoints';
import { useTypedSelector } from 'store/store';
import { useLocale } from 'utils/hooks/useLocale';

const nonEmptyString = z
  .string()
  .trim()
  .min(1, { message: 'errors.invalid_type_received_undefined' });
const schema = z.object({
  name: nonEmptyString,
  description: z.string(),
});

type FormFields = z.infer<typeof schema>;
export default function NewSkillset() {
  const { t } = useLocale();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const id = useCurrentUser().data?.clientId ?? 0;
  const resellerId = useTypedSelector((s) => s.auth.userCredentials.resellerId);
  const query = useClientQuery(id, { skip: id === 0 });

  const [editClient, mutation] = useEditClientMutation();

  const { control, handleSubmit, setError } = useForm<FormFields>({
    defaultValues: { description: '' },
    resolver: zodResolver(schema),
  });
  const onSubmit = handleSubmit(async (data) => {
    if (query.data) {
      const isDuplicate = query.data.masterSkills.some(
        (skill) =>
          skill.name.toLowerCase().trim() === data.name.toLowerCase().trim(),
      );
      if (isDuplicate) {
        return setError('name', {
          message: t('skill_duplicate', { ns: 'zod' }),
        });
      }

      const result = await editClient({
        ...query.data,
        resellerId,
        masterSkills: [...query.data.masterSkills, data],
      });

      if (isMutationSuccess(result)) {
        snackbar.enqueue({ message: t('skill_created_successfully') });
        navigate('..');
      } else {
        snackbar.enqueue({ message: t('skill_created_error') });
      }
    }
  });

  return (
    <Dialog onOpenChange={(open) => !open && navigate('..')} defaultOpen>
      <DialogTitle> {t('Add New Skillset')}</DialogTitle>
      <DialogBody>
        <form onSubmit={onSubmit} id="new-skillset-form">
          <FieldGroup>
            <TextField control={control} field="name">
              <Label>{t('Skillset Name')}</Label>
              <Input />
            </TextField>
            <TextField control={control} field="description">
              <Label>{t('Description')}</Label>
              <TextArea rows={5} />
            </TextField>
          </FieldGroup>
        </form>
      </DialogBody>
      <DialogActions>
        <Button
          size="lg"
          variant="plain"
          slot="close"
          isDisabled={mutation.isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button
          size="lg"
          isLoading={mutation.isLoading}
          type="submit"
          form="new-skillset-form"
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
