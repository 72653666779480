import { mergeOverrides } from 'baseui';
import type { KIND } from 'baseui/button';
import type { MenuOverrides } from 'baseui/menu';
import type {
  SelectProps as BaseSelectProps,
  SelectOverrides,
  SharedStylePropsArg,
} from 'baseui/select';
import { Select as BaseSelect } from 'baseui/select';

import { getDefaultKindStyles } from './styles';

import { useLocale } from 'utils/hooks/useLocale';

export type SelectProps = BaseSelectProps & {
  kind?: keyof typeof KIND;
};

const selectOverrides = (props: SelectProps): SelectOverrides => ({
  ControlContainer: {
    style: (sharedProps: SharedStylePropsArg) => ({
      ...getDefaultKindStyles(props.kind ?? 'secondary', sharedProps),
    }),
  },
  StatefulMenu: {
    props: {
      overrides: {
        EmptyState: {
          props: {
            className: 'text-gray-600',
          },
        },
      } as MenuOverrides,
    },
  },
});

export default function Select(props: SelectProps) {
  const { kind, ...baseSelectProps } = props;
  const { t } = useLocale();
  return (
    <BaseSelect
      placeholder={t('Select')}
      {...baseSelectProps}
      overrides={mergeOverrides(
        selectOverrides(props),
        baseSelectProps.overrides,
      )}
    />
  );
}
