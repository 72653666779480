import { forwardRef } from 'react';
import type { FileTriggerProps, InputProps } from 'react-aria-components';
import {
  FileTrigger,
  InputContext,
  TextFieldProps,
  useContextProps,
} from 'react-aria-components';
import type { Control, FieldPathByValue, FieldValues } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { z } from 'zod';

import { Button, ButtonProps } from '../ui/button';

import { cn } from 'utils/helpers';

export const tofilePickerField = (opts?: { maxSizeInKB: number }) => {
  return z
    .object({
      file: z
        .instanceof(File)
        .refine((fileItem) => {
          if (!opts?.maxSizeInKB) {
            return true;
          }
          const size = fileItem.size;
          if (size === 0) return false;
          const sizeInKB = (size / 1024).toFixed(1);

          return Number(sizeInKB) <= opts.maxSizeInKB;
        }, 'File too large')
        .optional(),
      url: z
        .string()
        .url()
        .nullish()
        .transform((x) => x ?? undefined),
      dataStoreId: z.coerce.number().optional(),
    })
    .array();
};

export const filePickerFieldSchema = tofilePickerField();
export type FileFieldShape = z.infer<typeof filePickerFieldSchema>;
type FilePickerFieldRenderProps = {
  files: FileFieldShape;
};

const FilteredInputProps = forwardRef<
  HTMLInputElement,
  InputProps & { children: React.ReactNode }
>(({ children, ...props }, ref) => {
  [props, ref] = useContextProps(props, ref, InputContext);
  return (
    <InputContext.Provider value={{ ...props, value: undefined }}>
      {children}
    </InputContext.Provider>
  );
});
FilteredInputProps.displayName = 'FilteredInputProps';

export default function FilePickerFieldRoot<
  T extends FieldValues,
  TName extends FieldPathByValue<T, FileFieldShape>,
>(
  props: {
    control: Control<T>;
    children: (
      args: FilePickerFieldRenderProps & {
        hasFieldValue: boolean;
        clearFiles: VoidFunction;
      },
    ) => React.ReactNode;
  } & Omit<TextFieldProps, 'children'> &
    Omit<FileTriggerProps, 'onSelect' | 'children'> & {
      control: Control<T>;
      field: TName;
    },
) {
  const {
    children,
    acceptedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'],
    allowsMultiple,
    defaultCamera,
    className,
    ...textFieldProps
  } = props;
  const controller = useController<T, TName>({
    control: textFieldProps.control,
    name: textFieldProps.field,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultValue: [],
  });
  // const [files, setFiles] = useState<FileFieldShape>(controller.field.value);
  const field = controller.field;
  const fieldValue = filePickerFieldSchema.parse(controller.field.value ?? []);

  return (
    <div data-slot="control" className={cn(className)}>
      <FilteredInputProps
        id={field.name}
        ref={field.ref}
        onBlur={field.onBlur}
        name={field.name}
        onChange={(e) => {
          const selectedFiles = e.target.files;
          const fileArray = Array.from(selectedFiles ?? []);
          const newValue = fileArray.map((file) => ({
            file,
            url: URL.createObjectURL(file),
          }));

          controller.field.onChange(newValue);
        }}
        accept={acceptedFileTypes.toString()}
        multiple={allowsMultiple}
        capture={defaultCamera}
      >
        {children({
          files: controller.field.value,
          hasFieldValue: fieldValue.length !== 0,
          clearFiles: () => controller.field.onChange([]),
        })}
      </FilteredInputProps>
    </div>
  );
}

function Trigger(props: ButtonProps) {
  return (
    <FileTrigger>
      <Button {...props} />
    </FileTrigger>
  );
}

export const FilePicker = Object.assign(FilePickerFieldRoot, {
  Trigger,
  Field: FilePickerFieldRoot,
});
