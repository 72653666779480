import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import {
  DataStoreImage,
  DatastorePurpose,
  EmailTemplateType,
  NotificationStatus,
  NotificationTemplateType,
  UploadedImage,
  Country,
} from './types';

import { baseAPI, isMutationSuccess } from '../base-api';
import { ScheduleSlotType } from '../schedule/types';

type DataStoreImagesQueryParams = {
  clientId: number;
  workRequestId: number;
};

type OneKey<T extends object> = {
  [K in keyof T]-?: { [P in K]: T[K] } & {
    [P in Exclude<keyof T, K>]?: never;
  } extends infer O
    ? { [P in keyof O]: O[P] }
    : never;
}[keyof T];

type OneOfKeys = OneKey<DataStoreImagesQueryParams>;

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = (reader.result as string).replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });

export const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    uploadImage: builder.mutation<
      UploadedImage,
      Pick<UploadedImage, 'resellerId'> &
        Partial<
          Pick<UploadedImage, 'purpose' | 'workRequestId' | 'clientId'>
        > & {
          file: File;
        }
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const { file, clientId, resellerId, workRequestId, purpose } = arg;
        const base64File = await toBase64(file);

        const result = await baseQuery({
          url: 'datastores',
          method: 'POST',
          body: {
            clientId,
            resellerId,
            workRequestId,
            description: 'image',
            dataName: file.name,
            purpose: purpose ?? 'General',
            length: file.size,
            mediaType: file.type,
            dataB64: base64File,
          },
        });
        if (isMutationSuccess(result)) {
          return { data: result.data as UploadedImage };
        } else {
          return { error: result.error as FetchBaseQueryError };
        }
      },
      invalidatesTags: (res, error, payload) =>
        res
          ? [
              { type: 'Datastore', id: 'list' },
              { type: 'Datastore', id: res.id },
            ]
          : [],
    }),
    updateImage: builder.mutation<
      DataStoreImage,
      Pick<DataStoreImage, 'id'> &
        Partial<Omit<DataStoreImage, 'id'>> & { file: File }
    >({
      async queryFn(payload, api, extraOptions, baseQuery) {
        const { file, ...dataStoreImage } = payload;
        const removeFalsyValues = (obj: Record<string, any>) => {
          Object.keys(obj).forEach((key) => {
            if (!obj[key]) {
              delete obj[key];
            }
          });
          return obj;
        };
        const base64File = await toBase64(file);
        const datastoreResult = await baseQuery(
          `datastores/${dataStoreImage.id}?inclData=true`,
        );
        if (datastoreResult.error) {
          return { error: datastoreResult.error as FetchBaseQueryError };
        }

        const updatePayload = removeFalsyValues(dataStoreImage);
        const result = await baseQuery({
          url: `datastores/${dataStoreImage.id}`,
          method: 'PUT',
          body: {
            ...(datastoreResult.data as any),
            ...updatePayload,
            dataName: file.name,
            length: file.size,
            mediaType: file.type,
            dataB64: base64File,
          },
        });
        if (isMutationSuccess(result)) {
          return { data: result.data as UploadedImage };
        } else {
          return { error: result.error as FetchBaseQueryError };
        }
      },
      invalidatesTags: (res, error, payload) =>
        res
          ? [
              { type: 'Datastore', id: 'image' },
              { type: 'Datastore', id: payload.id },
            ]
          : [],
    }),
    deleteImage: builder.mutation<void, { datastoreId: number }>({
      query(arg) {
        return {
          url: `datastores/${arg.datastoreId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (res) => (res ? ['Datastore'] : []),
    }),
    image: builder.query<DataStoreImage, number>({
      query(id) {
        return {
          url: `datastores/${id}?inclData=true`,
        };
      },
      providesTags: (res, error, imageId) =>
        res
          ? [
              { type: 'Datastore', id: 'image' },
              { type: 'Datastore', id: imageId },
            ]
          : [],
    }),
    images: builder.query<
      DataStoreImage[],
      | ({
          params?: OneOfKeys;
        } & { purpose: DatastorePurpose })
      | void
    >({
      query(args) {
        const { params = {}, purpose } = args ?? {};

        return {
          url: 'datastores',
          params: { ...params, purpose },
        };
      },
      providesTags: (response) =>
        response ? [{ type: 'Datastore', id: 'list' }] : [],
    }),
    sendSMS: builder.mutation<
      {
        message: string;
        referenceId: string;
      },
      {
        type: NotificationTemplateType;
        workRequestId: number;
        scheduleType: ScheduleSlotType;
      }
    >({
      query: (payload) => {
        return {
          url: `/work-requests/sms-template/${payload.workRequestId}/${payload.scheduleType}/${payload.type}`,
          method: 'POST',
        };
      },
    }),
    SMSStatus: builder.query<any, string>({
      query: (id) => `work-requests/sms/${id}`,
    }),
    sendEmail: builder.mutation<
      {
        message: string;
        referenceId: string;
      },
      { workRequestId: number; templateType: EmailTemplateType }
    >({
      query(payload) {
        return {
          url: `workrequests/email-template/${payload.workRequestId}/${payload.templateType}`,
          method: 'POST',
        };
      },
    }),
    emailStatus: builder.query<NotificationStatus, number>({
      query(id) {
        return {
          url: `workrequests/email/${id}`,
        };
      },
    }),
    previewPDF: builder.query<
      { pdfType: string; dataB64: string },
      { workRequestId: number; templateType: EmailTemplateType }
    >({
      query(args) {
        return {
          url: `workrequests/preview-template/${args.workRequestId}/${args.templateType}`,
        };
      },
    }),
    geocode: builder.mutation<
      {
        latitude: number;
        longitude: number;
        confidence: number;
        timeZone: string;
      },
      { address: string }
    >({
      query: (arg) => ({ url: 'geocode', method: 'POST', body: arg }),
    }),
    country: builder.query<Country[], void>({
      query: () => ({
        url: 'country',
        method: 'GET',
      }),
    }),
    countryByClientId: builder.query<Country[], number>({
      query: (clientId) => ({
        url: `country/${clientId}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useUploadImageMutation,
  useUpdateImageMutation,
  useImageQuery,
  useImagesQuery,
  useSendSMSMutation,
  useSMSStatusQuery,
  useSendEmailMutation,
  useEmailStatusQuery,
  usePreviewPDFQuery,
  useGeocodeMutation,
} = endpoints;

export { endpoints as UtilityService };
