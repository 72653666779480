import { useSnackbar } from 'baseui/snackbar';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import {
  AlertActions,
  AlertDescription,
  AlertDialog,
  AlertTitle,
} from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { isMutationSuccess } from 'services/api/base-api';
import { EquipmentService } from 'services/api/equipment/endpoint';
import { useLocale } from 'utils/hooks/useLocale';

export default function DeleteEquipment() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = z.coerce.number().catch(0).parse(useParams().equipmentId);
  const form = useForm<{ id: number }>({ defaultValues: { id } });

  const [deleteEquipment] =
    EquipmentService.endpoints.deleteEquipment.useMutation();
  const snackbar = useSnackbar();
  const { t } = useLocale();
  const onSubmit = form.handleSubmit(async (data) => {
    const result = await deleteEquipment({ equipmentId: data.id });
    if (isMutationSuccess(result)) {
      snackbar.enqueue({ message: t('equipment_delete_success') });
      navigate('../..', { replace: true });
    } else {
      snackbar.enqueue({ message: t('equipment_delete_error') });
    }
  });

  const isSubmitting = form.formState.isSubmitting;

  return (
    <AlertDialog isOpen>
      <AlertTitle>{t('equipment_delete_alert_title')}</AlertTitle>
      <AlertDescription>
        {t('equipment_delete_alert_description')}
      </AlertDescription>
      <AlertActions>
        <Button
          variant="plain"
          isDisabled={isSubmitting}
          onClick={() =>
            navigate(
              { pathname: '..', search: `?${searchParams.toString()}` },
              { replace: true },
            )
          }
        >
          {t('Cancel')}
        </Button>
        <form onSubmit={onSubmit}>
          <Button
            color="red"
            type="submit"
            isLoading={form.formState.isSubmitting}
          >
            {t('Delete Equipment')}
          </Button>
        </form>
      </AlertActions>
    </AlertDialog>
  );
}
