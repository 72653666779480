import { useSnackbar } from 'baseui/snackbar';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import EquipmentForm from './EquipmentForm';

import { MutationBanner } from 'components/Banner';
import { QueryResolver } from 'components/QueryResolver';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from 'components/ui/dialog';
import {
  isErrorWithMessage,
  isMutationFailed,
  isMutationSuccess,
} from 'services/api/base-api';
import { EquipmentService } from 'services/api/equipment/endpoint';
import { UtilityService } from 'services/api/utilities/endpoint';
import { useTypedSelector } from 'store/store';
import { useLocale } from 'utils/hooks/useLocale';

export default function EditEquipment() {
  const equipmentId = z.coerce.number().catch(0).parse(useParams().equipmentId);
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const useCredentials = useTypedSelector((s) => s.auth.userCredentials);
  const query = EquipmentService.endpoints.equipment.useQuery(
    { equipmentId },
    {
      skip: !equipmentId,
    },
  );

  const [updateEquipment, updateEquipmentMutation] =
    EquipmentService.endpoints.updateEquipment.useMutation();
  const [updateImage, updateImageMutation] =
    UtilityService.endpoints.updateImage.useMutation();
  const [uploadImage, uploadImageMutation] =
    UtilityService.endpoints.uploadImage.useMutation();
  const [deleteImage, deleteImageMutation] =
    UtilityService.endpoints.deleteImage.useMutation();

  const mutations = [
    updateImageMutation,
    updateEquipmentMutation,
    uploadImageMutation,
    deleteImageMutation,
  ];
  const isSubmitting = mutations.some((m) => m.isLoading);

  const isError = mutations.some((m) => m.isError);
  const isDuplicateIdentifier = isErrorWithMessage(
    updateEquipmentMutation.error,
  )
    ? updateEquipmentMutation.error.data.message ===
      'Equipment Identifier is already used.'
    : false;

  const close = () => {
    if (isSubmitting) return;
    navigate(
      { pathname: '..', search: `?${searchParams.toString()}` },
      { replace: true },
    );
  };

  return (
    <>
      <Dialog isOpen onOpenChange={(open) => !open && close()}>
        <DialogTitle>{t('Edit Equipment')}</DialogTitle>
        <DialogBody>
          <MutationBanner
            message={
              isDuplicateIdentifier
                ? t('equipment_save_duplicate_identifier_error')
                : t('equipment_update_error')
            }
            show={isError}
            focusOnError
          />
          <QueryResolver query={query}>
            {(equipment) => (
              <EquipmentForm
                id="edit-equipment-form"
                initialValues={{
                  ...equipment,
                  uid: equipment.identifier,
                  type: equipment.equipmentType,
                  image: equipment.logoDatastoreId
                    ? [{ dataStoreId: equipment.logoDatastoreId }]
                    : [],
                }}
                onSubmit={async (data) => {
                  const hasNoImageUploaded = equipment.logoDatastoreId === null;
                  const hasClearedImages = data.image.length === 0;
                  const hasExistingUploadedImage =
                    equipment.logoDatastoreId !== null;
                  const file = data.image.find((v) => v.file)?.file;
                  let newImageId = 0;

                  if (file && !hasExistingUploadedImage) {
                    const result = await uploadImage({
                      file,
                      resellerId: useCredentials.resellerId,
                      clientId: useCredentials.clientId,
                      purpose: 'General',
                    });
                    if (isMutationSuccess(result)) {
                      newImageId = result.data.id;
                    } else {
                      return snackbar.enqueue({
                        message: t('equipment_update_error'),
                      });
                    }
                  }

                  const results = await Promise.all([
                    file && !hasNoImageUploaded
                      ? updateImage({ file, id: equipment.logoDatastoreId })
                      : undefined,
                    /**
                     * We delete the uploaded image if they cleared the image field
                     */
                    // hasExistingUploadedImage && hasClearedImages
                    //   ? deleteImage({ datastoreId: equipment.logoDatastoreId })
                    //   : undefined,
                  ]);

                  const updateEquipmentResult = await updateEquipment({
                    ...equipment,
                    name: data.name,
                    description: data.description,
                    equipmentType: data.type,
                    logoDatastoreId:
                      hasExistingUploadedImage && hasClearedImages
                        ? undefined
                        : newImageId || equipment.logoDatastoreId,
                    identifier: data.uid,
                    available: data.available,
                  });

                  const error = [...results, updateEquipmentResult].find((r) =>
                    r ? isMutationFailed<unknown>(r) : false,
                  );

                  if (error) {
                    if (newImageId) {
                      await deleteImage({ datastoreId: newImageId });
                    }
                    snackbar.enqueue({ message: t('equipment_update_error') });
                  } else {
                    snackbar.enqueue({
                      message: t('equipment_update_success'),
                    });
                    close();
                  }
                }}
              />
            )}
          </QueryResolver>
        </DialogBody>
        <DialogActions>
          <Button type="button" variant="plain" onPress={close}>
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            form="edit-equipment-form"
            isLoading={isSubmitting}
          >
            {t('Update Equipment')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
