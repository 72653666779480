import { useSnackbar } from 'baseui/snackbar';
import { Save } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import CustomerForm from './features/CustomerForm';

import MuatationBanner from 'components/Banner/MutationBanner';
import { Button } from 'components/Button';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { isMutationSuccess } from 'services/api/base-api';
import { useAddCustomerMutation } from 'services/api/customers/endpoints';
import { useLocale } from 'utils/hooks/useLocale';

export default function NewCustomer() {
  const { t } = useLocale();
  const navigate = useNavigate();
  const [addCustomer, mutationState] = useAddCustomerMutation();
  const snackbar = useSnackbar();
  return (
    <div className="space-y-10">
      <DashboardContentHeader
        title={t('New Customer')}
        description={t('create_customer_description')}
        // Create a new Customer by filling in necessary details
      />
      <section className="space-y-4">
        <MuatationBanner
          show={mutationState.isError}
          message={t('customer_create_error')}
        />
        <CustomerForm
          id="add-customer-form"
          onSubmit={async (data) => {
            const { billingAddress, ...customerData } = data;
            const result = await addCustomer({
              ...customerData,
              lastName: customerData.lastName ?? '',
              contact: '',
              notes: '',
            });
            if (isMutationSuccess(result)) {
              snackbar.enqueue({ message: t('customer_create_successfully') });
              navigate('/fs-admin/customers');
            }
          }}
        />
        <div className="flex justify-end">
          <Button
            kind="tertiary"
            onClick={() => navigate('/fs-admin/customers')}
            disabled={mutationState.isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            form="add-customer-form"
            type="submit"
            startEnhancer={<Save />}
            size="compact"
            isLoading={mutationState.isLoading}
          >
            {t('Save')}
          </Button>
        </div>
      </section>
    </div>
  );
}
