import { Tab, Tabs } from 'baseui/tabs-motion';
import { format, isEqual } from 'date-fns';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import {
  ButtonContext,
  Collection,
  Header,
  Heading,
  ListBox,
  ListBoxItem,
  Button as Pressable,
  Provider,
  Section,
  Text,
  TextContext,
  TextProps,
} from 'react-aria-components';
import {
  AlertCircle,
  DollarSign,
  Edit,
  FileText,
  Mail,
  MapPin as MapPinIcon,
  Phone,
  Plus,
  User as UserIcon,
} from 'react-feather';
import { Link, Outlet, useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { Button } from 'components/Button';
import { LinkButton } from 'components/LinkButton';
import { MetricCard } from 'components/MetricCard';
import { Pagination } from 'components/Pagination';
import { QueryResolver } from 'components/QueryResolver';
import { createTableBuilder } from 'components/Table';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/ui/card';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import StatusBadge from 'features/work-requests/StatusBadge';
import { useCustomerQuery } from 'services/api/customers/endpoints';
import { useEstimatesQuery } from 'services/api/estimates/endpoints';
import { Estimate } from 'services/api/estimates/types';
import { formatAddress } from 'services/api/helpers';
import { useInvoicesQuery } from 'services/api/invoices/endpoints';
import { Invoice } from 'services/api/invoices/types';
import { useJobsQuery } from 'services/api/jobs/endpoints';
import { Job } from 'services/api/jobs/types';
import { ServiceLocationService } from 'services/api/service-locations/endpoints';
import { ServiceLocationNote } from 'services/api/service-locations/types';
import { cn, formatDate, formatDateTime, priceFormatter } from 'utils/helpers';
import { useLocale } from 'utils/hooks/useLocale';

function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
}

function objectToArray<T extends object, K>(
  obj: T,
  getIdFn: (entry: [string, T[keyof T]]) => K,
): { items: T[keyof T]; name: K }[] {
  return Object.entries(obj).map((entry) => {
    const value = entry[1];
    const name = getIdFn(entry);
    return { items: value, name: name };
  });
}

const JobHistoryBuilder = createTableBuilder<Job>();
const EstimateTableBuilder = createTableBuilder<Estimate>();
const BillingHistoryBuilder = createTableBuilder<Invoice>();

const TAB_KEYS = ['JobHistory', 'Estimates', 'Invoices', 'Notes'];
const getTabKey = (value: string) =>
  TAB_KEYS.map((item) => item.toLowerCase()).indexOf(value);

export default function CustomerDetails() {
  const { t } = useLocale();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <Outlet />
      <div className="flex flex-1 flex-col space-y-5">
        <DashboardContentHeader
          title={t('Customer')}
          description={t('Track and manage your Customers')}
        />
        <div className="h-full flex-1 space-y-5 overflow-hidden lg:flex lg:space-x-4 lg:space-y-0">
          <div className="flex-1 space-y-5 overflow-auto">
            <CustomerInformation />
            <Card>
              <CardContent>
                <Tabs
                  overrides={{
                    StyledTabBar: {
                      props: {
                        className: 'bg-white px-0',
                      },
                    },
                    TabHighlight: {
                      props: {
                        className: 'bg-brand-primary',
                      },
                    },
                    TabList: {
                      props: {
                        className: 'px-0',
                      },
                    },
                  }}
                  onChange={({ activeKey }) => {
                    searchParams.set(
                      'tab',
                      TAB_KEYS[Number(activeKey)].toLowerCase(),
                    );
                    setSearchParams(searchParams, { replace: true });
                  }}
                  activeKey={getTabKey(
                    searchParams.get('tab') ?? TAB_KEYS[0].toLowerCase(),
                  )}
                >
                  <Tab title={t('Job History')}>
                    <JobsTable />
                  </Tab>
                  <Tab title={t('Estimates')}>
                    <EstimatesTable />
                  </Tab>
                  <Tab title={t('Invoices')}>
                    <InvoicesTable />
                  </Tab>
                  <Tab
                    title={t('Location Notes')}
                    overrides={{
                      TabPanel: { props: { className: 'p-0' } },
                    }}
                  >
                    <ServiceLocationNotes />
                  </Tab>
                </Tabs>
              </CardContent>
            </Card>
            <Card title={t('Notes')} className="flex-1">
              <CardContent>
                <CustomerNotes />
              </CardContent>
            </Card>
          </div>
          <div className="w-full shrink-0 space-y-5 overflow-auto lg:max-w-md">
            <div>
              <OverdueBalance />
            </div>
            <BillingHistoryTable customerId={Number(params.customerId)} />
            {/* Removed Until feature will be supported */}
            {/* <Card title="Stored Payment Methods">
              <CardContent>
                <p>This customer has no stored payment methods.</p>
                <Button startEnhancer={<Plus />} size="mini">
                  Add Payment Method
                </Button>
              </CardContent>
            </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}

function BillingHistoryTable({ customerId }: { customerId: number }) {
  const { t } = useLocale();
  const [page, setPage] = useState(1);
  const query = useInvoicesQuery({
    params: {
      limit: 20,
      page: page,
      customerId: customerId,
      workRequestStatus: 'Collected',
    },
  });
  const invoices = query.data?.list ?? [];
  const customer = useCustomerQuery(customerId).data?.firstName ?? '';

  return (
    <Card className="flex h-[30rem] flex-col">
      <CardHeader>
        <CardTitle>{t('Billing History')}</CardTitle>
        <CardDescription>
          {t('customer_billing_history_table_description', { name: customer })}
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          <BillingHistoryBuilder.Table
            data={invoices}
            isLoading={query.isLoading || query.isFetching}
            isError={query.isError}
            isEmpty={invoices.length === 0}
          >
            <BillingHistoryBuilder.Column header={t('WR#')}>
              {(row) => (
                <Link
                  to={`/invoice/${row.id}/edit`}
                  className="underline hover:text-brand-primary"
                  title="View invoice details"
                  reloadDocument
                >
                  <span aria-hidden className="absolute inset-0" />
                  {row.num}
                </Link>
              )}
            </BillingHistoryBuilder.Column>
            <BillingHistoryBuilder.Column header={t('Date Paid')}>
              {(row) =>
                row.invoicePaid ? formatDate(new Date(row.invoicePaid)) : '---'
              }
            </BillingHistoryBuilder.Column>
            {/* <BillingHistoryBuilder.Column header={t('Status')}>
              {(row) => <StatusBadge status={row.progress} />}
            </BillingHistoryBuilder.Column> */}
          </BillingHistoryBuilder.Table>
        </div>
      </CardContent>
      <CardFooter className="mt-auto flex justify-end">
        <Pagination
          totalItems={query.data?.totalElements}
          currentPage={page}
          onChange={({ page }) => setPage(page)}
          pageSize={query.data?.pageSize}
        />
      </CardFooter>
    </Card>
  );
}
const toTitleCase = (value: string) => {
  const words = value.split(' ').map((word) => {
    // Capitalize the first letter of the word and lowercase the rest
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  });
  // Join the words by spaces and return the result
  return words.join(' ');
};

function ServiceLocationNotes() {
  const customerId = z.coerce.number().catch(0).parse(useParams()?.customerId);
  const { t } = useLocale();
  const query = ServiceLocationService.endpoints.notes.useQuery({
    params: {
      customerId,
    },
  });
  const servLocs = ServiceLocationService.endpoints.serviceLocations.useQuery(
    { customerId },
    { skip: customerId === 0 },
  );

  const noteNavigateSlug: Record<ServiceLocationNote['noteType'], string> = {
    ESTIMATE: '/estimates',
    INVOICE: '/invoice',
    JOB: '/jobs',
  };
  return (
    <QueryResolver query={servLocs}>
      {(serviceLocations) => (
        <QueryResolver query={query}>
          <ListBox
            aria-label={t('Notes')}
            className="max-h-[20rem] divide-y divide-gray-100 overflow-y-auto"
            items={objectToArray(
              groupBy(query.data ?? [], (n) => n.servLocId),
              ([key, value]) =>
                serviceLocations.find((v) => v.id.toString() === key),
            )}
            selectionMode="none"
            renderEmptyState={(props) => (
              <div className="text-center">
                <FileText className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">
                  {t('notes_empty')}
                </h3>
              </div>
            )}
          >
            {(section) => (
              <Section
                id={`Service Location ${section.name?.id}`}
                // className="pb-4 first:pt-0 last:pb-4 [&:not(:first-child)]:pt-4"
              >
                <Header className="border-b bg-gray-50 px-4 py-3 text-sm">
                  <Heading className="font-semibold text-gray-900">
                    {t('Service Location')} #{section.name?.id}
                  </Heading>
                  <div className="mt-0.5 text-gray-700">
                    {section.name?.address
                      ? formatAddress(section.name.address)
                      : ''}
                  </div>
                </Header>
                <Collection items={section.items}>
                  {(note) => (
                    <ListBoxItem
                      textValue={note.noteValue}
                      className="flex gap-x-4 px-4 pb-2 first:pt-0 last:pb-5 [&:not(:first-child)]:pt-2"
                    >
                      <div className="min-w-0">
                        <div className="flex-auto">
                          <div className="flex items-baseline justify-between gap-x-4">
                            <div className="flex items-center gap-x-3">
                              <div className="flex items-center space-x-1">
                                <UserIcon className="h-4 w-4 text-gray-400" />
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                  {note.createdByName}
                                </p>
                              </div>
                              <p
                                className={cn(
                                  'text-gray-600 bg-gray-50 ring-gray-500/10',
                                  'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                                )}
                              >
                                {t(toTitleCase(note.noteType))}
                              </p>
                              <Link
                                to={`${noteNavigateSlug[note.noteType]}/${
                                  note.workRequestId
                                }/edit`}
                                title={t('note_item_navigate_to_title', {
                                  type: t(toTitleCase(note.noteType)),
                                })}
                                className={cn(
                                  'text-gray-600 bg-gray-50 ring-gray-500/10 hover:bg-gray-100',
                                  'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                                )}
                              >
                                #{note.workRequestNumber}
                              </Link>
                            </div>
                          </div>
                          <ExpandableText>
                            {({ isClampable, isClamped }) => (
                              <>
                                <ExpandableParagraph className="mt-1 text-sm leading-6 text-gray-600">
                                  {note.noteValue}
                                </ExpandableParagraph>
                                {isClamped && isClampable && (
                                  <Pressable
                                    slot="expander"
                                    className="text-sm font-medium leading-6 text-brand-primary-600 hover:text-brand-primary-500"
                                  >
                                    {isClamped ? t('See More') : t('See Less')}
                                  </Pressable>
                                )}
                              </>
                            )}
                          </ExpandableText>
                        </div>
                        <div className="mt-1 flex items-center space-x-2 text-xs leading-5 text-gray-500">
                          {!isEqual(
                            new Date(note.dateCreated),
                            new Date(note.dateModified),
                          ) && (
                            <>
                              <p className="whitespace-nowrap">{t('Edited')}</p>
                              <svg
                                viewBox="0 0 2 2"
                                className="h-0.5 w-0.5 fill-current"
                              >
                                <circle cx={1} cy={1} r={1} />
                              </svg>
                            </>
                          )}
                          <p className="whitespace-nowrap">
                            <time dateTime={note.dateCreated}>
                              {formatDateTime(new Date(note.dateCreated))}
                            </time>
                          </p>
                        </div>
                      </div>
                    </ListBoxItem>
                  )}
                </Collection>
              </Section>
            )}
          </ListBox>
        </QueryResolver>
      )}
    </QueryResolver>
  );
}

type ExpandableTextValue = {
  isClamped: boolean;
  isClampable: boolean;
};
const ExpandableTextContext = createContext<ExpandableTextValue>({
  isClamped: true,
  isClampable: false,
});
function ExpandableText({
  children,
}: {
  children: React.ReactNode | ((args: ExpandableTextValue) => React.ReactNode);
}) {
  const elRef = useRef<HTMLParagraphElement | null>(null);
  const [isClamped, setIsClamped] = useState(true);
  const [canBeClamped, setCanBeClamped] = useState(false);

  useEffect(() => {
    const handler = () => {
      if (elRef.current) {
        const { clientHeight, scrollHeight } = elRef.current;
        setCanBeClamped(clientHeight !== scrollHeight);
      }
    };
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  useEffect(() => {
    if (elRef.current) {
      const { clientHeight, scrollHeight } = elRef.current;
      setIsClamped(clientHeight !== scrollHeight);
      setCanBeClamped(clientHeight !== scrollHeight);
    }
  }, []);

  return (
    <Provider
      values={[
        [ExpandableTextContext, { isClamped, isClampable: canBeClamped }],
        [TextContext, { ref: elRef }],
        [
          ButtonContext,
          {
            slots: {
              expander: {
                onPress: () => setIsClamped((prev) => !prev),
              },
            },
          },
        ],
      ]}
    >
      {typeof children === 'function'
        ? children({ isClamped, isClampable: canBeClamped })
        : children}
    </Provider>
  );
}

function ExpandableParagraph(props: TextProps) {
  const context = useContext(ExpandableTextContext);
  const isClamped = z.boolean().catch(true).parse(context.isClamped);

  return (
    <Text
      {...props}
      elementType="p"
      className={cn(props.className, {
        'line-clamp-2': isClamped,
      })}
    />
  );
}

function EstimatesTable() {
  const { t } = useLocale();
  const params = useParams();
  const [page, setPage] = useState(1);
  const estimatesQuery = useEstimatesQuery({
    params: {
      limit: 20,
      page: page,
      customerId: Number(params.customerId),
    },
  });
  const estimates = estimatesQuery.data?.list ?? [];

  return (
    <>
      <EstimateTableBuilder.Table
        data={estimates}
        isLoading={estimatesQuery.isLoading || estimatesQuery.isFetching}
        isError={estimatesQuery.isError}
        isEmpty={estimatesQuery.isSuccess && estimates.length === 0}
        // emptyMessage={
        //   estimatesQuery.isError ? (
        //     <div className="px-4 py-5 sm:p-6">
        //       <h3 className="text-lg font-medium leading-6 text-gray-900">
        //         Failed to get estimates
        //       </h3>
        //       <div className="mt-2 sm:flex sm:items-start sm:justify-between">
        //         <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
        //           <Button size="compact" onClick={estimatesQuery.refetch}>
        //             Try Again.
        //           </Button>
        //         </div>
        //       </div>
        //     </div>
        //   ) : (
        //     <div className="text-center">
        //       <Users className="mx-auto h-12 w-12 text-gray-400" />
        //       <h3 className="mt-2 text-sm font-medium text-gray-900">
        //         No estimates
        //       </h3>

        //       <p className="mt-1 text-sm text-gray-500">
        //         Get started by creating a work request.
        //       </p>
        //       <div className="mt-6">
        //         <LinkButton
        //           to="/work-requests/new"
        //           type="button"
        //           size="compact"
        //           startEnhancer={<Plus />}
        //         >
        //           New Work Request
        //         </LinkButton>
        //       </div>
        //     </div>
        //   )
        // }
      >
        <EstimateTableBuilder.Column header={t('WR#')}>
          {(row) => (
            <Link
              to={`/estimates/${row.id}/edit`}
              className="underline hover:text-brand-primary"
              title="View estimate details"
            >
              <span aria-hidden className="absolute inset-0" />
              {row.num}
            </Link>
          )}
        </EstimateTableBuilder.Column>
        {/* <EstimateTableBuilder.Column header={t('Customer')}>
          {(row) => row.custName}
        </EstimateTableBuilder.Column> */}
        <EstimateTableBuilder.Column header={t('Work Type')}>
          {(row) => row.workType}
        </EstimateTableBuilder.Column>
        <EstimateTableBuilder.Column header={t('Schedule Date')}>
          {(row) =>
            row.estimateScheduleDate
              ? format(
                  new Date(`${row.estimateScheduleDate}T00:00:00`),
                  'MMM dd, yyyy',
                )
              : '-'
          }
        </EstimateTableBuilder.Column>

        <EstimateTableBuilder.Column header={t('Assigned')}>
          {(row) =>
            row.jobAssignedTo.length
              ? row.jobAssignedTo.map((item) => item.name).join(',')
              : '-'
          }
        </EstimateTableBuilder.Column>
        <EstimateTableBuilder.Column header={t('Progress')}>
          {(row) => <StatusBadge status={row.progress} />}
        </EstimateTableBuilder.Column>
        <EstimateTableBuilder.Column header={t('Amount')}>
          {/* FIXME: Request addition of `estimateTotal` property */}
          {(row) => priceFormatter().format(0)}
        </EstimateTableBuilder.Column>
      </EstimateTableBuilder.Table>
      <div className="mt-4 flex justify-end">
        <Pagination
          totalItems={estimatesQuery.data?.totalElements}
          pageSize={20}
          currentPage={page}
          onChange={({ page }) => setPage(page)}
        />
      </div>
    </>
  );
}
function JobsTable() {
  const { t } = useLocale();
  const params = useParams();
  const [page, setPage] = useState(1);
  const jobsQuery = useJobsQuery({
    params: {
      limit: 20,
      page: page,
      customerId: Number(params.customerId),
    },
  });
  const jobs = jobsQuery.data?.list ?? [];
  return (
    <>
      <JobHistoryBuilder.Table
        data={jobs}
        isLoading={jobsQuery.isLoading || jobsQuery.isFetching}
        isError={jobsQuery.isError}
        isEmpty={jobsQuery.isSuccess && jobs.length === 0}
      >
        <JobHistoryBuilder.Column header={t('WR#')}>
          {(row) => (
            <Link
              to={`/jobs/${row.id}/edit`}
              className="underline hover:text-brand-primary"
              title="View job details"
            >
              <span aria-hidden className="absolute inset-0" />
              {row.num}
            </Link>
          )}
        </JobHistoryBuilder.Column>
        {/* <JobHistoryBuilder.Column header={t('Customer')}>
          {(row) => row.custName}
        </JobHistoryBuilder.Column> */}
        <JobHistoryBuilder.Column header={t('Work Type')}>
          {(row) => row.workType}
        </JobHistoryBuilder.Column>
        <JobHistoryBuilder.Column header={t('Schedule Type')}>
          {(row) => (row.recurring ? 'Recurring' : 'Non-recurring')}
        </JobHistoryBuilder.Column>
        <JobHistoryBuilder.Column header={t('Progress')}>
          {(row) => <StatusBadge status={row.progress} />}
        </JobHistoryBuilder.Column>
        <JobHistoryBuilder.Column header={t('Assigned')}>
          {(row) =>
            row.jobAssignedTo.length
              ? row.jobAssignedTo.map((item) => item.name).join(',')
              : '-'
          }
        </JobHistoryBuilder.Column>
      </JobHistoryBuilder.Table>
      <div className="mt-4 flex justify-end">
        <Pagination
          totalItems={jobsQuery.data?.totalElements}
          pageSize={20}
          currentPage={page}
          onChange={({ page }) => setPage(page)}
        />
      </div>
    </>
  );
}
function InvoicesTable() {
  const { t } = useLocale();
  const params = useParams();
  const [page, setPage] = useState(1);
  const invoicesQuery = useInvoicesQuery({
    params: {
      limit: 20,
      page: page,
      customerId: Number(params.customerId),
    },
  });
  const invoices = invoicesQuery.data?.list ?? [];

  return (
    <>
      <BillingHistoryBuilder.Table
        data={invoices}
        isLoading={invoicesQuery.isLoading || invoicesQuery.isFetching}
        isEmpty={invoices.length === 0}
        isError={invoicesQuery.isError}
      >
        <BillingHistoryBuilder.Column header={t('WR#')}>
          {(row) => (
            <Link
              to={`/invoice/${row.id}/edit`}
              className="underline hover:text-brand-primary"
              title="View invoice details"
            >
              <span aria-hidden className="absolute inset-0" />
              {row.num}
            </Link>
          )}
        </BillingHistoryBuilder.Column>
        {/* <BillingHistoryBuilder.Column header={t('Customer')}>
          {(row) => row.custName}
        </BillingHistoryBuilder.Column> */}
        <BillingHistoryBuilder.Column header={t('Work Type')}>
          {(row) => row.workType}
        </BillingHistoryBuilder.Column>
        <BillingHistoryBuilder.Column header={t('Date Sent')}>
          {(row) =>
            row.invoiceSent ? formatDate(new Date(row.invoiceSent)) : '-'
          }
        </BillingHistoryBuilder.Column>
        <BillingHistoryBuilder.Column header={t('Progress')}>
          {(row) => <StatusBadge status={row.progress} />}
        </BillingHistoryBuilder.Column>
        <BillingHistoryBuilder.Column header={t('Amount')}>
          {(row) => priceFormatter().format(row.invoiceTotal)}
        </BillingHistoryBuilder.Column>
      </BillingHistoryBuilder.Table>
      <div className="mt-4 flex justify-end">
        <Pagination
          totalItems={invoicesQuery.data?.totalElements}
          pageSize={20}
          currentPage={page}
          onChange={({ page }) => setPage(page)}
        />
      </div>
    </>
  );
}
function CustomerInformation() {
  const { t } = useLocale();
  const params = useParams();
  const query = useCustomerQuery(Number(params.customerId));
  return (
    <QueryResolver query={query}>
      {(customer) => (
        <Card className="flex-1">
          <CardHeader>
            <div className="flex items-center justify-between">
              <CardTitle className="text-xl">{t('Customer Details')}</CardTitle>
              <nav>
                <LinkButton
                  to="edit"
                  title="Edit Customer"
                  kind="secondary"
                  size="mini"
                  shape="square"
                >
                  <Edit size={16} />
                </LinkButton>
              </nav>
            </div>
          </CardHeader>
          <CardContent className="mt-4 flex flex-col space-y-6 xl:flex-row xl:space-x-12 xl:space-y-0">
            <div className="space-y-3">
              <span className="text-3xl font-semibold text-brand-primary">
                #{customer.id}
              </span>
              <div>
                <div className="text-2xl font-semibold text-gray-500">
                  {customer.firstName} {customer.lastName}
                </div>
                {customer.businessName && (
                  <div className="text-base text-gray-500">
                    {customer.businessName}
                  </div>
                )}
              </div>
            </div>
            <dl className="flex flex-1 flex-col space-y-0 xl:flex-row xl:space-x-12">
              <div className="flex flex-1 items-start space-x-7">
                <dt className="mt-1 text-gray-500" title="Address">
                  <MapPinIcon size={16} />
                </dt>
                <dd className="text-sm text-gray-500">
                  {customer.address.street}, {customer.address.street2},
                  {customer.address.city}, {customer.address.state},
                  {customer.address.country}
                </dd>
              </div>
              <div className="flex-1 space-y-4 xl:space-y-2 ">
                <div className="flex items-start space-x-7">
                  <dt className="mt-1 text-gray-500" title="Contact Number">
                    <Phone size={16} />
                  </dt>
                  <dd className="text-sm text-gray-500">{customer.phone}</dd>
                </div>
                <div className="flex items-start space-x-7">
                  <dt className="mt-1 text-gray-500" title="Email Address">
                    <Mail size={16} />
                  </dt>
                  <dd className="text-sm text-gray-500">{customer.email}</dd>
                </div>
              </div>
            </dl>
          </CardContent>
        </Card>
      )}
    </QueryResolver>
  );
}

function CustomerNotes() {
  const params = useParams();
  const query = useCustomerQuery(Number(params.customerId));
  const { t } = useLocale();

  return (
    <QueryResolver query={query}>
      {(customer) => (
        <div className="space-y-4">
          <p>
            {customer.notes ? customer.notes : t('customer_no_notes')}
            {/* There are no notes about this customer. */}
          </p>
          <LinkButton to="new-note" startEnhancer={<Plus />} size="compact">
            {t('Add Notes')}
          </LinkButton>
        </div>
      )}
    </QueryResolver>
  );
}

function OverdueBalance() {
  const { t } = useLocale();
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.set('tab', 'invoices');

  const params = useParams();
  const customerId = Number(params.customerId ?? '0');
  const query = useInvoicesQuery({
    params: {
      customerId,
      limit: 2000,
      workRequestStatus: 'InvoiceSent',
    },
  });
  const total = query.isSuccess
    ? query.data.list.reduce(
        (total, current) => total + current.invoiceTotal,
        0,
      )
    : 0;

  if (query.isError) {
    return (
      <MetricCard
        icon={<AlertCircle />}
        value="Error"
        description={t('metric_data_error')}
        preset="red"
        action={
          <Button kind="tertiary" size="compact">
            {t('Try Again')}
          </Button>
        }
      />
    );
  }

  return (
    <MetricCard
      icon={<DollarSign />}
      preset="yellow"
      value={priceFormatter().format(total)}
      description={t('due from this customer')}
      action={({ accentColor }) => (
        <Link
          to={{
            pathname: '.',
            search: searchParams.toString(),
          }}
          className={`text-[color:${accentColor}] text-base font-medium`}
        >
          {t('Check')}
        </Link>
      )}
      isLoading={query.isLoading}
    />
  );
}
