import * as z from 'zod';

import { Address, rawAddressSchema as addressSchema } from '../types.shared';
const noteTypeSchema = z.nativeEnum({
  JOB: 'JOB',
  ESTIMATE: 'ESTIMATE',
  INVOICE: 'INVOICE',
} as const);
export type NoteType = z.infer<typeof noteTypeSchema>;

export const serviceLocationNoteSchema = z.object({
  id: z.number(),
  noteType: noteTypeSchema,
  noteValue: z.string(),
  servLocId: z.number(),
  workRequestId: z.number(),
  createdBy: z.number(),
  createdByName: z.string(),
  modifiedBy: z.number(),
  modifiedByName: z.string(),
  workRequestNumber: z.number(),
  dateCreated: z.coerce.string().datetime(),
  dateModified: z.coerce.string().datetime(),
});
export type ServiceLocationNote = z.infer<typeof serviceLocationNoteSchema>;

export const serviceLocationSchema = z.object({
  id: z.number(),
  customerId: z.number(),
  address: addressSchema,
  contact: z.string(),
  phone: z.string(),
  notes: z.string().nullable(),
  photoId: z.string().array().nullable(),
});

export type ServiceLocation = {
  id: number;
  customerId: number;
  address: Address;
  contact: string;
  phone: string;
  notes: string | null;
  photos: string | null;
};
