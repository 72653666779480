import { Job } from './types';

import { baseAPI, toPaginatedList } from '../base-api';
import { PaginatedParams, PaginatedResponse } from '../types.shared';
import { WorkRequestStatus } from '../work-requests/types';

const PAGINATION_DEFAULT_VALUE: PaginatedParams = { limit: 10, page: 1 };

export type JobsQueryParams = Partial<
  {
    search: string;
    dateFrom: string;
    dateTo: string;
    customerId: number;
    workRequestStatus: WorkRequestStatus;
  } & PaginatedParams
>;

const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    jobs: builder.query<
      PaginatedResponse<Job>,
      {
        params?: JobsQueryParams;
      } | void
    >({
      query: (args) => {
        const { params = {} } = args ?? {};

        const parsedParams = Object.entries(params)
          .filter(([key, value]) => Boolean(value))
          .reduce(
            (all, [key, value]) => ({ ...all, [key]: `${value}` }),
            {} as Record<string, string>,
          );

        return {
          url: `portal/jobs`,
          params: { ...PAGINATION_DEFAULT_VALUE, ...parsedParams },
        };
      },
      transformResponse(baseQueryReturnValue, meta, arg) {
        return toPaginatedList(baseQueryReturnValue);
      },
      providesTags: (response) =>
        response ? ['Jobs', { type: 'Jobs', page: response.pageNumber }] : [],
    }),
  }),
  overrideExisting: false,
});

export const { useJobsQuery } = endpoints;
