/* eslint-disable react/prop-types */
import * as React from 'react';
import { tv, VariantProps } from 'tailwind-variants';

import { cn } from 'utils/helpers';

const alertVariants = tv({
  base: 'relative w-full rounded-lg border px-4 py-3 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-3  [&>svg~*]:pl-7',
  variants: {
    intent: {
      default:
        'border-muted bg-background text-foreground [&>svg]:text-muted-foreground',
      negative: 'border-red-50 bg-red-50 text-red-800 [&>svg]:text-red-400',
      positive:
        'border-emerald-50 bg-emerald-50 text-emerald-800 [&>svg]:text-emerald-400',
      warning:
        'border-yellow-50 bg-yellow-50 text-yellow-800 [&>svg]:text-yellow-400',
    },
  },
  defaultVariants: {
    intent: 'default',
  },
});

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, intent, ...props }, ref) => (
  <div
    role="alert"
    data-intent={intent}
    className={cn(alertVariants({ intent }), className)}
    {...props}
    ref={ref}
  />
));
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h5
    ref={ref}
    className={cn(
      'font-semibold leading-none tracking-tight text-sm ',
      className,
    )}
    {...props}
  />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('mt-2 font-sans text-sm ', className)}
    {...props}
  />
));
AlertDescription.displayName = 'AlertDescription';

const AlertFooter = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('pt-4', className)} {...props} />
));
AlertFooter.displayName = 'AlertFooter';

export { Alert, AlertTitle, AlertDescription, AlertFooter };
