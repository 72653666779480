import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'baseui/snackbar';
import { Save } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { MutationBanner } from 'components/Banner';
import { FormController } from 'components/FormController';
import { Input } from 'components/Input';
import { QueryResolver } from 'components/QueryResolver';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from 'components/ui/dialog';
import { getErrorMessage, isMutationSuccess } from 'services/api/base-api';
import {
  useEditEmployeeMutation,
  useEmployeeQuery,
} from 'services/api/employees/endpoints';

const nonEmptyString = z.string().trim().min(1, { message: 'Required' });
const schema = z.object({
  firstname: nonEmptyString,
  lastname: nonEmptyString,
  email: z.string().email(),
});
type FormFields = z.infer<typeof schema>;

export default function ResellerEditEmployee() {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const [editEmployee, mutation] = useEditEmployeeMutation();
  const id = Number(useParams().employeeId ?? '0');
  const query = useEmployeeQuery(id, { skip: id === 0 });

  const close = () => {
    if (mutation.isLoading) return;
    navigate('..', { replace: true });
  };

  return (
    <Dialog onOpenChange={(open) => !open && close()} isOpen isDismissable>
      <DialogTitle>Edit Employee</DialogTitle>
      <DialogBody>
        <MutationBanner
          show={mutation.isError}
          message={mutation.error ? getErrorMessage(mutation.error) : ''}
        />
        <QueryResolver query={query}>
          {(employee) => (
            <EmployeeForm
              initialValues={{
                email: employee.email,
                firstname: employee.firstname,
                lastname: employee.lastname,
              }}
              onSubmit={async (data) => {
                const result = await editEmployee({
                  ...employee,
                  ...data,
                });
                if (isMutationSuccess(result)) {
                  snackbar.enqueue({ message: 'Employee Updated' });
                  close();
                }
              }}
            />
          )}
        </QueryResolver>
      </DialogBody>
      <DialogActions>
        <Button variant="plain" onClick={close}>
          Cancel
        </Button>
        <Button
          startEnhancer={<Save className="h-full w-full" />}
          type="submit"
          form="kaleidoscope-edit-employee"
          isLoading={mutation.isLoading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function EmployeeForm({
  initialValues,
  onSubmit: onSubmitHandler,
}: {
  initialValues: Partial<FormFields>;
  onSubmit: (data: FormFields) => PromiseLike<void>;
}) {
  const { control, handleSubmit } = useForm<FormFields>({
    defaultValues: initialValues,
    resolver: zodResolver(schema),
  });
  const onSubmit = handleSubmit(onSubmitHandler);

  return (
    <form
      onSubmit={onSubmit}
      className="grid grid-cols-2 gap-x-4"
      id="kaleidoscope-edit-employee"
    >
      <FormController control={control} name="firstname" label="First Name">
        {({ field }) => <Input {...field} />}
      </FormController>
      <FormController control={control} name="lastname" label="Last Name">
        {({ field }) => <Input {...field} />}
      </FormController>
      <FormController control={control} name="email" label="Email Address">
        {({ field }) => <Input {...field} />}
      </FormController>
    </form>
  );
}
