import { z } from 'zod';

const hexPalleteValuesSchema = z.nativeEnum({
  '50': '50',
  '100': '100',
  '200': '200',
  '300': '300',
  '400': '400',
  '500': '500',
  '600': '600',
  '700': '700',
  '800': '800',
  '900': '900',
  '950': '950',
} as const);
export const hexColorPalleteSchema = z.record(
  hexPalleteValuesSchema,
  z.string(),
);
export type HexColorPallete = z.infer<typeof hexColorPalleteSchema>;

export const THEME_COLORS = {
  Blue: 'Blue',
  Tangerine: 'Tangerine',
  Green: 'Green',
  Purple: 'Purple',
  Red: 'Red',
  Gold: 'Gold',
} as const;

export const themeColorsSchema = z.nativeEnum(THEME_COLORS);
export type PredefinedTheme = z.infer<typeof themeColorsSchema>;

export const CSS_VAR_KEYS = {
  '--brand-primary-50': '--brand-primary-50',
  '--brand-primary-100': '--brand-primary-100',
  '--brand-primary-200': '--brand-primary-200',
  '--brand-primary-300': '--brand-primary-300',
  '--brand-primary-400': '--brand-primary-400',
  '--brand-primary-500': '--brand-primary-500',
  '--brand-primary-600': '--brand-primary-600',
  '--brand-primary-700': '--brand-primary-700',
  // '--brand-primary-800': '--brand-primary-800',
  // '--brand-primary-900': '--brand-primary-900',
} as const;

export const THEME_VALUES: Record<
  keyof typeof THEME_COLORS,
  Record<keyof typeof CSS_VAR_KEYS, string>
> = {
  Blue: {
    '--brand-primary-50': '#EBEBFF',
    '--brand-primary-100': '#D2D2FE',
    '--brand-primary-200': '#A6A4FE',
    '--brand-primary-300': '#7E7CFD',
    '--brand-primary-400': '#524FFD',
    '--brand-primary-500': '#2522FC',
    '--brand-primary-600': '#0703E2',
    '--brand-primary-700': '#0502AB',
  },
  Gold: {
    '--brand-primary-50': '#FFF8E5',
    '--brand-primary-100': '#FFEFC7',
    '--brand-primary-200': '#FFE194',
    '--brand-primary-300': '#FFD15C',
    '--brand-primary-400': '#FFC124',
    '--brand-primary-500': '#EEAB00',
    '--brand-primary-600': '#BD8700',
    '--brand-primary-700': '#8F6600',
    // '--brand-primary-800': '#614500',
    // '--brand-primary-900': '#2E2100',
  },
  Green: {
    '--brand-primary-50': '#DBFFE5',
    '--brand-primary-100': '#BDFFCF',
    '--brand-primary-200': '#7AFFA0',
    '--brand-primary-300': '#38FF70',
    '--brand-primary-400': '#00F545',
    '--brand-primary-500': '#00B232',
    '--brand-primary-600': '#008F28',
    '--brand-primary-700': '#006B1E',
    // '--brand-primary-800': '#004714',
    // '--brand-primary-900': '#00240A',
  },
  Purple: {
    '--brand-primary-50': '#EDE5FA',
    '--brand-primary-100': '#DBCBF6',
    '--brand-primary-200': '#B897ED',
    '--brand-primary-300': '#9463E4',
    '--brand-primary-400': '#702EDB',
    '--brand-primary-500': '#541EAD',
    '--brand-primary-600': '#44188B',
    '--brand-primary-700': '#331268',
    // '--brand-primary-800': '#220C46',
    // '--brand-primary-900': '#110623',
  },
  Red: {
    '--brand-primary-50': '#FFEBEB',
    '--brand-primary-100': '#FFD6D6',
    '--brand-primary-200': '#FFADAD',
    '--brand-primary-300': '#FF8585',
    '--brand-primary-400': '#FF5C5C',
    '--brand-primary-500': '#FF3030',
    '--brand-primary-600': '#F50000',
    '--brand-primary-700': '#B80000',
    // "--brand-primary-800": "#7A0000",
    // "--brand-primary-900": "#3D0000"
  },
  Tangerine: {
    '--brand-primary-50': '#FFF2EB',
    '--brand-primary-100': '#FEE5D7',
    '--brand-primary-200': '#FDCAAF',
    '--brand-primary-300': '#FDB38C',
    '--brand-primary-400': '#FC9964',
    '--brand-primary-500': '#FB813D',
    '--brand-primary-600': '#F55905',
    '--brand-primary-700': '#B94304',
    // '--brand-primary-800': '#782C02',
    // '--brand-primary-900': '#3C1601',
  },
};
