import { zodResolver } from '@hookform/resolvers/zod';
import { StyledAction, StyledBody, StyledTitle } from 'baseui/card';
import { Skeleton } from 'baseui/skeleton';
import { useSnackbar } from 'baseui/snackbar';
import clsx from 'clsx';
import { millisecondsToMinutes } from 'date-fns';
import parseDuration from 'parse-duration';
import prettyMilliseconds from 'pretty-ms';
import { useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  Dialog,
  Button as ModalButton,
} from 'react-aria-components';
import {
  DollarSign as DollarSignIcon,
  Edit as EditIcon,
  Plus as PlusIcon,
  Search,
  Check,
  X,
  Plus,
  Slash,
} from 'react-feather';
import { useForm } from 'react-hook-form';
import { Outlet, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { MutationBanner } from 'components/Banner';
import { FieldGroup } from 'components/forms/fieldset';
import { Input as SearchInput } from 'components/Input';
import { Pagination } from 'components/Pagination';
import { createTableBuilder } from 'components/Table';
import { Button } from 'components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/ui/card';
import { Input, TextField } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Select, SelectField, SelectItem } from 'components/ui/select';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { isMutationSuccess } from 'services/api/base-api';
import {
  useSaveWorkTypeMutation,
  useWorkTypesQuery,
} from 'services/api/work-type/endpoints';
import { WorkType } from 'services/api/work-type/types';
import { priceFormatter } from 'utils/helpers';
import useDebounce from 'utils/hooks/useDebounce';
import { useLocale } from 'utils/hooks/useLocale';

const useValidatedSearchParams = <Schema extends z.ZodTypeAny>(
  schema: Schema,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return {
    searchParams,
    setSearchParams,
    values: schema.parse(Object.fromEntries(searchParams.entries())) as z.infer<
      typeof schema
    >,
  };
};

export default function PricebookIndex() {
  const { t } = useLocale();

  return (
    <>
      <Outlet />
      <div className="flex-1 space-y-5">
        <DashboardContentHeader
          title={t('Pricebook')}
          description={t('Manage your Pricebook')}
        />
        <section className="flex items-start space-x-4">
          <div className="w-full">
            <PricebookTable />
          </div>
        </section>
      </div>
    </>
  );
}

const nonEmptyString = z
  .string()
  .trim()
  .min(1, { message: 'errors.invalid_type_received_undefined' });

const schema = z
  .object({
    name: nonEmptyString,
    duration: z.string(),
    price: z.coerce
      .number({
        invalid_type_error: 'price_required',
        required_error: 'price_required',
      })
      .min(1, { message: 'errors.invalid_type_received_undefined' }),
    type: z.nativeEnum(
      {
        SERVICE: 'SERVICE',
        INVENTORY: 'INVENTORY',
      } as const,
      {
        invalid_type_error: 'errors.invalid_type_received_undefined',
      },
    ),
    description: z
      .string()
      .trim()
      .max(255, { message: 'text_too_long' })
      .optional()
      .default(''),
    sku: z
      .string()
      .trim()
      .max(255, { message: 'text_too_long' })
      .optional()
      .default(''),
    cost: z.coerce.number(),
    isTaxable: z.nativeEnum(
      {
        true: 'true',
        false: 'false',
      } as const,
      {
        invalid_type_error: 'errors.invalid_type_received_undefined',
      },
    ),
    quantityOnHand: z.coerce.number(),
  })
  .superRefine((data, ctx) => {
    if (data.type === 'SERVICE') {
      if (!data.duration || data.duration.trim().length === 0) {
        ctx.addIssue({
          path: ['duration'],
          code: z.ZodIssueCode.custom,
          params: { i18n: 'invalid_duration' },
        });
      } else {
        const parsedValue = parseDuration(data.duration);
        if (parsedValue === null) {
          ctx.addIssue({
            path: ['duration'],
            code: z.ZodIssueCode.custom,
            params: { i18n: 'invalid_duration' },
          });
        } else if (millisecondsToMinutes(parsedValue) < 15) {
          ctx.addIssue({
            path: ['duration'],
            code: z.ZodIssueCode.custom,
            params: { i18n: 'invalid_duration' },
          });
        }
      }
    } else if (
      data.type === 'INVENTORY' &&
      data.duration &&
      data.duration.trim().length > 0
    ) {
      const parsedValue = parseDuration(data.duration);
      if (parsedValue === null) {
        ctx.addIssue({
          path: ['duration'],
          code: z.ZodIssueCode.custom,
          params: { i18n: 'invalid_duration' },
        });
      }
    }
  });

type FormFields = z.infer<typeof schema>;

function NewServiceForm({ onSuccess }: { onSuccess: VoidFunction }) {
  const { t } = useLocale();
  const snackbar = useSnackbar();
  const { control, handleSubmit, watch, reset } = useForm<FormFields>({
    defaultValues: {
      duration: '',
      name: '',
    },
    resolver: zodResolver(schema),
  });
  const [createWorkType, mutation] = useSaveWorkTypeMutation();
  const type = watch('type');
  const onSubmit = handleSubmit(async (data) => {
    const result = await createWorkType({
      type: 'create',
      payload: {
        ...data,
        estimatedDuration: parseDuration(data.duration ?? '', 's'),
      },
    });
    if (isMutationSuccess(result)) {
      const message =
        type === 'INVENTORY'
          ? t('pricebook_inventory_created_successfully')
          : t('pricebook_service_created_successfully');

      snackbar.enqueue({
        message: message,
      });

      if (onSuccess) {
        onSuccess();
      }
    }
  });

  useEffect(() => {
    if (type === 'INVENTORY') {
      reset((prevValues) => ({ ...prevValues, duration: '' }));
    }
  }, [type, reset]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('New Item')}</CardTitle>
        <CardDescription>{t('pricebook_form_description')}</CardDescription>
      </CardHeader>
      <CardContent>
        <MutationBanner
          show={mutation.isError}
          message={t('pricebook_service_created_error')}
        />
        <form onSubmit={onSubmit} id="create-pricebook-service-form">
          <FieldGroup>
            <div className="grid gap-x-4 gap-y-5 lg:grid-cols-3">
              <TextField control={control} field="name">
                <Label>{t('Item Name')}</Label>
                <Input placeholder="Cable Repair" />
              </TextField>
              <TextField control={control} field="description">
                <Label>{t('Item Description')}</Label>
                <Input />
              </TextField>
              <TextField control={control} field="sku">
                <Label>SKU</Label>
                <Input />
              </TextField>
            </div>
            <div className="grid gap-x-4 gap-y-5 lg:grid-cols-2">
              <SelectField control={control} field="type">
                <Label>{t('Item Type')}</Label>
                <Select clearable>
                  <SelectItem id="INVENTORY">{t('INVENTORY')}</SelectItem>
                  <SelectItem id="SERVICE">{t('SERVICE')}</SelectItem>
                </Select>
              </SelectField>
              <SelectField control={control} field="isTaxable">
                <Label>{t('Taxable')}</Label>
                <Select clearable>
                  <SelectItem id="true">{t('Yes')}</SelectItem>
                  <SelectItem id="false">{t('No')}</SelectItem>
                </Select>
              </SelectField>
            </div>
            <div className="grid gap-x-4 gap-y-5 lg:grid-cols-4">
              <TextField control={control} field="duration">
                <Label>{t('Estimated Time')}</Label>
                <Input placeholder="2h" />
              </TextField>
              <TextField control={control} field="price">
                <Label>{t('Sales Price')}</Label>
                <Input startEnhancer={<DollarSignIcon data-slot="icon" />} />
              </TextField>
              <TextField control={control} field="cost">
                <Label>{t('Cost')}</Label>
                <Input startEnhancer={<DollarSignIcon data-slot="icon" />} />
              </TextField>
              <TextField control={control} field="quantityOnHand">
                <Label>{t('Quantity On Hand')}</Label>
                <Input />
              </TextField>
            </div>
          </FieldGroup>
        </form>
      </CardContent>
      <CardFooter>
        <Button
          size="lg"
          type="submit"
          form="create-pricebook-service-form"
          className="w-full"
          startEnhancer={<PlusIcon />}
          isLoading={mutation.isLoading}
        >
          {t('Add New Item')}
        </Button>
      </CardFooter>
    </Card>
  );
}

const { Table, Column } = createTableBuilder<WorkType>();

const pricebookPageSearchParamsSchema = z.object({
  search: z.string().trim().optional().catch(''),
  page: z.coerce.number().optional(),
});

function PricebookTable() {
  const { t } = useLocale();
  const { searchParams, values: validatedSearchParams } =
    useValidatedSearchParams(pricebookPageSearchParamsSchema);
  const { page, search } = validatedSearchParams;

  const [searchQuery, setSearchQuery] = useState(search);
  const debouncedSearch = useDebounce(searchQuery, 300);
  const [isOpen, setIsOpen] = useState(false);

  const query = useWorkTypesQuery(
    {
      params: {
        limit: 25,
        search: debouncedSearch,
        page,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const data = useMemo(() => {
    const workTypes = query.data?.list ?? [];
    const searchTerm = debouncedSearch?.toLowerCase() ?? '';

    return workTypes.filter((workType) => {
      const nameMatch = workType.name.toLowerCase().includes(searchTerm);
      const skuMatch =
        workType.sku?.toLowerCase().includes(searchTerm) ?? false;
      return nameMatch || skuMatch;
    });
  }, [query.data, debouncedSearch]);

  return (
    <Card>
      <CardHeader>
        <StyledTitle $as="div">
          <div className="gap-4 sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <CardTitle>{t('Pricebook')}</CardTitle>
            </div>
            <div className="flex items-center space-x-4">
              <nav className="flex items-center">
                <Button
                  startEnhancer={<Plus />}
                  className="shrink-0"
                  onClick={() => setIsOpen(true)}
                >
                  {t('Add New Item')}
                </Button>
              </nav>
              <ModalOverlay
                className="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-black/50 py-3 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0"
                isOpen={isOpen}
                onOpenChange={setIsOpen}
              >
                <Modal className="relative z-50 grid w-full max-w-5xl gap-4 rounded-2xl border bg-white p-6 shadow-lg duration-200 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0 data-[entering]:zoom-in-95 data-[exiting]:zoom-out-95 md:w-full">
                  <Dialog className="outline-none">
                    {(props) => (
                      <>
                        <CloseButton {...props} />
                        <NewServiceForm onSuccess={() => setIsOpen(false)} />
                      </>
                    )}
                  </Dialog>
                </Modal>
              </ModalOverlay>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <SearchInput
                  placeholder={t('Search')}
                  endEnhancer={<Search size={16} />}
                  onChange={(e) => {
                    searchParams.delete('page');
                    setSearchQuery(e.target.value);
                  }}
                  onClear={() => searchParams.delete('page')}
                  value={searchQuery}
                  clearable
                />
              </form>
            </div>
          </div>
        </StyledTitle>
      </CardHeader>
      <StyledBody>
        <Table
          data={data}
          isLoading={query.isLoading || query.isFetching}
          loadingMessage={<Skeleton rows={4} height="100px" />}
          isError={query.isError}
          isEmpty={query.isSuccess && data.length === 0}
        >
          <Column header={t('Item Name')}>{(row) => row.name}</Column>
          <Column header={t('Type')}>{(row) => row.type}</Column>
          <Column header={t('Description')}>{(row) => row.description}</Column>
          <Column header={'SKU'}>
            {(row) => (row.sku === null ? '---' : row.sku)}
          </Column>
          <Column header={t('Estimated Time')}>
            {(row) =>
              row.estimatedDuration
                ? prettyMilliseconds(
                    parseDuration(`${row.estimatedDuration}`),
                    {
                      verbose: true,
                      keepDecimalsOnWholeSeconds: true,
                    },
                  )
                : '---'
            }
          </Column>
          <Column header={t('Sales Price')}>
            {(row) => `${priceFormatter().format(row.price)}`}
          </Column>
          <Column header={t('Cost')}>
            {(row) => `${priceFormatter().format(row.cost)}`}
          </Column>
          <Column header={t('Taxable')}>
            {(row) => (
              <div className="flex items-center justify-center">
                {row.isTaxable ? (
                  <Check className="text-green-600" />
                ) : (
                  <X className="text-red-600" />
                )}
              </div>
            )}
          </Column>
          <Column header={t('Quantity On Hand')}>
            {(row) => (
              <div className="flex items-center justify-center">
                {row.quantityOnHand === null ? '---' : row.quantityOnHand}
              </div>
            )}
          </Column>
          <Column header={t('Action')}>
            {(row) => {
              const isQuickBooksLinked = row.quickbooksId !== null;
              const isItemInventory = row.type === 'INVENTORY';

              return (
                <>
                  {isItemInventory && isQuickBooksLinked ? (
                    <Button variant="plain" isDisabled>
                      <Slash
                        className=" text-red-600"
                        height={16}
                        width={16}
                        strokeWidth={4}
                      />
                    </Button>
                  ) : (
                    <Button href={`${row.id}/edit`} variant="plain">
                      {isQuickBooksLinked ? (
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 66 60"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-green-500"
                          fill="currentColor"
                          aria-label="QuickBooks Icon"
                        >
                          <path d="M.5 30c0 9.665 7.835 17.5 17.5 17.5h2.5V41H18c-6.075 0-11-4.925-11-11s4.925-11 11-11h6v34c0 1.724.685 3.377 1.904 4.596 1.22 1.22 2.872 1.904 4.596 1.904v-47H18C8.335 12.5.5 20.335.5 30zM48 12.5h-2.5V19H48c6.075 0 11 4.925 11 11s-4.925 11-11 11h-6V7C42 3.41 39.09.5 35.5.5v47H48c6.252 0 12.03-3.335 15.155-8.75 3.127-5.415 3.127-12.085 0-17.5C60.03 15.835 54.252 12.5 48 12.5z" />
                        </svg>
                      ) : (
                        <EditIcon data-slot="icon" />
                      )}
                    </Button>
                  )}
                </>
              );
            }}
          </Column>
        </Table>
      </StyledBody>
      <StyledAction className="flex justify-end">
        <Pagination totalItems={query.data?.totalElements} pageSize={25} />
      </StyledAction>
    </Card>
  );
}

function CloseButton(props: { close: VoidFunction }) {
  const { t } = useLocale();
  return (
    <ModalButton
      onPress={props.close}
      className="focus:ring-ring absolute right-10 top-10 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none data-[entering]:bg-accent data-[entering]:text-muted-foreground"
    >
      <X className="h-5 w-5" />
      <span className="sr-only">{t('Close')}</span>
    </ModalButton>
  );
}
