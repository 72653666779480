import React from 'react';
import type {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseControllerReturn,
} from 'react-hook-form';
import { useController } from 'react-hook-form';

import { FormControl } from '../FormControl';
import type { FormControlProps } from '../FormControl';

import { useLocale } from 'utils/hooks/useLocale';

export default function FormController<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  children,
  control,
  name,
  rules,
  defaultValue,
  shouldUnregister,
  ...formControlProps
}: UseControllerProps<T, TName> &
  Omit<FormControlProps, 'children'> & {
    children:
      | ((props: {
          field: ControllerRenderProps<T, TName>;
          fieldState: ControllerFieldState;
          formState: UseControllerReturn<T, TName>['formState'];
        }) => React.ReactElement)
      | React.ReactNode;
  }) {
  const { field, fieldState, formState } = useController<T, TName>({
    control,
    name,
    rules,
    defaultValue,
    shouldUnregister,
  });
  const { t, i18n } = useLocale();
  const errorMessage = fieldState.error?.message;
  const keyedMessage = errorMessage ? `${errorMessage}` : '';

  /**
   * The zod `message` may be passed a translation key (ex:errors.undefined) to handle custom
   * error message without `refine`.
   */
  const keyedMessageExist = keyedMessage
    ? i18n.exists(keyedMessage, {
        ns: 'zod',
      })
    : false;

  const translatedMessage = keyedMessageExist
    ? t(keyedMessage, { ns: 'zod' })
    : // This should already contain the translated message if no `keyed` message
    errorMessage
    ? errorMessage
    : t('default_validation_message', {
        ns: 'zod',
      });

  return (
    <FormControl
      error={errorMessage ? translatedMessage : undefined}
      htmlFor={field.name}
      {...formControlProps}
    >
      {typeof children === 'function'
        ? children({ field, fieldState, formState })
        : children}
    </FormControl>
  );
}
