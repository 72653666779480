import { Banner } from 'baseui/banner';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { useSnackbar } from 'baseui/snackbar';
import { Save } from 'react-feather';
import { useNavigate } from 'react-router';
import { z } from 'zod';

import { MutationBanner } from 'components/Banner';
import { Button } from 'components/Button';
import { FSAdminEmployeeForm } from 'features/employees';
import {
  getErrorMessage,
  isErrorWithMessage,
  isMutationSuccess,
} from 'services/api/base-api';
import { useCreateEmployeeMutation } from 'services/api/employees/endpoints';
import { useTypedSelector } from 'store/store';
import { useLocale } from 'utils/hooks/useLocale';

const isFile = (file: unknown) =>
  z
    .instanceof(FileList)
    .refine((arg) => arg.length !== 0, 'Not valid file.')
    .safeParse(file);

export default function NewEmployee() {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const clientId = useTypedSelector((s) => s.auth.userCredentials.clientId);
  const [createEmployee, mutation] = useCreateEmployeeMutation();
  const { t } = useLocale();
  const techMaxLimitReached =
    isErrorWithMessage(mutation.error) &&
    mutation.error.status === 400 &&
    mutation.error.data.message === 'Not enough technician licenses';

  return (
    <Modal
      isOpen
      onClose={() => navigate('..', { replace: true })}
      overrides={{
        Dialog: {
          props: {
            className: 'max-w-2xl w-full',
          },
        },
      }}
    >
      <ModalHeader className="text-xl font-semibold">
        {t('Add New Employee')}
      </ModalHeader>
      <ModalBody>
        <MutationBanner
          show={mutation.isError}
          message={
            mutation.error && techMaxLimitReached
              ? t('create_client_employee_limit_reached')
              : t('create_employee_error')
          }
        />

        <FSAdminEmployeeForm
          id="fs-admin-new-employee-form"
          onSubmit={async (data) => {
            const result = await createEmployee({
              ...data,
              isTech: data.isTechnician,
              type: 'FSEmployee',
              notes: '',
              clientId,
              //TODO: How to determine if `Coordinator` or `Employee`
              roles: data.isTechnician ? ['Mobile'] : ['Coordinator'],
              image: data.image.file?.[0],
            });
            if (isMutationSuccess(result)) {
              snackbar.enqueue({ message: t('employee_create_success') });
              navigate('..');
            }
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          startEnhancer={<Save />}
          className="w-full"
          type="submit"
          form="fs-admin-new-employee-form"
          isLoading={mutation.isLoading}
        >
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
