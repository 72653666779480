import React, { forwardRef, useContext } from 'react';
import { mergeProps } from 'react-aria';
import {
  Button as AriaButton,
  Select as AriaSelect,
  SelectProps as AriaSelectProps,
  ButtonContext,
  ListBox,
  ListBoxItemProps,
  SelectContext,
  SelectStateContext,
  SelectValue,
  ValidationResult,
  useContextProps,
} from 'react-aria-components';
import { ChevronDown, X as XIcon } from 'react-feather';
import { useController } from 'react-hook-form';
import { tv } from 'tailwind-variants';

import { ButtonProps, buttonStyles } from './button';
import { DropdownItem, DropdownSection, DropdownSectionProps } from './listbox';
import { Popover } from './popover';
import { withControlledField } from './utils';

import {
  ControlledFieldProps,
  FieldErrorMessage,
  fieldStyles,
} from 'components/forms/fieldset';
import { cn } from 'utils/helpers';
import { useLocale } from 'utils/hooks/useLocale';

const styles = tv({
  // extend: focusRing,
  base: 'group peer inline-flex w-full min-w-[150px] cursor-default items-center gap-4 border-black/10 bg-white py-1.5 py-2.5 pl-3.5 pr-2 text-start text-base text-gray-900 transition data-[focus-visible]:bg-gray-50 data-[focus-visible]:outline-none data-[focus-visible]:ring-0',
  variants: {
    isDisabled: {
      false:
        'pressed:bg-gray-200 text-gray-800 hover:bg-gray-50 group-invalid:border-red-600',
      true: 'text-gray-200',
    },
  },
});

export interface SelectProps<T extends object>
  extends Omit<AriaSelectProps<T>, 'children'> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  items?: Iterable<T>;
  children: React.ReactNode | ((item: T) => React.ReactNode);
  variant?: ButtonProps['variant'];
  clearable?: boolean;
}

const SelectTrigger = forwardRef<
  HTMLButtonElement,
  Pick<ButtonProps, 'variant' | 'slot'> & Pick<SelectProps<object>, 'clearable'>
>((props, ref) => {
  [props, ref] = useContextProps(
    { ...props, slot: props.slot ? props.slot : null },
    ref,
    ButtonContext,
  );
  const { variant, clearable = false } = props;

  return (
    <>
      <div className="relative" data-slot="control">
        {variant ? (
          <>
            <AriaButton
              className={cn([
                buttonStyles.base,
                buttonStyles[variant as keyof typeof buttonStyles],
                buttonStyles.sizing.lg,
                'w-full text-left',

                // Invalid
                'group-data-[invalid]:border-red-500 group-data-[invalid]:data-[hovered]:border-red-500',
              ])}
            >
              <SelectValue className="flex-1 truncate font-medium placeholder-shown:italic data-[placeholder]:text-zinc-500" />
              <ChevronDown
                aria-hidden
                data-slot="icon"
                className="pointer-events-none h-full w-full"
              />
            </AriaButton>
            {clearable && (
              <span className="absolute inset-y-0 right-0 flex -translate-x-9 items-center justify-center">
                <SelectClearButton />
              </span>
            )}
          </>
        ) : (
          <>
            <AriaButton className={styles}>
              <SelectValue className="flex-1 text-base placeholder-shown:italic" />
              <ChevronDown
                aria-hidden
                className="pointer-events-none h-4 w-4 text-gray-600 group-disabled:text-gray-200"
              />
            </AriaButton>
            {clearable && (
              <span className="absolute inset-y-0 right-0 flex items-center justify-center pr-9">
                <SelectClearButton />
              </span>
            )}
          </>
        )}
        {variant === undefined && (
          <div
            className={cn([
              // Base
              'absolute inset-x-0 bottom-0 border-t border-gray-300 peer-data-[focused]:border-t-2 peer-data-[focused]:border-brand-primary-600',

              // Invalid state
              'group-data-[invalid]:border-red-500 group-data-[invalid]:peer-data-[hovered]:border-red-500',

              // Disabled state
              'group-data-[disabled]:border-zinc-950/20',
            ])}
            aria-hidden="true"
          />
        )}
      </div>
    </>
  );
});
SelectTrigger.displayName = 'SelectTrigger';

function _SelectField<TSelect extends object>({
  control,
  field: fieldName,
  children,
  ...selectProps
}: SelectProps<TSelect> & {
  children: React.ReactNode;
} & ControlledFieldProps) {
  const controller = useController({ control, name: fieldName });
  const field = controller.field;
  const error = !!controller.fieldState.error?.message;

  // const descriptionId = useId();

  return (
    <SelectBase
      {...mergeProps(selectProps, field)}
      onSelectionChange={field.onChange}
      selectedKey={field.value}
      isInvalid={error}
    >
      {children}
      <FieldErrorMessage control={control} field={fieldName} />
    </SelectBase>
  );
}
export const SelectField = withControlledField(_SelectField);

function SelectClearButton() {
  const state = React.useContext(SelectStateContext);
  const selectedKey = !!state.selectedKey;
  if (!selectedKey) {
    return null;
  }
  return (
    <button
      onClick={() => state?.setSelectedKey(null)}
      className="text-zinc-500 hover:text-zinc-950"
      title="Clear Selection"
      type="button"
      data-slot="icon"
    >
      <XIcon aria-hidden strokeWidth={2.5} className="h-4 w-4" />
    </button>
  );
}

function _SelectBase<T extends object>(
  {
    label,
    description,
    errorMessage,
    children,
    items,
    ...props
  }: SelectProps<T>,
  ref: React.Ref<HTMLDivElement>,
) {
  const { t } = useLocale();
  return (
    <AriaSelect
      {...props}
      ref={ref}
      className={cn([props.className, fieldStyles(), 'group relative'])}
      placeholder={props.placeholder ?? t('Select')}
    >
      {typeof children !== 'function' && children}
    </AriaSelect>
  );
}
const SelectBase = forwardRef<HTMLDivElement, SelectProps<object>>(_SelectBase);

export function Select<T extends object>({
  items,
  children,
  variant,
  clearable,
  ...props
}: SelectProps<T>) {
  const context = !!useContext(SelectContext);
  const { t } = useLocale();
  // If we are using this inside a `SelectField` we should be inside a Select context so we do not render the `BaseSelect`
  if (context) {
    return (
      <SelectContext.Provider
        value={{
          ...props,
          placeholder: props.placeholder ?? t('Select'),
        }}
      >
        <SelectTrigger variant={variant} clearable={clearable} />
        <Popover className="min-w-[--trigger-width]" placement="bottom left">
          <ListBox
            items={items}
            className="h-full max-h-[20rem] overflow-auto py-1 outline-none"
          >
            {children}
          </ListBox>
        </Popover>
      </SelectContext.Provider>
    );
  }

  return (
    <SelectBase {...props}>
      <SelectTrigger variant={variant} clearable={clearable} />
      <Popover className="min-w-[--trigger-width]">
        <ListBox
          items={items}
          className="h-full max-h-[20rem] overflow-auto py-1 outline-none"
        >
          {children}
        </ListBox>
      </Popover>
    </SelectBase>
  );
}

const selectItemStyles = tv({
  base: [
    // Base styles
    'group cursor-default select-none px-3.5 py-2.5 focus:outline-none sm:px-3 sm:py-1.5',

    // Text styles
    'text-left text-base/6 text-zinc-950 dark:text-white sm:text-sm/6',

    // Focus
    'data-[focused]:bg-blue-500 data-[focused]:text-white',

    // Disabled state
    'data-[disabled]:opacity-50',

    // Forced colors mode
    // 'forced-color-adjust-none forced-colors:data-[focused]:bg-[Highlight] forced-colors:data-[focused]:text-[HighlightText] forced-colors:[&>[data-slot=icon]]:data-[focused]:text-[HighlightText]',

    // Use subgrid when available but fallback to an explicit grid layout if not
    'supports-[grid-template-columns:subgrid]:grid-cols-subgrid col-span-full grid grid-cols-[auto_1fr_1.5rem_0.5rem_auto] items-center',

    // Icon
    '[&>[data-slot=icon]]:col-start-1 [&>[data-slot=icon]]:row-start-1 [&>[data-slot=icon]]:mr-2.5 [&>[data-slot=icon]]:h-5 [&>[data-slot=icon]]:w-5 sm:[&>[data-slot=icon]]:mr-2 [&>[data-slot=icon]]:sm:h-4 [&>[data-slot=icon]]:sm:w-4',
    '[&>[data-slot=icon]]:text-zinc-500 [&>[data-slot=icon]]:data-[focused]:text-white [&>[data-slot=icon]]:dark:text-zinc-500 [&>[data-slot=icon]]:data-[focused]:dark:text-white',
  ],
});

export function SelectItem(props: ListBoxItemProps) {
  return <DropdownItem {...props} className={selectItemStyles()} />;
}

export function SelectSection<T extends object>(
  props: DropdownSectionProps<T>,
) {
  return <DropdownSection {...props} />;
}
