/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSlotId } from '@react-aria/utils';
import { clsx } from 'clsx';
import { mergeProps, useLabel } from 'react-aria';
import {
  Checkbox as AriaCheckbox,
  CheckboxContext as AriaCheckboxContext,
  CheckboxGroup as AriaCheckboxGroup,
  CheckboxGroupProps as AriaCheckboxGroupProps,
  CheckboxProps as AriaCheckboxProps,
  TextContext as AriaTextContext,
  LabelContext,
} from 'react-aria-components';
import { useController } from 'react-hook-form';

import { ControlledFieldProps, useSlot, withControlledField } from './utils';

import { FieldErrorMessage } from '../forms/fieldset';

type HeadlessCheckboxGroupProps = AriaCheckboxGroupProps;
export function HeadlessCheckboxGroup({
  className,
  ...props
}: HeadlessCheckboxGroupProps) {
  return <AriaCheckboxGroup {...props} className={className} />;
}

export function CheckboxGroup({
  className,
  ...props
}: HeadlessCheckboxGroupProps) {
  return (
    <HeadlessCheckboxGroup
      data-slot="control"
      {...props}
      className={clsx(
        className,

        // Fieldset
        '[&>*+[data-slot=control]]:mt-6 [&>[data-slot=label]]:font-semibold [&>[data-slot=text]]:mt-1',
      )}
    />
  );
}

type HeadlessCheckboxFieldProps = Omit<
  AriaCheckboxProps,
  'isDisabled' | 'isIndeterminate'
> & {
  disabled?: boolean;
  indeterminate?: boolean;
};

export function HeadlessCheckboxField({
  children,
  control,
  field: _field,
  disabled: isDisabled,
  indeterminate,
  className,
  ...props
}: HeadlessCheckboxFieldProps & ControlledFieldProps) {
  const controller = useController({
    control,
    name: _field,
    defaultValue: props.defaultSelected,
  });
  const field = controller.field;
  const isInvalid = controller.fieldState.invalid;

  const [labelRef, label] = useSlot();
  // eslint-disable-next-line prefer-const
  let { labelProps, fieldProps } = useLabel({ label });
  const descriptionId = useSlotId();

  fieldProps = mergeProps(fieldProps, {
    'aria-describedby':
      [descriptionId, props['aria-describedby']].filter(Boolean).join(' ') ||
      undefined,
  });

  return (
    <LabelContext.Provider
      value={{
        ...labelProps,
        ref: labelRef,
        ...(isDisabled ? { 'data-disabled': true } : {}),
      }}
    >
      <AriaCheckboxContext.Provider
        value={{
          ...mergeProps(props, fieldProps, field),
          isSelected: field.value,
          isDisabled,
          isIndeterminate: indeterminate,
        }}
      >
        <AriaTextContext.Provider
          value={{
            slots: {
              description: {
                id: descriptionId,
                ...(isDisabled ? { 'data-disabled': true } : {}),
              },
            },
          }}
        >
          <div
            data-slot="field"
            {...(isDisabled ? { 'data-disabled': true } : {})}
            className={clsx(className)}
          >
            {typeof children !== 'function' ? children : null}
          </div>
        </AriaTextContext.Provider>
      </AriaCheckboxContext.Provider>
    </LabelContext.Provider>
  );
}

function _CheckboxField(
  props: HeadlessCheckboxFieldProps & ControlledFieldProps,
) {
  return (
    <HeadlessCheckboxField
      {...props}
      className={clsx([
        // Base layout
        'grid grid-cols-[1.125rem_1fr] items-center gap-x-4 gap-y-1 sm:grid-cols-[1rem_1fr]',

        // Control layout
        '[&>[data-slot=control]]:col-start-1 [&>[data-slot=control]]:row-start-1 [&>[data-slot=control]]:justify-self-center',

        // Label layout
        '[&>[data-slot=label]]:col-start-2 [&>[data-slot=label]]:row-start-1 [&>[data-slot=label]]:justify-self-start',

        // Description layout
        '[&>[data-slot=description]]:col-start-2 [&>[data-slot=description]]:row-start-2',

        // With description
        '[&_[data-slot=label]]:has-[[data-slot=description]]:font-medium',
      ])}
    />
  );
}
export const CheckboxField = withControlledField(_CheckboxField);

function HeadlessCheckboxGroupField({
  control,
  field,
  children,
  ...props
}: AriaCheckboxGroupProps & ControlledFieldProps) {
  const controller = useController({
    control,
    name: field,
    defaultValue: props.defaultValue,
  });
  const _field = controller.field;
  const isInvalid = controller.fieldState.invalid;

  return (
    <HeadlessCheckboxGroup {...mergeProps(props, _field)} isInvalid={isInvalid}>
      {(state) => (
        <>
          {typeof children === 'function' ? children(state) : children}
          {isInvalid ? (
            <FieldErrorMessage control={control} field={field} />
          ) : null}
        </>
      )}
    </HeadlessCheckboxGroup>
  );
}
function CheckboxGroupField({
  control,
  field,
  children,
  ...props
}: AriaCheckboxGroupProps & ControlledFieldProps) {
  const controller = useController({
    control,
    name: field,
    defaultValue: props.defaultValue,
  });
  const _field = controller.field;
  const isInvalid = controller.fieldState.invalid;

  return (
    <CheckboxGroup {...mergeProps(props, _field)} isInvalid={isInvalid}>
      {(state) => (
        <>
          {typeof children === 'function' ? children(state) : children}
          {isInvalid ? (
            <FieldErrorMessage control={control} field={field} />
          ) : null}
        </>
      )}
    </CheckboxGroup>
  );
}
const _CheckboxGroupField = withControlledField(CheckboxGroupField);
const _HeadlessCheckboxGroupField = withControlledField(
  HeadlessCheckboxGroupField,
);
export {
  _CheckboxGroupField as CheckboxGroupField,
  _HeadlessCheckboxGroupField as HeadlessCheckboxGroupField,
};

const base = [
  // Basic layout
  'relative isolate flex w-[1.125rem] h-[1.125rem] items-center justify-center rounded sm:w-5 sm:h-5',

  // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
  'before:absolute before:inset-0 before:-z-10 before:rounded-[calc(theme(borderRadius.md)-2px)] before:bg-white before:shadow',

  // Background color when checked
  'before:group-data-[selected]:bg-[--checkbox-checked-bg]',

  // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
  'dark:before:hidden',

  // Background color applied to control in dark mode
  'dark:bg-white/5 dark:group-data-[selected]:bg-[--checkbox-checked-bg]',

  // Border
  'border-2 border-brand-primary-500 group-data-[selected]:border-transparent group-data-[selected]:group-data-[hovered]:border-transparent group-data-[hovered]:border-brand-primary-700 group-data-[selected]:bg-[--checkbox-checked-border]',
  'dark:border-white/15 dark:group-data-[selected]:border-white/5 dark:group-data-[selected]:group-data-[hovered]:border-white/5 dark:group-data-[hovered]:border-white/30',

  // Inner highlight shadow
  'after:absolute after:inset-0 after:rounded-[calc(0.3125rem-1px)] after:shadow-[inset_0_1px_theme(colors.white/15%)]',
  'dark:after:-inset-px dark:after:hidden dark:after:rounded-[0.3125rem] dark:group-data-[selected]:after:block',

  // Focus ring
  'group-data-[focused]:outline group-data-[focused]:outline-2 group-data-[focused]:outline-offset-2 group-data-[focused]:outline-blue-500',

  // Disabled state
  'group-data-[disabled]:opacity-50',
  'group-data-[disabled]:border-zinc-950/25 group-data-[disabled]:bg-zinc-950/5 group-data-[disabled]:[--checkbox-check:theme(colors.zinc.950/50%)] group-data-[disabled]:before:bg-transparent',
  'dark:group-data-[disabled]:border-white/20 dark:group-data-[disabled]:bg-white/[2.5%] dark:group-data-[disabled]:[--checkbox-check:theme(colors.white/50%)] dark:group-data-[disabled]:group-data-[selected]:after:hidden',

  // Forced colors mode
  'forced-colors:[--checkbox-check:HighlightText] forced-colors:[--checkbox-checked-bg:Highlight] forced-colors:group-data-[disabled]:[--checkbox-check:Highlight]',
  'dark:forced-colors:[--checkbox-check:HighlightText] dark:forced-colors:[--checkbox-checked-bg:Highlight] dark:forced-colors:group-data-[disabled]:[--checkbox-check:Highlight]',
];

const colors = {
  brand: [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:var(--brand-primary-500)] [--checkbox-checked-border:var(--brand-primary-500)]',
    'dark:[--checkbox-checked-bg:var(--brand-primary-300)]',
  ],
  'dark/zinc': [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.zinc.900)] [--checkbox-checked-border:theme(colors.zinc.950/90%)]',
    'dark:[--checkbox-checked-bg:theme(colors.zinc.600)]',
  ],
  'dark/white': [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.zinc.900)] [--checkbox-checked-border:theme(colors.zinc.950/90%)]',
    'dark:[--checkbox-check:theme(colors.zinc.900)] dark:[--checkbox-checked-bg:theme(colors.white)] dark:[--checkbox-checked-border:theme(colors.zinc.950/15%)]',
  ],
  white:
    '[--checkbox-check:theme(colors.zinc.900)] [--checkbox-checked-bg:theme(colors.white)] [--checkbox-checked-border:theme(colors.zinc.950/15%)]',
  dark: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.zinc.900)] [--checkbox-checked-border:theme(colors.zinc.950/90%)]',
  zinc: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.zinc.600)] [--checkbox-checked-border:theme(colors.zinc.700/90%)]',
  red: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.red.600)] [--checkbox-checked-border:theme(colors.red.700/90%)]',
  orange:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.orange.500)] [--checkbox-checked-border:theme(colors.orange.600/90%)]',
  amber:
    '[--checkbox-check:theme(colors.amber.950)] [--checkbox-checked-bg:theme(colors.amber.400)] [--checkbox-checked-border:theme(colors.amber.500/80%)]',
  yellow:
    '[--checkbox-check:theme(colors.yellow.950)] [--checkbox-checked-bg:theme(colors.yellow.300)] [--checkbox-checked-border:theme(colors.yellow.400/80%)]',
  lime: '[--checkbox-check:theme(colors.lime.950)] [--checkbox-checked-bg:theme(colors.lime.300)] [--checkbox-checked-border:theme(colors.lime.400/80%)]',
  green:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.green.600)] [--checkbox-checked-border:theme(colors.green.700/90%)]',
  emerald:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.emerald.600)] [--checkbox-checked-border:theme(colors.emerald.700/90%)]',
  teal: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.teal.600)] [--checkbox-checked-border:theme(colors.teal.700/90%)]',
  cyan: '[--checkbox-check:theme(colors.cyan.950)] [--checkbox-checked-bg:theme(colors.cyan.300)] [--checkbox-checked-border:theme(colors.cyan.400/80%)]',
  sky: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.sky.500)] [--checkbox-checked-border:theme(colors.sky.600/80%)]',
  blue: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.blue.600)] [--checkbox-checked-border:theme(colors.blue.700/90%)]',
  indigo:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.indigo.500)] [--checkbox-checked-border:theme(colors.indigo.600/90%)]',
  violet:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.violet.500)] [--checkbox-checked-border:theme(colors.violet.600/90%)]',
  purple:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.purple.500)] [--checkbox-checked-border:theme(colors.purple.600/90%)]',
  fuchsia:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.fuchsia.500)] [--checkbox-checked-border:theme(colors.fuchsia.600/90%)]',
  pink: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.pink.500)] [--checkbox-checked-border:theme(colors.pink.600/90%)]',
  rose: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.rose.500)] [--checkbox-checked-border:theme(colors.rose.600/90%)]',
};

type Color = keyof typeof colors;
export { AriaCheckbox as HeadlessCheckbox };
export function Checkbox({
  color = 'brand',
  className,
  disabled,
  indeterminate,
  ...props
}: Omit<AriaCheckboxProps, 'isDisabled' | 'isIndeterminate'> & {
  color?: Color;
  className?: string;
  disabled?: boolean;
  indeterminate?: boolean;
}) {
  return (
    <AriaCheckbox
      {...props}
      className="group inline-block"
      isDisabled={disabled}
      isIndeterminate={indeterminate}
    >
      <span className={clsx([base, colors[color]])}>
        <svg
          className="h-4 w-4 stroke-[--checkbox-check] opacity-0 group-data-[selected]:opacity-100 sm:h-3.5 sm:w-3.5"
          viewBox="0 0 14 14"
          fill="none"
        >
          {/* Checkmark icon */}
          <path
            className="opacity-100 group-data-[indeterminate]:opacity-0"
            d="M3 8L6 11L11 3.5"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {/* Indeterminate icon */}
          <path
            className="opacity-0 group-data-[indeterminate]:opacity-100"
            d="M3 7H11"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </AriaCheckbox>
  );
}
