import { z } from 'zod';

import { ServiceLocation } from '../service-locations/types';
import {
  PortalEstimate,
  PortalInvoice,
  PortalJob,
  PortalWorkRequest,
} from '../types.shared';
import { LineItem } from '../work-requests/types';

export type Estimate = PortalWorkRequest &
  PortalJob &
  PortalInvoice &
  PortalEstimate;

export type AssignedTo = {
  name: string;
  occupation: string;
};

export const estimateDetailsSchema = z.object({
  id: z.number(),
  workRequestId: z.number(),
  notes: z.string(),
  subTotal: z.number(),
  taxable: z.number(),
  taxPercentage: z.number(),
  tax: z.number(),
  itemCost: z.number(),
  hours: z.string(),
  deposit: z.number(),
  total: z.number(),
  createdById: z.number(),
  dateCreated: z.string(),
  dateSent: z.string().nullable(),
  dateApproved: z.string().nullable(),
  dateModified: z.string(),
  isDeleted: z.boolean(),
  dateDeleted: z.string().nullable(),
});
export type EstimateAmounts = z.infer<typeof estimateDetailsSchema>;

export type EstimateDetails = {
  workRequestDetails: Estimate;
  estimateItems: LineItem[];
  servLoc: ServiceLocation;
  estimate: EstimateAmounts | null;
};

export type TrackedEstimateDetails = {
  workRequestId: number;
  clientId: number;
  resellerId: number;
};
