import clsx from 'clsx';
import { useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
} from 'react-aria-components';

import { SupportedLocales } from 'i18n-config';
import { useLocale } from 'utils/hooks/useLocale';

const SUPPORTED_LANGUAGES: Record<
  SupportedLocales,
  {
    label: string;
    code: string;
    country_code: string;
    isEnabled?: boolean;
  }
> = {
  en: {
    label: 'English',
    code: 'en-US',
    country_code: 'US',
    isEnabled: true,
  },
  ca: {
    label: 'Canadian French',
    code: 'fr-CA',
    country_code: 'CA',
    isEnabled: true,
  },
  es: {
    label: 'Spanish',
    code: 'es-ES',
    country_code: 'ES',
    isEnabled: true,
  },
};

export default function LangSwitcher() {
  const { i18n } = useLocale();
  const [lang, setLang] = useState(
    () =>
      Object.values(SUPPORTED_LANGUAGES).find(
        (item) =>
          i18n.language === item.code ||
          i18n.language.split('-')[0] === item.code.split('-')[0],
      )?.code ?? 'en-US',
  );

  return (
    <MenuTrigger>
      <Button aria-label="Select Language">
        <img
          src={`/country-flag-icons/3x2/${lang.split('-')[1]}.svg`}
          alt={lang}
          className="h-full w-8"
        />
      </Button>
      <Popover
        placement="bottom right"
        className={clsx(
          'z-50 min-w-[8rem] overflow-hidden rounded-md border bg-white p-1 text-gray-500 shadow-md',
          'data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0 data-[entering]:zoom-in-95 data-[exiting]:zoom-out-95 data-[placement=bottom]:slide-in-from-top-2 data-[placement=left]:slide-in-from-right-2 data-[placement=right]:slide-in-from-left-2 data-[placement=top]:slide-in-from-bottom-2',
        )}
      >
        <Menu
          className="outline-none"
          selectedKeys={[lang]}
          onAction={(key) => {
            const selectedLang = Object.values(SUPPORTED_LANGUAGES)
              .filter((lang) => lang.isEnabled)
              .find((item) => item.code === key);
            if (selectedLang) {
              i18n.changeLanguage(selectedLang.code);
              setLang(selectedLang.code);
            }
          }}
        >
          {Object.values(SUPPORTED_LANGUAGES)
            .filter((lang) => lang.isEnabled)
            .map((item) => (
              <MenuItem
                key={item.code}
                id={item.code}
                className="relative flex cursor-default select-none items-center space-x-1.5 rounded-sm px-3 py-2 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[focused]:bg-brand-primary-50 data-[selected]:bg-brand-primary-600 data-[focused]:text-gray-500 data-[disabled]:opacity-50"
              >
                <span>
                  <img
                    src={`/country-flag-icons/3x2/${item.country_code}.svg`}
                    alt={item.label}
                    className="h-full w-4"
                  />
                </span>
                <span>{item.label}</span>
              </MenuItem>
            ))}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
}
