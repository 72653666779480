import { Skeleton } from 'baseui/skeleton';
import { Plus, Repeat, Users } from 'react-feather';
import { Outlet } from 'react-router-dom';

import { Card } from 'components/Card';
import { createTableBuilder } from 'components/Table';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import { Heading } from 'components/ui/heading';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { useCurrentUser } from 'services/api/auth/endpoints';
import {
  ResellerService,
  useResellersQuery,
} from 'services/api/resellers/endpoints';
import { Reseller } from 'services/api/resellers/types';

export default function ResellersIndex() {
  const resellerId = useCurrentUser().data?.resellerId;
  const businessName =
    ResellerService.endpoints.reseller.useQuery(resellerId ?? 0, {
      skip: !resellerId,
    }).data?.businessName ?? '';

  return (
    <>
      <Outlet />
      <div className="flex-1 space-y-5">
        <DashboardContentHeader
          title="Resellers"
          description={`Track and manage your ${businessName} Resellers`}
        />
        <section className="flex items-start space-x-4">
          <div className="flex-1 overflow-hidden">
            <ResellersTable />
          </div>
        </section>
      </div>
    </>
  );
}

const { Table, Column } = createTableBuilder<Reseller>();
function ResellersTable() {
  const query = useResellersQuery();
  const resellers = query.data ?? [];

  return (
    <Card>
      <div className="mb-4 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Heading>Resellers</Heading>
        </div>
        <div className="flex items-center space-x-4">
          {/* <form>
            <Input endEnhancer={<Search size={16} />} />
          </form> */}

          <nav className="flex items-center space-x-4">
            {/* <Select kind="secondary" placeholder="Filter" /> */}
            <Button
              href="new"
              startEnhancer={<Plus className="h-full w-full" />}
            >
              New Reseller
            </Button>
          </nav>
        </div>
      </div>
      <Table
        data={resellers}
        isLoading={query.isLoading}
        loadingMessage={<Skeleton rows={4} height="100px" />}
        isError={query.isError}
        isEmpty={resellers.length === 0}
        emptyMessage={
          <div className="text-center">
            <Users className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No resellers
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by creating a reseller.
            </p>
            <div className="mt-6">
              <Button
                href="new"
                startEnhancer={<Plus className="h-full w-full" />}
              >
                New Reseller
              </Button>
            </div>
          </div>
        }
      >
        <Column header="ID #">{(row) => row.id}</Column>
        <Column header="Reseller Name">
          {(row) => (
            <div className="flex flex-col items-start space-y-2">
              {row.parentReseller && <Badge>Parent Reseller</Badge>}
              <span>{row.businessName}</span>
            </div>
          )}
        </Column>
        <Column header="Contact No.">{(row) => row.mainContactPhone}</Column>
        <Column header="Email">{(row) => row.mainContactEmail}</Column>
        <Column header="KAL Rep">{(row) => row.accountRep}</Column>
        {/* <Column header="Admin Access">
          {(row) => (
            <div className="flex justify-center">
              <input
                type="radio"
                value="1"
                name={`${row.id}-access`}
                defaultChecked={false}
                className="h-5 w-5  checked:accent-brand-primary"
              />
            </div>
          )}
        </Column> */}
        {/* <Column header="Read-only Access">
          {(row) => (
            <div className="flex justify-center">
              <input
                type="radio"
                value="1"
                name={`${row.id}-access`}
                defaultChecked={true}
                className="h-5 w-5  checked:accent-brand-primary"
              />
            </div>
          )}
        </Column> */}
        {/* <Column header="Write Access">
          {(row) => (
            <div className="flex justify-center">
              <input
                type="radio"
                value="1"
                name={`${row.id}-access`}
                defaultChecked={false}
                className="h-5 w-5  checked:accent-brand-primary"
              />
            </div>
          )}
        </Column> */}
        <Column header="Action">
          {(row) => (
            <nav className="flex items-center gap-4 divide-x">
              <Button href={`${row.id}`} variant="plain">
                View
              </Button>
              <div className="pl-4">
                <Button
                  href={`${row.id}/login-as`}
                  startEnhancer={<Repeat className="h-full w-full" />}
                  className="flex-1 whitespace-nowrap"
                  variant="plain"
                >
                  Login as
                </Button>
              </div>
            </nav>
          )}
        </Column>
      </Table>
    </Card>
  );
}
