import {
  BaseQueryApi,
  BaseQueryFn,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';
import { BaseQueryExtraOptions } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { z } from 'zod';

import {
  Invoice,
  InvoiceDetails,
  InvoicesQueryParams,
  SentInvoiceDestination,
} from './types';

import { baseAPI, isMutationSuccess, toPaginatedList } from '../base-api';
import {
  PaginatedParams,
  PaginatedResponse,
  WorkRequestStatus,
  invoiceSpecificsSchema,
} from '../types.shared';
import { endpoints as UtilitieService } from '../utilities/endpoint';
import { endpoints as WorkRequestService } from '../work-requests/endpoints';

const PAGINATION_DEFAULT_VALUE: PaginatedParams = { limit: 10, page: 1 };
function toRemovedNullable<Schema extends z.ZodObject<any>>(schema: Schema) {
  const entries = Object.entries(schema.shape) as [
    keyof Schema['shape'],
    z.ZodTypeAny,
  ][];
  const newProps = entries.reduce(
    (acc, [key, value]) => {
      acc[key] = value instanceof z.ZodNullable ? value.unwrap() : value;
      return acc;
    },
    {} as {
      [key in keyof Schema['shape']]: Schema['shape'][key] extends z.ZodNullable<
        infer T
      >
        ? T
        : Schema['shape'][key];
    },
  );
  return z.object(newProps);
}
const mutationOptionsSchema = z
  .object({
    invalidate: z.boolean().catch(true).default(true),
  })
  .partial()
  .default({
    invalidate: true,
  })
  .catch({
    invalidate: true,
  });
type MutationRequestOptions = {
  mutationOptions?: z.infer<typeof mutationOptionsSchema>;
};

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

const sendInvoicePayloadSchema = toRemovedNullable(
  invoiceSpecificsSchema.pick({
    dateDue: true,
    dateSent: true,
    workRequestId: true,
  }),
);
const modifiableInvoicePropsSchema = invoiceSpecificsSchema.omit({
  dateCreated: true,
  dateDeleted: true,
  dateModified: true,
  isDeleted: true,
});

type WorkRequestInvoice = z.infer<typeof modifiableInvoicePropsSchema>;
type SendInvoicePayload = z.infer<typeof sendInvoicePayloadSchema> & {
  invoiceTo?: SentInvoiceDestination;
};

const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    invoices: builder.query<
      PaginatedResponse<Invoice>,
      {
        params?: InvoicesQueryParams;
      } | void
    >({
      query: (args) => {
        const { params = {} } = args ?? {};

        const parsedParams = Object.entries(params)
          .filter(([key, value]) => Boolean(value))
          .reduce(
            (all, [key, value]) => ({ ...all, [key]: `${value}` }),
            {} as Record<string, string>,
          );

        return {
          url: `portal/invoices`,
          params: { ...PAGINATION_DEFAULT_VALUE, ...parsedParams },
        };
      },
      transformResponse(baseQueryReturnValue, meta, arg) {
        return toPaginatedList(baseQueryReturnValue);
      },
      providesTags: (response) =>
        response
          ? [
              { type: 'Invoices', page: response.pageNumber },
              { type: 'Invoices', id: 'list' },
            ]
          : [],
    }),
    invoice: builder.query<InvoiceDetails, number>({
      query: (id) => `portal/invoices/${id}`,
      transformResponse(baseQueryReturnValue: any, meta, arg) {
        return {
          ...baseQueryReturnValue,
          breakdown: baseQueryReturnValue.invoice,
          lineItems: baseQueryReturnValue.invoiceItems,
        } as InvoiceDetails;
      },
      providesTags: (response) =>
        response
          ? [{ type: 'Invoices', id: response.workRequestDetails.id }]
          : [],
    }),
    saveInvoice: builder.mutation<
      WorkRequestInvoice,
      Optional<WorkRequestInvoice, 'id' | 'datePaid' | 'dateSent'> &
        MutationRequestOptions
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const url = arg.id ? `invoices/${arg.id}` : `invoices`;
        const method = arg.id ? 'PUT' : 'POST';
        const result = await baseQuery({ url, method, body: arg });
        if (result.error) {
          return { error: result.error };
        }
        const validated = invoiceSpecificsSchema.safeParse(result.data);
        if (!validated.success) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Failed to parse response data',
            },
          };
        }
        return { data: validated.data };
      },
      invalidatesTags: (invoice, error, payload) => {
        const invalidate = mutationOptionsSchema.parse(
          payload.mutationOptions,
        ).invalidate;

        return invoice && invalidate
          ? [
              'Jobs',
              { type: 'Invoices', id: invoice.workRequestId },
              { type: 'Invoices', id: 'list' },
            ]
          : [];
      },
    }),
    sendInvoice: builder.mutation<
      WorkRequestInvoice,
      SendInvoicePayload & MutationRequestOptions
    >({
      async queryFn(payload, api, extraOptions, baseQuery) {
        const invoiceResult = await baseQuery(
          `portal/invoices/${payload.workRequestId}`,
        );
        if (invoiceResult.error) {
          return {
            error: invoiceResult.error,
          };
        }
        const validated = invoiceSpecificsSchema.safeParse(
          (invoiceResult.data as any).invoice,
        );

        if (!validated.success) {
          return {
            error: {
              status: 'FETCH_ERROR',
              error: 'Failed to parse invoice response',
            },
          };
        }
        const invoice = validated.data;

        const result = await sendInvoiceEmail(
          {
            ...invoice,
            dateSent: payload.dateSent,
            dateDue: payload.dateDue,
            invoiceTo: payload.invoiceTo ?? 'TO-CUSTOMER',
          },
          {
            api,
            extraOptions,
            baseQuery,
          },
        );

        if (result.error) {
          return { error: result.error };
        }

        return { data: result.data };
      },
      invalidatesTags: (invoice, error, payload) =>
        invoice
          ? [
              'Jobs',
              { type: 'Invoices', id: invoice.workRequestId },
              { type: 'Invoices', id: 'list' },
            ]
          : [],
    }),
    exportToQuickbooks: builder.mutation<void, { invoiceId: number }>({
      query(arg) {
        return {
          url: `quickbooks/invoice-export/${arg.invoiceId}`,
          method: 'POST',
        };
      },
    }),
    invoiceUpdateBalance: builder.mutation<void, { invoiceId: number }>({
      query(arg) {
        return {
          url: `quickbooks/invoice-update-balance/${arg.invoiceId}`,
          method: 'POST',
        };
      },
    }),
    fetchInvoiceDetails: builder.query<
      WorkRequestInvoice,
      { workRequestId: number }
    >({
      async queryFn(payload, api, extraOptions, baseQuery) {
        const invoiceResult = await baseQuery(
          `portal/invoices/${payload.workRequestId}`,
        );
        if (invoiceResult.error) {
          return { error: invoiceResult.error };
        }
        const validated = invoiceSpecificsSchema.safeParse(invoiceResult.data);
        if (!validated.success) {
          return {
            error: {
              status: 'FETCH_ERROR',
              error: 'Failed to parse invoice response',
            },
          };
        }
        return { data: validated.data };
      },
    }),
  }),

  overrideExisting: false,
});

async function sendInvoiceEmail(
  payload: WorkRequestInvoice & { invoiceTo?: SentInvoiceDestination },
  queryFnArgs: {
    api: BaseQueryApi;
    extraOptions: BaseQueryExtraOptions<BaseQueryFn>;
    baseQuery: (arg: Parameters<BaseQueryFn>[0]) => ReturnType<BaseQueryFn>;
  },
) {
  const { invoiceTo, ...invoice } = payload;
  const { api, baseQuery } = queryFnArgs;

  const emailRequest = () => {
    switch (invoiceTo) {
      case 'TO-CUSTOMER':
        return api.dispatch(
          UtilitieService.endpoints.sendEmail.initiate({
            workRequestId: invoice?.workRequestId,
            templateType: 'Invoice',
          }),
        );
      case 'TO-QUICKBOOKS':
        return baseQuery({
          url: `quickbooks/invoice-export/${invoice.id}`,
          method: 'POST',
        });
      default:
        return Promise.resolve();
    }
  };

  const result = await emailRequest();
  if (result !== undefined && 'error' in result) {
    return {
      error: result.error as FetchBaseQueryError,
    };
  }

  const workRequestResult = await api.dispatch(
    WorkRequestService.endpoints._work_request.initiate(invoice.workRequestId),
  );
  if (!isMutationSuccess(workRequestResult)) {
    return { error: workRequestResult.error as FetchBaseQueryError };
  }
  const workRequestObject = workRequestResult.data;

  // Condition if Job is In Progress, we set the status to 'Started'
  // and send the Invoice. However, we will not set the status to 'InvoiceSent'
  const isStarted =
    (workRequestObject.status as WorkRequestStatus) === 'Started';
  const status = isStarted
    ? ('Started' as WorkRequestStatus)
    : ('InvoiceSent' as WorkRequestStatus);
  const prevStatus = isStarted
    ? ('InvoiceCreated' as WorkRequestStatus)
    : workRequestObject.prevStatus;

  const results = await Promise.all([
    // Update Work Request status
    baseQuery({
      url: `work-requests/${invoice.workRequestId}`,
      method: 'PUT',
      body: {
        ...workRequestObject,
        status,
        prevStatus,
      },
    }),

    // Update Invoice Sent and Due date
    baseQuery({
      url: `invoices/${invoice.id}`,
      method: 'PUT',
      body: invoice,
    }),
  ]);

  const failedRequest = results.find((result) => !!result.error);
  if (failedRequest) {
    return { error: failedRequest.error as FetchBaseQueryError };
  }

  const validated = invoiceSpecificsSchema.safeParse(results[1].data);
  if (!validated.success) {
    return {
      error: {
        status: 'FETCH_ERROR',
        error: 'Failed to parse invoice response',
      } as FetchBaseQueryError,
    };
  }

  return { data: validated.data };
}

export const invoiceService = endpoints;
export const InvoiceService = endpoints;
export const {
  useInvoicesQuery,
  useInvoiceQuery,
  useSaveInvoiceMutation,
  useSendInvoiceMutation,
  useInvoiceUpdateBalanceMutation,
  useFetchInvoiceDetailsQuery,
} = endpoints;
