import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

export const useValidatedSearchParams = <Schema extends z.ZodTypeAny>(
  schema: Schema,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return {
    searchParams,
    setSearchParams,
    values: schema.parse(Object.fromEntries(searchParams.entries())) as z.infer<
      typeof schema
    >,
  };
};
