import { z } from 'zod';

import { Skill } from '../skills/types';
import { UserRole, paginatedParamsSchema } from '../types.shared';

import { toPayloadDateFormat } from 'utils/helpers';

const timeStringToDateISOSchema = z.preprocess((v) => {
  return z.coerce.date().safeParse(`${toPayloadDateFormat(new Date())}T${v}`)
    .success
    ? new Date(`${toPayloadDateFormat(new Date())}T${v}`).toISOString()
    : undefined;
}, z.string().datetime());

export type Employee = {
  id: number;
  clientId: number;
  resellerId: number;
  email: string;
  lastDay?: string | null;
  isAccountNonExpired: boolean;
  isAccountNonLocked: boolean;
  isCredentialsNonExpired: boolean;
  isEnabled: boolean;
  roles: UserRole[];
  firstname: string;
  lastname: string;
  notes: string;
  phone: string;
  photoId: number | null;
  skills: Skill[];
  isTech: boolean;
};

type RequiredEmployeePayload = Omit<
  Employee,
  | 'id'
  | 'clientId'
  | 'resellerId'
  | 'isAccountNonExpired'
  | 'isAccountNonLocked'
  | 'isCredentialsNonExpired'
  | 'isEnabled'
  | 'roles'
  | 'photoId'
>;

export const EmployeeTypes = {
  FSEmployee: 'FSEmployee',
  ResellerEmployee: 'ResellerEmployee',
  ResellerClientEmployee: 'ResellerClientEmployee',
  KaleidoscopeEmployee: 'KaleidoscopeEmployee',
} as const;

type FSEmployeePayload = {
  type: typeof EmployeeTypes.FSEmployee;
} & RequiredEmployeePayload &
  Partial<Omit<Employee, keyof RequiredEmployeePayload>> & {
    clientId: number;
    roles: Array<UserRole>;
    password: string;
    image?: File;
  };

type ResellerEmployeePayload = {
  type: typeof EmployeeTypes.ResellerEmployee;
} & Pick<Employee, 'email' | 'firstname' | 'lastname' | 'clientId'> & {
    password: string;
    roles: Array<UserRole>;
  };

type KaleidoscopeEmployeePayload = {
  type: typeof EmployeeTypes.KaleidoscopeEmployee;
} & Pick<Employee, 'email' | 'firstname' | 'lastname' | 'clientId'> & {
    password: string;
    roles: Array<UserRole>;
  };

type ResellerClientEmployeePayload = {
  type: typeof EmployeeTypes.ResellerClientEmployee;
} & RequiredEmployeePayload &
  Pick<Employee, 'roles' | 'clientId'> & {
    password: string;
  };

export type CreateEmployeePayload =
  | FSEmployeePayload
  | ResellerEmployeePayload
  | KaleidoscopeEmployeePayload
  | ResellerClientEmployeePayload;

export const TrackedEmployeeLocationSchema = z.object({
  id: z.number(),
  resellerId: z.number(),
  clientId: z.number(),
  assetType: z.number(),
  assetId: z.number(),
  timestamp: z.string(),
  reasonCode: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  altitude: z.number(),
  gpsAge: z.number(),
  satellites: z.number(),
  hdop: z.number(),
  accuracy: z.number(),
  speed: z.number(),
  heading: z.number(),
});

export type TrackedEmployeeLocation = z.infer<
  typeof TrackedEmployeeLocationSchema
>;

export const WORKING_DAYS_VALUES = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
} as const;

export const employeeWorkHoursSchema = z.object({
  techId: z.number(),
  startTime: timeStringToDateISOSchema,
  endTime: timeStringToDateISOSchema,
  lunchStartTime: timeStringToDateISOSchema.nullable(),
  lunchEndTime: timeStringToDateISOSchema.nullable(),
  preferredDays: z.array(z.nativeEnum(WORKING_DAYS_VALUES)),
  dateCreated: z.coerce.string().datetime(),
  dateModified: z.coerce.string().datetime(),
});
export type EmployeeWorkHours = z.infer<typeof employeeWorkHoursSchema>;

export const employeeTimeOffSchema = z.object({
  techId: z.coerce.number(),
  startDateTime: z.coerce.string().datetime(),
  endDateTime: z.coerce.string().datetime(),
  dateCreated: z.coerce.string().datetime(),
  dateModified: z.coerce.string().datetime(),
});
export type EmployeeTimeOff = z.infer<typeof employeeTimeOffSchema>;
export type EmployeeAvailability = {
  workHours: EmployeeWorkHours;
  timeoff: EmployeeTimeOff;
};
const employeeAvailabilityPayloadSchema = z.object({
  workHours: employeeWorkHoursSchema
    .omit({
      dateCreated: true,
      dateModified: true,
    })
    .partial(),
  timeoff: employeeTimeOffSchema
    .partial()
    .omit({
      dateCreated: true,
      dateModified: true,
      techId: true,
    })
    .extend({ shouldDelete: z.boolean(), techId: z.coerce.number() }),
});
export type EmployeeAvailabilityPayload = z.infer<
  typeof employeeAvailabilityPayloadSchema
>;

export const activeTechniciansParams = paginatedParamsSchema.extend({
  resellerId: z.coerce.number(),
  clientId: z.coerce.number(),
  dateFrom: z.string(),
  dateTo: z.string(),
});
export type ActiveTechnicianListParams = z.infer<
  typeof activeTechniciansParams
>;

export const activeTechnicianSchema = z.object({
  email: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  clientName: z.string(),
  resellerName: z.string(),
  parentResellerName: z.string().nullable(),
  active: z.string().datetime(),
  inactive: z.string().datetime().nullable(),
});
export type ActiveTechnician = z.infer<typeof activeTechnicianSchema>;

export const activeTechnicianCountSchema = z.object({
  resellerName: z.string(),
  parentResellerName: z.string().nullable(),
  clientName: z.string(),
  total: z.coerce.number(),
});
export type ActiveTechnicianCount = z.infer<typeof activeTechnicianCountSchema>;
