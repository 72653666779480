import React from 'react';
import {
  Popover as AriaPopover,
  OverlayArrow,
  type PopoverProps as AriaPopoverProps,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';

import { composeRenderProps } from './utils';

const styles = tv({
  variants: {
    withBase: {
      true: 'rounded-lg border border-black/10 bg-white bg-clip-padding text-slate-700 shadow-xl',
      false: '',
    },
    isEntering: {
      true: 'animate-in fade-in duration-200 ease-out data-[placement=bottom]:slide-in-from-top-1 data-[placement=left]:slide-in-from-right-1 data-[placement=right]:slide-in-from-bottom-1 data-[placement=right]:slide-in-from-left-1',
    },
    isExiting: {
      true: 'animate-out fade-out duration-150 ease-in data-[placement=bottom]:slide-out-to-top-1 data-[placement=left]:slide-out-to-right-1 data-[placement=right]:slide-out-to-bottom-1 data-[placement=right]:slide-out-to-left-1',
    },
  },
  defaultVariants: {
    withBase: true,
  },
});

export interface PopoverProps extends Omit<AriaPopoverProps, 'children'> {
  unstyled?: boolean;
  showArrow?: boolean;
  children: React.ReactNode;
}

export function Popover({
  children,
  showArrow,
  className,
  unstyled,
  ...props
}: PopoverProps) {
  return (
    <AriaPopover
      offset={showArrow ? 12 : 8}
      {...props}
      className={composeRenderProps(className, (className, renderProps) =>
        styles({ ...renderProps, withBase: !unstyled, className }),
      )}
    >
      {showArrow && (
        <OverlayArrow className="group">
          <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            className="block fill-white stroke-black/10 stroke-1 group-data-[placement=bottom]:rotate-180 group-data-[placement=left]:-rotate-90 group-data-[placement=right]:rotate-90"
          >
            <path d="M0 0 L6 6 L12 0" />
          </svg>
        </OverlayArrow>
      )}
      {children}
    </AriaPopover>
  );
}
