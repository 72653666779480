import { clsx } from 'clsx';
// import { Link } from './link'
import {
  Button as AriaButton,
  ButtonProps as AriaButtonProps,
  Heading as AriaHeading,
  HeadingProps as AriaHeadingProps,
  Text as AriaText,
  TextProps as AriaTextProps,
  Link,
} from 'react-aria-components';
import { LinkProps, useHref } from 'react-router-dom';

import { cn } from 'utils/helpers';

export function Text({ className, ...props }: AriaTextProps) {
  // To avoid slot errors we default to a native `paragraph` tag when no `slot` is supplied
  if (!props.slot) {
    return (
      <p
        data-slot="text"
        {...props}
        className={clsx(
          className,
          'text-base/6 text-zinc-500 dark:text-zinc-400 sm:text-sm/6',
        )}
      />
    );
  }

  return (
    <AriaText
      data-slot="text"
      {...props}
      elementType="p"
      className={clsx(
        className,
        'text-base/6 text-zinc-500 dark:text-zinc-400 sm:text-sm/6',
      )}
    />
  );
}

export function TextLink({
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<typeof Link>, 'href'> & {
  href: LinkProps['to'];
}) {
  const href = useHref(props.href ?? '');
  return (
    <Link
      {...props}
      href={href}
      className={cn(
        'text-zinc-950 underline decoration-zinc-950/50 data-[hovered]:decoration-zinc-950 dark:text-white dark:decoration-white/50 dark:data-[hovered]:decoration-white',
        className,
      )}
    />
  );
}

export function Strong({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'strong'>) {
  return (
    <strong
      {...props}
      className={cn('font-medium text-zinc-950 dark:text-white', className)}
    />
  );
}

export function Code({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'code'>) {
  return (
    <code
      {...props}
      className={clsx(
        className,
        'rounded border border-zinc-950/10 bg-zinc-950/[2.5%] px-0.5 text-sm font-medium text-zinc-950 sm:text-[0.8125rem]',
      )}
    />
  );
}

export function Heading({ className, slot, ...props }: AriaHeadingProps) {
  return (
    <AriaHeading
      {...props}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      slot={slot ?? null}
      className={clsx(
        className,
        'text-balance font-prompt text-lg/6 font-semibold text-zinc-950 dark:text-white sm:text-base/6',
      )}
    />
  );
}

export function TextButton({ className, slot, ...props }: AriaButtonProps) {
  return (
    <AriaButton
      {...props}
      className={cn(
        'text-zinc-950 underline decoration-zinc-950/50 data-[hovered]:decoration-zinc-950 dark:text-white dark:decoration-white/50 dark:data-[hovered]:decoration-white',
        className,
      )}
    />
  );
}
