import { WorkType, WorkTypeQueryParams } from './types';

import { baseAPI, toPaginatedList } from '../base-api';
import { PaginatedResponse, PaginatedParams } from '../types.shared';

const PAGINATION_DEFAULT_VALUE: PaginatedParams = { limit: 10, page: 1 };

const workTypeEndpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    workTypes: builder.query<
      PaginatedResponse<WorkType>,
      {
        params?: WorkTypeQueryParams;
      } | void
    >({
      query: (args) => {
        const { params = {} } = args ?? {};
        const parsedParams = Object.entries(params)
          .filter(([key, value]) => Boolean(value))
          .reduce(
            (all, [key, value]) => ({ ...all, [key]: `${value}` }),
            {} as Record<string, string>,
          );
        return {
          url: 'worktypes',
          params: { ...PAGINATION_DEFAULT_VALUE, ...parsedParams },
        };
      },
      providesTags: ['Work-Type'],
      transformResponse(
        baseQueryReturnValue,
        meta: { request: Request; response?: Response },
        arg,
      ) {
        return toPaginatedList(baseQueryReturnValue, meta.response?.headers);
      },
    }),
    workType: builder.query<WorkType, number>({
      query: (id) => `worktypes/${id}`,
      providesTags: (id) => [{ type: 'Work-Type', id: id?.id }],
    }),
    workTypeById: builder.query<WorkType, number>({
      query: (id) => `worktypes/${id}`,
      providesTags: (result, error, id) => [{ type: 'Work-Type', id }],
    }),
    saveWorkType: builder.mutation<
      WorkType,
      | {
          type: 'create';
          payload: Omit<
            WorkType,
            | 'id'
            | 'clientId'
            | 'resellerId'
            | 'estimatedDuration'
            | 'description'
            | 'type'
            | 'sku'
            | 'cost'
            | 'isTaxable'
            | 'quantityOnHand'
            | 'quickbooksId'
          > & {
            estimatedDuration: number;
          };
        }
      | {
          type: 'update';
          payload: Omit<WorkType, 'estimatedDuration'> & {
            estimatedDuration: number;
          };
        }
    >({
      query: (args) => {
        const { type, payload } = args;
        const method = {
          create: 'POST',
          update: 'PUT',
        }[type];

        return {
          url: type === 'create' ? `worktypes` : `worktypes/${payload.id}`,
          method: method,
          body: { ...payload },
        };
      },
      invalidatesTags: (workType) => (workType ? ['Work-Type'] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useWorkTypesQuery,
  useWorkTypeQuery,
  useSaveWorkTypeMutation,
  useWorkTypeByIdQuery,
} = workTypeEndpoints;
