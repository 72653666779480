import { zodResolver } from '@hookform/resolvers/zod';
import type { CheckboxOverrides } from 'baseui/checkbox';
import { StatefulCheckbox } from 'baseui/checkbox';
import type { Option } from 'baseui/select';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { AlertCircle as AlertCircleIcon, Edit } from 'react-feather';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from 'components/Button';
import { FormController } from 'components/FormController';
import { Description } from 'components/forms/fieldset';
import { Input } from 'components/Input';
import { QueryResolver } from 'components/QueryResolver';
import { Select } from 'components/Select';
import { Alert, AlertDescription, AlertTitle } from 'components/ui/alert';
import { Card, CardContent, CardFooter } from 'components/ui/card';
import { Checkbox, CheckboxField } from 'components/ui/checkbox';
import { Label } from 'components/ui/label';
import { CountrySelect } from 'features/CountrySelect';
import { StateSelect } from 'features/StateSelect';
import { useEmployeesQuery } from 'services/api/employees/endpoints';
import { UserRole } from 'services/api/types.shared';

const nonEmptyString = z.string().trim().min(1, { message: 'Required' });
const addressSchema = z.object({
  street: nonEmptyString,
  street2: z.string().nullable().optional().default(''),
  city: nonEmptyString,
  state: nonEmptyString,
  postalCode: nonEmptyString,
  country: nonEmptyString,
  lat: z.number().optional().default(0),
  lng: z.number().optional().default(0),
  timeZone: z.string().nullable().optional().default('')
});

export const baseSchema = z.object({
  resellerAccountName: nonEmptyString,
  operationalContactName: nonEmptyString,
  firstName: nonEmptyString,
  lastName: nonEmptyString,
  email: nonEmptyString.email(),
  password: nonEmptyString,
  phoneNumber: nonEmptyString,
  resellerAddress: addressSchema,
  sameAsResellerAddress: z.boolean().optional(),
  billingAddress: addressSchema,
  accountRepresentative: z.string(),
  editing: z.boolean().optional().default(false),
  subdomain: z.string().trim().optional().default(''),
  domain: z.string().trim().optional().default(''),
  isParentReseller: z.boolean().optional().default(false),
  allowParentResellerCreation: z.boolean().optional().default(false),
});

type FormFields = z.infer<typeof baseSchema>;

export default function ResellerForm({
  id = 'reseller-form',
  initialValues,
  allowedRepresentatives,
  onSubmit: onSubmitHandler,
}: {
  id?: string;
  initialValues?: Partial<FormFields>;
  allowedRepresentatives?: UserRole[];
  onSubmit: (data: FormFields) => void;
}) {
  const schema = baseSchema.extend({
    password: initialValues?.editing ? z.string().optional() : nonEmptyString,
  });
  const { control, handleSubmit, setValue, getValues, watch } =
    useForm<FormFields>({
      defaultValues: {
        ...initialValues,
        editing:
          initialValues?.editing !== undefined ? initialValues?.editing : false,
        allowParentResellerCreation:
          initialValues?.allowParentResellerCreation !== undefined
            ? initialValues?.allowParentResellerCreation
            : true,
      },
      resolver: zodResolver(schema),
    });
  const onSubmit = handleSubmit(onSubmitHandler);

  const editing = watch('editing');

  const matchWithResellerAddress = watch('sameAsResellerAddress');

  const prefillBillingAddress = useCallback(
    (address: Omit<FormFields['resellerAddress'], 'serviceArea'>) => {
      setValue(
        'billingAddress',
        { ...getValues().billingAddress, ...address },
        { shouldValidate: true },
      );
    },
    [getValues, setValue],
  );

  useEffect(() => {
    if (matchWithResellerAddress) {
      prefillBillingAddress(getValues('resellerAddress'));
    }
  }, [matchWithResellerAddress, getValues, prefillBillingAddress]);

  const isParentReseller = watch('isParentReseller', false);
  const allowParentResellerCreation = watch('allowParentResellerCreation');
  const canCreateParentReseller = editing ? false : allowParentResellerCreation;
  const resellerAddressCountryCode = watch('resellerAddress.country');
  const billingAddressCountryCode = watch('billingAddress.country');

  return (
    <form onSubmit={onSubmit} id={id} className="grid grid-cols-2 gap-4">
      <fieldset className="col-span-full">
        <legend className="sr-only">Basic Information</legend>
        <div className="grid grid-cols-2 gap-x-4">
          {canCreateParentReseller && (
            <div className="col-span-full mb-4">
              <Card dense>
                <CardContent>
                  <CheckboxField control={control} field="isParentReseller">
                    <Checkbox />
                    <Label>Allow to create other resellers</Label>
                    <Description>
                      When checked, allows this reseller to create resellers
                      under it.
                    </Description>
                  </CheckboxField>
                </CardContent>
                {isParentReseller && (
                  <CardFooter>
                    <Alert intent="warning">
                      <AlertCircleIcon className="h-4 w-4" />
                      <AlertTitle>Reminder on Reseller Setup</AlertTitle>
                      <AlertDescription>
                        This reseller{' '}
                        <span className="font-medium text-red-500">cannot</span>{' '}
                        create any clients. This option{' '}
                        <span className="font-medium text-red-500">cannot</span>{' '}
                        be changed after the reseller is created.
                      </AlertDescription>
                    </Alert>
                  </CardFooter>
                )}
              </Card>
            </div>
          )}
          <FormController
            control={control}
            name="resellerAccountName"
            label="Reseller Account Name"
          >
            {({ field }) => <Input {...field} placeholder="Account Name" />}
          </FormController>
          <FormController
            control={control}
            name="operationalContactName"
            label="Operational Contact Name"
          >
            {({ field }) => <Input {...field} placeholder="Contact Name" />}
          </FormController>
          <FormController control={control} name="firstName" label="First Name">
            {({ field }) => <Input {...field} placeholder="First Name" />}
          </FormController>
          <FormController control={control} name="lastName" label="Last Name">
            {({ field }) => <Input {...field} placeholder="Last Name" />}
          </FormController>
          <FormController control={control} name="email" label="Email Address">
            {({ field }) => (
              <Input {...field} placeholder="johnsmith@gmail.com" />
            )}
          </FormController>
          <FormController control={control} name="password" label="Password">
            {({ field }) => (
              <Input
                {...field}
                type="password"
                value={editing ? 'placeholder' : field.value}
                disabled={editing}
              />
            )}
          </FormController>
          <FormController
            control={control}
            name="phoneNumber"
            label="Phone Number"
          >
            {({ field }) => <Input {...field} placeholder="000-000-0000" />}
          </FormController>
          <div className="col-span-full grid grid-cols-4 gap-x-4">
            <FormController
              control={control}
              name="resellerAddress.street"
              label="Address"
              overrides={{ Root: { props: { className: 'col-span-3' } } }}
            >
              {({ field }) => <Input {...field} placeholder="26 Park Avenue" />}
            </FormController>
            <FormController
              control={control}
              name="resellerAddress.street2"
              label="Unit"
              overrides={{ Root: { props: { className: 'col-span-1' } } }}
            >
              {({ field }) => <Input {...field} value={field.value ?? ''} />}
            </FormController>
            <FormController
              control={control}
              name="resellerAddress.city"
              label="City"
              overrides={{ Root: { props: { className: 'col-span-2' } } }}
            >
              {({ field }) => <Input {...field} placeholder="Santee" />}
            </FormController>
            <FormController
              control={control}
              name="resellerAddress.country"
              label="Country"
              overrides={{ Root: { props: { className: 'col-span-2' } } }}
            >
              {({ field }) => (
                <CountrySelect
                  value={field.value}
                  onChange={({ option }) => {
                    field.onChange(option?.id);
                  }}
                />
              )}
            </FormController>
            <FormController
              control={control}
              name="resellerAddress.postalCode"
              label="Zip"
              overrides={{ Root: { props: { className: 'col-span-2' } } }}
            >
              {({ field }) => <Input {...field} placeholder="90271" />}
            </FormController>
            <FormController
              control={control}
              name="resellerAddress.state"
              label="State"
              overrides={{ Root: { props: { className: 'col-span-2' } } }}
            >
              {({ field }) => (
                <StateSelect
                  countryCode={resellerAddressCountryCode}
                  value={field.value}
                  onChange={({ option }) => {
                    field.onChange(option?.id);
                  }}
                />
              )}
            </FormController>
          </div>
        </div>
      </fieldset>
      <fieldset className="col-span-full space-y-7">
        <div className="flex items-center justify-between space-x-2">
          <legend className="text-xl font-semibold text-black">
            Billing Information
          </legend>
          <FormController
            control={control}
            name="sameAsResellerAddress"
            overrides={{
              ControlContainer: { style: { marginBottom: 0 } },
            }}
          >
            {({ field }) => (
              <StatefulCheckbox
                onChange={(e) => {
                  const checked = e.currentTarget.checked;
                  if (checked) {
                    prefillBillingAddress(getValues('resellerAddress'));
                  }
                  field.onChange(checked);
                }}
                overrides={
                  {
                    Checkmark: {
                      props: {
                        className:
                          'w-4 h-4 rounded border-2 border-brand-primary',
                      },
                    },
                    Label: {
                      props: { className: 'text-sm text-gray-500' },
                    },
                  } as CheckboxOverrides
                }
              >
                Same as reseller address
              </StatefulCheckbox>
            )}
          </FormController>
        </div>
        <div className="col-span-full grid grid-cols-4 gap-x-4">
          <FormController
            control={control}
            name="billingAddress.street"
            label="Address"
            overrides={{ Root: { props: { className: 'col-span-3' } } }}
          >
            {({ field }) => <Input {...field} placeholder="26 Park Avenue" />}
          </FormController>
          <FormController
            control={control}
            name="billingAddress.street2"
            label="Unit"
            overrides={{ Root: { props: { className: 'col-span-1' } } }}
          >
            {({ field }) => <Input {...field} value={field.value ?? ''} />}
          </FormController>
          <FormController
            control={control}
            name="billingAddress.city"
            label="City"
            overrides={{ Root: { props: { className: 'col-span-2' } } }}
          >
            {({ field }) => <Input {...field} placeholder="Santee" />}
          </FormController>
          <FormController
            control={control}
            name="billingAddress.country"
            label="Country"
            overrides={{ Root: { props: { className: 'col-span-2' } } }}
          >
            {({ field }) => (
              <CountrySelect
                value={field.value}
                onChange={({ option }) => {
                  field.onChange(option?.id);
                }}
              />
            )}
          </FormController>
          <FormController
            control={control}
            name="billingAddress.postalCode"
            label="Zip"
            overrides={{ Root: { props: { className: 'col-span-2' } } }}
          >
            {({ field }) => <Input {...field} placeholder="90271" />}
          </FormController>
          <FormController
            control={control}
            name="billingAddress.state"
            label="State"
            overrides={{ Root: { props: { className: 'col-span-2' } } }}
          >
            {({ field }) => (
              <StateSelect
                countryCode={billingAddressCountryCode}
                value={field.value}
                onChange={({ option }) => {
                  field.onChange(option?.id);
                }}
              />
            )}
          </FormController>
        </div>
      </fieldset>
      <FormController
        control={control}
        name="subdomain"
        label="Subdomain"
        caption="This will be used to configure the resellers' url"
      >
        {({ field }) => <Input {...field} placeholder="Enter Subdomain" />}
      </FormController>
      <FormController
        control={control}
        name="domain"
        label="Domain"
        caption="This will be used to add the resellers' domain"
      >
        {({ field }) => <Input {...field} placeholder="Enter Domain" />}
      </FormController>
      <FormController
        control={control}
        name="accountRepresentative"
        overrides={{ Root: { props: { className: 'col-span-full mt-12' } } }}
      >
        {({ field }) => (
          <AccountRepSelect
            representativeRoles={allowedRepresentatives}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      </FormController>
    </form>
  );
}

const ACCOUNT_REP_ROLES: UserRole[] = ['RootAdmin'];
function AccountRepSelect({
  value,
  representativeRoles = ACCOUNT_REP_ROLES,
  onChange: onChangeHandler,
}: {
  value?: string;
  representativeRoles?: UserRole[];
  onChange?: (value: string) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const query = useEmployeesQuery();

  return (
    <div className="flex items-center space-x-1">
      <div className=" font-prompt text-base text-gray-400">
        Represented By:
      </div>
      <div
        className={clsx('flex items-center space-x-4', {
          hidden: isEditing,
        })}
      >
        <span className="font-semibold text-brand-primary">
          {value ?? 'Select Representative'}
        </span>
        <nav>
          <Button
            kind="tertiary"
            size="mini"
            type="button"
            onClick={() => setIsEditing(true)}
          >
            <Edit size={16} />
          </Button>
        </nav>
      </div>
      <div
        className={clsx({
          hidden: !isEditing,
        })}
      >
        <QueryResolver query={query}>
          {(employees) => {
            const options: Option[] = employees
              .filter((employee) =>
                employee.roles.some((role) =>
                  representativeRoles.includes(role),
                ),
              )
              .map((employee) => ({
                label: `${employee.firstname} ${employee.lastname}`,
                id: `${employee.firstname} ${employee.lastname}`,
              }));
            return (
              <div className="flex items-center space-x-2">
                <div className="w-48">
                  <Select
                    options={options}
                    value={value ? [{ id: value }] : undefined}
                    kind="primary"
                    size="compact"
                    onChange={({ option }) => {
                      if (onChangeHandler) {
                        onChangeHandler(option?.id?.toString() ?? '');
                      }
                    }}
                  />
                </div>
                <Button
                  size="compact"
                  kind="tertiary"
                  type="button"
                  onClick={() => setIsEditing(false)}
                >
                  Confirm
                </Button>
              </div>
            );
          }}
        </QueryResolver>
      </div>
    </div>
  );
}
