import { Spinner } from 'baseui/spinner';
import NProgress from 'nprogress';
import React, { Suspense, useEffect, useMemo } from 'react';
import { RouterProvider as AriaRouterProvider } from 'react-aria-components';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  useNavigate,
  useNavigation,
} from 'react-router-dom';

import ErrorPageBoundary from 'features/ErrorPageBoundary';
import { useResellerBranding } from 'features/reseller';
import i18n from 'i18n-config';
import 'nprogress/nprogress.css';
import { BASE_SLUG as PARENT_RESELLER_ROOT_SLUG } from 'pages/parent-reseller/index';
import { useClientPropertiesQuery } from 'services/api/clients/endpoints';
import { currencyOptionSchema } from 'services/api/clients/types';
import { ResellerService } from 'services/api/resellers/endpoints';
import { UserRole } from 'services/api/types.shared';
import { initializeSession } from 'store/slices/auth';
import {
  initPreferences,
  setPreferenceValue,
} from 'store/slices/user-preferences';
import { useTypedDispatch, useTypedSelector } from 'store/store';
import theme from 'theme';
import { useGPSTrackItRevalidation } from 'utils/auth';
import { useLocale } from 'utils/hooks/useLocale';

function RequiresAuth({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, isInitialized } = useTypedSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isInitialized: state.auth.isSessionInitialized,
  }));

  if (!isInitialized) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner $color={theme.colors.primary} />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}

const BASE_ROUTE: Record<UserRole, string> = {
  Mobile: '/home',
  ClientAdmin: '/fs-admin',
  Coordinator: '/home',
  ResellerAdmin: '/res-admin',
  RootAdmin: '/kal-admin',
  ParentResellerAdmin: `/${PARENT_RESELLER_ROOT_SLUG}`,
};

function AuthorizeRole({
  children,
  authorizedRoles,
  fallbackSlug = '/logout',
}: {
  authorizedRoles: UserRole[];
  fallbackSlug?: string;
  children: React.ReactNode;
}) {
  /**
   * Based on the BE code the first index from the roles has the
   * highest access level.
   */
  const roles = useTypedSelector((state) => state.auth.roles);

  if (roles.length === 0) {
    return <Navigate to="/logout" />;
  }

  const role = roles[0];
  if (!authorizedRoles.includes(role)) {
    return <Navigate to={BASE_ROUTE[role]} />;
  }

  return <>{children}</>;
}

function LoadNamespace({
  children,
  namespaces,
}: {
  children: React.ReactNode;
  namespaces: Parameters<typeof useTranslation>[0];
}) {
  const { isLocaleReady } = useLocale(
    Array.isArray(namespaces)
      ? ['common', ...namespaces.filter((v) => v !== 'common')]
      : namespaces ?? 'common',
    { useSuspense: false },
  );

  if (!isLocaleReady) {
    return null;
  }
  return <>{children}</>;
}

function AriaRouterProviderWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const transition = useNavigation();
  useEffect(() => {
    // when the state is idle then we can to complete the progress bar
    if (transition.state === 'idle') NProgress.done();
    // and when it's something else it means it's either submitting a form or
    // waiting for the loaders of the next location so we start it
    else NProgress.start();
  }, [transition.state]);

  return (
    <AriaRouterProvider navigate={navigate}>{children}</AriaRouterProvider>
  );
}

const KalAdminRoutes = React.lazy(() => import('pages/kal-admin'));
const ParentResellerRoutes = React.lazy(() => import('pages/parent-reseller'));
const ResellerAdminRoutes = React.lazy(() => import('pages/reseller-admin'));
const FSAdminRoutes = React.lazy(() => import('pages/fs-admin'));

// Root
const Root = React.lazy(() => import('pages/root'));

// Auth
const ForgotPassword = React.lazy(() => import('pages/auth/forgot-password'));
const ResetPassword = React.lazy(() => import('pages/auth/reset-password'));
const Logout = React.lazy(() => import('pages/auth/logout'));
const GeotabAuth = React.lazy(() => import('pages/auth/sso/geotab'));

const requireNamespace = (ns: string | readonly string[]) => {
  return i18n.loadNamespaces(ns);
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <AriaRouterProviderWrapper>
          <Suspense
            fallback={
              <div className="flex h-screen w-full items-center justify-center">
                <Spinner $borderWidth={3} />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </AriaRouterProviderWrapper>
      }
      ErrorBoundary={
        process.env.NODE_ENV === 'production' ? ErrorPageBoundary : undefined
      }
    >
      <Route
        path={`/fs-admin/*`}
        element={
          <RequiresAuth>
            <AuthorizeRole authorizedRoles={['ClientAdmin']}>
              <LoadNamespace namespaces={['zod']}>
                <FSAdminRoutes />
              </LoadNamespace>
            </AuthorizeRole>
          </RequiresAuth>
        }
      />
      <Route
        path={`/res-admin/*`}
        element={
          <RequiresAuth>
            <AuthorizeRole authorizedRoles={['ResellerAdmin']}>
              <LoadNamespace namespaces={['zod', 'glossary', 'common']}>
                <ResellerAdminRoutes />
              </LoadNamespace>
            </AuthorizeRole>
          </RequiresAuth>
        }
      />
      <Route
        path={`/kal-admin/*`}
        element={
          <RequiresAuth>
            <AuthorizeRole authorizedRoles={['RootAdmin']}>
              <LoadNamespace namespaces={['zod', 'glossary', 'common']}>
                <KalAdminRoutes />
              </LoadNamespace>
            </AuthorizeRole>
          </RequiresAuth>
        }
      />
      <Route
        path={`/reseller-manager/*`}
        element={
          <RequiresAuth>
            <AuthorizeRole authorizedRoles={['ParentResellerAdmin']}>
              <LoadNamespace namespaces={['zod', 'glossary', 'common']}>
                <ParentResellerRoutes />
              </LoadNamespace>
            </AuthorizeRole>
          </RequiresAuth>
        }
      />
      <Route
        element={
          <LoadNamespace namespaces={['zod']}>
            <Outlet />
          </LoadNamespace>
        }
      >
        <Route path="/login" lazy={() => import('pages/auth/login')} />
        <Route
          path="login/mygeotab/sso/:database/:email/:sessionId"
          element={<GeotabAuth />}
        />
        <Route path="gpst" lazy={() => import('pages/auth/sso/gpstrackit')} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/logout"
          element={
            <RequiresAuth>
              <Logout />
            </RequiresAuth>
          }
        />
      </Route>
      <Route
        path="/estimates/review/:uuid"
        lazy={async () => {
          const {
            default: Component,
            loader,
            ErrorBoundary,
          } = await import('pages/estimates/review-estimate');
          return { Component, loader, ErrorBoundary };
        }}
      />
      <Route
        path="/my-tech/:scheduleItemId"
        lazy={async () => {
          const { default: Component, ErrorBoundary } = await import(
            'pages/my-tech/my-tech-notification'
          );
          return { Component, ErrorBoundary };
        }}
      />
      <Route
        element={
          <RequiresAuth>
            <AuthorizeRole
              authorizedRoles={['Mobile', 'Coordinator', 'ClientAdmin']}
            >
              <Root />
            </AuthorizeRole>
          </RequiresAuth>
        }
      >
        <Route index loader={() => redirect('/home')} />
        <Route
          path="/home"
          lazy={async () => {
            const { default: Component } = await import(
              'pages/dashboard/dashboard-index'
            );
            return { Component };
          }}
        />
        <Route
          path="/schedule"
          lazy={async () => {
            await requireNamespace(['zod']);
            return import('pages/schedule/schedule-index/route');
          }}
        />

        <Route path="/work-requests" element={<Outlet />}>
          <Route index loader={() => redirect('new')} />
          <Route
            path="new"
            loader={async () => {
              await requireNamespace(['zod', 'glossary']);
              return null;
            }}
            lazy={async () => {
              const { default: Component } = await import(
                'pages/work-requests/new-work-request/route'
              );
              return { Component };
            }}
          />
        </Route>
        <Route path="/jobs" element={<Outlet />}>
          <Route
            index
            lazy={async () => {
              const { default: Component } = await import(
                'pages/jobs/jobs-index'
              );
              return { Component };
            }}
          />
          <Route
            path=":jobId/edit"
            loader={async () => {
              await requireNamespace(['zod']);
              return null;
            }}
            lazy={async () => {
              const { default: Component } = await import(
                'pages/jobs/edit-job'
              );
              return { Component };
            }}
          />
        </Route>
        <Route path="/estimates" element={<Outlet />}>
          <Route
            index
            lazy={async () => {
              const { default: Component } = await import(
                'pages/estimates/estimates-index'
              );
              return { Component };
            }}
          />
          <Route
            path=":estimateId/edit"
            loader={async () => {
              await requireNamespace(['zod']);
              return null;
            }}
            lazy={async () => {
              const { default: Component } = await import(
                'pages/estimates/edit-estimate'
              );
              return { Component };
            }}
          />
        </Route>
        <Route path="/invoice" element={<Outlet />}>
          <Route
            index
            lazy={async () => {
              const { default: Component } = await import(
                'pages/invoice/invoice-index'
              );
              return { Component };
            }}
          />
          <Route
            path="new"
            loader={async () => {
              await requireNamespace('zod');
              return null;
            }}
            lazy={async () => {
              const { default: Component } = await import(
                'pages/invoice/new-invoice'
              );
              return { Component };
            }}
          />
          <Route
            path=":workRequestId/edit"
            loader={async () => {
              await requireNamespace('zod');
              return null;
            }}
            lazy={async () => {
              const { default: Component } = await import(
                'pages/invoice/edit-invoice'
              );
              return { Component };
            }}
          />
        </Route>
        <Route path="/customers" element={<Outlet />}>
          <Route
            index
            lazy={async () => {
              const { default: Component } = await import(
                'pages/customers/customers-index'
              );
              return { Component };
            }}
          />
          <Route
            path="new"
            loader={async () => {
              await requireNamespace('zod');
              return null;
            }}
            lazy={async () => {
              const { default: Component } = await import(
                'pages/customers/new-customer'
              );
              return { Component };
            }}
          />
          <Route
            path=":customerId"
            lazy={async () => {
              const { default: Component } = await import(
                'pages/customers/customer-details'
              );
              return { Component };
            }}
          >
            <Route
              path="new-note"
              loader={async () => {
                await requireNamespace('zod');
                return null;
              }}
              lazy={async () => {
                const { default: Component } = await import(
                  'pages/customers/new-note'
                );
                return { Component };
              }}
            />
          </Route>
          <Route
            path=":customerId/edit"
            loader={async () => {
              await requireNamespace('zod');
              return null;
            }}
            lazy={async () => {
              const { default: Component } = await import(
                'pages/customers/edit-customer'
              );
              return { Component };
            }}
          />
        </Route>
        <Route
          path="/maps"
          lazy={async () => {
            const { default: Component } = await import(
              'pages/maps/maps-index'
            );
            return { Component };
          }}
        >
          <Route
            index
            lazy={async () => {
              const { TrackedEmployees } = await import(
                'pages/maps/EmployeeTracking'
              );
              return { Component: TrackedEmployees };
            }}
          />
          <Route
            path="track/employees"
            lazy={async () => {
              const { TrackedEmployees } = await import(
                'pages/maps/EmployeeTracking'
              );
              return { Component: TrackedEmployees };
            }}
          />
          <Route
            path="track/vehicles"
            lazy={async () => {
              const { TrackedVehicles, vehiclesLoader } = await import(
                'pages/maps/VehicleTracking'
              );
              return { Component: TrackedVehicles, loader: vehiclesLoader };
            }}
          />
        </Route>
        <Route path="/reports" element={<Outlet />}>
          <Route
            index
            loader={async () => {
              await requireNamespace('zod');
              return null;
            }}
            lazy={async () => {
              const { default: Component } = await import(
                'pages/reports/reports-index'
              );
              return { Component };
            }}
          />
          <Route
            path="generate"
            loader={async () => {
              await requireNamespace('zod');
              return null;
            }}
            lazy={async () => {
              const { default: Component } = await import(
                'pages/reports/report-generate'
              );
              return { Component };
            }}
          />
          <Route
            path=":reportId"
            lazy={async () => {
              const { default: Component } = await import(
                'pages/reports/report-details'
              );
              return { Component };
            }}
          />
          <Route
            path="generate/custom"
            loader={async () => {
              await requireNamespace('zod');
              return null;
            }}
            lazy={async () => {
              const { default: Component } = await import(
                'pages/reports/generate-predefined-report'
              );
              return { Component };
            }}
          />
        </Route>
        <Route
          path="/telematics"
          lazy={() => import('pages/telematics-portal')}
        />
      </Route>
      <Route
        path="/*"
        element={
          <div className="text-center text-2xl font-bold text-brand-primary">
            Not found
          </div>
        }
      />
    </Route>,
  ),
  { basename: process.env.PUBLIC_URL },
);

const getSubdomain = (url: string): string | null => {
  const match = url.match(/https?:\/\/([A-Za-z_0-9.-]+).*/);
  if (match && match[1]) {
    return match[1].split('.')[0];
  }
  return null;
};

const useInitPreferences = () => {
  const dispatch = useTypedDispatch();
  const storedFavicon =
    useTypedSelector((s) => s.userPreferences.faviconB64) ?? '';
  const storedBusinessName =
    useTypedSelector((s) => s.userPreferences.businessName) ?? '';

  const subdomain = useMemo(() => getSubdomain(window.location.href) ?? '', []);
  const resellerAssets = ResellerService.endpoints.subdomainAssets.useQuery(
    { subdomain },
    { skip: !subdomain },
  ).data;
  const resellerName = resellerAssets?.businessName ?? storedBusinessName;
  const resellerFavicon = resellerAssets?.faviconB64 ?? storedFavicon;

  const query = useClientPropertiesQuery();
  const currency = currencyOptionSchema
    .catch('USD')
    .parse(
      query.data?.find((p) => p.propertyName === 'currencyPreference')
        ?.propertyValue,
    );

  useEffect(() => {
    dispatch(setPreferenceValue('currencyUsed', currency));
  }, [currency, dispatch]);

  useEffect(() => {
    if (document) {
      document.title = resellerName || 'Field Service Application';
      dispatch(setPreferenceValue('businessName', resellerName ?? ''));
    }
  }, [resellerName, dispatch]);

  useEffect(() => {
    if (resellerFavicon) {
      document
        .getElementById('site-favicon')
        ?.setAttribute('href', `data:image/x-icon;base64,${resellerFavicon}`);
      dispatch(setPreferenceValue('faviconB64', resellerFavicon ?? ''));
    }
  }, [resellerFavicon, dispatch]);
};

export default function App() {
  const dispatch = useTypedDispatch();
  useEffect(() => {
    dispatch(initPreferences());
    dispatch(initializeSession());
  }, [dispatch]);

  useInitPreferences();
  useResellerBranding();
  useGPSTrackItRevalidation();

  return (
    <Suspense fallback={null}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
