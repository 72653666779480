import React, { useId } from 'react';
import type { GroupProps, TextProps } from 'react-aria-components';
import { Group, GroupContext, LabelContext, Text } from 'react-aria-components';

import { cn } from 'utils/helpers';

function GroupSlots({ children }: { children: React.ReactNode }) {
  const labelId = useId();
  const descriptionId = useId();

  return (
    <LabelContext.Provider value={{ id: labelId }}>
      <GroupContext.Provider
        value={{
          slots: {
            root: {
              'aria-describedby': descriptionId,
              'aria-labelledby': labelId,
            },
            description: {
              id: descriptionId,
            },
          },
        }}
      >
        {children}
      </GroupContext.Provider>
    </LabelContext.Provider>
  );
}

export function GroupFieldRoot(props: GroupProps) {
  return (
    <GroupSlots>
      <Group {...props} slot={'root'} />
    </GroupSlots>
  );
}

function Description({ className, ...props }: TextProps) {
  return (
    <Text
      {...props}
      slot="description"
      elementType="p"
      className={cn('text-sm text-muted-foreground mt-1', className)}
    />
  );
}

export const GroupedField = Object.assign(GroupFieldRoot, {
  Description: Description,
});
