import { BaseProvider } from 'baseui';
import { SnackbarProvider } from 'baseui/snackbar';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import theme from './theme';
import './i18n-config';

const engine = new Styletron({ prefix: 'kl' });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyletronProvider value={engine}>
        <BaseProvider
          theme={theme}
          overrides={{
            AppContainer: {
              props: {
                className: 'font-sans',
              },
            },
          }}
        >
          <SnackbarProvider
            placement="bottomRight"
            defaultDuration={4500}
            overrides={{
              Root: {
                props: {
                  className:
                    'bg-white rounded-lg font-prompt border-2 border-gray-300 text-gray-700',
                },
              },
            }}
          >
            <App />
          </SnackbarProvider>
        </BaseProvider>
      </StyletronProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
