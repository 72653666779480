import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import { z } from 'zod';

import { AuthService } from 'services/api/auth/endpoints';
import { isMutationSuccess } from 'services/api/base-api';
import { logIn as setupCredentials } from 'store/slices/auth';
import { dispatch } from 'store/store';

export const jwtSchema = z.object({
  client_id: z.number(),
  reseller_id: z.number(),
  fsa_user_id: z.number(),
  exp: z.number(),
});

const gpsTrackItData = z.object({
  idToken: z.string().catch(''),
});
export const useGPSTrackItRevalidation = () => {
  useEffect(() => {
    const handler = async (event: MessageEvent) => {
      const domain = z
        .string()
        .catch('')
        .parse(event.origin.split('://')[1].split('.')[1]);
      const newToken = gpsTrackItData
        .catch({ idToken: '' })
        .parse(event.data).idToken;

      if (domain && domain === 'gpstrackit' && newToken) {
        const result = await dispatch(
          AuthService.endpoints.gpsTrackingLogin.initiate({
            cognitoAccessToken: newToken,
          }),
        );

        if (isMutationSuccess(result)) {
          const credentials = result.data;
          const parsed = jwtSchema.parse(jwt_decode(credentials.accessToken));
          dispatch(
            setupCredentials({
              accessToken: credentials.accessToken,
              userCredentials: {
                clientId: parsed.client_id,
                resellerId: parsed.reseller_id,
                fsaUserId: parsed.fsa_user_id,
              },
              refreshToken: credentials.refreshToken,
              expiresIn: Date.now() + parsed.exp,
              roles: credentials.roles,
            }),
          );
        }
      }
    };

    if (window) {
      window.addEventListener('message', handler);
    }

    return () => {
      if (window) {
        window.removeEventListener('message', handler);
      }
    };
  }, []);
};
