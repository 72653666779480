import React, { useEffect, useRef } from 'react';
import { XCircle } from 'react-feather';

import {
  Alert,
  AlertDescription,
  AlertFooter,
  AlertTitle,
} from 'components/ui/alert';
import { useLocale } from 'utils/hooks/useLocale';

export default function MuatationBanner({
  title,
  message,
  focusOnError = false,
  actions,
  show = true,
}: {
  title?: React.ReactNode;
  message: React.ReactNode;
  actions?: React.ReactNode;
  focusOnError?: boolean;
  show?: boolean;
}) {
  const { t } = useLocale();
  const bannerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (show && focusOnError) {
      bannerRef.current?.focus();
    }
  }, [show, focusOnError]);

  if (!show) return null;

  return (
    <Alert intent="negative" className="mb-4" tabIndex={0} ref={bannerRef}>
      <XCircle className="h-4 w-4" aria-hidden="true" />
      <div>
        <AlertTitle>{title ?? t('Error')}</AlertTitle>
        <AlertDescription>{message}</AlertDescription>
        {actions && <AlertFooter>{actions}</AlertFooter>}
      </div>
    </Alert>
    // <div className="mb-4 p-4">
    //   <div
    //     className="flex rounded-md bg-red-50 p-5"
    //     // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    //     tabIndex={0}
    //     role="alert"
    //     ref={bannerRef}
    //   >
    //     <div className="flex-shrink-0">

    //     </div>
    //     <div className="ml-3">
    //       <h3 className="text-sm font-semibold text-red-800">

    //       </h3>
    //       <div className="mt-2 text-sm font-medium text-red-700"></div>
    //       {!!actions && (
    //         <div className="mt-4">
    //           <div className="-mx-2 -my-1.5"></div>
    //         </div>
    //       )}
    //     </div>
    //   </div>
    // </div>
  );
}
