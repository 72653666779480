import { clsx, type ClassValue } from 'clsx';
import { format } from 'date-fns-tz';
import { parse, toSeconds } from 'iso8601-duration';
import prettyMilliseconds from 'pretty-ms';
import { twMerge } from 'tailwind-merge';

import i18n, {
  supportedDateFnsLocales,
  toSupportedLocaleValue,
} from 'i18n-config';
import { store } from 'store/store';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export const priceFormatter = (opts?: Intl.NumberFormatOptions) => {
  const locale = localStorage.getItem('i18nextLng') ?? 'en-US';
  const userPrefs = store.getState().userPreferences;
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency: userPrefs.currencyUsed,
    ...opts,
  });
};

const defaultDateToken = 'MMM dd, yyyy';
const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const formatDate = (date: Date, formatToken?: string) => {
  const resolvedLanguage = i18n.resolvedLanguage ?? 'en';
  const locale =
    supportedDateFnsLocales[toSupportedLocaleValue(resolvedLanguage)];

  return format(date, formatToken ?? defaultDateToken, {
    timeZone: TIMEZONE,
    locale,
  });
};

const defaultTimeToken = 'hh:mm aa';
export const formatTime = (date: Date, formatToken?: string) => {
  const resolvedLanguage = i18n.resolvedLanguage ?? 'en';
  const locale =
    supportedDateFnsLocales[toSupportedLocaleValue(resolvedLanguage)];

  return format(date, formatToken ?? defaultTimeToken, {
    timeZone: TIMEZONE,
    locale,
  });
};

export const formatDateTime = (date: Date, formatToken?: string) => {
  const resolvedLanguage = i18n.resolvedLanguage ?? 'en';
  const locale =
    supportedDateFnsLocales[toSupportedLocaleValue(resolvedLanguage)];

  return format(
    date,
    formatToken ?? `${defaultDateToken} ${defaultTimeToken}`,
    { timeZone: TIMEZONE, locale },
  );
};

export const toPayloadTimeFormat = (
  date: Date,
  options?: { includeSeconds: boolean },
) => formatTime(date, options?.includeSeconds ? 'HH:mm:ss' : 'HH:mm');
export const toPayloadDateFormat = (date: Date) =>
  formatDate(date, 'yyyy-MM-dd');

export const isoDurationToHumanReadable = (
  durationLikeString: string,
  options?: { verbose: boolean },
) => {
  try {
    const seconds = toSeconds(parse(durationLikeString));
    if (seconds === 0) {
      return '0s';
    }
    return prettyMilliseconds(seconds * 1000, {
      verbose: options?.verbose ?? false,
    });
  } catch (error) {
    return 'Invalid Duration';
  }
};

export function debounce<TFunc extends (...args: any[]) => void>(
  func: TFunc,
  wait?: number,
) {
  let timeout: ReturnType<typeof setTimeout>;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait ?? 300);
  };
}

export const stripUndefined = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  const result: { [key: string]: any } = {};
  for (const key in obj) {
    if (obj[key] !== undefined) {
      result[key] = obj[key];
    }
  }
  return result;
};
