import { getLocalTimeZone, parseDate, today } from '@internationalized/date';
import { isToday } from 'date-fns';
import { useState } from 'react';
import {
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  Heading,
  I18nProvider,
  ListBox,
  ListBoxItem,
  Button as Pressable,
  RangeCalendar,
} from 'react-aria-components';
import { ChevronLeft, ChevronRight, X } from 'react-feather';

import { Button } from 'components/Button';
import { cn, formatDate, toPayloadDateFormat } from 'utils/helpers';
import { useLocale } from 'utils/hooks/useLocale';

export default function AvailabilityCalendar({
  onSelected: onSelectedHandler,
  onRemovePlotted: onRemovePlottedHandler,
  value,
}: {
  value: Array<{ start: Date; end: Date }>;
  onSelected: (arg: { start: Date; end: Date }) => void;
  onRemovePlotted: (arg: { start: Date; end: Date }) => void;
}) {
  const { t, i18n } = useLocale();
  const [selectedDates, setSelectedDates] = useState<
    { start: Date; end: Date } | undefined
  >(undefined);

  return (
    <I18nProvider locale={i18n.language}>
      <RangeCalendar
        aria-label="Employee Time off"
        minValue={today(getLocalTimeZone())}
        value={
          selectedDates
            ? {
                start: parseDate(toPayloadDateFormat(selectedDates.start)),
                end: parseDate(toPayloadDateFormat(selectedDates.end)),
              }
            : null
        }
        isDateUnavailable={(date) =>
          value.some(
            (v) =>
              date.compare(parseDate(toPayloadDateFormat(v.start))) >= 0 &&
              date.compare(parseDate(toPayloadDateFormat(v.end))) <= 0,
          )
        }
        onChange={(value) =>
          setSelectedDates({
            start: value.start.toDate(getLocalTimeZone()),
            end: value.end.toDate(getLocalTimeZone()),
          })
        }
      >
        <header className="flex items-center text-gray-900">
          <Pressable
            slot="previous"
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <ChevronLeft className="h-5 w-5" aria-hidden="true" />
          </Pressable>
          <Heading className="flex-auto text-center text-sm font-semibold" />
          <Pressable
            slot="next"
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Next month</span>
            <ChevronRight className="h-5 w-5" aria-hidden="true" />
          </Pressable>
        </header>
        <CalendarGrid className="mt-5 w-full overflow-hidden rounded bg-white text-sm">
          <CalendarGridHeader>
            {(day) => (
              <CalendarHeaderCell>
                <div className="flex h-10 w-10 items-center justify-center text-gray-500">
                  <span>{day}</span>
                </div>
              </CalendarHeaderCell>
            )}
          </CalendarGridHeader>
          <CalendarGridBody>
            {(date) => (
              <CalendarCell date={date} className="">
                {(rp) => (
                  <span
                    className={cn(
                      'mx-auto w-10 h-10 flex items-center justify-center ',
                      {
                        'bg-brand-primary-600 text-white rounded':
                          // rp.isSelected && isToday(date.toDate(getTimezone())),
                          rp.isSelected,
                        'rounded-l-lg': rp.isSelectionStart,
                        'rounded-r-lg': rp.isSelectionEnd,
                        rounded: !rp.isSelected,
                        'bg-gray-50 text-gray-200': rp.isDisabled,
                        'line-through text-red-500': rp.isUnavailable,
                        'font-semibold': isToday(
                          date.toDate(getLocalTimeZone()),
                        ),
                      },
                    )}
                  >
                    {date.day}
                  </span>
                )}
              </CalendarCell>
            )}
          </CalendarGridBody>
        </CalendarGrid>
        <div className="mt-5 space-y-4">
          <ListBox className="space-y-2">
            {value.map((value) => (
              <ListBoxItem
                key={`${value.start.toISOString()}-${value.end.toISOString()}`}
                className="flex items-center justify-between rounded border bg-white px-4 py-2 text-sm text-gray-500"
              >
                <div>
                  {formatDate(value.start)} - {formatDate(value.end)}
                </div>
                <Button
                  size="mini"
                  shape="circle"
                  intent="danger"
                  className="h-5 w-5"
                  type="button"
                  onClick={() =>
                    onRemovePlottedHandler({
                      start: value.start,
                      end: value.end,
                    })
                  }
                  title={t('Remove Date')}
                >
                  <X className="h-3 w-3" />
                </Button>
              </ListBoxItem>
            ))}
          </ListBox>
          <div className="flex space-x-4">
            <Button
              size="compact"
              className="w-full"
              disabled={selectedDates === undefined}
              onClick={() => {
                if (selectedDates) {
                  onSelectedHandler(selectedDates);
                  setSelectedDates(undefined);
                }
              }}
            >
              {t('Confirm Selection')}
            </Button>
            <Button
              size="compact"
              disabled={selectedDates === undefined}
              onClick={() => setSelectedDates(undefined)}
            >
              {t('Clear')}
            </Button>
          </div>
        </div>
      </RangeCalendar>
    </I18nProvider>
  );
}
