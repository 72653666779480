import * as pagination from '@zag-js/pagination';
import { normalizeProps, useMachine } from '@zag-js/react';
import clsx from 'clsx';
import { useId, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { useLocale } from 'utils/hooks/useLocale';

const pageNumberSchema = z.coerce.number().min(1).catch(1);
const totalItemsSchema = z.coerce.number().catch(0);

export default function Pagination({
  id = 'table-pagination',
  currentPage,
  pageSize = 10,
  totalItems,
  onChange: onChangeHandler,
}: {
  id?: string;
  currentPage?: number;
  pageSize?: number;
  totalPages?: number;
  totalItems?: number;
  onChange?: (arg: {
    page: number;
    pageSize: number;
    // srcElement: HTMLElement | null;
  }) => void;
}) {
  const { t } = useLocale();

  const [searchParams] = useSearchParams();

  const currentParams = useMemo(() => {
    const _searchParams = new URLSearchParams(searchParams);
    _searchParams.delete('page');
    return _searchParams.toString();
  }, [searchParams]);

  // searchParams.forEach((value, key) => {
  //   if (key !== 'page') {
  //     currentParams += `&${key}=${value}`;
  //   }
  // });

  const isControlled = currentPage !== undefined;
  const initialCount = totalItemsSchema.parse(totalItems);

  const context: pagination.Context = {
    id: useId(),
    count: initialCount,
    page:
      currentPage === undefined
        ? pageNumberSchema.parse(searchParams.get('page'))
        : currentPage,
    pageSize,
    onPageChange(details) {
      if (onChangeHandler) {
        onChangeHandler(details);
      }
    },
  };

  const [state, send] = useMachine(pagination.machine(context), { context });
  const api = pagination.connect(state, send, normalizeProps);

  if (api.pages.length <= 1) {
    const count = totalItemsSchema.parse(totalItems);
    if (totalItems === undefined) {
      return null;
    }
    return (
      <div>
        <p className="font-prompt text-sm text-gray-400">
          {t('pagination_shown_all_items', { count })}
        </p>
      </div>
    );
  }

  return (
    <nav {...api.rootProps} className="relative z-0">
      <ul className="inline-flex items-center space-x-2">
        <li>
          <Link
            {...api.prevTriggerProps}
            to={
              isControlled
                ? // ? { hash: '#previous' }
                  { hash: '', search: `?${currentParams}` }
                : {
                    search: `?page=${api.isFirstPage ? 1 : api.previousPage}${
                      currentParams ? `&${currentParams}` : ''
                    }`,
                  }
            }
            title={t('Previous Page')}
            className="relative text-sm text-gray-400 hover:text-gray-600"
          >
            {t('Previous')}
          </Link>
        </li>
        {api.pages.map((page, i) => {
          if (page.type === 'page')
            return (
              <li key={page.value}>
                <Link
                  {...api.getItemProps(page)}
                  to={
                    isControlled
                      ? // ? { hash: `#${page.value}` }
                        { hash: ``, search: `?${currentParams}` }
                      : {
                          search: `?page=${page.value}${
                            currentParams ? `&${currentParams}` : ''
                          }`,
                        }
                  }
                  className={clsx(
                    'relative z-10 inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg border border-gray-200 text-sm hover:border-brand-primary hover:bg-brand-primary hover:text-white focus:z-20',
                    {
                      'z-10 text-white bg-brand-primary':
                        api.page === page.value,
                    },
                  )}
                >
                  {page.value}
                </Link>
              </li>
            );
          else
            return (
              <li key={`ellipsis-${i}`}>
                <span {...api.getEllipsisProps({ index: i })}>&#8230;</span>
              </li>
            );
        })}
        <li>
          <Link
            {...api.nextTriggerProps}
            to={
              isControlled
                ? // ? { hash: '#next' }
                  { hash: '', search: `?${currentParams}` }
                : {
                    search: `?page=${
                      api.isLastPage ? api.totalPages : api.nextPage
                    }${currentParams ? `&${currentParams}` : ''}`,
                  }
            }
            title={t('Next Page')}
            className="relative text-sm text-gray-400 hover:text-gray-600"
          >
            {t('Next')}
          </Link>
        </li>
      </ul>
    </nav>
  );
}
