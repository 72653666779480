/* eslint-disable react/prop-types */
'use client';

import {
  Transition as HeadlessTransition,
  TransitionChild as HeadlessTransitionChild,
} from '@headlessui/react';
import clsx from 'clsx';
import type React from 'react';
import { Fragment } from 'react';
import {
  Dialog as AriaDialog,
  Modal as AriaModal,
  ModalOverlay as AriaModalOverlay,
  ModalOverlayProps as AriaModalOverlayProps,
  DialogProps as AriaDialogProps,
  ButtonContext,
  HeadingProps,
} from 'react-aria-components';
import { X as XIcon } from 'react-feather';

import { Button } from './button';
import { Heading, Text } from './text';

const sizes = {
  xs: 'sm:max-w-xs',
  sm: 'sm:max-w-sm',
  md: 'sm:max-w-md',
  lg: 'sm:max-w-lg',
  xl: 'sm:max-w-xl',
  '2xl': 'sm:max-w-2xl',
  '3xl': 'sm:max-w-3xl',
  '4xl': 'sm:max-w-4xl',
  '5xl': 'sm:max-w-5xl',
};
export type DialogProps = {
  size?: keyof typeof sizes;
} & AriaModalOverlayProps &
  Pick<AriaDialogProps, 'role'>;
export function Dialog({
  size = 'lg',
  isDismissable = true,
  role,
  className,
  children,
  ...props
}: DialogProps) {
  // const _open = (ctx && ctx.) || open;

  return (
    <AriaModalOverlay
      {...props}
      isDismissable={isDismissable}
      className={clsx([
        // Base
        'group fixed inset-0 flex w-screen justify-center overflow-y-auto bg-zinc-950/25 p-2 focus:outline-0 dark:bg-zinc-950/50 sm:px-6 sm:py-8 lg:px-8 lg:py-16',

        // Transition
        'duration-100',

        // Enter
        'data-[entering]:animate-in data-[entering]:fade-in-0',

        // Exit
        'data-[exiting]:animate-out data-[exiting]:fade-out-0',
      ])}
    >
      {(renderProps) => (
        <HeadlessTransition
          appear
          as={Fragment}
          show={renderProps.state.isOpen}
        >
          <HeadlessTransitionChild
            className="fixed inset-0 w-screen overflow-y-auto pt-6 sm:pt-0"
            enter="ease-out duration-100"
            enterFrom="opacity-0 translate-y-12 sm:translate-y-0"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-12 sm:translate-y-0"
          >
            <div className="grid min-h-full grid-rows-[1fr_auto] justify-items-center sm:grid-rows-[1fr_auto_3fr] sm:p-4">
              <HeadlessTransitionChild
                as={AriaModal}
                isOpen
                className={clsx([sizes[size], 'row-start-2 w-full min-w-0'])}
                enter="ease-out duration-100"
                enterFrom="sm:scale-95"
                enterTo="sm:scale-100"
                leave="ease-in duration-100"
                leaveFrom="sm:scale-100"
                leaveTo="sm:scale-100"
              >
                <ButtonContext.Provider
                  value={{
                    slots: {
                      close: {
                        onPress: () => renderProps.state.close(),
                      },
                    },
                  }}
                >
                  <AriaDialog
                    role={role}
                    className={clsx(
                      className,
                      sizes[size],
                      'forced-colors:outline relative row-start-2 w-full min-w-0 rounded-t-3xl bg-white p-[--gutter] shadow-lg outline-none ring-1 ring-zinc-950/10 [--gutter:theme(spacing.8)] dark:bg-zinc-900 dark:ring-white/10 sm:mb-auto sm:rounded-xl',
                    )}
                  >
                    <>
                      {isDismissable && (
                        <div className="absolute right-0 top-0 flex justify-end p-2">
                          <Button
                            slot="close"
                            variant="plain"
                            aria-label="Close Dialog"
                          >
                            <XIcon data-slot="icon" />
                          </Button>
                        </div>
                      )}
                      {typeof children === 'function'
                        ? children(renderProps)
                        : children}
                    </>
                  </AriaDialog>
                </ButtonContext.Provider>
              </HeadlessTransitionChild>
            </div>
          </HeadlessTransitionChild>
        </HeadlessTransition>
      )}
    </AriaModalOverlay>
  );
}

export function DialogTitle({ ...props }: HeadingProps) {
  return <Heading {...props} slot="title" />;
}

export function DialogDescription({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return <Text {...props} className={clsx(className, 'text-pretty mt-2')} />;
}

export function DialogBody({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return <div {...props} className={clsx(className, 'mt-6')} />;
}

export function DialogActions({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      {...props}
      className={clsx(
        className,
        '*:w-full sm:*:w-auto mt-8 flex flex-col-reverse items-center justify-end gap-3 sm:flex-row',
      )}
    />
  );
}
