import {
  CreateEquipmentPayload,
  Equipment,
  EquipmentListParams,
  equipmentListParams,
} from './types';

import { baseAPI, toPaginatedList } from '../base-api';
import { PaginatedResponse } from '../types.shared';

import { stripUndefined } from 'utils/helpers';
import {
  toOptionalSchemaProperties,
  toSchemaWithDefaults,
} from 'utils/schemas';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

const undefinedToNull = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  const result: { [key: string]: any } = {};
  for (const key in obj) {
    if (obj[key] !== undefined) {
      result[key] = obj[key];
    } else {
      result[key] = null;
    }
  }
  return result;
};

const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    equipments: builder.query<
      PaginatedResponse<Equipment>,
      { params: Partial<EquipmentListParams> } | void
    >({
      query(arg) {
        const schema = toOptionalSchemaProperties(equipmentListParams);
        const params = stripUndefined(
          toSchemaWithDefaults(schema, {
            page: 1,
            limit: 20,
          }).parse(arg ? arg.params : {}),
        );

        return {
          url: 'equipment',
          params,
        };
      },
      transformResponse(baseQueryReturnValue) {
        return toPaginatedList(baseQueryReturnValue);
      },
      providesTags: (res) => (res ? [{ type: 'Equipments', id: 'list' }] : []),
    }),
    // This implementation is unstable and is not fully covered by RTK Query
    // DO NOT COPY this implmentation
    equipmentsMerged: builder.query<
      PaginatedResponse<Equipment>,
      { params: Partial<EquipmentListParams> } | void
    >({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const schema = toOptionalSchemaProperties(equipmentListParams);
        const params = stripUndefined(
          toSchemaWithDefaults(schema, {
            page: 1,
            limit: 20,
          }).parse(arg ? arg.params : {}),
        );

        const results = await Promise.all(
          Array.from({ length: params.page ?? 1 }).map((_, index) =>
            baseQuery({
              url: 'equipment',
              params: { ...params, page: index + 1 },
            }),
          ),
        );
        const error = results.find((r) => r.error)?.error;
        if (error) {
          return { error };
        }
        const mergedResults = results.reduce(
          (all, result) => [...all, ...(result.data as any).data],
          [] as any,
        );
        const lastResult =
          results.length > 1
            ? (results[results.length - 1].data as any)
            : (results[0].data as any);
        return {
          data: toPaginatedList({ ...lastResult, data: mergedResults }),
        };
      },
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        // currentCache.list.push(...newItems.list);
        return {
          ...currentCache,
          ...newItems,
          list: newItems.list,
        };
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.params.page !== previousArg?.params.page;
      },
      // transformResponse(baseQueryReturnValue) {
      //   return toPaginatedList(baseQueryReturnValue);
      // },
      providesTags: (res) =>
        res ? [{ type: 'Equipments', id: 'merged-list' }] : [],
    }),
    equipment: builder.query<Equipment, { equipmentId: number }>({
      query: (arg) => `equipment/${arg.equipmentId}`,
      providesTags: (res, err, arg) =>
        res ? [{ type: 'Equipments', id: arg.equipmentId }] : [],
    }),
    createEquipment: builder.mutation<
      Equipment,
      Optional<CreateEquipmentPayload, 'logoDatastoreId'>
    >({
      query(arg) {
        return { url: 'equipment', method: 'POST', body: undefinedToNull(arg) };
      },
      invalidatesTags: (res) => (res ? [{ type: 'Equipments' }] : []),
    }),
    updateEquipment: builder.mutation<
      Equipment,
      Optional<Equipment, 'logoDatastoreId'>
    >({
      query(arg) {
        return {
          url: `equipment/${arg.id}`,
          method: 'PUT',
          body: undefinedToNull(arg),
        };
      },
      invalidatesTags: (res) => (res ? [{ type: 'Equipments' }] : []),
    }),
    deleteEquipment: builder.mutation<Equipment, { equipmentId: number }>({
      query(arg) {
        return { url: `equipment/${arg.equipmentId}`, method: 'DELETE' };
      },
      invalidatesTags: (res) => (res ? [{ type: 'Equipments' }] : []),
    }),
  }),
  overrideExisting: false,
});

export const EquipmentService = endpoints;
