import { useEffect } from 'react';

import {
  CSS_VAR_KEYS,
  HexColorPallete,
  THEME_COLORS,
  THEME_VALUES,
  hexColorPalleteSchema,
  themeColorsSchema,
} from './constants';

import { useCurrentUser } from 'services/api/auth/endpoints';
import { useResellerProperties } from 'services/api/resellers/endpoints';
import { setPreferenceValue } from 'store/slices/user-preferences';
import { useTypedDispatch, useTypedSelector } from 'store/store';

export const applyTheme = (name: keyof typeof THEME_COLORS) => {
  const root = document.documentElement;
  const theme = THEME_VALUES[name];
  Object.keys(CSS_VAR_KEYS).forEach((key) => {
    root.style.setProperty(key, theme[key as keyof typeof CSS_VAR_KEYS]);
  });
};

export const toHexColorPallete = (hexPallete: unknown) =>
  hexColorPalleteSchema
    .refine((v) => Object.keys(v).length)
    .safeParse(JSON.parse(String(hexPallete || '{}')));
export const applyColorPallete = (pallete: HexColorPallete) => {
  const root = document.documentElement;
  Object.entries(pallete).forEach(([shade, code]) => {
    root.style.setProperty(`--brand-primary-${shade}`, code);
  });
};

export const useResellerBranding = () => {
  const resellerId = useCurrentUser().data?.resellerId ?? 0;
  const isAuthenticated = useTypedSelector(
    (state) => state.auth.isAuthenticated,
  );
  const query = useResellerProperties({
    names: ['theme', 'theme-hex-pallete'],
    resellerId: isAuthenticated && resellerId ? resellerId : 0,
  });
  const theme = useTypedSelector((s) => s.userPreferences.theme);
  const hexColorPallete = useTypedSelector(
    (s) => s.userPreferences.hexColorPallete,
    (a, b) => JSON.stringify(a ?? '{}') === JSON.stringify(b ?? '{}'),
  );
  const dispatch = useTypedDispatch();

  useEffect(() => {
    applyTheme(theme);
  }, [theme]);
  useEffect(() => {
    if (hexColorPallete && Object.keys(hexColorPallete)) {
      applyColorPallete(hexColorPallete);
    }
  }, [hexColorPallete]);

  useEffect(() => {
    if (query.isSuccess && !!query.data) {
      const validatedTheme = themeColorsSchema.safeParse(query.data.theme);
      const hexColorPallete = toHexColorPallete(
        query.data['theme-hex-pallete'],
      );
      if (hexColorPallete.success) {
        applyColorPallete(hexColorPallete.data);
        dispatch(setPreferenceValue('hexColorPallete', hexColorPallete.data));
      } else if (validatedTheme.success) {
        applyTheme(validatedTheme.data);
        dispatch(setPreferenceValue('theme', validatedTheme.data));
      } else {
        applyTheme('Blue');
      }
    }
  }, [query, dispatch]);
};
