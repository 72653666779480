import { z } from 'zod';

import { paginatedParamsSchema } from '../types.shared';

export const equipmentTypeSchema = z.nativeEnum({
  VEHICLE: 'VEHICLE',
  TOOL: 'TOOL',
} as const);
export type EquipmentType = z.infer<typeof equipmentTypeSchema>;

export const equipmentSchema = z.object({
  id: z.coerce.number(),
  name: z.string(),
  equipmentType: equipmentTypeSchema,
  identifier: z.string(),
  latitude: z.coerce.number(),
  longitude: z.coerce.number(),
  available: z.boolean(),
  description: z.string(),
  logoDatastoreId: z.coerce.number(),
  eventTimestamp: z.coerce.string().datetime().nullable(),
  gpsTimestamp: z.coerce.string().datetime().nullable(),
  dateCreated: z.coerce.string().datetime(),
  dateModified: z.coerce.string().datetime(),
  dateDeleted: z.coerce.string().datetime().nullable(),
});
export type Equipment = z.infer<typeof equipmentSchema>;

export const createEquipmentSchema = equipmentSchema.pick({
  name: true,
  equipmentType: true,
  identifier: true,
  available: true,
  description: true,
  logoDatastoreId: true,
});
export type CreateEquipmentPayload = z.infer<typeof createEquipmentSchema>;

export const equipmentListParams = paginatedParamsSchema.extend({
  type: equipmentTypeSchema,
  available: z.boolean(),
  sort: z.string(),
  order: z.nativeEnum({ asc: 'asc', desc: 'desc' } as const),
});
export type EquipmentListParams = z.infer<typeof equipmentListParams>;
