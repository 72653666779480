import { BaseQueryApi, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  BaseQueryExtraOptions,
  BaseQueryFn,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { z } from 'zod';

import {
  Estimate,
  EstimateAmounts,
  EstimateDetails,
  TrackedEstimateDetails,
  estimateDetailsSchema,
} from './types';

import {
  baseAPI,
  isMutationFailed,
  isMutationSuccess,
  toPaginatedList,
} from '../base-api';
import { PaginatedParams, PaginatedResponse } from '../types.shared';
import { endpoints as UtilitiesService } from '../utilities/endpoint';
import { endpoints as WorkRequestService } from '../work-requests/endpoints';
import { WorkRequestStatus } from '../work-requests/types';

const PAGINATION_DEFAULT_VALUE: PaginatedParams = { limit: 10, page: 1 };
const modifiableEstimateDetails = estimateDetailsSchema.omit({
  dateDeleted: true,
  dateModified: true,
  dateCreated: true,
  isDeleted: true,
});

const mutationOptionsSchema = z
  .object({
    invalidate: z.boolean().catch(true).default(true),
  })
  .partial()
  .default({
    invalidate: true,
  })
  .catch({
    invalidate: true,
  });
type MutationRequestOptions = {
  mutationOptions?: z.infer<typeof mutationOptionsSchema>;
};

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
type ModifiableEstimateDetails = z.infer<typeof modifiableEstimateDetails>;
type SaveEstimatePayload = Optional<
  ModifiableEstimateDetails,
  'id' | 'dateSent' | 'dateApproved'
> &
  MutationRequestOptions;
type WorkRequestEstimate = z.infer<typeof estimateDetailsSchema>;

const estimateEmailTypeSchema = z.nativeEnum({
  CUSTOMER_REVIEW: 'CUSTOMER_REVIEW',
  CUSTOMER_COPY: 'CUSTOMER_COPY',
} as const);
type EstimateEmailType = z.infer<typeof estimateEmailTypeSchema>;

const sendEstimatePayloadSchema = modifiableEstimateDetails
  .pick({
    dateSent: true,
    workRequestId: true,
  })
  .extend({
    dateSent: z.string(),
    emailType: estimateEmailTypeSchema.optional(),
  });
type SendEstimatePayload = z.infer<typeof sendEstimatePayloadSchema>;

export type EstimatesQueryParams = Partial<
  {
    search: string;
    dateFrom: string;
    dateTo: string;
    customerId: number;
    workRequestStatus: WorkRequestStatus;
  } & PaginatedParams
>;

const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    estimates: builder.query<
      PaginatedResponse<Estimate>,
      {
        params?: EstimatesQueryParams;
      } | void
    >({
      query: (args) => {
        const { params = {} } = args ?? {};

        const parsedParams = Object.entries(params)
          .filter(([key, value]) => Boolean(value))
          .reduce(
            (all, [key, value]) => ({ ...all, [key]: `${value}` }),
            {} as Record<string, string>,
          );

        return {
          url: `portal/estimates`,
          params: { ...PAGINATION_DEFAULT_VALUE, ...parsedParams },
        };
      },
      transformResponse(baseQueryReturnValue, meta, arg) {
        return toPaginatedList(baseQueryReturnValue);
      },
      providesTags: (response) =>
        response
          ? [
              { type: 'Estimates', page: response.pageNumber },
              { type: 'Estimates', id: 'list' },
            ]
          : [],
    }),
    estimate: builder.query<EstimateDetails, number>({
      query: (id) => `portal/estimates/${id}`,
      providesTags: (estimate) =>
        estimate
          ? [{ type: 'Estimates', id: estimate.workRequestDetails.id }]
          : [],
    }),
    trackedEstimate: builder.query<
      TrackedEstimateDetails,
      { trackingUUID: string }
    >({
      query(arg) {
        return { url: `estimate-tracking/${arg.trackingUUID}` };
      },
      providesTags: [{ type: 'Estimates', id: 'tracked-estimate' }],
    }),
    saveEstimate: builder.mutation<WorkRequestEstimate, SaveEstimatePayload>({
      async queryFn(payload, api, extraOptions, baseQuery) {
        return saveEstimate(payload, {
          api,
          baseQuery,
          extraOptions,
        });
      },
      invalidatesTags: (estimate, err, payload) => {
        const options = mutationOptionsSchema.parse(payload.mutationOptions);
        return estimate && options.invalidate
          ? [{ type: 'Estimates', id: estimate.workRequestId }]
          : [];
      },
    }),
    sendEstimate: builder.mutation<EstimateAmounts, SendEstimatePayload>({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const estimateResult = await baseQuery(
          `portal/estimates/${arg.workRequestId}`,
        );
        if (estimateResult.error) {
          return {
            error: estimateResult.error,
          };
        }
        const validated = estimateDetailsSchema.safeParse(
          (estimateResult.data as any).estimate,
        );

        if (!validated.success) {
          return {
            error: {
              status: 'FETCH_ERROR',
              error: 'Failed to parse invoice response',
            },
          };
        }
        const estimate = validated.data;
        return sendEstimateEmail(
          { ...estimate, dateSent: arg.dateSent, emailType: arg.emailType },
          { api, baseQuery, extraOptions },
        );
      },
      invalidatesTags: (id, err, payload) => {
        return payload.workRequestId
          ? [{ type: 'Estimates', id: payload.workRequestId }]
          : [];
      },
    }),
  }),
  overrideExisting: false,
});

const hasErrorInResult = (
  response: unknown,
): response is { error: FetchBaseQueryError } => {
  if ('error' in (response as any)) return true;
  return false;
};

async function saveEstimate(
  payload: SaveEstimatePayload,
  queryFnArgs: {
    api: BaseQueryApi;
    extraOptions: BaseQueryExtraOptions<BaseQueryFn>;
    baseQuery: (arg: Parameters<BaseQueryFn>[0]) => ReturnType<BaseQueryFn>;
  },
) {
  const { baseQuery } = queryFnArgs;
  const result = await baseQuery({
    url: payload.id ? `estimates/${payload.id}` : 'estimates',
    method: payload.id ? 'PUT' : 'POST',
    body: payload,
  });

  const validated = estimateDetailsSchema.safeParse(result.data);
  if (!validated.success) {
    return {
      error: {
        status: 'CUSTOM_ERROR',
        error: 'Failed to parse response data',
      } as FetchBaseQueryError,
    };
  }
  if (result.error) {
    return { error: result.error as FetchBaseQueryError };
  }
  return { data: validated.data };
}

async function sendEstimateEmail(
  estimate: WorkRequestEstimate & { emailType?: EstimateEmailType },
  queryFnArgs: {
    api: BaseQueryApi;
    extraOptions: BaseQueryExtraOptions<BaseQueryFn>;
    baseQuery: (arg: Parameters<BaseQueryFn>[0]) => ReturnType<BaseQueryFn>;
  },
) {
  const emailType = estimate.emailType ?? 'CUSTOMER_REVIEW';

  const { api, baseQuery } = queryFnArgs;
  const workRequestResult = await api.dispatch(
    WorkRequestService.endpoints._work_request.initiate(estimate.workRequestId),
  );
  if (!isMutationSuccess(workRequestResult)) {
    return { error: workRequestResult.error as FetchBaseQueryError };
  }
  const workRequestObject = workRequestResult.data;

  const results = await Promise.all([
    // Update Work Request status

    baseQuery({
      url: `work-requests/${estimate.workRequestId}`,
      method: 'PUT',
      body: {
        ...workRequestObject,
        /**
         * Pipeline
         *  EstCreated -> EstScheduled -> EstStarted -> EstCompleted -> EstSent -> EstApproved
         *
         * Since we allow editing of estimate before starting it(EstStarted), we only update its status to sent(EstSend) if its
         * already completed(EstCompleted)
         */
        /**
         * We only update the Work Request's status to `EstSent`
         * when we are sending it to the customer to review. Otherwise, we leave it as is
         */
        ...(emailType === 'CUSTOMER_REVIEW'
          ? {
              status: 'EstSent',
              prevStatus: 'EstCompleted',
            }
          : {}),
      },
    }),
    // Update Estimate `Sent` date
    baseQuery({
      url: `estimates/${estimate.id}`,
      method: 'PUT',
      body: estimate,
    }),
  ]);
  const failedRequest = results.find(hasErrorInResult);
  if (failedRequest) {
    return { error: failedRequest.error };
  }

  const validated = estimateDetailsSchema.safeParse(results[1].data);
  if (!validated.success) {
    return {
      error: {
        status: 'CUSTOM_ERROR',
        error: 'Failed to parse estimate response data',
      } as FetchBaseQueryError,
    };
  }

  if (emailType === 'CUSTOMER_COPY') {
    // Send Customer a copy of their estimate
    await api.dispatch(
      UtilitiesService.endpoints.sendEmail.initiate({
        workRequestId: estimate.workRequestId,
        templateType: 'Estimate',
      }),
    );
  } else {
    // Send estimate to customer for review
    await baseQuery({
      url: `estimate-email-link/${estimate.workRequestId}`,
      method: 'POST',
    });
  }

  return { data: validated.data };
}

export const {
  useEstimatesQuery,
  useEstimateQuery,
  useSaveEstimateMutation,
  useSendEstimateMutation,
} = endpoints;
export { endpoints as EstimateService };
