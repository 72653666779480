import { Skeleton } from 'baseui/skeleton';
import { useSnackbar } from 'baseui/snackbar';
import { useState } from 'react';
import { DialogTrigger } from 'react-aria-components';
import { Check, Plus, Trash } from 'react-feather';
import { useForm } from 'react-hook-form';
import { Outlet } from 'react-router';
import { z } from 'zod';

import { Description } from 'components/forms/fieldset';
import { createTableBuilder } from 'components/Table';
import { AlertTitle } from 'components/ui/alert';
import {
  AlertActions,
  AlertDescription,
  AlertDialog,
  AlertTitle as AlertDialogTitle,
} from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/ui/card';
import { Switch, SwitchField } from 'components/ui/switch';
import { Heading } from 'components/ui/text';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { useCurrentUser } from 'services/api/auth/endpoints';
import { isMutationSuccess } from 'services/api/base-api';
import {
  useClientQuery,
  useEditClientMutation,
} from 'services/api/clients/endpoints';
import {
  useEditEmployeeMutation,
  useEmployeesQuery,
} from 'services/api/employees/endpoints';
import { Employee } from 'services/api/employees/types';
import { useSkillsQuery } from 'services/api/skills/endpoints';
import { Skill } from 'services/api/skills/types';
import { UserRole } from 'services/api/types.shared';
import { useTypedSelector } from 'store/store';
import { useLocale } from 'utils/hooks/useLocale';

export default function EmployeesIndex() {
  const { t } = useLocale();
  return (
    <>
      <Outlet />
      <div className="flex-1 space-y-5">
        <DashboardContentHeader
          title={t('Employees')}
          description={t('Manage your employees')}
        />
        <section className="flex flex-col gap-4 lg:flex-row lg:items-start">
          <div className="w-full">
            <EmployeesTable />
          </div>
          <aside className="shrink-0 lg:w-96">
            <Card>
              <CardHeader>
                <CardTitle>{t('Skillsets')}</CardTitle>
                <CardDescription>{t('skillets_description')}.</CardDescription>
              </CardHeader>
              <CardContent>
                <Skillsets />
              </CardContent>
              <CardFooter>
                <Button
                  href="new-skillset"
                  startEnhancer={<Plus />}
                  className="w-full"
                >
                  {t('New Skillset')}
                </Button>
              </CardFooter>
            </Card>
          </aside>
        </section>
      </div>
    </>
  );
}

const { Table, Column } = createTableBuilder<Employee>();
const ALLOWED_EMPLOYEES: UserRole[] = ['Coordinator', 'Mobile'];
function EmployeesTable() {
  const { t } = useLocale();
  const query = useEmployeesQuery();
  const employees =
    query.data?.filter((employee) =>
      employee.roles.every((role) => ALLOWED_EMPLOYEES.includes(role)),
    ) ?? [];

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between">
          <CardTitle>{t('Employees')}</CardTitle>
          <nav className="flex items-center space-x-4">
            <Button href="new" startEnhancer={<Plus />} className="shrink-0">
              {t('New Employee')}
            </Button>
          </nav>
        </div>
      </CardHeader>
      <CardContent>
        <Table
          data={employees}
          onRefetch={query.refetch}
          loadingMessage={<Skeleton rows={4} height="100px" />}
          isLoading={query.isLoading}
          isError={query.isError}
          isEmpty={query.isSuccess && employees.length === 0}
        >
          <Column header={t('ID#')}>{(row) => row.id}</Column>
          <Column header={t('Client')}>
            {(row) => `${row.firstname} ${row.lastname}`}
          </Column>
          <Column header={t('Phone Number')}>{(row) => row.phone}</Column>
          <Column header={t('Skillset')}>
            {(row) => {
              const excessSkills = row.skills.length - 1;
              return row.skills.length ? (
                <div className="flex flex-wrap gap-1">
                  <div>
                    {row.skills
                      .slice(0, 1)
                      .map((item) => item.name)
                      .join(',')}
                  </div>
                  {excessSkills !== 0 && (
                    <span className="font-medium text-zinc-900">
                      + {excessSkills} more
                    </span>
                  )}
                </div>
              ) : (
                '---'
              );
            }}
          </Column>
          {/* <Column header={t("Crew")}>{(row) => row.crew}</Column> */}
          <Column header={t('Account Status')}>
            {(row) => (
              <div className="flex">
                <AccountStatusToggle
                  employee={row}
                  defaultValues={{
                    accessAllowed: row.isEnabled,
                    id: row.id,
                  }}
                />
              </div>
            )}
          </Column>
          <Column header={t('Action')}>
            {(row) => (
              <nav>
                <Button href={`${row.id}`} variant="plain">
                  {t('View')}
                </Button>
              </nav>
            )}
          </Column>
        </Table>
      </CardContent>
    </Card>
  );
}

const employeeAccessFormSchema = z.object({
  accessAllowed: z.boolean().optional().default(false),
  id: z.number(),
});

type AccountStatusFormFields = z.infer<typeof employeeAccessFormSchema>;

function AccountStatusToggle({
  employee,
  defaultValues,
}: {
  employee: Employee;
  defaultValues?: Partial<AccountStatusFormFields>;
}) {
  const form = useForm<AccountStatusFormFields>({
    defaultValues,
  });
  const { t } = useLocale();
  const [editEmployee] = useEditEmployeeMutation();
  const snackbar = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const onSubmit = form.handleSubmit(async (data) => {
    const result = await editEmployee({
      ...employee,
      isEnabled: data.accessAllowed,
      id: data.id,
    });

    if (isMutationSuccess(result)) {
      setIsOpen(false);
      snackbar.enqueue({
        message: t('employee_access_updated_successfully'),
      });
    } else {
      snackbar.enqueue({
        message: t('employee_access_update_failed'),
      });
    }
  });

  const isSubmitting = form.formState.isSubmitting;

  return (
    <form onSubmit={onSubmit} id={`update-employee-${employee.id}-access-form`}>
      <SwitchField control={form.control} field="accessAllowed">
        <DialogTrigger
          isOpen={isOpen}
          onOpenChange={(isOpenValue) => {
            setIsOpen(isOpenValue);
            if (!isOpenValue) {
              form.setValue('accessAllowed', !form.getValues().accessAllowed);
            }
          }}
        >
          <Switch />
          <AlertDialog>
            <AlertDialogTitle>
              {defaultValues?.accessAllowed
                ? t('alert_dialog_title_employee_access.deactivate')
                : t('alert_dialog_title_employee_access.activate')}
            </AlertDialogTitle>
            <AlertDescription>
              {defaultValues?.accessAllowed
                ? t('alert_dialog_description_employee_access.disable')
                : t('alert_dialog_description_employee_access.enable')}
            </AlertDescription>
            <AlertActions>
              <Button variant="plain" slot="close" isDisabled={isSubmitting}>
                {t('Cancel')}
              </Button>
              <Button
                color={defaultValues?.accessAllowed ? 'red' : 'green'}
                type="submit"
                isLoading={isSubmitting}
                form={`update-employee-${employee.id}-access-form`}
              >
                {defaultValues?.accessAllowed
                  ? t('button_employee_access.deactivate')
                  : t('button_employee_access.activate')}
              </Button>
            </AlertActions>
          </AlertDialog>
        </DialogTrigger>
      </SwitchField>
    </form>
  );
}

const { Table: SkillsetTable, Column: SkillsetColumn } =
  createTableBuilder<Skill>();

function Skillsets() {
  const id = useCurrentUser().data?.clientId ?? 0;
  const query = useSkillsQuery({ employeeId: id }, { skip: id === 0 });
  const { t } = useLocale();
  return (
    <SkillsetTable
      data={query.data ?? []}
      isLoading={query.isLoading}
      loadingMessage={<Skeleton rows={4} height="100px" />}
      isError={query.isError}
      isEmpty={query.isSuccess && query.data.length === 0}
    >
      <SkillsetColumn header={t('Skillset Name')}>
        {(row) => (
          <div>
            <Heading>{row.name}</Heading>
            {!!row.description && (
              <Description className="w-40 truncate line-clamp-2">
                {row.description}
              </Description>
            )}
          </div>
        )}
      </SkillsetColumn>
      <SkillsetColumn>{(row) => <DeleteSkill skill={row} />}</SkillsetColumn>
    </SkillsetTable>
  );
}

function DeleteSkill({ skill }: { skill: Skill }) {
  const id = useCurrentUser().data?.clientId ?? 0;
  const query = useClientQuery(id, { skip: id === 0 });
  const [editClient, mutation] = useEditClientMutation();
  const { enqueue } = useSnackbar();
  const resellerId = useTypedSelector((s) => s.auth.userCredentials.resellerId);
  const { t } = useLocale();

  const handleClick = async () => {
    if (query.data) {
      const result = await editClient({
        ...query.data,
        resellerId,
        masterSkills: query.data.masterSkills.filter(
          (masterSkill) =>
            masterSkill.name.toLowerCase().trim() !==
            skill.name.toLowerCase().trim(),
        ),
      });
      if (isMutationSuccess(result)) {
        enqueue({
          // message: 'Skill Deleted',
          message: t('skill_deleted_successfully'),
          startEnhancer: ({ size }) => <Check size={size} />,
        });
      } else {
        enqueue({
          message: t('skill_delete_error'),
          startEnhancer: ({ size }) => <Check size={size} />,
        });
      }
    }
  };
  return (
    <DialogTrigger>
      <Button
        type="button"
        variant="plain"
        isDisabled={query.isLoading || mutation.isLoading}
      >
        <Trash data-slot="icon" />
      </Button>
      <AlertDialog>
        <AlertTitle>{t('Delete Skill')}</AlertTitle>
        <AlertDescription>
          {/* Are you sure you want to delete this item? This action cannot be
          undone. */}
          {t('alert_dialog_generic_message')}
        </AlertDescription>
        <AlertActions>
          <Button variant="plain" slot="close">
            {t('Cancel')}
          </Button>
          <Button
            color="red"
            onClick={handleClick}
            isLoading={mutation.isLoading}
          >
            {t('Delete')}
          </Button>
        </AlertActions>
      </AlertDialog>
    </DialogTrigger>
  );
}
