import type { Option } from 'baseui/select';

import { Select, SelectProps } from 'components/Select';
import { UtilityService } from 'services/api/utilities/endpoint';
import { Country } from 'services/api/utilities/types';
import { useTypedSelector } from 'store/store';

export function StateSelect(
  props: Omit<SelectProps, 'value'> & {
    value?: string;
    countryCode: string;
    useClientCountryQuery?: boolean;
  },
) {
  const { value, useClientCountryQuery, countryCode, ...selectProps } = props;

  const clientId = useTypedSelector((s) => s.auth.userCredentials.clientId);

  const { data: countries = [] } = useClientCountryQuery
    ? UtilityService.endpoints.countryByClientId.useQuery(clientId)
    : UtilityService.endpoints.country.useQuery();

  const selectedCountry = countries.find(
    (country: Country) => country.code === countryCode,
  );

  const subdivisions = selectedCountry ? selectedCountry.subdivisions : [];

  const options: Option[] = subdivisions.map((subdivision) => ({
    label: subdivision.name,
    id: subdivision.code,
  }));

  const selected = options.filter((item) =>
    item.id ? value?.includes(item.id.toString()) : false,
  );

  return (
    <Select
      {...selectProps}
      value={selected}
      options={options}
      creatable={false}
      disabled={!countryCode}
    />
  );
}
