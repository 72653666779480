import { z } from 'zod';

import { WORK_REQUEST_STATUS_VALUES } from '../constants';
import { skillschema } from '../skills/types';

export const workRequestStatusSchema = z.nativeEnum(WORK_REQUEST_STATUS_VALUES);
export type WorkRequestStatus = z.infer<typeof workRequestStatusSchema>;

const DAY_CONSTRAINT_VALUES = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
} as const;
export const dayConstraintSchema = z.nativeEnum(DAY_CONSTRAINT_VALUES);
export type DayConstraint = z.infer<typeof dayConstraintSchema>;

export const workRequestSchema = z.object({
  id: z.number(),
  servLocId: z.number(),
  estimateId: z.number().nullable(),
  invoiceId: z.number().nullable(),
  description: z.string(),
  num: z.number(),
  estimateRequired: z.boolean(),
  estimateDuration: z.string(),
  notes: z.string(),
  photoIds: z.array(z.number()),
  prefTechId: z.number().nullable(),
  recurring: z.boolean(),
  prevStatus: z.string(),
  status: workRequestStatusSchema,
  skills: skillschema.array(),
  dateConstraints: dayConstraintSchema.array(),
  timeConstraintType: z.literal('Before').or(z.literal('After')).nullable(),
  timeConstraint: z.string().nullable(),
  dateCreated: z.string().datetime(),
  dateModified: z.string().datetime(),
  isDeleted: z.boolean(),
  dateDeleted: z.string().datetime().nullable(),
});
export type WorkRequest = z.infer<typeof workRequestSchema>;
export const modifiableWorkRequestPropertiesSchema = workRequestSchema.omit({
  dateCreated: true,
  dateModified: true,
  isDeleted: true,
  dateDeleted: true,
});
export type ModifiableWorkRequestProperties = z.infer<
  typeof modifiableWorkRequestPropertiesSchema
>;

export type LineItem = {
  id: number;
  workRequestId: number;
  name: string;
  description: string;
  duration: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  isTaxable: boolean;
  actualDuration: string | null;
  actualQuantity: number | null;
  actualTotalPrice: number | null;
  type: 'INVENTORY' | 'SERVICE';
  workTypeId?: number | null;
};
