import {
  CreateCustomerPayload,
  Customer,
  CustomerAddressQueryParams,
  CustomerQueryParams,
  UpdateCustomerPayload,
  UploadCsv,
  UploadCsvStatus,
} from './types';

import { baseAPI, toPaginatedList } from '../base-api';
import { Address, PaginatedParams, PaginatedResponse } from '../types.shared';

const PAGINATION_DEFAULT_VALUE: PaginatedParams = { limit: 10, page: 1 };

const customerAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    customers: builder.query<
      PaginatedResponse<Customer>,
      {
        params?: CustomerQueryParams;
      } | void
    >({
      query: (args) => {
        const { params = {} } = args ?? {};
        const parsedParams = Object.entries(params)
          .filter(([key, value]) => Boolean(value))
          .reduce(
            (all, [key, value]) => ({ ...all, [key]: `${value}` }),
            {} as Record<string, string>,
          );
        return {
          url: 'customers',
          params: { ...PAGINATION_DEFAULT_VALUE, ...parsedParams },
        };
      },
      providesTags: ['Customers'],
      transformResponse(
        baseQueryReturnValue,
        meta: { request: Request; response?: Response },
        arg,
      ) {
        return toPaginatedList(baseQueryReturnValue, meta.response?.headers);
      },
    }),
    customersAddress: builder.query<
      Address[],
      { params?: CustomerAddressQueryParams } | void
    >({
      query: (args) => {
        const { params = {} } = args ?? {};
        const filteredParams = Object.entries(params)
          .filter(([key, value]) => Boolean(value))
          .reduce(
            (all, [key, value]) => ({ ...all, [key]: `${value}` }),
            {} as Record<string, string>,
          );

        return {
          url: 'customers/address/search',
          params: filteredParams,
        };
      },
      providesTags: ['Customers'],
      transformResponse(baseQueryReturnValue: unknown): Address[] {
        return baseQueryReturnValue as Address[];
      },
    }),
    customer: builder.query<Customer, number>({
      query: (id) => ({
        url: `customers/${id}`,
      }),
      providesTags: (customer) =>
        customer ? [{ type: 'Customers', id: customer?.id }] : [],
    }),
    addCustomer: builder.mutation<Customer, CreateCustomerPayload>({
      query: (payload) => ({
        url: 'customers',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Customers'],
    }),
    editCustomer: builder.mutation<Customer, UpdateCustomerPayload>({
      query: (payload) => ({
        url: `customers/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (customer) =>
        customer ? ['Customers', { type: 'Customers', id: customer.id }] : [],
    }),
    uploadCsv: builder.mutation<UploadCsv, FormData>({
      query: (formData) => ({
        url: 'customers/upload',
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Customers'],
    }),
    uploadCsvStatus: builder.query<UploadCsvStatus[], void>({
      query: () => 'customers/upload',
      providesTags: (result) => (result ? ['Customers'] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCustomersQuery,
  useCustomersAddressQuery,
  useCustomerQuery,
  useAddCustomerMutation,
  useEditCustomerMutation,
  useUploadCsvMutation,
  useUploadCsvStatusQuery,
} = customerAPI;
